import React, { memo } from "react"
import "./ChannelNode.scss"
import CommonNode from "../Common/CommonNode"
import { ChannelPayload, ScenarioBlockType } from "../../../../models/scenario"
import { NodeComponentProps } from "react-flow-renderer/dist/nocss/types"
import { Channel } from "../../../../models/channel"
import { useTranslation } from "react-i18next"
import ChannelIconLarge from "../../../ChannelIconLarge/ChannelIconLarge"
import { Handle, Position } from "react-flow-renderer"

export interface ChannelNodeData extends ChannelPayload {
    Channel?: Channel
    AddBlock: (source: string, sourceHandle: string) => void
}

type Props = NodeComponentProps<ChannelNodeData>

const ChannelNode: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { data, id, isConnectable } = props

    return (
        <CommonNode
            id={id}
            className="channel-node"
            headerClassName="channel-node__header"
            type={ScenarioBlockType.Channel}
            isConnectable={isConnectable}
        >
            <div className="channel-node__content">
                <div className="channel-node__channel">
                    <div className="channel-node__icon">
                        <ChannelIconLarge type={data.ChannelType} />
                    </div>
                    <div className="channel-node__channel-content">
                        <span className="channel-node__title">{data.Channel?.Title ?? data.ChannelId}</span>
                        <span className="channel-node__type">{t(`channel:channel-type.${data.ChannelType}`)}</span>
                    </div>
                </div>
                <Handle
                    id={id}
                    type="source"
                    position={Position.Right}
                    className="channel-node__source-handle"
                    onClick={() => data.AddBlock(id, id)}
                    isConnectable={isConnectable}
                />
            </div>
        </CommonNode>
    )
}

export default memo(ChannelNode)
