import React from "react"
import "./RadioButtonValidatableInput.scss"
import { Form } from "react-bootstrap"
import { useField } from "formik"
import cn from "classnames"
import { ValidatableInputProps } from "../ValidatableInput/ValidatableInput"

export interface RadioButtonValidatableInputProps extends ValidatableInputProps {
    children?: never
    disabled?: boolean
    value: string
    inline?: boolean
}

const RadioButtonValidatableInput: React.FC<RadioButtonValidatableInputProps> = props => {
    const [field, meta] = useField<any>(props)
    const { id, className, label, disabled, value, inline, icon } = props

    const formLabel = (label || icon) && (
        <>
            {label}
            {icon}
        </>
    )

    return (
        <Form.Check
            custom
            inline={inline}
            value={value}
            disabled={disabled}
            type="radio"
            id={`${id}-input`}
            label={formLabel}
            name={field.name}
            onChange={field.onChange}
            onBlur={field.onBlur}
            checked={field.value === value}
            isInvalid={meta.touched && !!meta.error}
            className={cn("radio-input", className)}
        />
    )
}

export default RadioButtonValidatableInput
