import React from "react"
import useDelay from "../../utility/common/useDelay"
import Skeleton from "../Skeleton/Skeleton"
import "./ArticleCommentsView.scss"
import ArticleCommentComponentLoader from "../ArticleComment/ArticleCommentComponentLoader"

const ArticleCommentsViewLoader: React.FC = () => {
    const display = useDelay()
    if (!display) return null

    return (
        <div className="article-comments-view article-comments-view_loader">
            <div className="article-comments-view__input-container">
                <Skeleton />
            </div>
            {new Array<number>(2).fill(0).map((_, i) => (
                <ArticleCommentComponentLoader key={i} />
            ))}
        </div>
    )
}

export default ArticleCommentsViewLoader
