import React, { useMemo } from "react"
import { FieldArray, FormikProps } from "formik"
import { WithT } from "i18next"
import { Form } from "react-bootstrap"
import { preventSubmitOnEnter } from "../../../utility/common/preventSubmitOnEnter"
import { formTranslation } from "../../../locales/form"
import LoadingButton from "../../LoadingButton/LoadingButton"
import { Props as ClassifierFormProps } from "./FormikClassifierForm"
import {
    ClassifierConfigDto,
    ClassifierFormValues,
    ClassifierLanguage,
    ClassifierType
} from "../../../models/classifier"
import ClassifierFormHeader from "../ClassifierFormHeader/ClassifierFormHeader"
import { nameof } from "../../../utility/common/nameof"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import DebouncedCheckBox from "../../CheckBoxValidatableInput/DebouncedCheckBox"
import InfoIcon from "../../InfoIcon/InfoIcon"
import { Select } from "../../Select/Select"
import Tag from "../../Tag/Tag"
import { ValueType } from "react-select"
import { OptionType } from "../../AsyncSearchableInput/AsyncSearchableInput"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { selectArticleTypes } from "../../../store/knowledgeBase/selectors"
import {
    selectClassifierServers,
    selectCreateClassifierState,
    selectCurrentClassifier,
    selectUpdateClassifierState
} from "../../../store/classifiers/selectors"
import { activateClassifier, deactivateClassifier } from "../../../store/classifiers/thunks"
import Slider from "../../Slider/Slider"
import CreatableTagSelect from "../../CreatableTagSelect/CreatableTagSelect"

const tNamespace = "classifiers:form."
const tTooltipNamespace = "classifiers:tooltip."
const tLanguageNamespace = "classifiers:classifier-language."

const isServerVisible = (classifierServers: string[], classifier?: ClassifierConfigDto) =>
    classifierServers?.length !== 1 || (classifier && classifierServers[0] !== classifier?.Url)

const FormikClassifierFormGeneral: React.FC<ClassifierFormProps & FormikProps<ClassifierFormValues> & WithT> =
    props => {
        const {
            t,
            project,
            values,
            classifierType,
            classifier,
            handleSubmit,
            setFieldValue,
            disabled,
            linkedClassifier
        } = props
        const articleTypes = useSelector(selectArticleTypes)
        const classifierServers = useSelector(selectClassifierServers)
        const dispatch = useDispatch()
        const asyncState = useSelector(
            classifier ? selectUpdateClassifierState : selectCreateClassifierState,
            shallowEqual
        )
        const currentClassifier = useSelector(selectCurrentClassifier)

        const articleTypesOptions = useMemo(
            () =>
                (articleTypes?.Types ?? []).map(type => ({
                    label: type.Title,
                    value: type.Id
                })),
            [articleTypes]
        )

        const articleTypesMap = useMemo(
            () =>
                articleTypes
                    ? articleTypes.Types.reduce((obj: { [key: string]: string }, type) => {
                          obj[type.Id] = type.Title
                          return obj
                      }, {})
                    : {},
            [articleTypes]
        )

        const handleToggleActivity = (e: React.ChangeEvent<HTMLInputElement>) => {
            e.target.checked
                ? dispatch(activateClassifier(project.id, values.ClassifierConfigId))
                : dispatch(deactivateClassifier(project.id, values.ClassifierConfigId))
        }

        const languageOptions = [
            {
                label: t(`${tLanguageNamespace}russian`),
                value: ClassifierLanguage.Russian
            },
            {
                label: t(`${tLanguageNamespace}english`),
                value: ClassifierLanguage.English
            },
            {
                label: t(`${tLanguageNamespace}uzbek`),
                value: ClassifierLanguage.Uzbek
            }
        ]

        const handleThresholdChange = (v: number) => {
            setFieldValue(nameof<ClassifierFormValues>("SlotThreshold"), v, false)
        }

        return (
            <Form className="classifier-form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
                <div className="classifier-form__content">
                    <ClassifierFormHeader
                        t={t}
                        classifierType={classifierType}
                        isActive={classifier && currentClassifier.Active}
                        onToggle={handleToggleActivity}
                        status={classifier && currentClassifier.Status}
                        disabled={classifier?.Type === ClassifierType.KNN && linkedClassifier?.Active === false}
                    />
                    <div className="classifier-form__section">
                        {isServerVisible(classifierServers, classifier) && (
                            <DebouncedValidatableInput
                                id="formServer"
                                as="select"
                                name={nameof<ClassifierFormValues>("Url")}
                                label={t(`${tNamespace}server`)}
                                disabled={!classifierServers.length}
                            >
                                <option value="" hidden />
                                {classifierServers.map(server => (
                                    <option value={server} key={server}>
                                        {server}
                                    </option>
                                ))}
                            </DebouncedValidatableInput>
                        )}
                        <DebouncedValidatableInput
                            disabled={!!classifier}
                            id="formName"
                            type="text"
                            name={nameof<ClassifierFormValues>("Name")}
                            label={t(`${tNamespace}title`)}
                        />
                        <DebouncedValidatableInput
                            id="formPreprocessor"
                            type="text"
                            name={nameof<ClassifierFormValues>("Preprocessor")}
                            label={t(`${tNamespace}preprocessor`)}
                        />
                        <DebouncedValidatableInput
                            disabled={!!classifier}
                            id="formLanguage"
                            as="select"
                            name={nameof<ClassifierFormValues>("Language")}
                            label={t(`${tNamespace}language`)}
                        >
                            <option value="" hidden>
                                {t(`${tNamespace}select-language`)}
                            </option>
                            {languageOptions.map(option => (
                                <option value={option.value} key={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </DebouncedValidatableInput>
                    </div>
                    <div className="classifier-form__section">
                        <DebouncedCheckBox
                            id="formUseSpellCorrection"
                            name={nameof<ClassifierFormValues>("UseSpellCorrection")}
                            label={t(`${tNamespace}spelling-correction`)}
                        />
                        <DebouncedCheckBox
                            id="formSlotThresholdEnabled"
                            name={nameof<ClassifierFormValues>("DoSynonymousAugmentation")}
                            label={t(`${tNamespace}use-slots-to-expand-data`)}
                            icon={
                                <InfoIcon
                                    id="popoverSlotThresholdEnabled"
                                    content={t(`${tTooltipNamespace}use-slots`)}
                                />
                            }
                        />
                        <div className="extra-settings">
                            <Form.Group controlId="formThreshold">
                                <Form.Label>{t(`${tNamespace}threshold`)}</Form.Label>
                                <Slider
                                    min={0}
                                    max={100}
                                    step={1}
                                    marks={{ 0: "0", 100: "100%" }}
                                    onChange={handleThresholdChange}
                                    value={values.SlotThreshold}
                                    disabled={!values.DoSynonymousAugmentation}
                                />
                            </Form.Group>
                        </div>
                    </div>
                    <div className="classifier-form__section">
                        <span className="classifier-form__section-subtitle">{t(`${tNamespace}training-data`)}</span>
                        <CreatableTagSelect
                            id="formTags"
                            label={t(`${tNamespace}articles-with-one-of-tags`)}
                            name={nameof<ClassifierFormValues>("Tags")}
                            placeholder={t(`${tNamespace}add-tag`)}
                            t={t}
                        />

                        <FieldArray
                            name={nameof<ClassifierFormValues>("ArticleTypes")}
                            render={({ push, remove }) => (
                                <>
                                    <Form.Group controlId="formArticleType" className="classifier-form__tag-form-group">
                                        <Form.Label>{t(`${tNamespace}articles-with-one-of-types`)}</Form.Label>
                                        <Select
                                            value={{ label: t(`${tNamespace}select-type`), value: "" }}
                                            noOptionsMessage={() => t(formTranslation.noResultsFound)}
                                            options={articleTypesOptions.filter(
                                                type => !values.ArticleTypes.includes(type.value)
                                            )}
                                            onChange={(option: ValueType<OptionType, false>) =>
                                                option && push(option.value)
                                            }
                                        />
                                    </Form.Group>
                                    <div className="classifier-form__tags">
                                        {values.ArticleTypes.map((type: string, index: number) => (
                                            <Tag
                                                className="classifier-form__tag"
                                                key={type}
                                                index={index}
                                                title={articleTypesMap[type] ?? type}
                                                onDelete={() => remove(index)}
                                            />
                                        ))}
                                    </div>
                                </>
                            )}
                        />
                    </div>
                    <div className="classifier-form__section">
                        <span className="classifier-form__section-subtitle">
                            {t(`${tNamespace}stop-words`)}{" "}
                            <InfoIcon id="stopWords" content={t(`${tTooltipNamespace}stop-words`)} />
                        </span>
                        <CreatableTagSelect
                            id="formPlusStopWords"
                            label={t(`${tNamespace}additional-stop-words`)}
                            name={nameof<ClassifierFormValues>("PlusStopWords")}
                            placeholder={t(`${tNamespace}add-word`)}
                            t={t}
                        />
                        <CreatableTagSelect
                            id="formMinusStopWords"
                            label={t(`${tNamespace}exclude-stop-words`)}
                            name={nameof<ClassifierFormValues>("MinusStopWords")}
                            placeholder={t(`${tNamespace}add-word`)}
                            t={t}
                        />
                    </div>
                </div>
                <div className="classifier-form__footer">
                    <LoadingButton
                        type="submit"
                        loading={asyncState.inProcess}
                        variant="primary"
                        disabled={disabled}
                        block
                    >
                        {t(formTranslation.save)}
                    </LoadingButton>
                </div>
            </Form>
        )
    }

export default FormikClassifierFormGeneral
