import React from "react"
import styles from "./FormSubSection.module.scss"

export interface FormSubSectionProps {}

const FormSubSection: React.FC<FormSubSectionProps> = ({ children }) => {
    return <div className={styles.formSubSection}>{children}</div>
}

export default FormSubSection
