import React from "react"
import { ChannelFormProps } from "../ChannelForm/ChannelForm"
import { FormikProps } from "formik"
import { WithT } from "i18next"
import { shallowEqual, useSelector } from "react-redux"
import { selectCreateChannelState, selectUpdateChannelState } from "../../store/channels/selectors"
import { nameof } from "../../utility/common/nameof"
import FormAccordion from "../FormAccordion/FormAccordion"
import InfoIcon from "../InfoIcon/InfoIcon"
import { ChannelValues } from "../../models/channelValues"
import DebouncedValidatableInput from "../ValidatableInput/DebouncedValidatableInput"
import IdField from "../IdField/IdField"
import ChannelFormWrapper from "../ChannelFormWrapper/ChannelFormWrapper"

const tNamespace = "channel:form."
const tTooltipNamespace = "channel:tooltip."

const FormikMSTeamsChannelFormAdvanced: React.FC<ChannelFormProps & FormikProps<ChannelValues> & WithT> = props => {
    const { channel, values, t, handleSubmit, disabled } = props

    const asyncState = useSelector(channel ? selectUpdateChannelState : selectCreateChannelState, shallowEqual)

    return (
        <ChannelFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={asyncState.inProcess}>
            <IdField id={values.BotSettings.Id} className="channel-form__section" />
            <FormAccordion title={t(`${tNamespace}webhook-preprocessing`)}>
                <DebouncedValidatableInput
                    id="formPreprocessorWebHook"
                    type="text"
                    name={nameof<ChannelValues>("PreprocessorWebHook")}
                    label={t(`${tNamespace}webhook`)}
                    icon={
                        <InfoIcon
                            id="popoverPreprocessorWebHook"
                            title={t(`${tNamespace}webhook-preprocessing`)}
                            content={t(`${tTooltipNamespace}webhook-preprocessing`)}
                        />
                    }
                />
            </FormAccordion>
        </ChannelFormWrapper>
    )
}

export default FormikMSTeamsChannelFormAdvanced
