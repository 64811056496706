import { NotificationInternalPayload } from "../../models/notification"
import { SystemError } from "../../core/error"
import { ActionResult, Dispatch } from "../../utility/common/storeHelper"
import { actions } from "./slice"
import * as constants from "./constants"
import toNotificationPayload from "../../utility/notifications/toNotificationPayload"
import { handleHttpException } from "../handleHttpException"
import notificationController from "../../api/controllers/notification"
import { NotificationDto } from "../../models/notificationDto"

export const saveNotificationError = (dispatch: Dispatch, error: SystemError) => dispatch(actions.saveError(error))

export const saveNotificationInfo = (dispatch: Dispatch, payload: NotificationInternalPayload) =>
    dispatch(actions.saveInfo(toNotificationPayload(payload)))

export const clearNotifications = (dispatch: Dispatch) => dispatch(actions.clear())

export const saveNotificationNews = (dispatch: Dispatch, value: NotificationDto) => dispatch(actions.saveNews(value))

export const deleteNotificationNewsWithoutLoading = (dispatch: Dispatch, notificationId: string) =>
    dispatch(actions.deleteNewsSuccess(notificationId))

export const deleteNotificationNews = (notificationId: string) => async (dispatch: Dispatch) => {
    dispatch(actions.deleteNewsProcess(notificationId))
    try {
        await notificationController.deleteNews(notificationId)
        dispatch(actions.deleteNewsSuccess(notificationId))
    } catch (e) {
        handleHttpException(
            e,
            constants.DELETE_NOTIFICATION_FAILED,
            () => actions.deleteNewsFailed(notificationId),
            dispatch
        )
    }
}

export const deleteNotification = (dispatch: Dispatch, payload: string) => dispatch(actions.deleteNotification(payload))

export const getNotificationNews = (): ActionResult<Promise<void>> => async (dispatch: Dispatch) => {
    dispatch(actions.getNewsProcess())
    try {
        const notificationNews = await notificationController.getNews()
        dispatch(actions.getNewsSuccess(notificationNews))
    } catch (e) {
        handleHttpException(e, constants.GET_NOTIFICATION_NEWS_FAILED, err => actions.getNewsFailed(err), dispatch)
    }
}

export const clearAndUpdateNotificationNews = (): ActionResult<Promise<void>> => async (dispatch: Dispatch) => {
    dispatch(actions.getNewsProcess())
    try {
        const protectedNotificationNews = await notificationController.clearNews()
        dispatch(actions.getNewsSuccess(protectedNotificationNews))
    } catch (e) {
        handleHttpException(
            e,
            constants.CLEAR_AND_UPDATE_NOTIFICATION_NEWS_FAILED,
            err => actions.getNewsFailed(err),
            dispatch
        )
    }
}

export const clearCurrentNotification = (notificationId: string, dispatch: Dispatch) =>
    dispatch(actions.clearCurrent(notificationId))
