import React, { ReactNode } from "react"
import { Form } from "react-bootstrap"
import { formTranslation } from "../../../locales/form"
import { FieldArray } from "formik"
import { useTranslation } from "react-i18next"
import { ValueType } from "react-select"
import { OptionType } from "../../AsyncSearchableInput/AsyncSearchableInput"
import { Select } from "../../Select/Select"
import { OptionsType } from "react-select/src/types"

export interface MultipleSelectControlProps {
    id: string
    label: string
    name: string
    options?: OptionsType<OptionType>
    selectedItems: (remove: (index: number) => void) => ReactNode
    disabled?: boolean
    onSelect: (name: string, option: string) => void
}

const MultipleSelectControl: React.FC<MultipleSelectControlProps> = props => {
    const { t } = useTranslation()
    const { id, label, name, options, selectedItems, disabled, onSelect } = props

    return (
        <FieldArray
            name={name}
            render={({ push, remove }) => (
                <Form.Group controlId={id}>
                    <Form.Label>{label}</Form.Label>
                    <Select
                        menuPlacement="auto"
                        value={{
                            label: t(formTranslation.select),
                            value: ""
                        }}
                        isDisabled={disabled}
                        options={options}
                        onChange={(option: ValueType<OptionType, false>) => {
                            if (option) {
                                onSelect(name, option.value)
                                push(option.value)
                            }
                        }}
                        noOptionsMessage={() => t(formTranslation.noResultsFound)}
                    />
                    {selectedItems(remove)}
                </Form.Group>
            )}
        />
    )
}

export default MultipleSelectControl
