import {
    GetUsersAndRolesByPermissionsRequest,
    GetUsersAndRolesByPermissionsResponse,
    KnowledgeBasePermission
} from "../../models/knowledgeBasePermission"
import knowledgeBasePermissionsController from "../../api/controllers/knowledgeBasePermissions"
import { SimpleUser } from "../../models/user"
import { SimpleRole } from "../../models/role"
import { UserAccessPrimitive } from "../../models/knowledgeBaseUserAccess"
import { Project } from "../../models/project"

export const getUsersAndRolesByKnowledgeBasePermissions = async (
    accessItems: UserAccessPrimitive[],
    projectId: string
): Promise<GetUsersAndRolesByPermissionsResponse> => {
    try {
        const request: GetUsersAndRolesByPermissionsRequest = {
            AccessItems: accessItems
        }
        return await knowledgeBasePermissionsController.getUsersAndRolesByPermissions(projectId, request)
    } catch (e) {
        console.error(e)
        return { Users: [], Roles: [] }
    }
}

export const usersToMap = (users: SimpleUser[]) => {
    let map: { [login: string]: SimpleUser } = {}
    for (const user of users) {
        map[user.Login] = user
    }
    return map
}

export const rolesToMap = (roles: SimpleRole[]) => {
    let map: { [id: string]: SimpleRole } = {}
    for (const role of roles) {
        map[role.Id] = role
    }
    return map
}

export const filterProjectByPermissions = (permissions: KnowledgeBasePermission[], projects?: Project[]) =>
    projects ? projects.filter(p => !!permissions.find(perm => perm.ProjectId === p.id)) : undefined
