import React from "react"
import styles from "./QuickActionItem.module.scss"
import { faUsers } from "@fortawesome/pro-light-svg-icons/faUsers"
import { faCoffee } from "@fortawesome/pro-light-svg-icons/faCoffee"
import { faCamera } from "@fortawesome/pro-light-svg-icons/faCamera"
import { faMoneyBill } from "@fortawesome/pro-light-svg-icons/faMoneyBill"
import { faAddressBook } from "@fortawesome/pro-light-svg-icons/faAddressBook"
import { faTrain } from "@fortawesome/pro-light-svg-icons/faTrain"
import { faCalendar } from "@fortawesome/pro-light-svg-icons/faCalendar"
import { faUser } from "@fortawesome/pro-light-svg-icons/faUser"
import { faIceCream } from "@fortawesome/pro-light-svg-icons/faIceCream"
import { faHouzz } from "@fortawesome/free-brands-svg-icons/faHouzz"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { faFileAlt } from "@fortawesome/pro-light-svg-icons/faFileAlt"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "react-bootstrap"
import { ClassProps } from "../../../../../utility/common/props"
import cn from "classnames"
import {
    QuickAction,
    QuickActionType
} from "../../../../../utility/articleContentEditor/quickActionParser/quickActionAttribute"
import { findIconDefinition, library } from "@fortawesome/fontawesome-svg-core"
import { IconName } from "@fortawesome/fontawesome-common-types"
import { openExternalLink } from "../../../../../utility/common/urlHelper"
import ChannelIconLarge from "../../../../ChannelIconLarge/ChannelIconLarge"
import { buildAddChannelPath, buildEditChannelPath, buildInvitePath } from "../../../../../utility/common/urlFormHelper"
import { faEnvelopeOpen } from "@fortawesome/pro-light-svg-icons/faEnvelopeOpen"

library.add(
    faCoffee,
    faCamera,
    faMoneyBill,
    faAddressBook,
    faTrain,
    faCalendar,
    faUser,
    faHouzz,
    faIceCream,
    faPlus,
    faFileAlt,
    faEnvelopeOpen
)

export interface QuickActionItemProps extends ClassProps {
    action: QuickAction
    isEdit?: boolean
    onLocationChange: (path: string) => void
}

export const isIconName = (iconName?: string): iconName is IconName =>
    Boolean(
        iconName &&
            findIconDefinition({
                iconName: iconName as IconName,
                prefix: "fal"
            })
    )

const getIcon = (action: QuickAction, className: string) => {
    switch (action.Type) {
        case QuickActionType.AddUser:
            return <FontAwesomeIcon icon={faUsers} className={className} />
        case QuickActionType.AddChannel:
        case QuickActionType.OpenChannel:
            return <ChannelIconLarge type={action.ChannelType} className={className} />
        case QuickActionType.OpenURL:
            return (
                <FontAwesomeIcon
                    icon={isIconName(action.Icon) ? ["fal", action.Icon] : faFileAlt}
                    className={className}
                />
            )
    }
}

const getClickHandler = (action: QuickAction, handleLocationChange: (path: string) => void) => {
    switch (action.Type) {
        case QuickActionType.AddUser:
            return () => handleLocationChange(buildInvitePath())
        case QuickActionType.AddChannel:
            return () => handleLocationChange(buildAddChannelPath(action.ChannelType))
        case QuickActionType.OpenChannel:
            return () => handleLocationChange(buildEditChannelPath(action.ChannelId))
        case QuickActionType.OpenURL:
            if (action.Url) return () => openExternalLink(action.Url)
    }
}

const QuickActionItem: React.FC<QuickActionItemProps> = props => {
    const { action, isEdit, className, onLocationChange } = props
    const { Title, SubTitle, ButtonTitle } = action

    return (
        <div className={cn(styles.quickAction, className)}>
            {getIcon(action, styles.quickAction__icon)}
            {Title && <div className={styles.quickAction__title}>{Title}</div>}
            {SubTitle && <div className={styles.quickAction__subtitle}>{SubTitle}</div>}
            <Button
                variant="primary"
                className={styles.quickAction__btn}
                onClick={isEdit ? undefined : getClickHandler(action, onLocationChange)}
            >
                {ButtonTitle}
            </Button>
        </div>
    )
}

export default QuickActionItem
