import React from "react"
import { ArticleView, SearchArticlesHighlights, SearchArticlesScores } from "../../models/article"
import { ListGroup } from "react-bootstrap"
import SearchQueryArticle from "../SearchQueryArticle/SearchQueryArticle"
import { getSearchQueryArticlesCountDescription } from "../../utility/knowledgeBase/knowledgeBase"
import { useTranslation } from "react-i18next"
import styles from "./SearchQueryArticles.module.scss"

interface Props {
    articles: ArticleView[]
    highlights?: SearchArticlesHighlights
    scores?: SearchArticlesScores
    onArticleClick: (articleCode: string) => void
}

const SearchQueryArticles: React.FC<Props> = props => {
    const { articles, highlights, onArticleClick } = props
    const { i18n } = useTranslation()

    return (
        <div className={styles.searchQueryArticles}>
            <div className={styles.searchQueryArticles__header}>
                {getSearchQueryArticlesCountDescription(articles ? articles.length : 0, i18n.language)}
            </div>

            {articles && articles.length > 0 && (
                <div className={styles.searchQueryArticles__body}>
                    <ListGroup variant="flush">
                        {articles.map(article => (
                            <ListGroup.Item key={article.Id}>
                                <SearchQueryArticle
                                    article={article}
                                    highlights={highlights && highlights[article.Id]}
                                    onArticleClick={onArticleClick}
                                />
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </div>
            )}
        </div>
    )
}

export default SearchQueryArticles
