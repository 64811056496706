import React from "react"
import ValidatableInput from "../../ValidatableInput/ValidatableInput"
import { StatisticsCalculatingOptions } from "../../../models/projectSettings"
import { useTranslation } from "react-i18next"
import { formTranslation } from "../../../locales/form"

const tNamespace = "project:project-settings.statistics."

interface Props {
    id: string
    name: string
    label: string
}

const StatisticsCalculatingSelect: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { id, name, label } = props

    const statisticsCalculatingOptions = [
        { value: StatisticsCalculatingOptions.FirstReply, label: t(`${tNamespace}first-reply`) },
        { value: StatisticsCalculatingOptions.Routed, label: t(`${tNamespace}assignment-time`) }
    ]

    return (
        <ValidatableInput id={id} as="select" name={name} label={label}>
            <option value="" hidden>
                {t(formTranslation.select)}
            </option>
            {statisticsCalculatingOptions.map(o => (
                <option key={o.value} value={o.value}>
                    {o.label}
                </option>
            ))}
        </ValidatableInput>
    )
}

export default StatisticsCalculatingSelect
