import React from "react"
import styles from "./FormSectionTitle.module.scss"

export interface FormSectionTitleProps {}

const FormSectionTitle: React.FC<FormSectionTitleProps> = ({ children }) => {
    return <div className={styles.formSectionTitle}>{children}</div>
}

export default FormSectionTitle
