import React, { useCallback } from "react"
import "./ProjectSidebar.scss"
import MenuItemComponent from "../MenuItem/MenuItem"
import { MenuItem } from "../../models/menu"
import ProjectSelector, { ProjectSelectorLoading } from "../ProjectSelector/ProjectSelector"
import MenuItemUser from "../MenuItemUser/MenuItemUser"
import Sidebar from "../Sidebar/Sidebar"
import { connect, useDispatch, useSelector } from "react-redux"
import { selectCurrentProject, selectFrameLoadingState, selectOtherProjects } from "../../store/projects/selectors"
import { push } from "connected-react-router"
import { projectPath } from "../../routerPaths"
import { selectCurrentMainMenu } from "../../store/menu/selectors"
import { selectCurrentUser } from "../../store/users/selectors"
import { useTranslation } from "react-i18next"
import { RootState } from "../../store/rootReducer"
import { Project } from "../../models/project"
import { User } from "../../models/user"
import MenuItemRest from "../MenuItemRest/MenuItemRest"
import OperatorStatusSelector, { OperatorStatusSelectorLoading } from "../OperatorStatusSelector/OperatorStatusSelector"
import { selectOperatorStatuses, selectOperatorStatusState } from "../../store/userOperator/selectors"
import { updateOperatorStatus } from "../../store/userOperator/thunks"
import AsyncWithoutError from "../Async/AsyncWithoutError"
import { definedMenuItems } from "../../utility/menu/definedMenuItems"
import Can from "../Can/Can"
import { ViewClient } from "../../permissions"

const tNamespace = "project:"

interface StateToProps {
    selectedProject?: Project
    selectedMainMenu?: string
    nonSelectedProjects: Project[]
    user?: User
}

export interface ProjectSidebarProps {
    mainMenuItems: MenuItem[]
    menuItemsRest: MenuItem[]
    toggleSidebar?: Function
    selectMenu: (menuItemId: string) => void
}

const ProjectSidebar: React.FC<ProjectSidebarProps & StateToProps> = props => {
    const {
        user,
        selectedProject,
        selectedMainMenu,
        nonSelectedProjects,
        mainMenuItems,
        menuItemsRest,
        toggleSidebar,
        selectMenu
    } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const operatorStatusState = useSelector(selectOperatorStatusState)
    const frameState = useSelector(selectFrameLoadingState)
    const operatorStatuses = useSelector(selectOperatorStatuses)

    const getSelectedMenu = useCallback(
        (menuId: string) => (selectedMainMenu ? selectedMainMenu === menuId : false),
        [selectedMainMenu]
    )
    const selectProjectUrl = useCallback(
        (projectId: string) => dispatch(push(`${projectPath}/${projectId}/${selectedMainMenu || ""}`)),
        [dispatch, selectedMainMenu]
    )

    if (!user) return null

    return (
        <Sidebar
            className="project-sidebar"
            header={
                <>
                    <Can permission={ViewClient}>
                        <MenuItemComponent
                            id={definedMenuItems.Dialogs.id}
                            title={t(`${tNamespace}${definedMenuItems.Dialogs.title}`)}
                            isSelected={getSelectedMenu(definedMenuItems.Dialogs.id)}
                            onClick={() => selectMenu(definedMenuItems.Dialogs.id)}
                            testId={definedMenuItems.Dialogs.id}
                        />
                    </Can>
                    <AsyncWithoutError
                        dataState={operatorStatusState}
                        processView={<OperatorStatusSelectorLoading t={t} />}
                    >
                        {({ data }) => (
                            <OperatorStatusSelector
                                currentStatus={data}
                                statuses={operatorStatuses}
                                onChange={status => dispatch(updateOperatorStatus(status))}
                            />
                        )}
                    </AsyncWithoutError>
                </>
            }
            content={
                <>
                    {selectedProject && !frameState.inProcess ? (
                        <ProjectSelector
                            selectedProject={selectedProject}
                            nonSelectedProject={nonSelectedProjects}
                            selectProject={selectProjectUrl}
                        />
                    ) : (
                        <ProjectSelectorLoading t={t} />
                    )}
                    {selectedProject &&
                        mainMenuItems.map(menuItem => (
                            <MenuItemComponent
                                id={menuItem.id}
                                key={menuItem.id}
                                title={t(`${tNamespace}${menuItem.title}`)}
                                isSelected={getSelectedMenu(menuItem.id)}
                                onClick={() => selectMenu(menuItem.id)}
                                testId={menuItem.id}
                            />
                        ))}
                    <MenuItemRest
                        onClick={selectMenu}
                        getSelectedMenu={getSelectedMenu}
                        items={selectedProject ? menuItemsRest : []}
                        className="project-sidebar__rest"
                        toggleSidebar={toggleSidebar}
                    />
                </>
            }
            footer={
                <MenuItemUser
                    user={user}
                    selectMenu={selectMenu}
                    getSelectedMenu={getSelectedMenu}
                    className="project-sidebar__user-menu"
                />
            }
        />
    )
}

const mapStateToProps = (state: RootState): StateToProps => ({
    selectedProject: selectCurrentProject(state),
    selectedMainMenu: selectCurrentMainMenu(state),
    nonSelectedProjects: selectOtherProjects(state),
    user: selectCurrentUser(state)
})

export default connect(mapStateToProps)(ProjectSidebar)
