import React from "react"
import "./BlockTypeForm.scss"
import { useTranslation } from "react-i18next"
import TypeFormCard from "../TypeFormCard/TypeFormCard"
import BlockIcon from "../BlockIcon/BlockIcon"
import { Connection, ScenarioBlockType } from "../../models/scenario"

const tNamespace = "scenarioEditor:"

const blockTypes = [ScenarioBlockType.Channel, ScenarioBlockType.Article]

interface Props {
    onSelect: () => void
    onAddArticle: (connection: Connection) => void
    onAddChannel: (connection: Connection) => void
    connection: Connection
}

const BlockTypeFormForChannels: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { onSelect, onAddArticle, onAddChannel, connection } = props

    const handleChooseBlock = (type: ScenarioBlockType) => {
        onSelect()

        switch (type) {
            case ScenarioBlockType.Channel:
                return onAddChannel(connection)
            case ScenarioBlockType.Article:
                return onAddArticle(connection)
        }
    }

    return (
        <div className="block-type-form">
            <div className="block-type-form__content">
                {blockTypes.map(type => (
                    <TypeFormCard
                        key={type}
                        icon={<BlockIcon type={type} />}
                        onSelect={() => handleChooseBlock(type)}
                        title={t(`${tNamespace}block-type-channels.${type}`)}
                    />
                ))}
            </div>
        </div>
    )
}

export default BlockTypeFormForChannels
