import React, { useContext } from "react"
import "./AddAgentBlockForm.scss"
import { useTranslation } from "react-i18next"
import TypeFormCard from "../TypeFormCard/TypeFormCard"
import { faBoxOpen } from "@fortawesome/pro-light-svg-icons/faBoxOpen"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AgentIcon from "../AgentIcon/AgentIcon"
import { useSelector } from "react-redux"
import { selectAgents } from "../../store/agents/selectors"
import { addConnection, addNode } from "../../utility/scenario/scenarioGraph"
import { Connection, ScenarioBlockType } from "../../models/scenario"
import { ScenarioContext } from "../ScenarioEditor/ScenarioContext"
import { AgentNodeData } from "../Graph/nodes/Agent/AgentNode"
import { Agent } from "../../models/agent"
import orderBy from "lodash-es/orderBy"
import usePermissionsCheck from "../../utility/common/usePermissionsCheck"
import { ModifyAgents } from "../../permissions"

const tScenarioEditorNamespace = "scenarioEditor:"
const tAgentNamespace = "agent:"

interface Props {
    connection: Connection
    onCreate: () => void
    onSelect: () => void
    onAddBlock: (source?: string, sourceHandle?: string) => void
}

const AddAgentBlockForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const agents = useSelector(selectAgents)
    const { connection, onSelect, onCreate, onAddBlock } = props
    const modifyAgentsAllowed = usePermissionsCheck([ModifyAgents])

    const { instance, setElements } = useContext(ScenarioContext)

    const handleSelect = (agent: Agent) => {
        onSelect()
        if (instance !== null) {
            const nodeData: AgentNodeData = {
                AgentId: agent.Id,
                AgentType: agent.Type,
                Agent: agent,
                OwnedByThisScenario: false,
                AddBlock: onAddBlock
            }

            const id = addNode(ScenarioBlockType.Agent, setElements, nodeData, instance, connection.source)
            addConnection(setElements, id, connection.source, connection.sourceHandle)
        }
    }

    return (
        <div className="add-agent-block-form">
            <div className="add-agent-block-form__content">
                {modifyAgentsAllowed && (
                    <TypeFormCard
                        onSelect={onCreate}
                        icon={<FontAwesomeIcon icon={faBoxOpen} />}
                        title={t(`${tScenarioEditorNamespace}add-new-agent`)}
                    />
                )}
                <div className="add-agent-block-form__agent-types">
                    {agents && (
                        <>
                            <div className="add-agent-block-form__agent-types-title">
                                {`${t(`${tScenarioEditorNamespace}choose-existing`)}:`}
                            </div>
                            {orderBy(agents, "Id").map(agent => (
                                <TypeFormCard
                                    key={agent.Id}
                                    onSelect={() => handleSelect(agent)}
                                    icon={
                                        <AgentIcon
                                            type={agent.Type}
                                            className="add-agent-block-form__agent-type-icon"
                                        />
                                    }
                                    title={agent.Id}
                                    description={t(`${tAgentNamespace}agent-type.${agent.Type}`)}
                                    mini
                                />
                            ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default AddAgentBlockForm
