import React, { useEffect } from "react"
import "./ProjectCopyWizard.scss"
import "../../components/ColorCircleInput/ColorCircleInput.module.scss"
import { useDispatch, useSelector } from "react-redux"
import {
    selectCreateProjectCopyState,
    selectProjectCopyWizardReady,
    selectProjectCopyWizardStage
} from "../../store/projects/selectors"
import { actions } from "../../store/projects/slice"
import { Container } from "react-bootstrap"
import FullscreenLoader from "../../components/FullscreenLoader/FullscreenLoader"
import { TFunction, useTranslation } from "react-i18next"
import FullscreenError from "../../components/FullscreenError/FullscreenError"
import AsyncWithoutData from "../../components/Async/AsyncWithoutData"
import { Supervisor } from "../../permissions"
import Can from "../../components/Can/Can"
import { errorTranslation } from "../../locales/error"
import Stepper from "../../components/Stepper/Stepper"
import CopyWizardView from "../../components/WizardView/CopyWizardView"
import PublicPage from "../../components/PublicPage/PublicPage"
import ProjectWizardError from "../ProjectWizard/ProjectWizardError"

const tNamespace = "wizard:"
const tNamespaceSteps = "wizard:steps:"

const getSteps = (t: TFunction) => {
    return [
        t(`${tNamespaceSteps}name`),
        t(`${tNamespaceSteps}id`),
        t(`${tNamespaceSteps}workspace-upload`),
        t(`${tNamespaceSteps}done`)
    ]
}

// TODO create common component for project and project copy wizards
const ProjectCopyWizard: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const ready = useSelector(selectProjectCopyWizardReady)
    const createProjectState = useSelector(selectCreateProjectCopyState)
    const stage = useSelector(selectProjectCopyWizardStage)
    const steps = getSteps(t)

    useEffect(() => {
        !ready && dispatch(actions.initCopyWizard())
    }, [dispatch, ready])

    return (
        <Can permission={Supervisor} no={() => <FullscreenError title={t(errorTranslation.accessDenied)} />}>
            <AsyncWithoutData
                state={createProjectState}
                processView={<FullscreenLoader message={t(`${tNamespace}loading`)} />}
                errorView={() => <ProjectWizardError onReset={() => dispatch(actions.resetCopyWizard())} />}
            >
                <PublicPage contentInCenter title={t(`${tNamespace}title`)} className="project-wizard">
                    <div className="project-wizard__sidebar">
                        <Stepper steps={steps} activeStep={stage} />
                    </div>
                    <Container fluid className="project-wizard__container">
                        <CopyWizardView />
                    </Container>
                </PublicPage>
            </AsyncWithoutData>
        </Can>
    )
}

export default ProjectCopyWizard
