export enum ButtonImageAlign {
    Left = "left",
    Center = "center"
}

const buttonImageAlignValues = Object.values(ButtonImageAlign)

export const validateButtonImageAlign = (buttonImageAlignAttrValue: string) => {
    const imageAlign = buttonImageAlignAttrValue as ButtonImageAlign
    return buttonImageAlignValues.includes(imageAlign) ? imageAlign : ButtonImageAlign.Left
}

export const buttonImageAlignToClassModifier = (buttonImageAlign: string) => {
    switch (buttonImageAlign) {
        case ButtonImageAlign.Center:
            return "markdown-buttons__button_columned"
        default:
            return ""
    }
}
