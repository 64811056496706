import { apiInstanceV1 } from "../instances"
import {
    AddStickerRequest,
    AddStickerResponse,
    DeleteStickerRequest,
    DeleteStickerResponse,
    EditStickerRequest,
    EditStickerResponse,
    GetAllStickersRequest,
    GetAllStickersResponse
} from "../../models/sticker"

const stickersController = {
    getStickers: (request: GetAllStickersRequest): Promise<GetAllStickersResponse> =>
        apiInstanceV1.post("stickers/get-all-stickers", request).then(response => response.data),
    create: (request: AddStickerRequest): Promise<AddStickerResponse> =>
        apiInstanceV1.post("stickers", request).then(response => response.data),
    update: (request: EditStickerRequest): Promise<EditStickerResponse> =>
        apiInstanceV1.post("stickers", request).then(response => response.data),
    delete: (request: DeleteStickerRequest): Promise<DeleteStickerResponse> =>
        apiInstanceV1.delete("stickers", { data: request }).then(response => response.data)
}

export default stickersController
