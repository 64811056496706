import { TFunction } from "i18next"

export interface TimeSpan {
    Hr: number
    Min: number
    Sec: number
}

export class Time {
    static msToTimeSpan(ms: number) {
        const seconds = Math.floor(ms / 1000)
        const secondsPart = seconds % 60

        const minutes = Math.floor(seconds / 60)
        const minutesPart = minutes % 60

        const hours = Math.floor(minutes / 60)
        const hoursPart = hours % 24

        const daysPart = Math.floor(hours / 24)

        return {
            Days: daysPart,
            Hr: hoursPart,
            Min: minutesPart,
            Sec: secondsPart
        }
    }

    static msToSeconds(ms: number) {
        return Math.floor(ms / 1000)
    }

    static msToMinutes(ms: number) {
        return Math.floor(Time.msToSeconds(ms) / 60)
    }

    static msToHours(ms: number) {
        return Math.floor(Time.msToMinutes(ms) / 60)
    }

    static humanizeDuration(ms: number, t: TFunction) {
        if (ms < 1000) return t("queues:time.zero-seconds")

        let res = ""

        let { Hr, Min, Sec } = Time.msToTimeSpan(ms)

        if (Hr >= 1) res += Hr + t("queues:time.hour")
        if (Min >= 1) res += Min + t("queues:time.minute")
        if (Sec >= 1 && !Hr) res += Sec + t("queues:time.second")

        return res === "" ? t("queues:time.zero-seconds") : res
    }

    static msToTimeString(ms: number) {
        const { Hr, Min } = Time.msToTimeSpan(ms)
        return `${Hr > 9 ? "" : 0}${Hr}:${Min > 9 ? "" : 0}${Min}`
    }

    static daysToMs(days: number) {
        return Time.hoursToMs(24 * days)
    }

    static hoursToMs(hr: number) {
        return Time.minutesToMs(60 * hr)
    }

    static minutesToMs(min: number) {
        return Time.secondsToMs(60 * min)
    }

    static secondsToMs(sec: number) {
        return sec * 1000
    }

    static secondsToHours(sec: number) {
        return Time.msToHours(Time.secondsToMs(sec))
    }
}
