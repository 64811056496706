import React from "react"
import { Button } from "react-bootstrap"
import { WithT } from "i18next"
import { testId } from "../../utility/tests/testId"
import styles from "./ImageUploadInput.module.scss"

const tNamespace = "common:image."

interface Props extends WithT {
    disabled: boolean
    onClick: () => void
}

const UploadButton: React.FC<Props> = props => {
    const { disabled, onClick, t } = props

    return (
        <Button
            disabled={disabled}
            variant="light"
            className={styles.imageUploadInput__btn}
            onClick={onClick}
            data-test-id={testId.selectFile}
        >
            {t(`${tNamespace}select-file`)}
        </Button>
    )
}

export default UploadButton
