import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import "./ProjectSettings.scss"
import { selectCurrentProject } from "../../store/projects/selectors"
import ProjectRolesContainer from "../../components/ProjectRoles/ProjectRolesContainer"
import { WithTitle } from "../../utility/common/withTitle"
import { AllRoleSettings, Role } from "../../models/role"
import RoleForm from "../../components/RoleForm/RoleForm"
import PageLayout from "../../components/PageLayout/PageLayout"
import ProjectUsersContainer from "../../components/ProjectUsers/ProjectUsersContainer"
import { Project } from "../../models/project"
import InviteUsersForm from "../../components/InviteUsersForm/InviteUsersForm"
import ProjectUserForm from "../../components/ProjectUserForm/ProjectUserForm"
import { ControlClassifiers, Supervisor, ViewAgents, ViewChannels, ViewPositions, ViewUsers } from "../../permissions"
import { ProjectUser } from "../../models/projectUser"
import { deleteRole } from "../../store/roles/thunks"
import ProjectSettingsForm from "../../components/ProjectSettingsForm/ProjectSettingsForm"
import MenuItemRow from "../../components/MenuItemRow/MenuItemRow"
import { push, replace } from "connected-react-router"
import { Route, useParams, useRouteMatch } from "react-router-dom"
import ChannelSettings from "../ChannelSettings/ChannelSettings"
import AgentSettings from "../AgentSettings/AgentSettings"
import Can from "../../components/Can/Can"
import Tab from "react-bootstrap/Tab"
import { Nav } from "react-bootstrap"
import ProjectSettingsMenuIcon from "../../components/ProjectSettingsMenuIcon/ProjectSettingsMenuIcon"
import ConditionalWrapper from "../../components/ConditionalWrapper/ConditionalWrapper"
import {
    blockUser,
    getProjectSettings,
    subscribeOnProjectSettingsUpdate,
    unsubscribeOnProjectSettingsUpdate
} from "../../store/projects/thunks"
import ChannelScenario from "../ChannelScenario/ChannelScenario"
import Slots from "../Slots/Slots"
import { SlotDto } from "../../models/slot"
import SlotForm from "../../components/SlotSettings/SlotForm/SlotForm"
import { deleteSlot } from "../../store/slots/thunks"
import Classifiers from "../Classifiers/Classifiers"
import ProjectStickersContainer from "../../components/ProjectStickers/ProjectStickersContainer"
import { Sticker } from "../../models/sticker"
import { deleteSticker } from "../../store/stickers/thunks"
import StickerForm from "../../components/ProjectStickers/StickerForm/StickerForm"
import ProjectArticleTypesContainer from "../../components/ProjectArticleTypes/ProjectArticleTypesContainer"
import { ArticleType } from "../../models/articleType"
import { deleteArticleType } from "../../store/knowledgeBase/thunks"
import ArticleTypeForm from "../../components/ProjectArticleTypes/ArticleTypeForm/ArticleTypeForm"
import { useModal } from "../../utility/common/useModal"
import AlertDialog from "../../components/AlertDialog/AlertDialog"
import { formTranslation } from "../../locales/form"
import SettingsContextMenuItem from "../../components/SettingsContextMenuItem/SettingsContextMenuItem"
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash"
import { SurveyDto, SurveyValues } from "../../models/survey"
import ProjectSurveysContainer from "../../components/ProjectSurveys/ProjectSurveysContainer"
import SurveyForm from "../../components/ProjectSurveys/SurveyForm/SurveyForm"
import ChooseSlotForm from "../../components/ChooseSlotForm/ChooseSlotForm"
import { pushField } from "../../components/ProjectSurveys/SurveyForm/FormikSurveyForm"
import { deleteSurvey } from "../../store/surveys/thunks"
import { definedMenuItems } from "../../utility/menu/definedMenuItems"
import cn from "classnames"
import DialogProjectSettingsForm from "../../components/ProjectSettings/Dialog/DialogProjectSettingsForm"
import SystemProjectSettingsForm from "../../components/ProjectSettings/System/SystemProjectSettingsForm"
import StatisticsProjectSettings from "../../components/ProjectSettings/Statistics/StatisticsProjectSettings"
import OperatorsWorkSettingsForm from "../../components/ProjectSettings/OperatorsWork/OperatorsWorkSettingsForm"

const tNamespace = "project:"
const tRoleNamespace = "roles:"
const tSlotNamespace = "slot:"
const tStickersNamespace = "stickers:"
const tArticleTypesNamespace = "articleTypes:"
const tSurveyNamespace = "surveys:"

export enum SettingsType {
    general = "general",
    channels = "channels",
    channelScenario = "channel-scenario",
    agents = "agents",
    classifiers = "classifiers",
    users = "users",
    roles = "roles",
    slots = "slots",
    stickers = "stickers",
    articleTypes = "article-types",
    surveys = "surveys",
    dialog = "dialog",
    operator = "operator",
    statistics = "statistics",
    system = "system"
}

interface TabEntryValue {
    value: string
    permission?: string
    disabled?: boolean
    component: ReactNode
    subItem?: boolean
    hidden?: boolean
}

type TabEntries = {
    [key in SettingsType]: TabEntryValue
}

interface SidebarContentBase extends WithTitle {
    settings?: JSX.Element[]
    stepBack?: boolean
    onBack?: () => void
    extended?: boolean
}

const addRoleForm = "addRoleForm"

interface RoleAddFormContent extends SidebarContentBase {
    type: typeof addRoleForm
    allRoleSettings: AllRoleSettings
}

const updateRoleForm = "updateRoleForm"

interface UpdateRoleFormContent extends SidebarContentBase {
    type: typeof updateRoleForm
    role: Role
    allRoleSettings: AllRoleSettings
}

const inviteUsersForm = "inviteUsersForm"

interface InviteUsersFormContent extends SidebarContentBase {
    type: typeof inviteUsersForm
    roles: Role[]
}

const updateProjectUserForm = "updateProjectUserForm"

interface UpdateProjectUserFormContent extends SidebarContentBase {
    type: typeof updateProjectUserForm
    user: ProjectUser
    roles: Role[]
    allRoleSettings: AllRoleSettings
}

const createSlotForm = "createSlotForm"

interface CreateSlotFormContent extends SidebarContentBase {
    type: typeof createSlotForm
    onAdd: (slotId: string, slotTitle: string) => void
}

const editSlotForm = "editSlotForm"

interface EditSlotFormContent extends SidebarContentBase {
    type: typeof editSlotForm
    slot: SlotDto
    onUpdate: (slot: SlotDto) => void
}

const surveyForm = "surveyForm"

interface SurveyFormContent extends SidebarContentBase {
    type: typeof surveyForm
    survey?: SurveyDto
    values?: SurveyValues
}

const addSlotForm = "addSlotForm"

interface AddSlotFormContent extends SidebarContentBase {
    type: typeof addSlotForm
    onSelect: (slotId: string, slotTitle: string) => void
    onCreate: () => void
}

const stickerForm = "stickerForm"

interface StickerFormContent extends SidebarContentBase {
    type: typeof stickerForm
    sticker?: Sticker
}

const articleTypeForm = "articleTypeForm"

interface ArticleTypeFormContent extends SidebarContentBase {
    type: typeof articleTypeForm
    articleType?: ArticleType
}

export type SidebarContent =
    | RoleAddFormContent
    | UpdateRoleFormContent
    | InviteUsersFormContent
    | UpdateProjectUserFormContent
    | CreateSlotFormContent
    | EditSlotFormContent
    | SurveyFormContent
    | AddSlotFormContent
    | StickerFormContent
    | ArticleTypeFormContent

export type FormChangeCallback = (formParams?: string) => void

interface Props {
    onTabChange: (key: string) => void
    url: string
    project: Project
}

const settingsTypes: SettingsType[] = [
    SettingsType.general,
    SettingsType.dialog,
    SettingsType.operator,
    SettingsType.statistics,
    SettingsType.system,
    SettingsType.channels,
    SettingsType.channelScenario,
    SettingsType.agents,
    SettingsType.classifiers,
    SettingsType.users,
    SettingsType.roles,
    SettingsType.slots,
    SettingsType.stickers,
    SettingsType.articleTypes,
    SettingsType.surveys
]

const projectSettingsTabs: string[] = [
    SettingsType.general,
    SettingsType.dialog,
    SettingsType.operator,
    SettingsType.statistics,
    SettingsType.system
]

const ProjectSettingsContent: React.FC<Props> = props => {
    const { onTabChange, url, project } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const { tabId } = useParams<{ tabId?: string }>()

    const defaultTab = tabId ? tabId : SettingsType.general
    const [key, setKey] = useState(defaultTab)

    const handleFormChange = useCallback(
        (formParams = "") => {
            dispatch(replace(`${url}/${key}/${formParams}`))
        },
        [dispatch, url, key]
    )

    const [sidebarClosed, setSidebarClosed] = useState(true)
    const [sidebarContent, setSidebarContent] = useState<SidebarContent | null>(null)

    const closeSidebar = useCallback(() => setSidebarClosed(true), [])

    const closeSidebarWithRouting = useCallback(() => {
        closeSidebar()
        handleFormChange()
    }, [closeSidebar, handleFormChange])

    const openSidebar = useCallback(() => setSidebarClosed(false), [])

    const handleAddRole = useCallback(
        (allRoleSettings: AllRoleSettings) => {
            closeSidebar()
            setSidebarContent({
                type: addRoleForm,
                title: t(`${tRoleNamespace}adding-role`),
                allRoleSettings
            })
            openSidebar()
        },
        [closeSidebar, openSidebar, t]
    )

    const handleUpdateRole = useCallback(
        (role: Role, allRoleSettings: AllRoleSettings) => {
            closeSidebar()
            setSidebarContent({
                type: updateRoleForm,
                title: t(`${tRoleNamespace}role-view`),
                role,
                allRoleSettings
            })
            openSidebar()
        },

        [closeSidebar, openSidebar, t]
    )

    const handleDeleteRole = useCallback(
        (role: Role) => {
            closeSidebar()
            setSidebarContent(null)
            dispatch(deleteRole(role.Id))
        },
        [closeSidebar, dispatch]
    )

    const handleAddSticker = useCallback(() => {
        closeSidebar()
        setSidebarContent({
            type: stickerForm,
            title: t(`${tStickersNamespace}creating-sticker`)
        })
        openSidebar()
    }, [closeSidebar, openSidebar, t])

    const [stickerToDelete, setStickerToDelete] = useState<Sticker | null>(null)
    const {
        modalOpen,
        openModal: openStickerModal,
        closeModal,
        onExited
    } = useModal(() => (
        <AlertDialog
            show={modalOpen}
            title={t(`${tStickersNamespace}delete-confirmation.title`)}
            message={t(`${tStickersNamespace}delete-confirmation.message`)}
            submitBtnText={t(formTranslation.delete)}
            onClose={closeModal}
            onSubmit={() => {
                if (!stickerToDelete) return

                closeSidebar()
                setSidebarContent(null)
                project && dispatch(deleteSticker(project.id, stickerToDelete.Id))
            }}
            variant="danger"
            onExited={onExited}
        />
    ))

    const handleUpdateSticker = useCallback(
        (sticker: Sticker) => {
            closeSidebar()

            const settings = [
                <SettingsContextMenuItem
                    key={sticker.Id}
                    id={sticker.Id}
                    icon={faTrash}
                    text={t(formTranslation.delete)}
                    danger
                    onClick={() => {
                        setStickerToDelete(sticker)
                        openStickerModal()
                    }}
                />
            ]
            setSidebarContent({
                type: stickerForm,
                title: t(`${tStickersNamespace}editing-sticker`),
                sticker,
                settings
            })
            openSidebar()
        },

        [closeSidebar, openSidebar, t, openStickerModal]
    )

    const handleAddArticleType = useCallback(() => {
        closeSidebar()
        setSidebarContent({
            type: articleTypeForm,
            title: t(`${tArticleTypesNamespace}creating-article-type`)
        })
        openSidebar()
    }, [closeSidebar, openSidebar, t])

    const handleUpdateArticleType = useCallback(
        (articleType: ArticleType) => {
            closeSidebar()
            setSidebarContent({
                type: articleTypeForm,
                title: t(`${tArticleTypesNamespace}editing-article-type`),
                articleType
            })
            openSidebar()
        },

        [closeSidebar, openSidebar, t]
    )

    const handleDeleteArticleType = useCallback(
        (articleType: ArticleType) => {
            closeSidebar()
            setSidebarContent(null)
            dispatch(deleteArticleType(articleType.Id))
        },
        [closeSidebar, dispatch]
    )

    const handleAddSurvey = useCallback(
        (values?: SurveyValues) => {
            closeSidebar()
            setSidebarContent({
                type: surveyForm,
                title: t(`${tSurveyNamespace}survey-template`),
                values
            })
            openSidebar()
        },
        [closeSidebar, openSidebar, t]
    )

    const handleUpdateSurvey = useCallback(
        (survey: SurveyDto, values?: SurveyValues) => {
            closeSidebar()
            setSidebarContent({
                type: surveyForm,
                title: t(`${tSurveyNamespace}survey-template`),
                survey,
                values
            })
            openSidebar()
        },

        [closeSidebar, openSidebar, t]
    )

    const handleDeleteSurvey = useCallback(
        (projectId: string, survey: SurveyValues) => {
            closeSidebar()
            setSidebarContent(null)
            dispatch(deleteSurvey(projectId, survey.Id))
        },
        [closeSidebar, dispatch]
    )

    const handleCreateSlot = useCallback(
        (callback: (slotId: string, slotTitle: string) => void, onBack?: () => void) => () => {
            closeSidebar()
            setSidebarContent({
                type: createSlotForm,
                title: t(`${tSlotNamespace}create-slot`),
                extended: true,
                onAdd: callback,
                onBack
            })
            openSidebar()
        },
        [closeSidebar, openSidebar, t]
    )

    const handleAddSlot = useCallback(
        (values: SurveyValues, survey?: SurveyDto) => {
            closeSidebar()

            const handleGetBack = () => handleAddSlot(values, survey)
            const handleSelectSlot = (slotId: string, slotTitle: string) => {
                survey
                    ? handleUpdateSurvey(survey, pushField(slotId, slotTitle, values))
                    : handleAddSurvey(pushField(slotId, slotTitle, values))
            }

            setSidebarContent({
                type: addSlotForm,
                title: t(`${tSurveyNamespace}add-slot`),
                onSelect: handleSelectSlot,
                onCreate: handleCreateSlot(handleSelectSlot, handleGetBack),
                stepBack: true,
                onBack: () => {
                    survey ? handleUpdateSurvey(survey, values) : handleAddSurvey(values)
                }
            })
            openSidebar()
        },
        [t, openSidebar, closeSidebar, handleCreateSlot, handleAddSurvey, handleUpdateSurvey]
    )

    const handleInviteUsers = useCallback(
        (roles: Role[]) => {
            closeSidebar()
            setSidebarContent({
                type: inviteUsersForm,
                title: t(`${tNamespace}invite-users`),
                roles
            })
            openSidebar()
        },
        [closeSidebar, openSidebar, t]
    )

    const handleUpdateUser = useCallback(
        (user: ProjectUser, allRoleSettings: AllRoleSettings, roles: Role[]) => {
            closeSidebar()
            setSidebarContent({
                type: updateProjectUserForm,
                title: t(`${tNamespace}user-view`),
                user,
                allRoleSettings,
                roles
            })
            openSidebar()
        },
        [closeSidebar, openSidebar, t]
    )

    const handleSelect = useCallback(
        (key: string | null) => {
            if (key === null) return
            onTabChange(key)
            setKey(key)
            closeSidebar()
        },
        [onTabChange, closeSidebar]
    )

    const handleBlockUser = useCallback(
        (user: ProjectUser) => {
            closeSidebar()
            setSidebarContent(null)
            dispatch(blockUser({ Login: user.Login, ProjectId: user.ProjectId }))
        },
        [closeSidebar, dispatch]
    )

    const handleEditSlot = useCallback(
        (onUpdate: (slot: SlotDto) => void) => (slot: SlotDto) => {
            closeSidebar()
            setSidebarContent({
                type: editSlotForm,
                title: t(`${tSlotNamespace}edit-slot`),
                slot,
                extended: true,
                onUpdate: (slot: SlotDto) => {
                    onUpdate(slot)
                    closeSidebar()
                }
            })
            openSidebar()
        },
        [closeSidebar, openSidebar, t]
    )

    const handleDeleteSlot = useCallback(
        (slotId: string, callback: () => void) => {
            closeSidebar()
            setSidebarContent(null)
            project &&
                dispatch(
                    deleteSlot(project.id, slotId, () => {
                        callback()
                    })
                )
        },
        [closeSidebar, dispatch, project]
    )

    const renderSidebarContent = useCallback(
        (project: Project) => {
            if (sidebarContent === null) return null
            switch (sidebarContent.type) {
                case addRoleForm:
                    return (
                        <RoleForm
                            projectId={project.id}
                            allRoleSettings={sidebarContent.allRoleSettings}
                            close={closeSidebar}
                        />
                    )
                case updateRoleForm:
                    return (
                        <RoleForm
                            projectId={project.id}
                            role={sidebarContent.role}
                            allRoleSettings={sidebarContent.allRoleSettings}
                            close={closeSidebar}
                        />
                    )
                case inviteUsersForm:
                    return <InviteUsersForm project={project} roles={sidebarContent.roles} close={closeSidebar} />
                case updateProjectUserForm:
                    return (
                        <ProjectUserForm
                            user={sidebarContent.user}
                            roles={sidebarContent.roles}
                            allRoleSettings={sidebarContent.allRoleSettings}
                            close={closeSidebar}
                        />
                    )
                case stickerForm:
                    return <StickerForm projectId={project.id} sticker={sidebarContent.sticker} close={closeSidebar} />
                case articleTypeForm:
                    return (
                        <ArticleTypeForm
                            projectId={project.id}
                            articleType={sidebarContent.articleType}
                            close={closeSidebar}
                        />
                    )
                case createSlotForm:
                    return <SlotForm onAddSlot={sidebarContent.onAdd} />
                case editSlotForm:
                    return <SlotForm slot={sidebarContent.slot} onUpdateSlot={sidebarContent.onUpdate} />
                case surveyForm:
                    return (
                        <SurveyForm
                            onAddSlot={handleAddSlot}
                            projectId={project.id}
                            survey={sidebarContent.survey}
                            values={sidebarContent.values}
                            onClose={closeSidebar}
                        />
                    )
                case addSlotForm:
                    return <ChooseSlotForm onSelect={sidebarContent.onSelect} onCreate={sidebarContent.onCreate} />
                default:
                    return null
            }
        },
        [closeSidebar, sidebarContent, handleAddSlot]
    )

    const isSettingsReceived = useRef(false)
    const isGeneralActive = !tabId || projectSettingsTabs.includes(tabId)

    const tabEntries: TabEntries = {
        [SettingsType.general]: {
            value: t(`${tNamespace}general`),
            component: (
                <>
                    <div className="project-settings__header">
                        <div className="project-settings__title">{t(`${tNamespace}general`)}</div>
                    </div>
                    <ProjectSettingsForm project={project} />
                </>
            )
        },
        [SettingsType.dialog]: {
            value: t(`${tNamespace}dialog`),
            component: (
                <DialogProjectSettingsForm
                    title={t(`${tNamespace}${SettingsType.dialog}`)}
                    projectId={project.id}
                    open={key === SettingsType.dialog}
                />
            ),
            subItem: true,
            hidden: !isGeneralActive
        },
        [SettingsType.operator]: {
            value: t(`${tNamespace}operator`),
            component: (
                <OperatorsWorkSettingsForm
                    title={t(`${tNamespace}${SettingsType.operator}`)}
                    projectId={project.id}
                    open={key === SettingsType.operator}
                />
            ),
            subItem: true,
            hidden: !isGeneralActive
        },
        [SettingsType.statistics]: {
            value: t(`${tNamespace}statistics`),
            component: (
                <StatisticsProjectSettings
                    title={t(`${tNamespace}${SettingsType.statistics}`)}
                    projectId={project.id}
                    open={key === SettingsType.statistics}
                />
            ),
            subItem: true,
            hidden: !isGeneralActive
        },
        [SettingsType.system]: {
            value: t(`${tNamespace}system`),
            component: (
                <SystemProjectSettingsForm
                    projectId={project.id}
                    title={t(`${tNamespace}${SettingsType.system}`)}
                    open={key === SettingsType.system}
                />
            ),
            subItem: true,
            hidden: !isGeneralActive
        },
        [SettingsType.channels]: {
            value: t(`${tNamespace}channels`),
            permission: ViewChannels,
            component: <ChannelSettings onFormChange={handleFormChange} />
        },
        [SettingsType.channelScenario]: {
            value: t(`${tNamespace}channel-scenario`),
            permission: Supervisor,
            disabled: true,
            component: <ChannelScenario />
        },
        [SettingsType.agents]: {
            value: t(`${tNamespace}agents`),
            permission: ViewAgents,
            component: <AgentSettings onFormChange={handleFormChange} />
        },
        [SettingsType.classifiers]: {
            value: t(`${tNamespace}machine-learning`),
            permission: ControlClassifiers,
            component: <Classifiers />
        },
        [SettingsType.users]: {
            value: t(`${tNamespace}users`),
            permission: ViewUsers,
            component: (
                <ProjectUsersContainer
                    projectId={project?.id}
                    title={t(`${tNamespace}${SettingsType.users}`)}
                    onInviteClick={handleInviteUsers}
                    onSettingsClick={handleUpdateUser}
                    onBlockClick={handleBlockUser}
                    onFormChange={handleFormChange}
                />
            )
        },
        [SettingsType.roles]: {
            value: t(`${tNamespace}roles`),
            permission: ViewPositions,
            component: (
                <ProjectRolesContainer
                    projectId={project?.id}
                    title={t(`${tNamespace}${SettingsType.roles}`)}
                    onAddClick={handleAddRole}
                    onSettingsClick={handleUpdateRole}
                    onDeleteClick={handleDeleteRole}
                />
            )
        },
        [SettingsType.slots]: {
            value: t(`${tNamespace}slots`),
            component: (
                <Slots
                    projectId={project?.id}
                    title={t(`${tNamespace}${SettingsType.slots}`)}
                    onCreate={(callback: () => void) =>
                        handleCreateSlot(() => {
                            closeSidebar()
                            callback()
                        })
                    }
                    onSettingsClick={handleEditSlot}
                    onDelete={handleDeleteSlot}
                />
            )
        },
        [SettingsType.stickers]: {
            value: t(`${tNamespace}stickers`),
            component: (
                <ProjectStickersContainer
                    projectId={project.id}
                    title={t(`${tNamespace}${SettingsType.stickers}`)}
                    onAddClick={handleAddSticker}
                    onSettingsClick={handleUpdateSticker}
                />
            )
        },
        [SettingsType.articleTypes]: {
            value: t(`${tNamespace}article-types`),
            component: (
                <ProjectArticleTypesContainer
                    projectId={project.id}
                    title={t(`${tArticleTypesNamespace}${SettingsType.articleTypes}`)}
                    onAddClick={handleAddArticleType}
                    onSettingsClick={handleUpdateArticleType}
                    onDeleteClick={handleDeleteArticleType}
                />
            )
        },
        [SettingsType.surveys]: {
            value: t(`${tNamespace}surveys`),
            component: (
                <ProjectSurveysContainer
                    projectId={project.id}
                    title={t(`${tNamespace}${SettingsType.surveys}`)}
                    onAddClick={() => handleAddSurvey()}
                    onSettingsClick={handleUpdateSurvey}
                    onDeleteClick={handleDeleteSurvey}
                />
            )
        }
    }

    useEffect(() => {
        if (!isSettingsReceived.current && isGeneralActive) {
            dispatch(getProjectSettings(project.id))
            isSettingsReceived.current = true

            dispatch(subscribeOnProjectSettingsUpdate(project.id))
            return () => {
                dispatch(unsubscribeOnProjectSettingsUpdate(project.id))
            }
        }
    }, [dispatch, project, isGeneralActive])

    return (
        <PageLayout isCollapsed={sidebarClosed}>
            <Tab.Container
                id="project-settings-tabs"
                activeKey={key}
                mountOnEnter={true}
                unmountOnExit={true}
                onSelect={handleSelect}
            >
                <PageLayout.LeftSidebar title={t(`${tNamespace}${definedMenuItems.Settings.title}`)}>
                    <Nav variant="pills" className="flex-column">
                        {settingsTypes
                            .filter(type => !tabEntries[type].hidden)
                            .map(
                                type =>
                                    !tabEntries[type].disabled && (
                                        <ConditionalWrapper
                                            key={type}
                                            conditionValue={tabEntries[type].permission}
                                            wrapper={(children, permission) => (
                                                <Can permission={permission}>{children}</Can>
                                            )}
                                        >
                                            <Nav.Item>
                                                <Nav.Link eventKey={type} bsPrefix="project-settings__nav-link">
                                                    <MenuItemRow
                                                        icon={<ProjectSettingsMenuIcon menuId={type} />}
                                                        title={tabEntries[type].value}
                                                        selected={type === key}
                                                        className={cn(
                                                            "nav-link__menu-item",
                                                            tabEntries[type].subItem && "nav-link__menu-item_sub-item"
                                                        )}
                                                        testId={type}
                                                    />
                                                </Nav.Link>
                                            </Nav.Item>
                                        </ConditionalWrapper>
                                    )
                            )}
                    </Nav>
                </PageLayout.LeftSidebar>
                <PageLayout.Content className="project-settings">
                    <Tab.Content>
                        {settingsTypes.map(
                            type =>
                                !tabEntries[type].disabled && (
                                    <ConditionalWrapper
                                        key={type}
                                        conditionValue={tabEntries[type].permission}
                                        wrapper={(children, permission) => (
                                            <Can permission={permission}>{children}</Can>
                                        )}
                                    >
                                        <Tab.Pane key={type} className="project-settings__content" eventKey={type}>
                                            {tabEntries[type].component}
                                        </Tab.Pane>
                                    </ConditionalWrapper>
                                )
                        )}
                    </Tab.Content>
                </PageLayout.Content>
                <PageLayout.Sidebar
                    title={sidebarContent ? sidebarContent.title : ""}
                    onClose={closeSidebar}
                    onCloseClick={closeSidebarWithRouting}
                    settings={sidebarContent?.settings}
                    onBack={sidebarContent?.onBack}
                    extended={sidebarContent?.extended}
                >
                    {renderSidebarContent(project)}
                </PageLayout.Sidebar>
            </Tab.Container>
        </PageLayout>
    )
}

const ProjectSettings: React.FC = () => {
    const { url, path } = useRouteMatch()
    const dispatch = useDispatch()
    const project = useSelector(selectCurrentProject)

    const handleTabChange = useCallback(
        (key: string) => {
            dispatch(push(`${url}/${key}`))
        },
        [dispatch, url]
    )

    if (!project) {
        return null
    }

    return (
        <Route path={path + "/:tabId?/:formId?/:param?"}>
            <ProjectSettingsContent onTabChange={handleTabChange} url={url} project={project} />
        </Route>
    )
}

export default ProjectSettings
