import React, { useCallback, useEffect, useState } from "react"
import styles from "../KnowledgeBase/KnowledgeBase.module.scss"
import sharedStyles from "./SharedKnowledgeBase.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { Route, useRouteMatch } from "react-router-dom"
import PageLayout from "../../components/PageLayout/PageLayout"
import { KnowledgeBaseWorkMode } from "../../models/knowledgeBaseWorkMode"
import SearchQueryArticlesContainer from "../../components/SearchQueryArticlesContainer/SearchQueryArticlesContainer"
import { useTranslation } from "react-i18next"
import SharedCatalogs from "../../components/Catalogs/SharedCatalogs/SharedCatalogs"
import { selectKnowledgeBaseWorkMode, selectSharedTypeState } from "../../store/knowledgeBase/selectors"
import cn from "classnames"
import { articlePath } from "../../routerPaths"
import { Switch } from "react-router"
import SharedKnowledgeBaseContextProvider from "../../components/KnowledgeBaseProvider/SharedKnowledgeBaseProvider/SharedKnowledgeBaseContextProvider"
import Async from "../../components/Async/Async"
import FullscreenLoader from "../../components/FullscreenLoader/FullscreenLoader"
import FullscreenError from "../../components/FullscreenError/FullscreenError"
import { SharedType } from "../../models/sharing"
import {
    getSharedArticle,
    getSharedArticleTypes,
    getSharedCatalog,
    getSharedType
} from "../../store/knowledgeBase/sharedThunks"
import SharedArticleViewContainer from "../../components/ArticleView/SharedArticleViewContainer/SharedArticleViewContainer"
import useSharedKnowledgeBaseSidebar from "./SharedKnowledgeBaseSidebar"
import SharedSearchArticleInputContainer from "../../components/SearchArticlesInput/SharedSearchArticleInputContainer/SharedSearchArticleInputContainer"
import { sharedKnowledgeBaseHub } from "../../api/instances"

const SharedKnowledgeBase: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {
        path,
        url,
        params: { shareId }
    } = useRouteMatch<{ shareId: string }>()
    const { sidebarClosed, sidebarContent, closeSidebar, handleChooseAnswer, renderSidebarContent } =
        useSharedKnowledgeBaseSidebar(t)
    const [connected, setConnected] = useState<boolean>(false)
    const workMode = useSelector(selectKnowledgeBaseWorkMode)
    const sharedTypeState = useSelector(selectSharedTypeState)

    const isCatalogsVisible = workMode === KnowledgeBaseWorkMode.CatalogsView

    useEffect(() => {
        if (shareId) {
            dispatch(getSharedType(shareId))
        }
    }, [dispatch, shareId])

    useEffect(() => {
        if (shareId && sharedTypeState.data) {
            //TODO loading twice
            if (sharedTypeState.data === SharedType.Catalog) {
                dispatch(getSharedCatalog(shareId))
            }
            if (sharedTypeState.data === SharedType.Article) {
                dispatch(getSharedArticle(shareId, url))
            }
            dispatch(getSharedArticleTypes(shareId))
        }
    }, [dispatch, shareId, sharedTypeState.data, url])

    useEffect(() => {
        sharedKnowledgeBaseHub.connect().then(() => {
            setConnected(true)
            sharedKnowledgeBaseHub.setReconnectingCallback(() => setConnected(false))
            sharedKnowledgeBaseHub.setReconnectedCallback(() => setConnected(true))
        })
    }, [])

    useEffect(() => {
        sharedKnowledgeBaseHub.setPageUrl(url)
    }, [url])

    const handleSubscribeToArticle = useCallback((articleCode: string) => {
        sharedKnowledgeBaseHub.subscribe(articleCode)
    }, [])

    const handleUnsubscribeFromArticle = useCallback((articleCode: string) => {
        sharedKnowledgeBaseHub.unsubscribe(articleCode)
    }, [])

    return (
        <SharedKnowledgeBaseContextProvider
            value={{
                onChooseAnswer: handleChooseAnswer,
                shareId,
                url
            }}
        >
            <Async
                dataState={sharedTypeState}
                processView={<FullscreenLoader />}
                errorView={({ message }) => <FullscreenError message={message} />}
            >
                {({ data: sharedType }) => (
                    <PageLayout isCollapsed={sidebarClosed}>
                        {sharedType === SharedType.Catalog && (
                            <PageLayout.LeftSidebar
                                title=""
                                headerClassName={sharedStyles.sharedKnowledgeBase__leftSidebarHeader}
                                intermediate={
                                    <SharedSearchArticleInputContainer
                                        shareId={shareId}
                                        className={styles.knowledgeBase__search}
                                    />
                                }
                                isScrollable
                            >
                                {workMode === KnowledgeBaseWorkMode.Search && (
                                    <SearchQueryArticlesContainer shareId={shareId} />
                                )}
                                <div
                                    className={
                                        isCatalogsVisible
                                            ? cn(styles.knowledgeBase__catalogs, styles.knowledgeBase__catalogs_visible)
                                            : cn(styles.knowledgeBase__catalogs, styles.knowledgeBase__catalogs_hidden)
                                    }
                                >
                                    <SharedCatalogs url={url} />
                                </div>
                            </PageLayout.LeftSidebar>
                        )}

                        <PageLayout.Content overlappingSidebar>
                            <Switch>
                                <Route path={`${path}${articlePath}/:code`}>
                                    <SharedArticleViewContainer
                                        onSubscribeToArticle={handleSubscribeToArticle}
                                        onUnsubscribeFromArticle={handleUnsubscribeFromArticle}
                                        connected={connected}
                                    />
                                </Route>
                            </Switch>
                        </PageLayout.Content>

                        <PageLayout.Sidebar
                            title={sidebarContent ? sidebarContent.title : ""}
                            onClose={closeSidebar}
                            stepBack={sidebarContent?.stepBack}
                            onBack={sidebarContent?.onBack}
                            extended={sidebarContent?.extended}
                        >
                            {renderSidebarContent()}
                        </PageLayout.Sidebar>
                    </PageLayout>
                )}
            </Async>
        </SharedKnowledgeBaseContextProvider>
    )
}

export default SharedKnowledgeBase
