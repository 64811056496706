export interface Message {
    MsgId: string
    MsgType: MessageType
    Direction: MessageDirection
    IdInChannel: string
    ChannelId?: string
    ReplyToMessage?: Message
    Attachment?: MessageAttachment
    Attachments?: MessageAttachment[]
    Sticker?: Sticker
    Text: string
    Actions: Action[]
    ClientId: number
    OperatorId?: number
    OperatorName: string
    CallCenterOperatorId?: string
    DialogID: string
    UserPic?: string
    UserName?: string
    Context: string[][]
    IntentQaId?: string
    Flags: MessageFlags[]
    IsServiceMessage: boolean
    IsClientServiceMessage: boolean
    Added: string
    Meta: Record<string, string>
}

export enum MessageType {
    Initial = 0,
    Message = 1,
    SentConfirmation = 2,
    ReferenceCreated = 3,
    ReadConfirmation = 4,
    FailedConfirmation = 5,
    UpdateDialogProgram = 6,
    WritingStop = 7,
    Deleted = 8,
    PhoneNumber = 9,
    Location = 10,
    ReceivedByMediator = 11,
    ReceivedByOperator = 12,
    OperatorIsTyping = 13,
    OperatorStoppedTyping = 14,
    UpdateDialogScore = 15,
    FinishDialog = 16,
    CloseDialogIntention = 17,
    ConnectedOperator = 18,
    DialogFinished = 19,
    AutoGreeting = 20,
    UpdateDialogNegativeReason = 21,
    UpdateUserData = 22,
    ClientHold = 23,
    UpdateDialogUsefulness = 24,
    CobrowsingRequest = 100,
    CobrowsingStarted = 101,
    CobrowsingDenied = 102,
    CobrowsingStateUpdate = 103,
    CobrowsingFinished = 104
}

export enum MessageDirection {
    Incoming = 0,
    Outgoing = 1
}

export interface MessageAttachment {
    Url: string
    ContentType: string
    Name: string
}

interface Sticker {
    sticker_id: string
    sticker_url?: string
    animation_type: StickerAnimationType
}

enum StickerAnimationType {
    NoAnimation,
    Lottie
}

enum MessageFlags {
    Unknown = -1,
    Sent = 0,
    Delivered = 1,
    Read = 2,
    Failed = 3,
    Deleted = 4,
    Phone = 5,
    Location = 6,
    AutoGreeting = 7
}

export interface Action {
    action_id: string
    action_text: string
}
