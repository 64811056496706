import React, { useMemo } from "react"
import { ChannelFormProps, Props } from "./ChannelForm"
import CommonChannelForm from "../CommonChannelForm/CommonChannelForm"
import FormikTelegramChannelFormGeneral from "../TelegramChannelForm/TelegramChannelGeneral"
import FormikTelegramChannelFormAppearance from "../TelegramChannelForm/TelegramChannelAppearance"
import FormikTelegramChannelFormAdvanced from "../TelegramChannelForm/TelegramChannelAdvanced"
import FormikVKChannelFormGeneral from "../VKChannelForm/VKChannelGeneral"
import FormikVKChannelFormAppearance from "../VKChannelForm/VKChannelAppearance"
import FormikVKChannelFormAdvanced from "../VKChannelForm/VKChannelAdvanced"
import FormikViberChannelFormGeneral from "../ViberChannelForm/ViberChannelGeneral"
import FormikViberChannelFormAppearance from "../ViberChannelForm/ViberChannelAppearance"
import FormikViberChannelFormAdvanced from "../ViberChannelForm/ViberChannelAdvanced"
import FormikOdnoklassnikiChannelFormGeneral from "../OdnoklassnikiChannelForm/OdnoklassnikiChannelGeneral"
import FormikOdnoklassnikiChannelFormAppearance from "../OdnoklassnikiChannelForm/OdnoklassnikiChannelAppearance"
import FormikOdnoklassnikiChannelFormAdvanced from "../OdnoklassnikiChannelForm/OdnoklassnikiChannelAdvanced"
import FormikFacebookChannelFormGeneral from "../FacebookChannelForm/FacebookChannelGeneral"
import FormikFacebookChannelFormAppearance from "../FacebookChannelForm/FacebookChannelAppearance"
import FormikFacebookChannelFormAdvanced from "../FacebookChannelForm/FacebookChannelAdvanced"
import FormikMSTeamsChannelFormGeneral from "../MSTeamsChannelForm/MSTeamsChannelGeneral"
import FormikMSTeamsChannelFormAppearance from "../MSTeamsChannelForm/MSTeamsChannelAppearance"
import FormikMSTeamsChannelFormAdvanced from "../MSTeamsChannelForm/MSTeamsChannelAdvanced"
import FormikEmailChannelFormGeneral from "../EmailChannelForm/EmailChannelGeneral"
import FormikEmailChannelFormAppearance from "../EmailChannelForm/EmailChannelAppearance"
import FormikEmailChannelFormAdvanced from "../EmailChannelForm/EmailChannelAdvanced"
import {
    FormikYandexAlisaChannelFormAdvanced,
    FormikYandexAlisaChannelFormGeneral
} from "../YandexAlisaChannelForm/YandexAlisaChannelForm"
import {
    FormikYandexDialogsChannelFormGeneral,
    FormikYandexDialogsChannelFormAdvanced
} from "../YandexDialogsChannelForm/YandexDialogsChannelForm"
import { ChannelTypeString } from "../../models/channel"
import FormikInstagramChannelFormGeneral from "../InstagramChannelForm/InstagramChannelGeneral"
import FormikInstagramChannelFormAppearance from "../InstagramChannelForm/InstagramChannelAppearance"
import FormikInstagramChannelFormAdvanced from "../InstagramChannelForm/InstagramChannelAdvanced"

interface MappingType {
    [key: string]: React.ElementType[]
}

const mapChannelTypeToForms: MappingType = {
    [ChannelTypeString.Telegram]: [
        FormikTelegramChannelFormGeneral,
        FormikTelegramChannelFormAppearance,
        FormikTelegramChannelFormAdvanced
    ],
    [ChannelTypeString.VK]: [FormikVKChannelFormGeneral, FormikVKChannelFormAppearance, FormikVKChannelFormAdvanced],
    [ChannelTypeString.Odnoklassniki]: [
        FormikOdnoklassnikiChannelFormGeneral,
        FormikOdnoklassnikiChannelFormAppearance,
        FormikOdnoklassnikiChannelFormAdvanced
    ],
    [ChannelTypeString.Viber]: [
        FormikViberChannelFormGeneral,
        FormikViberChannelFormAppearance,
        FormikViberChannelFormAdvanced
    ],
    [ChannelTypeString.Facebook]: [
        FormikFacebookChannelFormGeneral,
        FormikFacebookChannelFormAppearance,
        FormikFacebookChannelFormAdvanced
    ],
    [ChannelTypeString.MSBot]: [
        FormikMSTeamsChannelFormGeneral,
        FormikMSTeamsChannelFormAppearance,
        FormikMSTeamsChannelFormAdvanced
    ],
    [ChannelTypeString.Teams]: [
        FormikMSTeamsChannelFormGeneral,
        FormikMSTeamsChannelFormAppearance,
        FormikMSTeamsChannelFormAdvanced
    ],
    [ChannelTypeString.Email]: [
        FormikEmailChannelFormGeneral,
        FormikEmailChannelFormAppearance,
        FormikEmailChannelFormAdvanced
    ],
    [ChannelTypeString.YandexAlisa]: [FormikYandexAlisaChannelFormGeneral, FormikYandexAlisaChannelFormAdvanced],
    [ChannelTypeString.YandexDialogs]: [FormikYandexDialogsChannelFormGeneral, FormikYandexDialogsChannelFormAdvanced],
    [ChannelTypeString.Instagram]: [
        FormikInstagramChannelFormGeneral,
        FormikInstagramChannelFormAppearance,
        FormikInstagramChannelFormAdvanced
    ]
}

const ChannelFormByType: React.FC<ChannelFormProps & Props> = props => {
    const availableTypes = useMemo(() => Object.keys(mapChannelTypeToForms), [])

    if (!availableTypes.includes(props.channelType)) {
        return null
    }

    return <CommonChannelForm {...props}>{mapChannelTypeToForms[props.channelType]}</CommonChannelForm>
}

export default ChannelFormByType
