export enum ButtonAttribute {
    Type = "type",
    Action = "action",
    Visible = "visible",
    Color = "color",
    Image = "image",
    ImageAlign = "image_align",
    ImageEmoji = "image_emoji",
    Id = "id",
    IsExternal = "is_external"
}
