import { Task } from "./task"

export enum QueueType {
    Auto,
    Manual
}

export interface TimeoutSettings {
    TimeoutQueueId: string
    TimeoutMSec: number
}

export interface IndividualTimeoutSettings {
    TimeoutMSec: number
}

export interface SlSettings {
    SlThresholdMSec: number
    SlPeriodMSec: number
    SlTarget: number
}

export interface WaitTimeSettings {
    Id: string
    LookbehindMSec: number
}

export interface FinishedDialogsSettings {
    Id: string
    LookbehindMSec: number
}

export interface FinishedDialogsDailySettings {
    Id: string
    LocalResetTime: number
}

export interface CreateQueueRequest {
    Name: string
    Type?: QueueType
    TaskSize?: number
    Priority?: number
    TimeoutSettings?: TimeoutSettings
    SlSettings?: SlSettings
    AwtStatsSettings?: WaitTimeSettings[]
    FinishedDialogsSettings?: FinishedDialogsSettings[]
    FinishedDialogsDailySettings?: FinishedDialogsDailySettings[]
    RoutingWebHook?: string
}

export type CreateQueueInCategoryRequest = CreateQueueRequest & { CategoryId: string }

export interface UpdateQueueRequest {
    Name: string
    Type: QueueType
    TaskSize: number
    Priority: number
    TimeoutSettings: TimeoutSettings | null
    SlSettings: SlSettings | null
    AwtStatsSettings: WaitTimeSettings[] | null
    FinishedDialogsSettings?: FinishedDialogsSettings[] | null
    FinishedDialogsDailySettings?: FinishedDialogsDailySettings[] | null
    RoutingWebHook: string | null
}

export type UpdateQueueInCategoryRequest = UpdateQueueRequest & { CategoryId: string }

export interface UpdateIndividualQueuesRequest {
    Priority: number
    TimeoutSettings: IndividualTimeoutSettings
}

export interface OperatorsTasksCount {
    [id: string]: number
}

export interface FinishedDialogsSummary {
    Handled: number
    Lost: number
}

export interface FinishedDialogs {
    [id: string]: FinishedDialogsSummary
}

export interface FinishedDialogsDaily {
    [id: string]: FinishedDialogsSummary
}

export interface Awt {
    [id: string]: number
}

export interface Queue {
    Id: string
    TenantId: string
    Type: QueueType
    CategoryId: string
    Name: string
    TaskSize: number
    Priority: number
    ActiveTasksCount?: number
    TimeoutSettings?: TimeoutSettings
    IndividualPriority: number
    IndividualTimeoutSettings: IndividualTimeoutSettings
    SlSettings?: SlSettings
    AwtStatsSettings?: WaitTimeSettings[]
    FinishedDialogsSettings?: FinishedDialogsSettings[]
    FinishedDialogsDailySettings?: FinishedDialogsDailySettings[]
    RoutingWebHook?: string
    LastPickTime: number
    Sl?: number
    Awt: Awt
    FinishedDialogs: FinishedDialogs
    FinishedDialogsDaily: FinishedDialogsDaily
    CSI?: number
    OperatorsIds: string[]
    OperatorsTasksCount: OperatorsTasksCount
    PendingTasks: Task[]
    PendingIndividualTasks: Task[]
}

export const isQueue = (value: any): value is Queue => {
    return !!(
        typeof value === "object" &&
        typeof value.Id === "string" &&
        typeof value.TenantId === "string" &&
        typeof value.CategoryId === "string" &&
        typeof value.TaskSize === "number" &&
        typeof value.Priority === "number" &&
        typeof value.IndividualPriority === "number" &&
        typeof value.IndividualTimeoutSettings === "object" &&
        typeof value.LastPickTime === "number" &&
        typeof value.Awt === "object" &&
        typeof value.FinishedDialogs === "object" &&
        typeof value.FinishedDialogsDaily === "object" &&
        Array.isArray(value.OperatorsIds) &&
        typeof value.OperatorsTasksCount === "object" &&
        Array.isArray(value.PendingTasks) &&
        Array.isArray(value.PendingIndividualTasks)
    )
}

export interface QueueName {
    Id: string
    Name: string
}

export interface QueueAddedMsg {
    Queue: Queue
}

export const isQueueAddedMsg = (value: any): value is QueueAddedMsg => {
    return typeof value === "object" && isQueue(value.Queue)
}

export interface QueueUpdatedMsg {
    QueueId: string
    CategoryId: string
    Name: string
    Type: QueueType
    TaskSize: number
    Priority: number
    TimeoutSettings?: TimeoutSettings
    IndividualPriority: number
    IndividualTimeoutSettings: IndividualTimeoutSettings
    SlSettings: SlSettings
    AwtStatsSettings: WaitTimeSettings[]
    FinishedDialogsSettings?: FinishedDialogsSettings[]
    FinishedDialogsDailySettings?: FinishedDialogsDailySettings[]
    RoutingWebHook?: string
}

export interface QueueSlUpdatedMsg {
    QueueId: string
    CategoryId: string
    Sl: number
}

export interface QueueAwtUpdatedMsg {
    QueueId: string
    CategoryId: string
    Awt: Awt
}

export interface QueueOperatorTasksCountUpdatedMsg {
    OperatorId: string
    Count: number
}

export interface QueueFinishedDialogsUpdatedMsg {
    QueueId: string
    CategoryId: string
    FinishedDialogs: FinishedDialogs
}

export interface QueueFinishedDialogsDailyUpdatedMsg {
    QueueId: string
    CategoryId: string
    FinishedDialogs: FinishedDialogsDaily
}

export type MonitoringOverview = {
    AutomaticProcessedDialogs: number
    FirstAutomaticProcessedDialogStartedTime: number
    LastAutomaticProcessedDialogStartedTime: number
}

export type QueuesWithMonitoringOverviewDtoResponse = {
    Queues: Queue[]
    MonitoringOverview?: MonitoringOverview
}

export type QueuesWithMonitoringOverviewResponse = {
    Queues: Queue[]
    MonitoringOverview: MonitoringOverview
}
