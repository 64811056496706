import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Formik } from "formik"
import * as Yup from "yup"
import { StickerChannel } from "../../../models/sticker"
import FormikArticleTypeForm from "./FormikArticleTypeForm"
import { ArticleType } from "../../../models/articleType"
import { createArticleType, updateArticleType } from "../../../store/knowledgeBase/thunks"
import { projectIdRegex } from "../../../utility/common/projectIdRegex"

const tNamespace = "articleTypes:form.error."

export const allStickersChannels = [
    StickerChannel.Viber,
    StickerChannel.Telegram,
    StickerChannel.Odnoklassniki,
    StickerChannel.Vkontakte,
    StickerChannel.WebChat
]

const getDefaultValues = (): ArticleType => ({
    Id: "",
    Title: ""
})

interface Props {
    projectId: string
    articleType?: ArticleType
    close: () => void
}

const ArticleTypeForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { projectId, articleType, close } = props

    return (
        <Formik
            enableReinitialize={true}
            initialValues={articleType ? { ...articleType } : getDefaultValues()}
            validationSchema={() =>
                Yup.object().shape({
                    Id: Yup.string()
                        .requiredExcludeEmpty(`${tNamespace}id-required`)
                        .matches(projectIdRegex, {
                            message: `${tNamespace}invalid-id`,
                            excludeEmptyString: true
                        }),
                    Title: Yup.string().requiredExcludeEmpty(`${tNamespace}title-required`)
                })
            }
            onSubmit={(values: ArticleType) => {
                if (articleType) {
                    dispatch(updateArticleType(projectId, values, close))
                } else {
                    dispatch(createArticleType(projectId, values, close))
                }
            }}
        >
            {formikProps => <FormikArticleTypeForm articleType={articleType} {...formikProps} t={t} />}
        </Formik>
    )
}

export default ArticleTypeForm
