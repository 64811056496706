import React from "react"
import { SurveyFieldValue } from "../../../models/survey"
import { Draggable } from "react-beautiful-dnd"
import { useField } from "formik"
import DraggableItem from "../../DraggableItem/DraggableItem"
import { ClassProps } from "../../../utility/common/props"

interface Props extends ClassProps {
    index: number
    title: string
    name: string
    onDelete: () => void
    editable?: boolean
}

const SurveyField: React.FC<Props> = props => {
    const { index, title, name, onDelete, className, editable = true } = props
    const [field, , helper] = useField<SurveyFieldValue>(name)
    const value = field.value

    const handleEdit = () => {
        helper.setValue({ ...field.value, IsEditing: true })
    }

    return (
        <Draggable key={value.Id} draggableId={value.Id} index={index}>
            {provided => (
                <DraggableItem
                    title={title}
                    content={value.Comment}
                    onEdit={editable ? handleEdit : undefined}
                    onDelete={onDelete}
                    provided={provided}
                    className={className}
                />
            )}
        </Draggable>
    )
}

export default SurveyField
