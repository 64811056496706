import { FileUpload } from "../../models/file"
import { apiInstanceV1 } from "../instances"

const fileToDataURL = async (file: FileUpload<Blob>): Promise<string> => {
    const reader = new FileReader()
    return new Promise((resolve, reject) => {
        reader.onload = (e: ProgressEvent<FileReader>) => {
            if (e.target?.result) {
                resolve(e.target.result.toString())
            }
        }
        reader.onerror = () => {
            reader.abort()
            reject(reader.error)
        }
        reader.readAsDataURL(file.Content)
    })
}

const filesController = {
    async uploadFileB64(file: FileUpload<Blob>): Promise<string> {
        const formData = new FormData()
        formData.append("file", file.Content)

        return apiInstanceV1
            .post("/dialogs/upload_file_stream", formData, {
                headers: { "Content-type": "multipart/form-data" },
                timeout: 600000
            })
            .then(response => response.data)
    }
}

export default filesController
