export enum ButtonType {
    Action = "action",
    Url = "url"
}

const buttonTypeValues = Object.values(ButtonType)

export const validateButtonType = (typeAttrValue: string) => {
    const type = typeAttrValue as ButtonType
    return buttonTypeValues.includes(type) ? type : ButtonType.Action
}
