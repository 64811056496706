import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import { SystemError } from "../../core/error"
import { Menu } from "../../models/menu"

export type MenuState = Readonly<{
    mainMenu: AsyncState<Menu>
    selectedMainMenu?: string
    additionalMenus: Menu[]
}>

const initialState: MenuState = {
    mainMenu: AsyncState.create(),
    selectedMainMenu: undefined,
    additionalMenus: []
}

const menu = createSlice({
    name: "menu",
    initialState,
    reducers: {
        getMainMenuProcess(state) {
            state.mainMenu = state.mainMenu.toProcess()
        },
        getMainMenuSuccess(state, action: PayloadAction<Menu>) {
            state.mainMenu = state.mainMenu.toSuccess(action.payload)
        },
        getMainMenuFailed(state, action: PayloadAction<SystemError>) {
            state.mainMenu = state.mainMenu.toFailed(action.payload)
        },
        selectMainMenu(state, action: PayloadAction<string>) {
            state.selectedMainMenu = action.payload
        }
    }
})

export default menu.reducer

export const actions = menu.actions
