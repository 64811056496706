import React from "react"
import { useField } from "formik"
import { Form, ColProps, Row, Col } from "react-bootstrap"
import { ValidatableInputProps } from "../ValidatableInput/ValidatableInput"
import cn from "classnames"
import { useTranslation } from "react-i18next"

interface Props extends ValidatableInputProps {
    inputCol: ColProps
    labelCol?: ColProps
}

const HorizontalValidatableInput: React.FC<Props> = props => {
    const { t } = useTranslation()
    const [field, meta] = useField<any>(props)
    const { id, type, placeholder, as, readonly, label, labelCol, inputCol, className, disabled } = props

    return (
        <Form.Group className={cn(className)} as={Row} controlId={id}>
            {label && (
                <Form.Label {...labelCol} column>
                    {label}
                </Form.Label>
            )}
            <Col {...inputCol}>
                <Form.Control
                    plaintext={readonly}
                    readOnly={readonly}
                    type={type}
                    as={as as any}
                    name={field.name}
                    placeholder={placeholder}
                    onChange={field.onChange}
                    onBlur={readonly ? undefined : field.onBlur}
                    value={field.value}
                    isInvalid={meta.touched && !!meta.error}
                    disabled={disabled}
                >
                    {props.children}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{meta.error && t(meta.error)}</Form.Control.Feedback>
            </Col>
        </Form.Group>
    )
}

export default HorizontalValidatableInput
