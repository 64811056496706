import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { Formik } from "formik"
import { OptionType } from "../AsyncSearchableInput/AsyncSearchableInput"
import { ValueType } from "react-select"
import { Connection, ScenarioBlockType } from "../../models/scenario"
import { ScenarioContext } from "../ScenarioEditor/ScenarioContext"
import { addConnection, addNode } from "../../utility/scenario/scenarioGraph"
import ArticleSelect from "../ArticleSelect/ArticleSelect"
import { nameof } from "../../utility/common/nameof"
import { ChooseArticleFormValues } from "../../models/article"
import "./ChooseArticleForm.scss"
import { useDispatch } from "react-redux"

const tNamespace = "scenarioEditor:"

interface Props {
    projectId?: string
    onSelect: () => void
    connection: Connection
}

const ChooseArticleForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { projectId, onSelect, connection } = props
    const dispatch = useDispatch()

    const { instance, setElements } = useContext(ScenarioContext)

    const handleChooseBlock = (option: OptionType) => {
        onSelect()
        if (instance !== null) {
            const nodeData = {
                ArticleId: option.value,
                ArticleTitle: option.label
            }

            const id = addNode(ScenarioBlockType.Article, setElements, nodeData, instance, connection.source)
            addConnection(setElements, id, connection.source, connection.sourceHandle)
        }
    }

    return (
        <Formik enableReinitialize={true} initialValues={{ SymbolCode: "" }} onSubmit={() => {}}>
            {({ setFieldValue }) => (
                <div className="choose-article-form">
                    <ArticleSelect
                        id="chooseArticleForm"
                        className="choose-article-form__content"
                        name={nameof<ChooseArticleFormValues>("SymbolCode")}
                        label={t(`${tNamespace}go-to-article`)}
                        projectId={projectId}
                        onSelect={(value: ValueType<OptionType, false>) => {
                            if (value) {
                                handleChooseBlock(value)
                            }
                            setFieldValue(nameof<ChooseArticleFormValues>("SymbolCode"), value?.value ?? "", false)
                        }}
                        dispatch={dispatch}
                    />
                </div>
            )}
        </Formik>
    )
}

export default ChooseArticleForm
