import React from "react"
import { FormikProps } from "formik"
import { WithT } from "i18next"
import { Form } from "react-bootstrap"
import { preventSubmitOnEnter } from "../../../utility/common/preventSubmitOnEnter"
import { formTranslation } from "../../../locales/form"
import LoadingButton from "../../LoadingButton/LoadingButton"
import { Props as ClassifierFormProps } from "./FormikClassifierForm"
import {
    ClassifierConfigDto,
    ClassifierConfigurationProcessed,
    ClassifierFormValues,
    ClassifierStatus,
    ClassifierType
} from "../../../models/classifier"
import RetrainClassifierPopoverContainer from "../RetrainClassifierPopover/RetrainClassifierPopoverContainer"
import { useModal } from "../../../utility/common/useModal"
import AlertDialog from "../../AlertDialog/AlertDialog"
import ClassifierStatusIcon from "../ClassifierStatusIcon/ClassifierStatusIcon"
import cn from "classnames"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import {
    selectCreateClassifierState,
    selectCurrentClassifier,
    selectCurrentTrainingClassifier,
    selectStopTrainingClassifierState,
    selectUpdateClassifierState
} from "../../../store/classifiers/selectors"
import { stopTraining } from "../../../store/classifiers/thunks"

const tNamespace = "classifiers:form."
const tStopNamespace = "classifiers:stop-confirmation."
const tStatusNamespace = "classifiers:training-status."

const checkRetrainEmbeddings = (
    trained: boolean,
    classifier?: ClassifierConfigurationProcessed,
    linkedClassifier?: ClassifierConfigDto
): boolean | undefined => {
    if (!classifier) return undefined
    if ([ClassifierType.KNN, ClassifierType.GRU].includes(classifier.Type) && linkedClassifier) {
        if (!trained) return false
        return classifier.Type === ClassifierType.GRU
    }
}

const FormikClassifierFormTraining: React.FC<ClassifierFormProps & FormikProps<ClassifierFormValues> & WithT> =
    props => {
        const dispatch = useDispatch()
        const { project, t, classifier, values, handleSubmit, disabled, linkedClassifier, classifierModels } = props
        const asyncState = useSelector(
            classifier ? selectUpdateClassifierState : selectCreateClassifierState,
            shallowEqual
        )
        const currentClassifier = useSelector(selectCurrentClassifier)
        const stopTrainingClassifierState = useSelector(selectStopTrainingClassifierState)

        const currentTrainingClassifier = useSelector(selectCurrentTrainingClassifier)
        const trained = !!(classifierModels?.length && classifierModels.find(m => m.LearnedAt))

        const handleStopClassifier = () =>
            classifier && dispatch(stopTraining(project.id, classifier.ClassifierConfigId))

        const { modalOpen, openModal, closeModal, onExited } = useModal(() => (
            <AlertDialog
                show={modalOpen}
                title={t(`${tStopNamespace}title`)}
                message={t(`${tStopNamespace}message`)}
                onClose={closeModal}
                onSubmit={handleStopClassifier}
                variant="danger"
                onExited={onExited}
            />
        ))

        return (
            <Form className="classifier-form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
                <div className="classifier-form__content">
                    <div className={cn("classifier-form__section", "classifier-form__train-buttons")}>
                        <RetrainClassifierPopoverContainer
                            className="classifier-form__train-button"
                            projectId={project.id}
                            configId={values.ClassifierConfigId}
                            isActive={currentClassifier.Active}
                            disabled={!classifier || currentClassifier.Status === ClassifierStatus.Training}
                            retrainEmbeddings={checkRetrainEmbeddings(trained, classifier, linkedClassifier)}
                        />
                        <LoadingButton
                            className={cn("classifier-form__train-button", "classifier-form__train-button_stop")}
                            variant="light"
                            onClick={openModal}
                            disabled={!classifier || currentClassifier?.Status === ClassifierStatus.Stopped}
                            loading={stopTrainingClassifierState.inProcess}
                        >
                            {t(`${tNamespace}stop`)}
                        </LoadingButton>
                    </div>
                    {currentTrainingClassifier?.ClassifierConfigId === currentClassifier.ClassifierConfigId && (
                        <div className={cn("classifier-form__section", "classifier-form__training-status-section")}>
                            {t(`${tNamespace}training-status`)}
                            <ClassifierStatusIcon
                                status={currentTrainingClassifier.EventType}
                                className="classifier-form__training-status"
                            >
                                {t(`${tStatusNamespace}${currentTrainingClassifier.EventType}`)}
                            </ClassifierStatusIcon>
                        </div>
                    )}
                </div>
                <div className="classifier-form__footer">
                    <LoadingButton
                        type="submit"
                        loading={asyncState.inProcess}
                        variant="primary"
                        disabled={disabled}
                        block
                    >
                        {t(formTranslation.save)}
                    </LoadingButton>
                </div>
            </Form>
        )
    }

export default FormikClassifierFormTraining
