import React from "react"
import styles from "./Pagination.module.scss"
import IconControl from "../IconControl/IconControl"
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-light-svg-icons"
import { useTranslation } from "react-i18next"
import cn from "classnames"

const tNamespace = "common:pagination."

export interface PaginationProps {
    onPreviousPage: () => void
    onNextPage: () => void
    canPreviousPage: boolean
    canNextPage: boolean
    total: number
    pageIndex: number
    resultsPerPage: number
}

const Pagination: React.FC<PaginationProps> = props => {
    const { t } = useTranslation()
    const { pageIndex, total, onPreviousPage, onNextPage, canPreviousPage, canNextPage, resultsPerPage } = props

    const currentPageRange = () =>
        `${total ? pageIndex * resultsPerPage + 1 : 0}-${Math.min((pageIndex + 1) * resultsPerPage, total)}`

    return (
        <div className={styles.pagination}>
            <span className={styles.pagination__pages}>{`${currentPageRange()} ${t(`${tNamespace}of`)} ${total}`}</span>
            <IconControl
                icon={faAngleLeft}
                className={cn(styles.pagination__pageArrow, !canPreviousPage && styles.pagination__pageArrow_disabled)}
                onClick={onPreviousPage}
                disabled={!canPreviousPage}
            />
            <IconControl
                icon={faAngleRight}
                className={cn(styles.pagination__pageArrow, !canNextPage && styles.pagination__pageArrow_disabled)}
                onClick={onNextPage}
                disabled={!canNextPage}
            />
        </div>
    )
}

export default Pagination
