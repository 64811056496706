import { SystemError } from "./error"

class AsyncState<T> {
    private _inProcess: boolean
    private _data?: T
    private _error?: SystemError
    private _timerID?: number

    get inProcess() {
        return this._inProcess
    }

    get data() {
        return this._data
    }

    get error() {
        return this._error
    }

    protected constructor(inProcess: boolean, data?: T, error?: SystemError, timerId?: number) {
        this._inProcess = inProcess
        this._error = error
        this._data = data
        this._timerID = timerId
    }

    static create<T>() {
        return new AsyncState<T>(false)
    }

    static createProcess<T>() {
        return new AsyncState<T>(true)
    }

    static createSuccess<T>(data: T) {
        return new AsyncState<T>(false, data)
    }

    static createFailed<T>(error: SystemError) {
        return new AsyncState<T>(false, undefined, error)
    }

    static getDataOrDefault<T>(initialState: AsyncState<T>, defaultValue: T) {
        const state = initialState ? initialState : AsyncState.createSuccess<T>(defaultValue)
        return state.data ? state.data : defaultValue
    }

    toProcess<T>() {
        return AsyncState.createProcess<T>()
    }

    toProcessWithRetry<T>(retryCallback: () => void, retryAfterMs: number) {
        const timerId = window.setTimeout(retryCallback, retryAfterMs)

        return new AsyncState<T>(true, undefined, undefined, timerId)
    }

    toSuccess(data: T) {
        if (this._timerID) {
            clearTimeout(this._timerID)
            this._timerID = undefined
        }

        return AsyncState.createSuccess(data)
    }

    toFailed<T>(error: SystemError) {
        if (this._timerID) {
            clearTimeout(this._timerID)
            this._timerID = undefined
        }

        return AsyncState.createFailed<T>(error)
    }

    static merge2<T1, T2>(r1: AsyncState<T1>, r2: AsyncState<T2>) {
        return new AsyncState<[T1, T2]>(
            r1.inProcess || r2.inProcess,
            r1.data && r2.data && [r1.data, r2.data],
            r1.error || r2.error
        )
    }

    static merge3<T1, T2, T3>(r1: AsyncState<T1>, r2: AsyncState<T2>, r3: AsyncState<T3>) {
        return new AsyncState<[T1, T2, T3]>(
            r1.inProcess || r2.inProcess || r3.inProcess,
            r1.data && r2.data && r3.data && [r1.data, r2.data, r3.data],
            r1.error || r2.error || r3.error
        )
    }

    map(f: (v: T) => T) {
        return this.data ? new AsyncState(this.inProcess, f(this.data), this.error) : this
    }
}

export default AsyncState
