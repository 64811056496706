import React from "react"
import { Operator } from "../../models/operator"
import cn from "classnames"
import { ClassProps } from "../../utility/common/props"
import UserCard from "../UserCard/UserCard"
import styles from "./OperatorCard.module.scss"
import { useTranslation } from "react-i18next"
import OperatorStatusContainer from "../OperatorStatusContainer/OperatorStatusContainer"
import { formatDistanceToNowLocal } from "../../utility/common/formatDate"

interface Props extends ClassProps {
    operator: Operator
    onClick?: (operator: Operator) => void
}

const OperatorCard: React.FC<Props> = props => {
    const { className, operator, onClick } = props
    const { t } = useTranslation()

    const handleClick = () => {
        if (onClick === undefined) {
            return
        }
        onClick(operator)
    }

    return (
        <UserCard
            className={cn(styles.operatorCard, className)}
            firstName={operator.FirstName}
            lastName={operator.LastName}
            onClick={handleClick}
        >
            <div className={styles.operatorCard__status}>
                <OperatorStatusContainer status={operator.Status} t={t} className={styles.operatorCard__item} />
                <div className={styles.operatorCard__duration}>
                    {formatDistanceToNowLocal(operator.Status.UpdatedAt)}
                </div>
            </div>
        </UserCard>
    )
}

export default OperatorCard
