import React, { useMemo } from "react"
import { ChannelFormProps } from "../ChannelForm/ChannelForm"
import { FormikProps } from "formik"
import { WithT } from "i18next"
import { shallowEqual, useSelector } from "react-redux"
import { selectCreateChannelState, selectUpdateChannelState } from "../../store/channels/selectors"
import { selectAgents } from "../../store/agents/selectors"
import ChannelFormHeader from "../ChannelFormHeader/ChannelFormHeader"
import { nameof, nameof2 } from "../../utility/common/nameof"
import { BotSettingsValues, ChannelValues } from "../../models/channelValues"
import { ParamValues } from "../../models/parameterDeclaration"
import DocLink from "../DocLink/DocLink"
import DebouncedValidatableInput from "../ValidatableInput/DebouncedValidatableInput"
import ChannelServerAddress from "../ChannelServerAddress/ChannelServerAddress"
import IdField from "../IdField/IdField"
import ChannelFormWrapper from "../ChannelFormWrapper/ChannelFormWrapper"

const tNamespace = "channel:form."
const tLinkNamespace = "channel:link."

const FormikYandexDialogsChannelFormGeneral: React.FC<ChannelFormProps & FormikProps<ChannelValues> & WithT> =
    props => {
        const { channel, channelType, t, handleSubmit, disabled, values } = props

        const asyncState = useSelector(channel ? selectUpdateChannelState : selectCreateChannelState, shallowEqual)
        const agents = useSelector(selectAgents)

        const agentOptions = useMemo(
            () => (agents ? agents.map(agent => ({ name: agent.Id, value: agent.Id })) : []),
            [agents]
        )

        return (
            <ChannelFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={asyncState.inProcess}>
                <ChannelFormHeader channelType={channelType} t={t} isActive={false} disabled />
                <DocLink type={channelType}>{t(`${tLinkNamespace}creating-bot-instructions`)}</DocLink>
                <div className="channel-form__section">
                    <DebouncedValidatableInput
                        id="formTitle"
                        type="text"
                        name={nameof<ChannelValues>("Title")}
                        label={t(`${tNamespace}title`)}
                    />
                </div>
                <div className="channel-form__section">
                    <div className="channel-form__section-subtitle">{t(`${tNamespace}call-routing`)}</div>
                    <DebouncedValidatableInput
                        id="formAgent"
                        as="select"
                        name={nameof2<ChannelValues, ParamValues>("Params", "default_agent")}
                        label={t(`${tNamespace}call-processing-agent`)}
                    >
                        <option value="" hidden />
                        {agentOptions.map(option => (
                            <option value={option.value} key={option.value}>
                                {option.name}
                            </option>
                        ))}
                    </DebouncedValidatableInput>
                </div>
                <div className="channel-form__section">
                    <div className="channel-form__section-subtitle">{t(`${tNamespace}bot-data-section`)}</div>
                    <DebouncedValidatableInput
                        id="formChatId"
                        type="text"
                        name={nameof2<ChannelValues, ParamValues>("Params", "chat_id")}
                        label={t(`${tNamespace}chat-id`)}
                    />
                    <DebouncedValidatableInput
                        id="formYandexDialogsUrl"
                        type="text"
                        name={nameof2<ChannelValues, ParamValues>("Params", "yandex_dialogs_url")}
                        label={t(`${tNamespace}yandex-dialogs-api-address`)}
                    />
                    <DebouncedValidatableInput
                        id="formToken"
                        type="text"
                        name={nameof2<ChannelValues, BotSettingsValues>("BotSettings", "Token")}
                        label={t(`${tNamespace}token`)}
                    />
                    <DebouncedValidatableInput
                        id="formApiToken"
                        type="text"
                        name={nameof2<ChannelValues, ParamValues>("Params", "api_token")}
                        label={t(`${tNamespace}api-token`)}
                    />
                    <ChannelServerAddress channelId={values.BotSettings.Id} serverAddress={values.ServerAddress} />
                </div>
            </ChannelFormWrapper>
        )
    }

const FormikYandexDialogsChannelFormAdvanced: React.FC<ChannelFormProps & FormikProps<ChannelValues> & WithT> =
    props => {
        const { channel, values, handleSubmit, disabled } = props

        const asyncState = useSelector(channel ? selectUpdateChannelState : selectCreateChannelState, shallowEqual)

        return (
            <ChannelFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={asyncState.inProcess}>
                <IdField id={values.BotSettings.Id} className="channel-form__section" />
            </ChannelFormWrapper>
        )
    }

export { FormikYandexDialogsChannelFormGeneral, FormikYandexDialogsChannelFormAdvanced }
