import { ChannelTypeString } from "../../../models/channel"

export enum QuickActionType {
    AddUser = "AddUser",
    OpenChannel = "OpenChannel",
    AddChannel = "AddChannel",
    OpenURL = "OpenURL",
    Unknown = "Unknown"
}

export interface QuickActionBase {
    Row: number
    Type: QuickActionType
    Title: string
    SubTitle: string
    ButtonTitle: string
}

export interface AddUserAction extends QuickActionBase {
    Type: QuickActionType.AddUser
}

export interface OpenChannelAction extends QuickActionBase {
    Type: QuickActionType.OpenChannel
    ChannelType: ChannelTypeString
    ChannelId: string
}

export interface AddChannelAction extends QuickActionBase {
    Type: QuickActionType.AddChannel
    ChannelType: ChannelTypeString
}

export interface OpenURLAction extends QuickActionBase {
    Type: QuickActionType.OpenURL
    Url: string
    Icon?: string
}

export type QuickAction = AddUserAction | OpenChannelAction | AddChannelAction | OpenURLAction

export enum QuickActionAttribute {
    Action = "action",
    Url = "url",
    SubTitle = "subtitle",
    ButtonTitle = "buttonTitle",
    Icon = "icon",
    ChannelType = "channelType",
    ChannelId = "channelId"
}
