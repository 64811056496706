import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import { SystemError } from "../../core/error"
import { VerifyResetPasswordCodeResponse } from "../../models/password"

export type PasswordState = Readonly<{
    forgotPassword: AsyncState<string>
    verifyResetPasswordCode: AsyncState<VerifyResetPasswordCodeResponse>
    resetPassword: AsyncState<boolean>
}>

const initialState: PasswordState = {
    forgotPassword: AsyncState.create(),
    verifyResetPasswordCode: AsyncState.create(),
    resetPassword: AsyncState.create()
}

const password = createSlice({
    name: "password",
    initialState,
    reducers: {
        forgotPasswordClear(state) {
            state.forgotPassword = AsyncState.create()
        },
        forgotPasswordProcess(state) {
            state.forgotPassword = state.forgotPassword.toProcess()
        },
        forgotPasswordSuccess(state, action: PayloadAction<string>) {
            state.forgotPassword = state.forgotPassword.toSuccess(action.payload)
        },
        forgotPasswordFailed(state, action: PayloadAction<SystemError>) {
            state.forgotPassword = state.forgotPassword.toFailed(action.payload)
        },
        verifyResetPasswordCodeProcess(state) {
            state.verifyResetPasswordCode = state.verifyResetPasswordCode.toProcess()
        },
        verifyResetPasswordCodeSuccess(state, action: PayloadAction<VerifyResetPasswordCodeResponse>) {
            state.verifyResetPasswordCode = state.verifyResetPasswordCode.toSuccess(action.payload)
        },
        verifyResetPasswordCodeFailed(state, action: PayloadAction<SystemError>) {
            state.verifyResetPasswordCode = state.verifyResetPasswordCode.toFailed(action.payload)
        },
        resetPasswordClear(state) {
            state.resetPassword = AsyncState.create()
        },
        resetPasswordProcess(state) {
            state.resetPassword = state.resetPassword.toProcess()
        },
        resetPasswordSuccess(state) {
            state.resetPassword = state.resetPassword.toSuccess(true)
        },
        resetPasswordFailed(state, action: PayloadAction<SystemError>) {
            state.resetPassword = state.resetPassword.toFailed(action.payload)
        }
    }
})

export default password.reducer

export const actions = password.actions
