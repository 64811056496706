import { CreateSurveyRequest, SurveyDto, SurveyValues, UpdateSurveyRequest } from "../../models/survey"

export const getDefaultValues = (): SurveyValues => ({
    Id: "",
    Title: "",
    Fields: []
})

export const getValuesFromSurvey = (survey: SurveyDto) => ({
    ...survey,
    Fields: (survey.Fields ?? []).map(f => ({ ...f, EditedComment: f.Comment }))
})

export const buildCreateRequest = (values: SurveyValues): CreateSurveyRequest => ({
    Title: values.Title,
    Fields: values.Fields.filter(f => f.Comment).map(f => ({
        Comment: f.Comment,
        SlotId: f.SlotId
    }))
})

export const buildUpdateRequest = (values: SurveyValues): UpdateSurveyRequest => ({
    Title: values.Title,
    Fields: values.Fields.filter(f => f.Comment).map(f => ({
        Id: f.Id,
        Comment: f.Comment,
        SlotId: f.SlotId
    }))
})
