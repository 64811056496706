import { ChannelTypeString } from "../../models/channel"

export const getTestChannelLink = (channelType: string, id: string, param?: string) => {
    switch (channelType) {
        case ChannelTypeString.MSBot:
        case ChannelTypeString.Teams:
            return `https://teams.microsoft.com/l/entity/${id}/${param}`
        case ChannelTypeString.Telegram:
            return `https://t.me/${id}`
        case ChannelTypeString.Viber:
            return `https://viber.me/${id}`
        case ChannelTypeString.VK:
            return `https://vk.com/${id}`
        case ChannelTypeString.Whatsapp:
            return `https://wa.me/${id}`
        case ChannelTypeString.Odnoklassniki:
            return `https://ok.ru/${id}`
        case ChannelTypeString.Facebook:
            return `https://www.facebook.com/${id}`
        case ChannelTypeString.Instagram:
            return `https://www.instagram.com/${id}`
        default:
            return ""
    }
}
