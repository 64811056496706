import React from "react"
import "./ClassifierTrainingHistory.scss"
import { ClassifierEventType, Initiator, ModelMetrics } from "../../../models/classifierEvent"
import cn from "classnames"
import format from "date-fns/format"
import { useTranslation } from "react-i18next"
import SettingsButton from "../../SettingsButton/SettingsButton"
import SettingsContextMenu from "../../SettingsContextMenu/SettingsContextMenu"
import SettingsContextMenuItem from "../../SettingsContextMenuItem/SettingsContextMenuItem"
import ClassifierMetrics from "../ClassifierMetrics/ClassifierMetrics"
import { parseISO } from "date-fns"
import { getDateLocale } from "../../../locales"
import { getLanguage } from "../../../utility/common/language"
import i18n from "i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserCircle } from "@fortawesome/pro-light-svg-icons/faUserCircle"
import { isDateTimeEmpty } from "../../../utility/classifier/classifierEvent"

const tNamespace = "classifiers:form."

interface Props {
    metrics?: ModelMetrics
    previousMetrics?: ModelMetrics
    current: boolean
    learningStartedAt: string
    learnedAt: string
    eventType?: ClassifierEventType
    onRollback?: () => void
    trained: boolean
    user?: Initiator
    rollbackDisabled?: boolean
}

const ClassifierTrainingHistory: React.FC<Props> = props => {
    const { t } = useTranslation()
    const {
        metrics,
        previousMetrics,
        current,
        learningStartedAt,
        learnedAt,
        eventType,
        onRollback,
        trained,
        user,
        rollbackDisabled
    } = props
    const locale = { locale: getDateLocale(getLanguage(i18n)) }

    const settings = [
        <SettingsContextMenuItem
            key="make-version-current"
            id="make-version-current"
            text={t(`${tNamespace}make-version-current`)}
            onClick={onRollback}
        />
    ]

    const formatDateTimeOffset = (offset: string) => {
        try {
            return format(parseISO(offset), "dd.MM.yyyy HH:mm:ss", locale)
        } catch {
            return ""
        }
    }

    const isRollbackAllowed = !rollbackDisabled && !current && trained

    const getStatus = () => {
        const getStatusKey = () => {
            if (eventType === ClassifierEventType.Training) {
                return "training"
            }
            if (current) {
                return "current"
            }
            const isNotTrained = !(trained || eventType || current)
            if (isNotTrained) {
                return "not-trained"
            }
        }

        const statusKey = getStatusKey()
        return statusKey ? (
            <span className="classifier-training-history__status">{t(`${tNamespace}${statusKey}`)}</span>
        ) : null
    }

    return (
        <div className={cn("classifier-training-history", current && "classifier-training-history_current")}>
            <div className="classifier-training-history__header">
                <div className="classifier-training-history__date-time">
                    {!isDateTimeEmpty(learningStartedAt) && formatDateTimeOffset(learningStartedAt)}
                    {!isDateTimeEmpty(learnedAt) && ` - ${formatDateTimeOffset(learnedAt)}`}
                </div>
                <div>
                    {getStatus()}
                    {isRollbackAllowed && (
                        <SettingsContextMenu items={settings} className="classifier-training-history__settings">
                            <SettingsButton />
                        </SettingsContextMenu>
                    )}
                </div>
            </div>
            {user && (
                <div className="classifier-training-history__initiator">
                    <FontAwesomeIcon icon={faUserCircle} className="classifier-training-history__initiator-icon" />
                    {`${user.FirstName} ${user.LastName}`}
                </div>
            )}
            <ClassifierMetrics metrics={metrics} previousMetrics={previousMetrics} t={t} />
        </div>
    )
}

export default ClassifierTrainingHistory
