import React, { ReactNode } from "react"
import "./InfoCard.scss"
import cn from "classnames"
import { ClassProps } from "../../utility/common/props"

export interface CardContent {
    icon?: JSX.Element
    title?: string
    header?: ReactNode
    text: ReactNode
}

interface Props extends ClassProps {
    content: CardContent
    onClick?: () => void
    selected?: boolean
    iconClassName?: string
    headerClassName?: string
}

const InfoCard: React.FC<Props> = props => {
    const {
        content: { header, icon, title, text },
        onClick,
        selected,
        className,
        iconClassName,
        headerClassName,
        children
    } = props

    return (
        <div className={cn("info-card", selected && "selected", className)} onClick={onClick}>
            <div className={cn("info-card__header", headerClassName)}>{header}</div>
            <div className="info-card__body">
                {icon && <div className={cn("info-card__icon", iconClassName)}>{icon}</div>}
                <div className="info-card__content">
                    {title && <span className="info-card__title">{title}</span>}
                    <span className="info-card__type">{text}</span>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default InfoCard
