import React from "react"
import { useField } from "formik"
import { useTranslation } from "react-i18next"
import DropdownButton from "react-bootstrap/DropdownButton"
import { InputGroup } from "react-bootstrap"
import DropdownItem from "react-bootstrap/DropdownItem"
import { formTranslation } from "../../locales/form"
import SelectableContext from "react-bootstrap/esm/SelectableContext"

export interface AppendDropdownButtonProps {
    id: string
    name: string
    options: string[]
    disabled?: boolean
}

const AppendDropdownButton: React.FC<AppendDropdownButtonProps> = props => {
    const { t } = useTranslation()
    const { id, name, options, disabled } = props
    const [field, , { setValue }] = useField(name)

    return (
        <SelectableContext.Provider value={null}>
            <DropdownButton
                as={InputGroup.Append}
                variant="outline-secondary"
                title={field.value || t(formTranslation.selectButton)}
                id={`${id}-dropdown`}
                disabled={disabled}
                onSelect={(eventKey: string | null) => setValue(eventKey)}
            >
                {options.map(option => (
                    <DropdownItem key={option} eventKey={option}>
                        {option}
                    </DropdownItem>
                ))}
            </DropdownButton>
        </SelectableContext.Provider>
    )
}

export default AppendDropdownButton
