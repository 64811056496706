import React from "react"
import "./CopyInputAsync.scss"
import Skeleton from "../Skeleton/Skeleton"
import CopyInput from "../CopyInput/CopyInput"

interface Props {
    value?: string
    loading?: boolean
}

const CopyInputAsync: React.FC<Props> = props => {
    const { value, loading } = props

    if (loading || !value) {
        return (
            <div className="textarea-info-loader">
                <Skeleton />
            </div>
        )
    }

    return <CopyInput as="textarea" id="form-textarea-info" value={value} className="textarea-info" />
}

export default CopyInputAsync
