import React from "react"
import "./ValidatableInput.scss"
import { useField } from "formik"
import { CloseButton, Form } from "react-bootstrap"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import { useTranslation } from "react-i18next"

export interface ValidatableInputProps extends ClassProps {
    id: string
    name: string
    type?: string
    placeholder?: string
    as?: React.ElementType
    readonly?: boolean
    plaintext?: boolean
    label?: string
    rows?: string
    cols?: string
    icon?: JSX.Element
    disabled?: boolean
    autocomplete?: "on" | "off" | string
    onDelete?: () => void
    testId?: string
}

const ValidatableInput: React.FC<ValidatableInputProps> = props => {
    const { t } = useTranslation()
    const [field, meta] = useField<any>(props)
    const {
        id,
        type,
        placeholder,
        as,
        label,
        readonly,
        plaintext,
        className,
        rows,
        cols,
        children,
        icon,
        disabled,
        autocomplete = "on",
        onDelete,
        testId
    } = props

    const formLabel = (label || icon) && (
        <>
            {label}
            {icon}
            {onDelete && (
                <CloseButton className="validatable-input__delete-icon" onClick={onDelete} disabled={disabled} />
            )}
        </>
    )

    return (
        <Form.Group className={cn("validatable-input", className)} controlId={id}>
            {formLabel && <Form.Label>{formLabel}</Form.Label>}
            <Form.Control
                className={as === "select" ? "select-input" : undefined}
                disabled={disabled}
                plaintext={readonly || plaintext}
                readOnly={readonly}
                type={type}
                as={as as any}
                name={field.name}
                placeholder={placeholder}
                onChange={field.onChange}
                onBlur={readonly ? undefined : field.onBlur}
                value={field.value}
                rows={rows}
                cols={cols}
                isInvalid={meta.touched && !!meta.error}
                autoComplete={autocomplete}
                data-test-id={testId}
            >
                {children}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{meta.error && t(meta.error)}</Form.Control.Feedback>
        </Form.Group>
    )
}

export default ValidatableInput
