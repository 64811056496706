import React, { useCallback, useMemo, useState } from "react"
import "./CalendarModal.scss"
import { Button, Modal, ModalProps } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { formTranslation } from "../../locales/form"
import DatePicker, { registerLocale } from "react-datepicker"
import MenuItemRow from "../MenuItemRow/MenuItemRow"
import ru from "date-fns/locale/ru"
import enGb from "date-fns/locale/en-GB"

registerLocale("ru", ru)
registerLocale("en", enGb)

const tNamespace = "common:calendar."

interface Props extends ModalProps {
    show: boolean
    onClose: () => void
    onSubmit: (dates: Date[]) => void
    datesBetween?: boolean
}

const getDatesBetween = (start: Date, end: Date) => {
    const dates: Date[] = []
    const theDate = new Date(start)

    while (theDate < end) {
        dates.push(new Date(theDate))
        theDate.setDate(theDate.getDate() + 1)
    }
    dates.push(end)

    return dates
}

const CalendarModal: React.FC<Props> = props => {
    const { t, i18n } = useTranslation()
    const { show, onClose, onSubmit, datesBetween, ...modalProps } = props

    const now = useMemo(() => new Date(), [])
    const [startDate, setStartDate] = useState<Date>(now)
    const [endDate, setEndDate] = useState<Date | null>(null)

    const handleSubmit = useCallback(() => {
        if (!endDate) {
            onSubmit([startDate])
        } else {
            onSubmit(datesBetween ? getDatesBetween(startDate, endDate) : [startDate, endDate])
        }
        onClose()
    }, [datesBetween, startDate, endDate, onSubmit, onClose])

    const handleChange = useCallback((dates: [Date, Date]) => {
        const [start, end] = dates
        setStartDate(start)
        setEndDate(end)
    }, [])

    return (
        <Modal {...modalProps} dialogClassName="calendar-modal" show={show} onHide={onClose}>
            <div className="calendar-modal__content">
                <div className="calendar-modal__sidebar">
                    <MenuItemRow
                        title={t(`${tNamespace}today`)}
                        onClick={() => {
                            setStartDate(now)
                            setEndDate(null)
                        }}
                    />
                </div>
                <div className="calendar-modal__date-picker">
                    <DatePicker
                        adjustDateOnChange={true}
                        selected={startDate}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={handleChange}
                        monthsShown={2}
                        locale={i18n.language}
                        selectsRange
                        inline
                    />
                </div>
            </div>

            <Modal.Footer>
                <Button variant="light" onClick={onClose}>
                    {t(formTranslation.cancel)}
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    {t(formTranslation.apply)}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CalendarModal
