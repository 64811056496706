import React from "react"
import { ModelMetrics } from "../../../models/classifierEvent"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCommentAltLines, faFileAlt, faThLarge } from "@fortawesome/pro-light-svg-icons"
import InfoIcon from "../../InfoIcon/InfoIcon"
import PopoverTrigger from "../../PopoverTrigger/PopoverTrigger"
import ZoomImagePan from "../../ZoomImagePan/ZoomImagePan"
import { ReactComponent as MatrixIcon } from "../../../assets/images/matrix.svg"
import cn from "classnames"
import { WithT } from "i18next"
import round from "lodash/round"

const tNamespace = "classifiers:form."
const tTooltipNamespace = "classifiers:tooltip."

interface MetricsDifferenceProps {
    previousValue?: number
    currentValue: number
}

const MetricsDifference: React.FC<MetricsDifferenceProps> = ({ previousValue, currentValue }) => {
    if (!previousValue) return null

    const diff = round((currentValue - previousValue) * 100, 1)
    if (!diff) return null

    return (
        <span
            className={cn(
                "classifier-training-history__metric-diff",
                `classifier-training-history__metric-diff_${diff < 0 ? "negative" : "positive"}`
            )}
        >
            {diff > 0 && "+"}
            {diff} %
        </span>
    )
}

interface Props extends WithT {
    metrics?: ModelMetrics
    previousMetrics?: ModelMetrics
}

const ClassifierMetrics: React.FC<Props> = props => {
    const { metrics, previousMetrics, t } = props

    if (!metrics) return null

    return (
        <>
            <div className="classifier-training-history__nums">
                <FontAwesomeIcon
                    icon={faCommentAltLines}
                    size="1x"
                    className="classifier-training-history__nums-icon"
                />
                {t(`${tNamespace}samples`)} ({metrics.NumSamples})
                <FontAwesomeIcon icon={faFileAlt} size="1x" className="classifier-training-history__nums-icon" />
                {t(`${tNamespace}articles`)} ({metrics.NumIntents})
            </div>
            <div className="classifier-training-history__metrics">
                {metrics.Precision !== 0 && (
                    <span className="classifier-training-history__metric">
                        Precision
                        <InfoIcon
                            placement="bottom"
                            id="popoverPrecision"
                            content={t(`${tTooltipNamespace}precision`)}
                        />
                        <span className="classifier-training-history__metric-value">
                            {round(metrics.Precision * 100, 2)} %
                        </span>
                        <MetricsDifference
                            previousValue={previousMetrics?.Precision}
                            currentValue={metrics.Precision}
                        />
                    </span>
                )}
                {metrics.Recall !== 0 && (
                    <span className="classifier-training-history__metric">
                        Recall
                        <InfoIcon placement="bottom" id="popoverRecall" content={t(`${tTooltipNamespace}recall`)} />
                        <span className="classifier-training-history__metric-value">
                            {round(metrics.Recall * 100, 2)} %
                        </span>
                        <MetricsDifference previousValue={previousMetrics?.Recall} currentValue={metrics.Recall} />
                    </span>
                )}
                {metrics.F1 !== 0 && (
                    <span className="classifier-training-history__metric">
                        F1
                        <span className="classifier-training-history__metric-value">
                            {round(metrics.F1 * 100, 2)} %
                        </span>
                        <MetricsDifference previousValue={previousMetrics?.F1} currentValue={metrics.F1} />
                    </span>
                )}
            </div>
            <div className="classifier-training-history__matrices">
                {metrics.ConfusionOther && (
                    <PopoverTrigger
                        rootClose
                        className="classifier-training-history__popover"
                        id="article-filter"
                        content={<ZoomImagePan src={metrics.ConfusionOther} />}
                        trigger="click"
                        placement="left"
                    >
                        <MatrixIcon className="classifier-training-history__matrix" />
                    </PopoverTrigger>
                )}

                {metrics.ConfusionNoOther && (
                    <PopoverTrigger
                        rootClose
                        className="classifier-training-history__popover"
                        id="article-filter"
                        content={<ZoomImagePan src={metrics.ConfusionNoOther} />}
                        trigger="click"
                        placement="left"
                    >
                        <FontAwesomeIcon icon={faThLarge} className="classifier-training-history__matrix" />
                    </PopoverTrigger>
                )}
            </div>
        </>
    )
}

export default ClassifierMetrics
