import React, { useCallback, useMemo } from "react"
import { ChannelFormProps } from "../ChannelForm/ChannelForm"
import { FormikProps } from "formik"
import { ChannelValues, WhatsAppChannelValues } from "../../models/channelValues"
import { WithT } from "i18next"
import { shallowEqual, useSelector } from "react-redux"
import { selectCreateChannelState, selectUpdateChannelState } from "../../store/channels/selectors"
import { selectAgents } from "../../store/agents/selectors"
import { Form } from "react-bootstrap"
import ChannelFormHeader from "../ChannelFormHeader/ChannelFormHeader"
import { nameof, nameof2 } from "../../utility/common/nameof"
import { formTranslation } from "../../locales/form"
import RadioButtonValidatableInput from "../RadioButtonValidatableInput/RadioButtonValidatableInput"
import { ParamValues } from "../../models/parameterDeclaration"
import CheckBoxValidatableInput from "../CheckBoxValidatableInput/CheckBoxValidatableInput"
import Slider from "../Slider/Slider"
import FormAccordion from "../FormAccordion/FormAccordion"
import DocLink from "../DocLink/DocLink"
import DebouncedValidatableInput from "../ValidatableInput/DebouncedValidatableInput"
import ChannelFormWrapper from "../ChannelFormWrapper/ChannelFormWrapper"
import CodeBlockValidatableInput from "../CodeBlockValidatableInput/CodeBlockValidatableInput"

const tNamespace = "channel:form."
const tLinkNamespace = "channel:link."
const tTimeNamespace = "common:time."

const FormikEmailChannelFormGeneral: React.FC<ChannelFormProps & FormikProps<WhatsAppChannelValues> & WithT> =
    props => {
        const { channel, channelType, values, setFieldValue, t, handleSubmit, disabled } = props

        const asyncState = useSelector(channel ? selectUpdateChannelState : selectCreateChannelState, shallowEqual)
        const agents = useSelector(selectAgents)

        const agentOptions = useMemo(
            () => (agents ? agents.map(agent => ({ name: agent.Id, value: agent.Id })) : []),
            [agents]
        )

        const handleReadMessagesCountChange = useCallback(
            (v: number) => {
                setFieldValue(nameof2<ChannelValues, ParamValues>("Params", "read_messages_count"), v, false)
            },
            [setFieldValue]
        )

        const handleRefreshIntervalChange = useCallback(
            (v: number) => {
                setFieldValue(nameof2<ChannelValues, ParamValues>("Params", "refresh_interval"), v, false)
            },
            [setFieldValue]
        )

        return (
            <ChannelFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={asyncState.inProcess}>
                <ChannelFormHeader channelType={channelType} t={t} isActive={false} disabled />
                <DocLink type={channelType}>{t(`${tLinkNamespace}email-connecting-instructions`)}</DocLink>
                <div className="channel-form__section">
                    <DebouncedValidatableInput
                        id="formTitle"
                        type="text"
                        name={nameof<ChannelValues>("Title")}
                        label={t(`${tNamespace}title`)}
                    />
                </div>
                <div className="channel-form__section">
                    <div className="channel-form__section-subtitle">{t(`${tNamespace}call-routing`)}</div>
                    <DebouncedValidatableInput
                        id="formAgent"
                        as="select"
                        name={nameof2<ChannelValues, ParamValues>("Params", "default_agent")}
                        label={t(`${tNamespace}call-processing-agent`)}
                    >
                        <option value="" hidden />
                        {agentOptions.map(option => (
                            <option value={option.value} key={option.value}>
                                {option.name}
                            </option>
                        ))}
                    </DebouncedValidatableInput>
                </div>
                <DebouncedValidatableInput
                    id="formEmail"
                    type="text"
                    name={nameof2<ChannelValues, ParamValues>("Params", "email")}
                    label={t(`${tNamespace}sender-email`)}
                />
                <FormAccordion title={t(`${tNamespace}receiving-mail`)}>
                    <div className="channel-form__text">{t(`${tNamespace}protocol`)}</div>
                    <RadioButtonValidatableInput
                        value="POP3"
                        id="formProtocol1"
                        name={nameof2<ChannelValues, ParamValues>("Params", "imap_or_pop3")}
                        label="POP 3"
                    />
                    <RadioButtonValidatableInput
                        value="IMAP"
                        id="formProtocol2"
                        name={nameof2<ChannelValues, ParamValues>("Params", "imap_or_pop3")}
                        label="IMAP"
                    />
                    <div className="channel-form__section">
                        <DebouncedValidatableInput
                            id="formImap"
                            type="text"
                            name={nameof2<ChannelValues, ParamValues>("Params", "imap")}
                            label={t(`${tNamespace}server`)}
                        />
                        <DebouncedValidatableInput
                            id="formImapPort"
                            type="text"
                            name={nameof2<ChannelValues, ParamValues>("Params", "imap_port")}
                            label={t(`${tNamespace}port`)}
                        />
                    </div>
                    <CheckBoxValidatableInput
                        id="formImapEnableSsl"
                        name={nameof2<ChannelValues, ParamValues>("Params", "imap_enable_ssl_certificate_check")}
                        label={t(`${tNamespace}verify-ssl-certificate`)}
                    />
                    <DebouncedValidatableInput
                        id="formImapLogin"
                        type="text"
                        name={nameof2<ChannelValues, ParamValues>("Params", "imap_login")}
                        label={t(formTranslation.login)}
                    />
                    <DebouncedValidatableInput
                        id="formImapPassword"
                        type="password"
                        name={nameof2<ChannelValues, ParamValues>("Params", "imap_password")}
                        label={t(formTranslation.password)}
                    />
                    <div className="channel-form__section">
                        <Form.Group controlId="formReadMessagesCount">
                            <Form.Label>{t(`${tNamespace}number-of-messages-in-session`)}</Form.Label>
                            <Slider
                                min={1}
                                max={100}
                                step={1}
                                marks={{ 1: "1", 100: "100" }}
                                onChange={handleReadMessagesCountChange}
                                value={values.Params.read_messages_count}
                            />
                        </Form.Group>
                        <Form.Group controlId="formRefreshInterval">
                            <Form.Label>{t(`${tNamespace}polling-interval`)}</Form.Label>
                            <Slider
                                min={1}
                                max={120}
                                step={1}
                                marks={{
                                    1: "1",
                                    120: `120 ${t(`${tTimeNamespace}min`)}`
                                }}
                                onChange={handleRefreshIntervalChange}
                                value={values.Params.refresh_interval}
                            />
                        </Form.Group>
                    </div>
                    <div className="channel-form__section">
                        <CheckBoxValidatableInput
                            id="formEachMessageAsUniqueDialog"
                            name={nameof2<ChannelValues, ParamValues>("Params", "each_message_as_unique_dialog")}
                            label={t(`${tNamespace}treat-every-email-as-new-dialog`)}
                        />
                    </div>
                </FormAccordion>
                <FormAccordion title={t(`${tNamespace}sending-mail`)}>
                    <div className="channel-form__text">{t(`${tNamespace}protocol`)} SMTP</div>
                    <DebouncedValidatableInput
                        id="formSmtp"
                        type="text"
                        name={nameof2<ChannelValues, ParamValues>("Params", "smtp")}
                        label={t(`${tNamespace}server`)}
                    />
                    <DebouncedValidatableInput
                        id="formSmtpPort"
                        type="text"
                        name={nameof2<ChannelValues, ParamValues>("Params", "smtp_port")}
                        label={t(`${tNamespace}port`)}
                    />
                    <div className="channel-form__section">
                        <CheckBoxValidatableInput
                            id="formSmtpEnableAuth"
                            name={nameof2<ChannelValues, ParamValues>("Params", "smtp_enable_auth")}
                            label={t(`${tNamespace}enable-authorization`)}
                        />
                        <CheckBoxValidatableInput
                            id="formSmtpEnableSslCertificateCheck"
                            name={nameof2<ChannelValues, ParamValues>("Params", "smtp_enable_ssl_certificate_check")}
                            label={t(`${tNamespace}verify-ssl-certificate`)}
                        />
                    </div>
                    <DebouncedValidatableInput
                        id="formSmtpLogin"
                        type="text"
                        name={nameof2<ChannelValues, ParamValues>("Params", "smtp_login")}
                        label={t(formTranslation.login)}
                    />
                    <DebouncedValidatableInput
                        id="formSmtpPassword"
                        type="password"
                        name={nameof2<ChannelValues, ParamValues>("Params", "smtp_password")}
                        label={t(formTranslation.password)}
                    />
                    <div className="channel-form__section">
                        <CheckBoxValidatableInput
                            id="formEnableScore"
                            name={nameof2<ChannelValues, ParamValues>("Params", "enable_score")}
                            label={t(`${tNamespace}send-score`)}
                        />
                        <div className="channel-form__subfield">
                            <CodeBlockValidatableInput
                                id="formScoreMessage"
                                name={nameof2<ChannelValues, ParamValues>("Params", "score_message")}
                                label={t(`${tNamespace}request-score-page-html`)}
                                disabled={!values.Params.enable_score}
                            />
                            <CodeBlockValidatableInput
                                id="formScorePage"
                                name={nameof2<ChannelValues, ParamValues>("Params", "score_page")}
                                label={t(`${tNamespace}score-page`)}
                                disabled={!values.Params.enable_score}
                            />
                        </div>
                    </div>
                </FormAccordion>
            </ChannelFormWrapper>
        )
    }

export default FormikEmailChannelFormGeneral
