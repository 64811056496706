import React, { useRef, useState } from "react"
import { locales, Locale } from "../../locales"
import { useTranslation } from "react-i18next"
import { useOutsideClick } from "../../utility/common/useOutsideClick"
import styles from "./LanguageSelector.module.scss"
import cn from "classnames"
import { getLanguage } from "../../utility/common/language"
import { ClassProps } from "../../utility/common/props"

interface Props extends ClassProps {}

const LanguageSelector: React.FC<Props> = props => {
    const { className } = props
    const [opened, setOpen] = useState(false)
    const wrapperRef = useRef<HTMLDivElement>(null)
    useOutsideClick<HTMLDivElement>(() => setOpen(false), wrapperRef)
    const { i18n } = useTranslation()
    const actionSelected = (locale: string) => (!opened ? setOpen(true) : setLanguage(locale))
    const setLanguage = (locale: string) => {
        i18n.changeLanguage(locale)
        setOpen(false)
    }
    const IsCurrentLanguage = (locale: string, selectedLanguage: string) =>
        selectedLanguage === locale ? styles.languageContainer__item_selected : ""
    return (
        <div className={cn(styles.languageContainer, className)} ref={wrapperRef}>
            {locales.map((element: Locale) => (
                <div
                    className={cn(
                        styles.languageContainer__item,
                        opened && styles.languageContainer__item_opened,
                        IsCurrentLanguage(element.id, getLanguage(i18n))
                    )}
                    onClick={() => actionSelected(element.id)}
                    data-language-id={element.id}
                    key={element.id}
                >
                    {element.shortName}
                </div>
            ))}
        </div>
    )
}

export default LanguageSelector
