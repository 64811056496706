import React from "react"
import styles from "./EditSurveyField.module.scss"
import { formTranslation } from "../../../locales/form"
import { Button, Form } from "react-bootstrap"
import { WithT } from "i18next"
import { useField } from "formik"
import { SurveyFieldValue } from "../../../models/survey"
import { Draggable } from "react-beautiful-dnd"
import { ClassProps } from "../../../utility/common/props"
import cn from "classnames"

const tNamespace = "surveys:form."

interface Props extends WithT, ClassProps {
    index: number
    title: string
    name: string
    onDelete: () => void
}

const EditSurveyField: React.FC<Props> = props => {
    const { t, title, name, index, onDelete, className } = props
    const [field, , helper] = useField<SurveyFieldValue>(name)
    const [editedCommentField, editedCommentMeta] = useField<string>(`${name}.EditedComment`)

    const value = field.value

    const handleSave = () => {
        value.EditedComment &&
            helper.setValue({ ...value, IsEditing: false, IsAdding: false, Comment: value.EditedComment })
    }

    const handleCancel = () => {
        value.IsAdding ? onDelete() : helper.setValue({ ...value, IsEditing: false, EditedComment: value.Comment })
    }

    const isInvalid = editedCommentMeta.touched && !!editedCommentMeta.error

    return (
        <Draggable key={value.Id} draggableId={value.Id} index={index}>
            {provided => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={cn(styles.editSurveyField, className)}
                >
                    <div className={styles.editSurveyField__slot}>{title}</div>
                    <Form.Group
                        controlId={value.Id}
                        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (value.EditedComment && e.key === "Enter") handleSave()
                        }}
                    >
                        <Form.Label>{t(`${tNamespace}field-title`)}</Form.Label>
                        <Form.Control
                            name={`${name}.EditedComment`}
                            onChange={editedCommentField.onChange}
                            onBlur={editedCommentField.onBlur}
                            value={editedCommentField.value}
                            isInvalid={isInvalid}
                        />
                        <Form.Control.Feedback type="invalid">
                            {isInvalid && t(formTranslation.titleRequired)}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <div className={styles.editSurveyField__footer}>
                        <Button variant="light" onClick={handleCancel}>
                            {t(formTranslation.cancel)}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleSave}
                            disabled={!editedCommentField.value || !!editedCommentMeta.error}
                            className={styles.editSurveyField__saveBtn}
                        >
                            {t(formTranslation.save)}
                        </Button>
                    </div>
                </div>
            )}
        </Draggable>
    )
}

export default EditSurveyField
