import React, { useCallback, useEffect, useState } from "react"
import "./CheckBoxValidatableInput.scss"
import { Form } from "react-bootstrap"
import { useField } from "formik"
import cn from "classnames"
import debounce from "lodash/debounce"
import CheckBoxValidatableInput from "./CheckBoxValidatableInput"

interface Props extends React.ComponentPropsWithoutRef<typeof CheckBoxValidatableInput> {
    children?: never
    disabled?: boolean
    inline?: boolean
    icon?: JSX.Element
    onChange?: (v: boolean) => void
}

const DebouncedCheckBox: React.FC<Props> = props => {
    const { id, className, name, label, disabled, inline, icon, onChange } = props

    const [field, meta] = useField<any>(name)
    const [innerValue, setInnerValue] = useState(field.value)

    const formLabel = (label || icon) && (
        <span className="checkbox-input__label">
            {label}
            {icon}
        </span>
    )

    useEffect(() => {
        field.value ? setInnerValue(field.value) : setInnerValue(false)
    }, [field.value])

    const debounced = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        field.onChange(event)
    }, 200)

    const handleOnChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            event.persist()
            setInnerValue(event.currentTarget.checked)
            onChange?.(event.currentTarget.checked)
            debounced(event)
        },
        [debounced, onChange]
    )

    return (
        <Form.Check
            custom
            inline={inline}
            disabled={disabled}
            type="checkbox"
            id={`${id}-input`}
            label={formLabel}
            name={field.name}
            onChange={handleOnChange}
            checked={innerValue ?? false}
            isInvalid={!!meta.error}
            className={cn("checkbox-input", className)}
        />
    )
}

export default DebouncedCheckBox
