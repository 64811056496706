import scorePageImage from "../../assets/images/scorePageImage.png"
import { formTranslation } from "../../locales/form"
import { tl } from "../common/language"

const tNamespace = "channel:score-page."
const logoSrc =
    "http://cdn.mcauto-images-production.sendgrid.net/021a26e08987261a/a22a1bbb-f618-4c16-9377-d3657f3f5ea7/164x159.png"
const feedbackSlot = "score_slot_comment"

export const getScorePage = (language: string) => `<!DOCTYPE html>
<html lang="en">
<head>
    <link rel="icon" href="${process.env.PUBLIC_URL}/favicon.png" />
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title>Send score</title>
    <style>
        * {
            box-sizing: border-box;
        }

        html,
        body {
            margin: 0;
            padding: 0;
            overflow: hidden;
        }

        body {
            font-family: arial, helvetica, sans-serif;
            font-size: 16px;
            color: #333333;
        }

        img {
            max-width: 100%;
        }

        .container {
            min-height: 100vh;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 40px;
        }

        .header {
            width: 100%;
            display: flex;
            flex-direction: row;
            margin-bottom: auto;
        }

        .logo {
            width: 48px;
        }

        .content {
            margin: 0 auto;
            width: 450px;
            text-align: center;
        }

        .footer {
            margin-top: auto;
        }

        h1 {
            font-size: 36px;
            font-weight: 500;
            margin-bottom: 35px;
        }

        h2 {
            font-size: 28px;
            font-weight: 400;
            margin-bottom: 40px;
        }

        label {
            font-size: 20px;
            color: #202124;
            display: inline-block;
            margin-bottom: 24px;
        }

        .stars {
            margin: 0 auto 60px;
            width: 348px;
            display: flex;
            justify-content: space-between;
        }

        .comment {
            height: 120px;
            width: 100%;
            margin-bottom: 30px;
            resize: none;
            border: 1px solid #A2A2A2;
            border-radius: 5px;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-family: inherit;
        }

        .btn {
            cursor: pointer;
            width: 100%;
            font-weight: 400;
            color: #fff;
            background-color: #1066A8;
            text-align: center;
            vertical-align: middle;
            border: 1px solid #1066A8;
            padding: 10px 0.75rem;
            font-size: 0.875rem;
            line-height: 1.5;
            border-radius: 0.25rem;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }

        .btn:hover {
            background-color: #0d5185;
            border-color: #0c4a79;
        }

        .score {
            color: #A2A2A2;
            transition: color .3s ease-in;
            cursor: pointer;
        }

        .score_selected {
            color: #F79F1F;
        }

        #thanks {
            display: none;
        }
    </style>
</head>

<body>
<div class="container">
    <header class="header">
        <img class="logo" src="${logoSrc}" alt="">
    </header>
    <main class="content">
        <div id="send-score">
            <h1>${tl(`${tNamespace}title`, language)}!</h1>
            <form action="" id="score-form">
                <div class="stars">
                    <div class="score">
                        <svg width="54" height="54" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M51 21.625L37.3125 19.6562L31.125 7.1875C30.0938 5.03125 26.9062 4.9375 25.7812 7.1875L19.6875 19.6562L5.90625 21.625C3.46875 22 2.53125 25 4.3125 26.7812L14.1562 36.4375L11.8125 50.0312C11.4375 52.4688 14.0625 54.3438 16.2188 53.2188L28.5 46.75L40.6875 53.2188C42.8438 54.3438 45.4688 52.4688 45.0938 50.0312L42.75 36.4375L52.5938 26.7812C54.375 25 53.4375 22 51 21.625ZM39.4688 35.3125L42.0938 50.5L28.5 43.375L14.8125 50.5L17.4375 35.3125L6.375 24.625L21.6562 22.375L28.5 8.5L35.25 22.375L50.5312 24.625L39.4688 35.3125Z" fill="currentColor" />
                        </svg>
                    </div>
                    <div class="score">
                        <svg width="54" height="54" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M51 21.625L37.3125 19.6562L31.125 7.1875C30.0938 5.03125 26.9062 4.9375 25.7812 7.1875L19.6875 19.6562L5.90625 21.625C3.46875 22 2.53125 25 4.3125 26.7812L14.1562 36.4375L11.8125 50.0312C11.4375 52.4688 14.0625 54.3438 16.2188 53.2188L28.5 46.75L40.6875 53.2188C42.8438 54.3438 45.4688 52.4688 45.0938 50.0312L42.75 36.4375L52.5938 26.7812C54.375 25 53.4375 22 51 21.625ZM39.4688 35.3125L42.0938 50.5L28.5 43.375L14.8125 50.5L17.4375 35.3125L6.375 24.625L21.6562 22.375L28.5 8.5L35.25 22.375L50.5312 24.625L39.4688 35.3125Z" fill="currentColor" />
                        </svg>
                    </div>
                    <div class="score">
                        <svg width="54" height="54" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M51 21.625L37.3125 19.6562L31.125 7.1875C30.0938 5.03125 26.9062 4.9375 25.7812 7.1875L19.6875 19.6562L5.90625 21.625C3.46875 22 2.53125 25 4.3125 26.7812L14.1562 36.4375L11.8125 50.0312C11.4375 52.4688 14.0625 54.3438 16.2188 53.2188L28.5 46.75L40.6875 53.2188C42.8438 54.3438 45.4688 52.4688 45.0938 50.0312L42.75 36.4375L52.5938 26.7812C54.375 25 53.4375 22 51 21.625ZM39.4688 35.3125L42.0938 50.5L28.5 43.375L14.8125 50.5L17.4375 35.3125L6.375 24.625L21.6562 22.375L28.5 8.5L35.25 22.375L50.5312 24.625L39.4688 35.3125Z" fill="currentColor" />
                        </svg>
                    </div>
                    <div class="score">
                        <svg width="54" height="54" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M51 21.625L37.3125 19.6562L31.125 7.1875C30.0938 5.03125 26.9062 4.9375 25.7812 7.1875L19.6875 19.6562L5.90625 21.625C3.46875 22 2.53125 25 4.3125 26.7812L14.1562 36.4375L11.8125 50.0312C11.4375 52.4688 14.0625 54.3438 16.2188 53.2188L28.5 46.75L40.6875 53.2188C42.8438 54.3438 45.4688 52.4688 45.0938 50.0312L42.75 36.4375L52.5938 26.7812C54.375 25 53.4375 22 51 21.625ZM39.4688 35.3125L42.0938 50.5L28.5 43.375L14.8125 50.5L17.4375 35.3125L6.375 24.625L21.6562 22.375L28.5 8.5L35.25 22.375L50.5312 24.625L39.4688 35.3125Z" fill="currentColor" />
                        </svg>
                    </div>
                    <div class="score">
                        <svg width="54" height="54" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M51 21.625L37.3125 19.6562L31.125 7.1875C30.0938 5.03125 26.9062 4.9375 25.7812 7.1875L19.6875 19.6562L5.90625 21.625C3.46875 22 2.53125 25 4.3125 26.7812L14.1562 36.4375L11.8125 50.0312C11.4375 52.4688 14.0625 54.3438 16.2188 53.2188L28.5 46.75L40.6875 53.2188C42.8438 54.3438 45.4688 52.4688 45.0938 50.0312L42.75 36.4375L52.5938 26.7812C54.375 25 53.4375 22 51 21.625ZM39.4688 35.3125L42.0938 50.5L28.5 43.375L14.8125 50.5L17.4375 35.3125L6.375 24.625L21.6562 22.375L28.5 8.5L35.25 22.375L50.5312 24.625L39.4688 35.3125Z" fill="currentColor" />
                        </svg>
                    </div>
                </div>
    
                <p>${tl(`${tNamespace}share-your-feedback`, language)}:</p>
                <textarea class="comment" name="slots[${feedbackSlot}]"></textarea>
                <button class="btn" type="submit">${tl(formTranslation.send, language)}</button>
    
            </form>
        </div>
    
        <div id="thanks">
            <h1>${tl(`${tNamespace}thanks-for-feedback`, language)}!</h1>
            <img src="${scorePageImage}" alt=""/>
            <h2>${tl(`${tNamespace}working-to-improve-the-service`, language)}</h2>
        </div>
    </main>
    <footer class="footer"></footer>
</div>

<script>
    window.onload = function() {
        var pathArray = window.location.pathname.split("/");
        var scoreFromPath = pathArray[6];
        var score = (scoreFromPath && scoreFromPath >= 1 && scoreFromPath <= 5) ? scoreFromPath : 0;
        var scores = document.querySelectorAll(".score");
        var i;

        [].forEach.call(scores, function(star, index) {
            star.addEventListener("click", function() {
                score = index + 1;
                for(i = 0; i < scores.length; i++)
                {
                    scores[i].classList.remove("score_selected");
                }
                for(i = 0; i < score; i++)
                {
                    scores[i].classList.add("score_selected");
                }
            })

            if (index < score) {
                star.classList.add("score_selected");
            }
        });

        document.getElementById("score-form").addEventListener("submit", function (event) {
            event.preventDefault();
            var formData = new FormData(this);
            formData.append("score", score);

            fetch(window.location.href, {
                method: "POST",
                body: formData
            }).then(function () {
                document.getElementById("send-score").style.display = "none";
                document.getElementById("thanks").style.display = "block";
            }).catch(function (error) {
                console.error(error);
            });
        });
    }
</script>
</body>

</html>
`
