import React, { useContext } from "react"
import { Form } from "react-bootstrap"
import { Formik, FormikProps } from "formik"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"
import "./ProjectUserForm.scss"
import { AllRoleSettings, Role } from "../../models/role"
import { WithT } from "i18next"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import SettingsGroup, { getCheckedValues, initSettings, Settings } from "../SettingsGroup/SettingsGroup"
import { QueueName } from "../../models/queue"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import FormAccordion from "../FormAccordion/FormAccordion"
import { updateProjectUser } from "../../store/users/thunks"
import UserCard from "../UserCard/UserCard"
import { formTranslation } from "../../locales/form"
import { selectUpdateProjectUser } from "../../store/users/selectors"
import { nameof } from "../../utility/common/nameof"
import { ProjectUser, UpdateProjectUserRequest } from "../../models/projectUser"
import { UserProjectSettings } from "../../models/user"
import LoadingButton from "../LoadingButton/LoadingButton"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import { ModifyOperatorParallelTasks } from "../../permissions"
import usePermissionsCheck from "../../utility/common/usePermissionsCheck"
import ConfigContext from "../ConfigContext/ConfigContext"
import { handleSettingsLabelTranslation, SettingsType } from "../../utility/common/handleSettingsLabelTranslation"

const tUserNamespace = "user:"
const tRolesNamespace = "roles:"

interface ProjectUserValues {
    roleId: string
    permissions: Settings<string>
    queues: Settings<QueueName>
    maxDialogs: number
}

interface Props {
    user: ProjectUser
    roles: Role[]
    allRoleSettings: AllRoleSettings
    close: Function
}

const extractPermissionsAndQueues = (roleId: string, settings: UserProjectSettings) => {
    if (roleId === "") {
        return [settings.Permissions, settings.Queues]
    }
    const roleSettings = settings.RolesSettings.find(s => s.RoleId === roleId)
    if (roleSettings === undefined) {
        throw new Error(`${roleId} not found`)
    }

    return [roleSettings.Permissions, roleSettings.Queues]
}

const FormikProjectUserForm: React.FC<Props & FormikProps<ProjectUserValues> & WithT> = props => {
    const { user, roles, allRoleSettings, t, values, setFieldValue, handleSubmit } = props

    const updateState = useSelector(selectUpdateProjectUser, shallowEqual)
    const modifyOperatorParallelTasksAllowed = usePermissionsCheck([ModifyOperatorParallelTasks])
    const {
        WebConfig: {
            appSettings: { signUpEnabled }
        }
    } = useContext(ConfigContext)

    const handleChangeCurrentRole = (e: React.ChangeEvent<HTMLInputElement>) => {
        let roleId = e.target.value
        let [permissions, queues] = extractPermissionsAndQueues(roleId, user.ProjectSettings)
        const permissionsSettings: Settings<string> = {}
        initSettings(
            permissionsSettings,
            allRoleSettings.Permissions,
            permissions,
            v => v,
            v => v
        )
        const queuesSettings: Settings<QueueName> = {}
        initSettings(
            queuesSettings,
            allRoleSettings.Queues,
            queues,
            v => v.Id,
            v => v
        )
        setFieldValue(nameof<ProjectUserValues>("roleId"), roleId)
        setFieldValue(nameof<ProjectUserValues>("permissions"), permissionsSettings)
        setFieldValue(nameof<ProjectUserValues>("queues"), queuesSettings)
    }

    return (
        <Form className="project-user-form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <div className="project-user-form__content">
                <div className="project-user-form__card">
                    <UserCard firstName={user.FirstName} lastName={user.LastName} picture={user.Picture} />
                </div>
                <FormAccordion title={t(`${tUserNamespace}user-info`)}>
                    <div className="user-info">
                        <div className="user-info__section">
                            <div className="user-info__name">{t(formTranslation.login)}</div>
                            <div className="user-info__value">{user.Login}</div>
                        </div>
                    </div>
                </FormAccordion>
                <Form.Group className="role-selector" controlId="formCurrentRole">
                    <Form.Label>{t(`${tRolesNamespace}role`)}</Form.Label>
                    <Form.Control
                        disabled={!signUpEnabled}
                        as="select"
                        onChange={handleChangeCurrentRole}
                        value={values.roleId}
                    >
                        <option key="default" value={""}>
                            {t(formTranslation.select)}
                        </option>
                        {roles.map(r => (
                            <option key={r.Id} value={r.Id}>
                                {r.Title}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <SettingsGroup<string>
                    name={nameof<ProjectUserValues>("permissions")}
                    title={t(`${tRolesNamespace}permissions`)}
                    values={values.permissions}
                    disabled={!signUpEnabled}
                    getLabel={v => handleSettingsLabelTranslation(tRolesNamespace, v, SettingsType.Roles, t)}
                />
                <SettingsGroup<QueueName>
                    name={nameof<ProjectUserValues>("queues")}
                    title={t(`${tRolesNamespace}queues`)}
                    values={values.queues}
                    getLabel={v => v.Name}
                >
                    <ValidatableInput
                        id="formMaxCapacity"
                        type="number"
                        name={nameof<ProjectUserValues>("maxDialogs")}
                        label={t(formTranslation.maxConcurrentTasks)}
                        disabled={!modifyOperatorParallelTasksAllowed}
                    />
                </SettingsGroup>
            </div>
            <div className="project-user-form__footer">
                <LoadingButton type="submit" loading={updateState.inProcess} variant="primary" block>
                    {t(formTranslation.save)}
                </LoadingButton>
            </div>
        </Form>
    )
}

const getValuesFromUser = (user: ProjectUser, allRoleSettings: AllRoleSettings): ProjectUserValues => {
    const currentRoleId = user.ProjectSettings.CurrentRoleId ?? ""

    const permissionsSettings: Settings<string> = {}
    const [permissions, queues] = extractPermissionsAndQueues(currentRoleId, user.ProjectSettings)
    initSettings(
        permissionsSettings,
        allRoleSettings.Permissions,
        permissions,
        v => v,
        v => v
    )
    const queuesSettings: Settings<QueueName> = {}
    initSettings(
        queuesSettings,
        allRoleSettings.Queues,
        queues,
        v => v.Id,
        v => v
    )

    return {
        roleId: currentRoleId,
        permissions: permissionsSettings,
        queues: queuesSettings,
        maxDialogs: user.MaxDialogs
    }
}

const buildRequest = (user: ProjectUser, values: ProjectUserValues): UpdateProjectUserRequest => {
    return {
        Login: user.Login,
        CurrentRoleId: values.roleId === "" ? undefined : values.roleId,
        Permissions: getCheckedValues(values.permissions),
        Queues: getCheckedValues(values.queues).map(v => v.Id),
        MaxDialogs: values.maxDialogs
    }
}

const ProjectUserForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { user, allRoleSettings, close } = props

    return (
        <Formik
            enableReinitialize={true}
            initialValues={getValuesFromUser(user, allRoleSettings)}
            validationSchema={() => {
                return Yup.object().shape({})
            }}
            onSubmit={(values: ProjectUserValues) => {
                dispatch(updateProjectUser(user.ProjectId, buildRequest(user, values), close))
            }}
        >
            {formikProps => <FormikProjectUserForm {...props} {...formikProps} t={t} />}
        </Formik>
    )
}

export default ProjectUserForm
