import { useField, useFormikContext } from "formik"
import React, { useEffect, useRef, useState } from "react"
import { Button, Form, ProgressBar } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { formTranslation } from "../../locales/form"
import { nameof } from "../../utility/common/nameof"
import "./WizardProjectUpload.scss"
import cn from "classnames"
import { useDispatch, useSelector } from "react-redux"
import { uploadProject } from "../../store/projects/thunks"
import { selectUploadProjectState } from "../../store/projects/selectors"
import { ProjectCopyWizardFormValues } from "../../models/projectWizard"

const WizardProjectUpload: React.FC = () => {
    const fieldName = nameof<ProjectCopyWizardFormValues>("ArchiveName")
    const [, archiveNameMeta] = useField<string>(fieldName)
    const { setFieldValue } = useFormikContext<ProjectCopyWizardFormValues>()

    const inputFileRef = useRef<HTMLInputElement>(null)

    const dispatch = useDispatch()
    const [progress, setProgress] = useState(0)
    const [selectedFile, setSelectedFile] = useState<File | null>(null)

    const uploadProjectState = useSelector(selectUploadProjectState)

    const { t } = useTranslation()

    const handleInputFileRedirect = () => {
        if (inputFileRef && inputFileRef.current) {
            inputFileRef.current.click()
        }
    }

    const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files == null || e.target.files.length === 0) {
            return
        }
        const file = e.target.files[0]
        setSelectedFile(file)
    }

    const handleUpload = async () => {
        if (!selectedFile) return

        setProgress(0)

        const updateProgress = (event: any) => {
            const updatedProgress = Math.round((event.loaded / event.total) * 100)
            setProgress(updatedProgress)
        }
        dispatch(uploadProject(selectedFile, updateProgress))
    }

    useEffect(() => {
        if (uploadProjectState.data) setFieldValue(fieldName, uploadProjectState.data)
    }, [fieldName, setFieldValue, uploadProjectState.data])

    return (
        <div className="wizard-project-upload">
            <div className="wizard-project-upload__select">
                <input
                    type="file"
                    className="wizard-project-upload__file-input"
                    accept="application/gzip, .gz"
                    ref={inputFileRef}
                    onChange={handleSelectFile}
                />
                <Button
                    className="wizard-project-upload__select-btn"
                    disabled={uploadProjectState.inProcess}
                    variant="outline-primary"
                    onClick={handleInputFileRedirect}
                >
                    {t(formTranslation.selectFile)}
                </Button>
                {selectedFile && <div className="wizard-project-upload__file-name">{selectedFile.name}</div>}
            </div>
            {archiveNameMeta.error && (
                <Form.Control.Feedback className="wizard-project-upload__feedback" type="invalid">
                    {t(archiveNameMeta.error)}
                </Form.Control.Feedback>
            )}
            <div
                className={cn("wizard-project-upload__upload", !selectedFile && "wizard-project-upload__upload_hidden")}
            >
                <Button disabled={uploadProjectState.inProcess} variant="outline-primary" onClick={handleUpload}>
                    {t(formTranslation.upload)}
                </Button>
            </div>
            <div
                className={cn(
                    "wizard-project-upload__progress",
                    !selectedFile && "wizard-project-upload__progress_hidden"
                )}
            >
                <ProgressBar
                    animated={uploadProjectState.inProcess}
                    striped={uploadProjectState.inProcess}
                    variant={uploadProjectState.data ? "success" : undefined}
                    now={progress}
                    label={uploadProjectState.data ? t(formTranslation.completed) : `${progress}%`}
                />
            </div>
        </div>
    )
}

export default WizardProjectUpload
