import React, { useContext } from "react"
import { useSelector } from "react-redux"
import { selectCatalogsState } from "../../store/knowledgeBase/selectors"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import Async from "../Async/Async"
import CatalogsLoader from "./CatalogsLoader"
import KnowledgeBaseContext from "../KnowledgeBaseProvider/KnowledgeBaseContext"
import EditableCatalog from "../Catalog/EditableCatalog/EditableCatalog"
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary"

interface CatalogsProps {
    onEditCatalogClick: () => void
    onCancelEditCatalogClick: () => void
}

const Catalogs: React.FC<CatalogsProps> = props => {
    const apiState = useSelector(selectCatalogsState)
    const { url, canModifyKnowledgeBase, onOpenSharingCatalog, projectId } = useContext(KnowledgeBaseContext)

    return (
        <Async
            dataState={apiState}
            processView={<CatalogsLoader itemsCount={3} />}
            errorView={({ message }) => <ErrorMessage text={message} />}
        >
            {({ data: catalogIds }) => (
                <ErrorBoundary local>
                    {projectId &&
                        catalogIds.map(id => (
                            <EditableCatalog
                                key={`${id}-editable-catalog`}
                                projectId={projectId}
                                id={id as string}
                                url={url}
                                canModifyKnowledgeBase={canModifyKnowledgeBase}
                                onOpenSharingCatalog={onOpenSharingCatalog}
                                {...props}
                            />
                        ))}
                </ErrorBoundary>
            )}
        </Async>
    )
}

export default Catalogs
