import React, { useCallback, useEffect } from "react"
import { RouteComponentProps, useParams } from "react-router"
import styles from "./ProjectMenuSwitch.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { definedMenuItems, knownComponents } from "../../utility/menu/definedMenuItems"
import loadable from "@loadable/component"
import FullscreenLoader from "../FullscreenLoader/FullscreenLoader"
import { MenuItem } from "../../models/menu"
import { buildArticleActionUrl } from "../../utility/knowledgeBase/articleUrlHelper"
import { ArticleAction } from "../../models/article"
import { push } from "connected-react-router"
import OldWorkplaceFrame from "../OldWorkplaceFrame/OldWorkplaceFrame"
import KnowledgeBase from "../../pages/KnowledgeBase/KnowledgeBase"
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary"
import { promiseDelay } from "../../utility/common/promiseDelay"
import { selectCurrentMainMenu } from "../../store/menu/selectors"
import { selectMainMenu } from "../../store/menu/thunks"
import { buildMenuItemUrl } from "../../utility/menu/menuUrlHelper"
import { MatchPath } from "../../types/matchPath"
import { selectCurrentProject } from "../../store/projects/selectors"
import UserPage from "../../pages/User/User"
import UserManagement from "../../pages/UserManagement/UserManagement"

const Loading = <FullscreenLoader hideLogo />
const Queues = loadable(() => promiseDelay(import("../../pages/Queues/Queues"), 200), { fallback: Loading })
const ProjectSettings = loadable(() => promiseDelay(import("../../pages/ProjectSettings/ProjectSettings"), 200), {
    fallback: Loading
})

export interface ProjectMenuSwitchProps extends RouteComponentProps<MatchPath> {
    url: string
    menuItems: MenuItem[]
    defaultArticleId?: string
}

const ProjectMenuSwitch: React.FC<ProjectMenuSwitchProps> = props => {
    const { defaultArticleId, url, menuItems } = props
    const selectedMenu = useSelector(selectCurrentMainMenu)
    const currentProject = useSelector(selectCurrentProject)
    const dispatch = useDispatch()
    const { menuId } = useParams<MatchPath>()

    const handleRouteToDefault = useCallback(() => {
        const allowedItem = menuItems[0]
        if (defaultArticleId) {
            const routePath = buildArticleActionUrl(
                `${url}/${definedMenuItems.KnowledgeBase.id}`,
                ArticleAction.View,
                defaultArticleId
            )
            dispatch(push(routePath))
        } else if (allowedItem) {
            const routePath = buildMenuItemUrl(url, allowedItem.id)
            dispatch(push(routePath))
        }
    }, [defaultArticleId, dispatch, menuItems, url])

    const isMenuAllowed = useCallback(
        (menuId: string) => {
            const selectedMenuItem = menuItems.find(i => i.id === menuId)
            return !menuId || selectedMenuItem
        },
        [menuItems]
    )

    useEffect(() => {
        if (!selectedMenu && !menuId) {
            handleRouteToDefault()
        } else if (menuId && isMenuAllowed(menuId)) {
            dispatch(selectMainMenu(menuId))
        }
    }, [dispatch, handleRouteToDefault, isMenuAllowed, menuId, menuItems, selectedMenu])

    useEffect(() => {
        if (menuId) {
            const menuIndex = knownComponents.indexOf(menuId)
            if (menuIndex === -1 && !isMenuAllowed(menuId)) {
                handleRouteToDefault()
            }
        }
    }, [handleRouteToDefault, isMenuAllowed, menuId])

    const renderMenus = () => {
        switch (menuId) {
            case definedMenuItems.Queues.id:
                return <Queues />
            case definedMenuItems.Profile.id:
                return <UserPage />
            case definedMenuItems.Settings.id:
                return <ProjectSettings />
            case definedMenuItems.UserManagement.id:
                return <UserManagement />
            case definedMenuItems.KnowledgeBase.id:
                return <KnowledgeBase />
            default:
                return null
        }
    }

    return (
        <div className={styles.projectMenuSwitch}>
            <ErrorBoundary hideLogo>
                {renderMenus()}
                <OldWorkplaceFrame selectedMenu={selectedMenu} selectedProject={currentProject?.id} menuId={menuId} />
            </ErrorBoundary>
        </div>
    )
}

export default ProjectMenuSwitch
