import React, { ReactNode } from "react"
import styles from "./FormControlWithAdd.module.scss"
import { faPlus } from "@fortawesome/pro-light-svg-icons"
import { FieldArray } from "formik"
import { FieldArrayRenderProps } from "formik/dist/FieldArray"
import IconControl from "../../IconControl/IconControl"

export interface FormControlWithAddProps {
    name: string
    label: string
    icon?: JSX.Element
    onCreateEmptyValue: () => any
    children: (arrayHelpers: FieldArrayRenderProps) => ReactNode
}

const FormControlWithAdd: React.FC<FormControlWithAddProps> = props => {
    const { name, label, icon, onCreateEmptyValue, children } = props
    return (
        <FieldArray
            name={name}
            render={arrayHelpers => (
                <>
                    <div className={styles.formControlWithAdd__addSection}>
                        <div className={styles.formControlWithAdd__label}>
                            {label}
                            {icon}
                        </div>

                        <IconControl
                            icon={faPlus}
                            className={styles.formControlWithAdd__addButton}
                            onClick={() => arrayHelpers.push(onCreateEmptyValue())}
                        />
                    </div>
                    {children(arrayHelpers)}
                </>
            )}
        />
    )
}

export default FormControlWithAdd
