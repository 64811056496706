import React, { useContext, useCallback, useEffect, useRef, useState } from "react"
import "./ProjectUsers.scss"
import { useTranslation } from "react-i18next"
import { CellProps, Column } from "react-table"
import SettingsButton from "../SettingsButton/SettingsButton"
import UserCard from "../UserCard/UserCard"
import { formTranslation } from "../../locales/form"
import { Role } from "../../models/role"
import { Admin, InviteUsersToProject, ModifyUsers } from "../../permissions"
import { ProjectUser } from "../../models/projectUser"
import Can from "../Can/Can"
import { faBan, faPen } from "@fortawesome/pro-light-svg-icons"
import SettingsContextMenu from "../SettingsContextMenu/SettingsContextMenu"
import SettingsContextMenuItem from "../SettingsContextMenuItem/SettingsContextMenuItem"
import AddButton from "../AddButton/AddButton"
import { useModal } from "../../utility/common/useModal"
import AlertDialog from "../AlertDialog/AlertDialog"
import { testId } from "../../utility/tests/testId"
import SettingsTableWithoutPagination from "../SettingsTable/SettingsTableWithoutPagination/SettingsTableWithoutPagination"
import ConfigContext from "../ConfigContext/ConfigContext"
import { invitePath } from "../../routerPaths"
import { useParams } from "react-router-dom"
import { FormPathParams } from "../../utility/common/urlFormHelper"
import { FormChangeCallback } from "../../pages/ProjectSettings/ProjectSettings"
import { removeFirstSlash } from "../../utility/common/urlHelper"

const tProjectsNamespace = "project:"

interface Props {
    projectId: string
    title: string
    users: ProjectUser[]
    roles: Role[]
    onInviteClick: (roles: Role[]) => void
    onSettingsClick: (user: ProjectUser) => void
    onBlockClick: (user: ProjectUser) => void
    onFormChange: FormChangeCallback
}

const ProjectUsers: React.FC<Props> = props => {
    const { title, users, roles, onInviteClick, onSettingsClick, onBlockClick, onFormChange } = props
    const { t } = useTranslation()

    const {
        WebConfig: {
            appSettings: { signUpEnabled }
        }
    } = useContext(ConfigContext)

    const {
        current: { formId }
    } = useRef(useParams<FormPathParams>())

    const [userToBlock, setUserToBlock] = useState<ProjectUser | null>(null)
    const { modalOpen, openModal, closeModal, onExited } = useModal(() => (
        <AlertDialog
            show={modalOpen}
            title={t(`${tProjectsNamespace}block-user-confirmation.title`)}
            message={t(`${tProjectsNamespace}block-user-confirmation.message`)}
            submitBtnText={t(formTranslation.block)}
            onClose={closeModal}
            onSubmit={() => {
                userToBlock && onBlockClick(userToBlock)
            }}
            variant="danger"
            onExited={onExited}
        />
    ))

    const getItems = (user: ProjectUser) => {
        return (
            <>
                <Can permission={ModifyUsers}>
                    <SettingsContextMenuItem
                        id="project-user-edit"
                        icon={faPen}
                        text={t(formTranslation.edit)}
                        onClick={() => onSettingsClick(user)}
                    />
                </Can>
                <Can permission={Admin}>
                    <SettingsContextMenuItem
                        id="project-user-block"
                        icon={faBan}
                        text={t(formTranslation.block)}
                        useSeparator={true}
                        danger={true}
                        onClick={() => {
                            setUserToBlock(user)
                            openModal()
                        }}
                    />
                </Can>
            </>
        )
    }

    const columns: Array<Column<ProjectUser>> = [
        {
            Header: t(formTranslation.user).toString(),
            id: "User",
            accessor: original => original.FirstName + " " + original.LastName,
            Cell: ({ row: { original } }: CellProps<ProjectUser>) => (
                <UserCard firstName={original.FirstName} lastName={original.LastName} picture={original.Picture} />
            )
        },
        {
            Header: t(formTranslation.group).toString(),
            id: "Group",
            accessor: original => {
                const currentRole = roles.find(r => r.Id === original.ProjectSettings.CurrentRoleId)
                return currentRole && currentRole.Title
            }
        },
        {
            Header: t(formTranslation.login).toString(),
            accessor: "Login"
        },
        {
            id: "Settings",
            disableSortBy: true,
            Cell: (props: CellProps<ProjectUser>) => (
                <Can permission={ModifyUsers}>
                    <SettingsContextMenu items={getItems(props.row.original)}>
                        <SettingsButton />
                    </SettingsContextMenu>
                </Can>
            )
        }
    ]

    const handleInviteClick = useCallback(() => onInviteClick(roles), [onInviteClick, roles])

    useEffect(() => {
        if (formId === removeFirstSlash(invitePath)) {
            handleInviteClick()
        }
    }, [formId, handleInviteClick])

    return (
        <Can permission={ModifyUsers}>
            {canModify => (
                <div className="project-users">
                    <div className="project-users__header">
                        <span className="project-users__title">{title}</span>
                        {signUpEnabled && (
                            <Can permission={InviteUsersToProject}>
                                <AddButton
                                    variant="outline-primary"
                                    className="project-users__btn-add"
                                    text={t(`${tProjectsNamespace}invite-users`)}
                                    onClick={() => {
                                        handleInviteClick()
                                        onFormChange(invitePath)
                                    }}
                                    data-test-id={testId.inviteUsers}
                                />
                            </Can>
                        )}
                    </div>
                    <SettingsTableWithoutPagination
                        columns={columns}
                        data={users}
                        onRowClick={canModify ? onSettingsClick : undefined}
                    />
                </div>
            )}
        </Can>
    )
}

export default ProjectUsers
