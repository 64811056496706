import { Catalog } from "../../models/catalog"
import { mutateTree, TreeData } from "@atlaskit/tree"
import { ItemId, TreeItem } from "@atlaskit/tree/types"
import { Category, CategoryMutation, CategoryPlacement } from "../../models/category"
import { ROOT_CATEGORY_CODE } from "./knowledgeBase"
import { ArticleRelatedCategories } from "../../models/articleRelatedCategories"
import { TreeItemMutation } from "@atlaskit/tree/dist/types/utils/tree"
import { isDraft, isPublished } from "./articleStatus"
import { isModifyPermitted } from "./useModifyPermitted"

export const createTreeItemFromCategoryMutation = (mutation: CategoryMutation): TreeItem => ({
    id: mutation.Id,
    children: [],
    hasChildren: mutation.HasChildren,
    isExpanded: false,
    isChildrenLoading: false,
    data: {
        title: mutation.Title,
        parentId: mutation.ParentId,
        symbolCode: mutation.SymbolCode,
        catalogCode: mutation.CatalogCode,
        permissions: mutation.Permissions,
        permittedAction: mutation.PermittedAction,
        status: mutation.Status,
        sort: mutation.Sort
    }
})

export const convertCategoryToTreeItem = (category: Category): TreeItem => ({
    id: category.Id,
    children: [],
    hasChildren: category.HasChildren,
    isExpanded: false,
    isChildrenLoading: false,
    data: {
        title: category.Title,
        parentId: category.ParentId,
        symbolCode: category.SymbolCode,
        catalogCode: category.CatalogCode,
        permissions: category.Permissions,
        permittedAction: category.PermittedAction,
        status: category.Status,
        sort: category.Sort
    }
})

export const convertCategoriesToTreeItemsMap = (categories: Category[]): Record<ItemId, TreeItem> => {
    return categories.reduce(
        (result: Record<ItemId, TreeItem>, category: Category) => ({
            ...result,
            [category.Id]: convertCategoryToTreeItem(category)
        }),
        {}
    )
}

export const convertCatalogToTree = (catalog: Catalog): TreeData => {
    const { Title, SymbolCode, RootCategories = [], Permissions, PermittedAction } = catalog
    const items = convertCategoriesToTreeItemsMap(RootCategories)

    const catalogItem: TreeItem = {
        id: SymbolCode,
        children: Object.keys(items),
        hasChildren: true,
        isExpanded: true,
        isChildrenLoading: false,
        data: {
            title: Title,
            symbolCode: ROOT_CATEGORY_CODE,
            catalogCode: SymbolCode,
            permissions: Permissions,
            permittedAction: PermittedAction
        }
    }

    return {
        rootId: SymbolCode,
        items: {
            [catalogItem.id]: catalogItem,
            ...items
        }
    }
}

export const addChildrenCategories = (parentId: ItemId, tree: TreeData, categories: Category[]): TreeData => {
    const newItems = convertCategoriesToTreeItemsMap(categories)
    const newChildren = [...Object.keys(newItems), ...tree.items[parentId].children]

    const newTree = {
        rootId: tree.rootId,
        items: {
            ...tree.items,
            ...newItems
        }
    }

    return mutateTree(newTree, parentId, { children: newChildren, isChildrenLoading: false })
}

export const insertTreeItem = (item: TreeItem, catalog: TreeData) => {
    const {
        id,
        data: { parentId, permissions, permittedAction, sort }
    } = item

    if (permissions && !permittedAction) {
        return catalog
    }

    let parentItem = parentId ? catalog.items[parentId] : undefined

    if (!parentItem) {
        return catalog
    }

    const { isExpanded, children } = parentItem

    // if parent is not expanded and children are not loaded we don't add tree item in order not to break the category cache
    if (!isExpanded && children.length === 0) {
        return catalog
    }

    const childItems = children.map(c => catalog.items[c])
    const nextItemIndex = childItems.findIndex(c => c.data.sort > sort)

    const newChildren =
        nextItemIndex === -1
            ? [...children, id]
            : nextItemIndex === 0
            ? [id, ...children]
            : [...children.slice(0, nextItemIndex), id, ...children.slice(nextItemIndex)]

    const newTree = {
        rootId: catalog.rootId,
        items: {
            ...catalog.items,
            [item.id]: item
        }
    }

    return mutateTree(newTree, parentId, { children: newChildren, isChildrenLoading: false })
}

export const mutateTreeItem = (mutation: CategoryMutation, item: TreeItem, catalog: TreeData) => {
    const { Id, ParentId, Permissions, PermittedAction, Status } = mutation
    if (Permissions) {
        // we may not update the category if the current category is published, and the received mutation is a draft
        // with view permitted action
        if (Status && isPublished(item.data.status) && isDraft(Status) && !isModifyPermitted(PermittedAction)) {
            return catalog
        } else if (!PermittedAction) {
            // remove category from tree
            let parentItem = ParentId ? catalog.items[ParentId] : undefined
            if (parentItem) {
                parentItem.children = parentItem.children.filter(c => c !== Id)
            }

            delete catalog.items[Id]

            return catalog
        }
    }

    return mutateTree(catalog, mutation.Id, categoryMutationToTreeItemMutation(mutation, item))
}

export const getPreviousAndNextItem = (
    draggedItemId: ItemId,
    children: ItemId[],
    treeItems: Record<ItemId, TreeItem>
) => {
    const draggedItemIndex = children.indexOf(draggedItemId)

    if (draggedItemIndex === -1 || children.length === 1) {
        return [undefined, undefined]
    }
    if (draggedItemIndex === 0) {
        return [undefined, treeItems[children[1]]]
    }
    if (draggedItemIndex === children.length - 1) {
        return [treeItems[children[draggedItemIndex - 1]], undefined]
    }
    return [treeItems[children[draggedItemIndex - 1]], treeItems[children[draggedItemIndex + 1]]]
}

export const treeItemToMoveCategoryDTO = (treeItem: TreeItem): CategoryPlacement => {
    const id = treeItem.id as string
    return {
        Id: id,
        ParentId: treeItem.data.parentId,
        CatalogCode: treeItem.data.catalogCode
    }
}

const updateRelatedCategory = (treeItem: TreeItem, mutations: CategoryMutation[]): TreeItem => {
    const mutation = mutations.find(m => m.Id === treeItem.id)
    const treeItemMutation = mutation ? categoryMutationToTreeItemMutation(mutation, treeItem) : undefined
    return { ...treeItem, ...treeItemMutation }
}

export const updateRelatedCategories = (
    relatedCategories: ArticleRelatedCategories,
    mutations: CategoryMutation[] = []
) => {
    const { ParentCategory, ChildCategories = [], ...rest } = relatedCategories
    return {
        ...rest,
        ParentCategory: updateRelatedCategory(ParentCategory, mutations),
        ChildCategories: ChildCategories.map(c => updateRelatedCategory(c, mutations))
    }
}

export const categoryMutationToTreeItemMutation = (mutation: CategoryMutation, item: TreeItem): TreeItemMutation => {
    const { ParentId, HasChildren, Sort, Title, Status, Permissions, PermittedAction } = mutation
    return {
        ...item,
        ...(HasChildren && { hasChildren: HasChildren }),
        data: {
            ...item.data,
            ...(ParentId && { parentId: ParentId }),
            ...(Sort && { sort: Sort }),
            ...(Title && { title: Title }),
            ...(Status && { status: Status }),
            ...(Permissions && { permissions: Permissions }),
            ...(PermittedAction && { permittedAction: PermittedAction })
        }
    }
}
