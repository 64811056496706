import { TaskChannel } from "./channel"
import { Client } from "./client"

export interface RouteTaskRequest {
    TaskId: string
    QueueId: string
    Type: string
    PreferredOperatorId?: string
    Channel: TaskChannel
    Client: Client
    CreatedAt: number
}

export interface AssignTaskRequest {
    TaskId: string
    OperatorId: string
    Type: string
    Channel: TaskChannel
    Client: Client
    CreatedAt: number
}

export interface MoveTaskRequest {
    TaskId: string
    QueueId: string
    OperatorId?: string
    CreatedAt?: number
}

export interface RerouteTaskRequest {
    TaskId: string
    OperatorId: string
    QueueId: string
    Message?: string
}

export interface SwitchTaskRequest {
    TaskId: string
    OperatorId: string
    QueueId: string
}

export enum TaskStatus {
    Pending,
    Assigned
}

export interface Task {
    Id: string
    TenantId: string
    QueueId?: string
    Type: string
    Status: TaskStatus
    ShortId?: string
    PreferredOperatorId?: string
    Channel: TaskChannel
    Client: Client
    CreatedAt: number
}

export interface TaskQueuedMsg {
    Task: Task
    QueueId: string
    ToIndividual: boolean
    CategoryId: string
}

export const isTaskQueuedMsg = (value: any): value is TaskQueuedMsg => {
    return (
        typeof value === "object" &&
        isTask(value.Task) &&
        typeof value.QueueId === "string" &&
        typeof value.ToIndividual === "boolean" &&
        typeof value.CategoryId === "string"
    )
}

export interface TasksMovedMsg {
    FromIndividual: boolean
    FromQueueId: string
    FromCategoryId: string
    ToQueueId: string
    ToIndividual: boolean
    ToCategoryId: string
    TasksIds: string[]
}

export const isTasksMovedMsg = (value: any): value is TasksMovedMsg => {
    return (
        typeof value === "object" &&
        typeof value.FromIndividual === "boolean" &&
        typeof value.FromQueueId === "string" &&
        typeof value.FromCategoryId === "string" &&
        typeof value.ToIndividual === "boolean" &&
        typeof value.ToCategoryId === "string" &&
        typeof value.ToQueueId === "string" &&
        Array.isArray(value.TasksIds)
    )
}

export interface TaskDetachedMsg {
    TaskId: string
    OperatorId: string
}

export interface TaskAssignedMsg {
    Task: Task
    OperatorId: string
}

export interface TaskRoutedMsg {
    Task: Task
    OperatorId: string
    FromQueueId: string
    FromIndividual: boolean
    FromCategoryId: string
}

export interface TaskCompletedMsg {
    TaskId: string
    TenantId: string
    QueueId?: string
    OperatorId?: string
}

export type TaskOfAutomaticProcessing = {
    Id: string
    TenantId: string
    ChannelId: string
    ChannelType: string
    Client: Client
    AgentId: string
    StartedAt: number
    FirstReplyAt: number
    CurrentFirstReplyAt: number
    RoutedAt: number
    CurrentRoutedAt: number
    EnqueuedAt: number
    ConnectedOperatorIds: string[]
}

export type TaskCommon = Task | TaskOfAutomaticProcessing

export function isTask(value: any): value is Task {
    return !!(
        typeof value === "object" &&
        value &&
        typeof value.Id === "string" &&
        typeof value.TenantId === "string" &&
        typeof value.Status === "number" &&
        value.Channel &&
        typeof value.Channel.Id === "string" &&
        typeof value.CreatedAt === "number"
    )
}

export function isTaskOfAutomaticProcessing(value: any): value is TaskOfAutomaticProcessing {
    return !!(
        typeof value === "object" &&
        value &&
        typeof value.Id === "string" &&
        typeof value.TenantId === "string" &&
        typeof value.ChannelId === "string" &&
        typeof value.ChannelType === "string" &&
        typeof value.AgentId === "string" &&
        value.Client &&
        typeof value.Client.Id === "string"
    )
}

export function isTaskCompletedMsg(value: any): value is TaskCompletedMsg {
    return !!(
        typeof value === "object" &&
        value &&
        typeof value.TaskId === "string" &&
        typeof value.TenantId === "string" &&
        (!value.QueueId || typeof value.QueueId === "string") &&
        (!value.OperatorId || typeof value.OperatorId === "string")
    )
}
