import React, { useEffect, useState } from "react"
import styles from "./NotificationContainer.module.scss"
import { Notification, NotificationSettings } from "../../models/notification"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentNotification, selectNotificationsListState } from "../../store/notifications/selectors"
import NotificationItem from "../NotificationItem/NotificationItem"
import ReactNotification, { ReactNotificationOptions, store } from "react-notifications-component"
import "react-notifications-component/dist/scss/notification.scss"
import { push } from "connected-react-router"
import { selectCurrentProjectId } from "../../store/projects/selectors"
import { handleAcceptInviteOperator } from "../../utility/notifications/handleAcceptInviteOperator"
import { clearCurrentNotification } from "../../store/notifications/thunks"

export const defaultNotificationSettings: NotificationSettings = {
    placement: "top-right",
    insert: "top",
    closeDelay: 4000,
    width: 365
}

const NotificationContainer: React.FC = () => {
    const notification = useSelector(selectCurrentNotification)
    const isListOpen = useSelector(selectNotificationsListState)
    const currentProjectId = useSelector(selectCurrentProjectId)
    const dispatch = useDispatch()
    const [notificationIds, setNotificationIds] = useState<string[]>([])

    const handleNotificationClose = (id: string) => () => store.removeNotification(id)

    useEffect(() => {
        if (notification) {
            const onNotificationClick = (link: string) => dispatch(push(link))
            const formatNotification = (notification: Notification): ReactNotificationOptions => ({
                id: notification.Id,
                container: defaultNotificationSettings.placement,
                insert: defaultNotificationSettings.insert,
                content: (
                    <NotificationItem
                        notification={notification}
                        onClose={handleNotificationClose(notification.Id)}
                        onClick={onNotificationClick}
                        onAcceptInviteOperator={handleAcceptInviteOperator(currentProjectId)}
                        disableDate
                        className={styles.notificationContainer__item}
                    />
                ),
                animationIn: [styles.notificationContainer_animate, styles.notificationContainer_fadeInRight],
                animationOut: [styles.notificationContainer_animate, styles.notificationContainer_fadeOutRight],
                dismiss: {
                    duration: defaultNotificationSettings.closeDelay,
                    pauseOnHover: true
                },
                slidingEnter: {
                    duration: 300
                },
                slidingExit: {
                    duration: 300
                },
                onRemoval: id => {
                    setNotificationIds(notificationIds => notificationIds.filter(n => n !== id))
                },
                width: defaultNotificationSettings.width
            })

            clearCurrentNotification(notification.Id, dispatch)
            const id = store.addNotification(formatNotification(notification))
            setNotificationIds(notificationIds => [id, ...notificationIds])
        }
    }, [currentProjectId, dispatch, notification])

    useEffect(() => {
        if (notificationIds.length && isListOpen) {
            for (let id of notificationIds) {
                store.removeNotification(id)
            }
        }
    }, [isListOpen, notificationIds])

    return <ReactNotification className={styles.notificationContainer} />
}

export default NotificationContainer
