import React from "react"
import Tab from "react-bootstrap/Tab"
import { ClassifierType } from "../../../models/classifier"
import { TabEntry } from "../../../types/tabEntry"
import { WithT } from "i18next"
import ClassifierFormHeader from "../ClassifierFormHeader/ClassifierFormHeader"
import { Form } from "react-bootstrap"

const tNamespace = "classifiers:form."

interface Props extends WithT {
    classifierType: ClassifierType
    tabEntry: TabEntry
}

const UnavailableClassifierForm: React.FC<Props> = ({ classifierType, tabEntry, t }) => (
    <Tab.Pane eventKey={tabEntry.key}>
        <Form className="classifier-form">
            <div className="classifier-form__content">
                <ClassifierFormHeader t={t} classifierType={classifierType} disabled={true} />
                <div className="classifier-form__section">{t(`${tNamespace}classifier-not-available`)}</div>
            </div>
        </Form>
    </Tab.Pane>
)

export default UnavailableClassifierForm
