import React from "react"
import "./ChannelList.scss"
import { Channel } from "../../models/channel"
import { useSelector } from "react-redux"
import { selectGetChannelsState } from "../../store/channels/selectors"
import Async from "../Async/Async"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import InfoCard from "../InfoCard/InfoCard"
import { useTranslation } from "react-i18next"
import InfoCardListLoader from "../InfoCardList/InfoCardListLoader"
import InfoCardList from "../InfoCardList/InfoCardList"
import ChannelIconLarge from "../ChannelIconLarge/ChannelIconLarge"
import useDelay from "../../utility/common/useDelay"

const tNamespace = "channel:"

interface Props {
    onSettingsClick: (channel: Channel) => void
    selectedChannel: string | null
}

const loader = <InfoCardListLoader count={6} className="channel-list" cardClassName="channel-list__card" />

const ChannelList: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { onSettingsClick, selectedChannel } = props
    const apiState = useSelector(selectGetChannelsState)
    const display = useDelay()

    if (!display) {
        return loader
    }

    return (
        <Async dataState={apiState} processView={loader} errorView={({ message }) => <ErrorMessage text={message} />}>
            {({ data: channels }) => (
                <InfoCardList className="channel-list">
                    {channels.map(channel => (
                        <InfoCard
                            className="channel-list__card"
                            iconClassName="channel-card__icon"
                            key={channel.Id}
                            content={{
                                icon: <ChannelIconLarge type={channel.Type} />,
                                title: channel.Title,
                                text: t(`${tNamespace}channel-type.${channel.Type}`)
                            }}
                            onClick={() => onSettingsClick(channel)}
                            selected={!!selectedChannel && selectedChannel === channel.Id}
                        />
                    ))}
                </InfoCardList>
            )}
        </Async>
    )
}

export default ChannelList
