import { KnowledgeBaseDefaultEntity } from "./knowledgeBaseDefaultEntity"
import { KnowledgeBasePermission, KnowledgeBasePermittedAction } from "./knowledgeBasePermission"
import { Article, ArticleStatus } from "./article"

export interface Category extends KnowledgeBaseDefaultEntity {
    ParentId: string
    CatalogCode: string
    IsOpen: boolean
    Permissions: KnowledgeBasePermission[]
    PermittedAction?: KnowledgeBasePermittedAction
    Status: ArticleStatus
    HasChildren: boolean
    Sort?: string
}

export interface CategoryMutation {
    Id: string
    CatalogCode: string
    SymbolCode: string
    ParentId?: string
    HasChildren?: boolean
    Sort?: string
    Title?: string
    Status?: ArticleStatus
    Permissions?: KnowledgeBasePermission[]
    PermittedAction?: KnowledgeBasePermittedAction
}

export const isCategoryMutation = (raw: any): raw is CategoryMutation => {
    return (
        raw && typeof raw.Id === "string" && typeof raw.CatalogCode === "string" && typeof raw.SymbolCode === "string"
    )
}

export const isCategoryMutations = (raw: any[]): raw is CategoryMutation[] => {
    return raw.every(isCategoryMutation)
}

export interface GetCategoriesRequest {
    CatalogCode: string
    ParentCode: string
}

export interface GetCategoriesResponse {
    Categories: Category[]
}

export interface GetRelatedCategoriesRequest {
    Id?: string
    CategoryCode?: string
    CatalogCode?: string
}

export interface GetRelatedCategoriesResponse {
    Id: string
    ParentCategory: Category
    ChildCategories: Category[]
}

export interface CategoryPlacement {
    Id: string
    ParentId: string
    CatalogCode: string
}

export interface CreateCategoryRequest {
    ParentId: string
    Permissions: KnowledgeBasePermission[]
    NextItem?: CategoryPlacement
}

export interface CreateCategoryResponse {
    CategoryId: string
    Article: Article
}

export interface CategoryAddedEvent {
    Category: Category
    ParentItemId: string
    EventSenderLogin: string
}

export interface MoveCategoryRequest {
    SourceParent: CategoryPlacement
    DestinationParent: CategoryPlacement
    DraggableItem: CategoryPlacement
    PrevItem?: CategoryPlacement
    NextItem?: CategoryPlacement
}

export interface MoveCategoryResponse {
    DraggableId: string
    Mutations: CategoryMutation[]
    ParentCategory?: Category
}
