import React from "react"
import "./OperatorStatusContainer.scss"
import cn from "classnames"
import { SelectedOperatorStatus } from "../../models/operatorStatus"
import { mapOperatorStatusToClassName } from "../../utility/operatorStatus/mapOperatorStatusToClassName"
import { handleLocalizedTextTranslation } from "../../utility/common/handleLocalizedTextTranslation"
import { TFunction } from "i18next"
import { ClassProps } from "../../utility/common/props"
import { testId } from "../../utility/tests/testId"

export interface OperatorStatusContainerProps extends ClassProps {
    status: SelectedOperatorStatus
    t: TFunction
    sideElement?: JSX.Element
    testId?: string
}

const OperatorStatusContainer: React.FC<OperatorStatusContainerProps> = props => {
    const { status, sideElement, t, className } = props
    return (
        <div
            className={cn(
                "operatorStatus",
                mapOperatorStatusToClassName(status.Description, "operatorStatus_"),
                className
            )}
        >
            <div className="operatorStatus__title" data-test-id={testId.currentStatus}>
                {handleLocalizedTextTranslation(status.Title, t)}
            </div>
            {sideElement && <div className="operatorStatus__sideElement">{sideElement}</div>}
        </div>
    )
}

export default OperatorStatusContainer
