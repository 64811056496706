import React from "react"
import AgentIcon from "../AgentIcon/AgentIcon"
import "./AgentFormHeader.scss"
import { WithT } from "i18next"

const tNamespace = "agent:"

export interface AgentFormHeaderProps extends WithT {
    agentType: string
}

const AgentFormHeader: React.FC<AgentFormHeaderProps> = props => {
    const { agentType, t } = props

    return (
        <div className="agent-form-header">
            <div className="agent-form-header__content">
                <div className="agent-form-header__icon">
                    <AgentIcon type={agentType} />
                </div>
                {t(`${tNamespace}agent-type.${agentType}`)}
            </div>
        </div>
    )
}

export default AgentFormHeader
