import React from "react"
import "./AgentList.scss"
import { useSelector } from "react-redux"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import { selectGetAgentsState } from "../../store/agents/selectors"
import InfoCard from "../InfoCard/InfoCard"
import AgentIcon from "../AgentIcon/AgentIcon"
import { Agent } from "../../models/agent"
import { useTranslation } from "react-i18next"
import InfoCardListLoader from "../InfoCardList/InfoCardListLoader"
import InfoCardList from "../InfoCardList/InfoCardList"
import Async from "../Async/Async"
import useDelay from "../../utility/common/useDelay"

const tNamespace = "agent:"

interface Props {
    onClick: (agent: Agent) => void
    selectedAgent: string | null
}

const loader = <InfoCardListLoader count={6} className="agent-list" cardClassName="agent-list__card" />

const AgentList: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { onClick, selectedAgent } = props
    const apiState = useSelector(selectGetAgentsState)
    const display = useDelay()

    if (!display) {
        return loader
    }

    return (
        <Async dataState={apiState} processView={loader} errorView={({ message }) => <ErrorMessage text={message} />}>
            {({ data: agents }) => (
                <InfoCardList className="agent-list">
                    {agents.map(agent => (
                        <InfoCard
                            className="agent-list__card"
                            iconClassName="agent-card__icon"
                            key={agent.Id}
                            content={{
                                icon: <AgentIcon type={agent.Type} />,
                                title: agent.Id,
                                text: t(`${tNamespace}agent-type.${agent.Type}`)
                            }}
                            onClick={() => onClick(agent)}
                            selected={!!selectedAgent && selectedAgent === agent.Id}
                        />
                    ))}
                </InfoCardList>
            )}
        </Async>
    )
}

export default AgentList
