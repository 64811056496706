export const splitSpacesExcludeQuotes = (s: string): string[] => {
    const result: string[] = []
    const regex = /(?=\S)[^"\s]*(?:"[^\\"]*(?:\\[\s\S][^\\"]*)*"[^"\s]*)*/g

    let match
    while ((match = regex.exec(s)) !== null) {
        if (match.index === regex.lastIndex) {
            regex.lastIndex++
        }

        match.forEach(match => {
            result.push(match.replace(/\\"/g, '"'))
        })
    }

    return result
}
