import React, { ChangeEvent, useCallback, useState } from "react"
import styles from "./SearchInput.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faTimes } from "@fortawesome/pro-light-svg-icons"
import { FormControl } from "react-bootstrap"
import { formTranslation } from "../../locales/form"
import { useTranslation } from "react-i18next"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import { testId } from "../../utility/tests/testId"
import debounce from "lodash/debounce"
import Spinner from "../Spinner/Spinner"

export interface SearchInputProps extends ClassProps {
    onChange: (searchValue: string) => void
    disabled?: boolean
    label?: string
    isLoading?: boolean
}

const SearchInput: React.FC<SearchInputProps> = props => {
    const { t } = useTranslation()
    const { onChange, className, disabled, label, isLoading = false } = props
    const [searchValue, setSearchValue] = useState("")

    const handleChangeCallback = useCallback(
        debounce((searchValue: string) => {
            onChange(searchValue)
        }, 300),
        []
    )

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
        handleChangeCallback(e.target.value)
    }

    const handleClear = () => {
        setSearchValue("")
        handleChangeCallback("")
    }

    const getRightAction = () => {
        if (isLoading) {
            return (
                <div className={cn(styles.searchInput__iconPosition, styles.searchInput__iconPosition_right)}>
                    <Spinner className={styles.searchInput__loading} />
                </div>
            )
        }
        return (
            <div
                className={cn(
                    styles.searchInput__clear,
                    styles.searchInput__iconPosition,
                    styles.searchInput__iconPosition_right
                )}
                onClick={handleClear}
            >
                <FontAwesomeIcon icon={faTimes} className={styles.searchInput__icon} />
            </div>
        )
    }

    return (
        <div className={cn(styles.searchInput, className)}>
            <div
                className={cn(
                    styles.searchInput__optionContainer,
                    styles.searchInput__iconPosition,
                    styles.searchInput__iconPosition_left
                )}
            >
                <FontAwesomeIcon icon={faSearch} className={cn(styles.searchInput__icon)} />
                {label && <span className={styles.searchInput__label}>{label}</span>}
            </div>
            <FormControl
                onChange={handleChange}
                value={searchValue}
                className={cn(styles.searchInput__input, label && styles.searchInput__input_withLabel)}
                placeholder={t(formTranslation.search)}
                disabled={disabled}
                data-test-id={testId.searchInput}
            />
            {getRightAction()}
        </div>
    )
}

export default SearchInput
