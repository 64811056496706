import React from "react"
import { ChannelFormProps } from "../ChannelForm/ChannelForm"
import { FormikProps } from "formik"
import { ChannelValues } from "../../models/channelValues"
import { WithT } from "i18next"
import { shallowEqual, useSelector } from "react-redux"
import { selectCreateChannelState, selectUpdateChannelState } from "../../store/channels/selectors"
import { nameof2 } from "../../utility/common/nameof"
import CheckBoxValidatableInput from "../CheckBoxValidatableInput/CheckBoxValidatableInput"
import { ParamValues } from "../../models/parameterDeclaration"
import RadioButtonValidatableInput from "../RadioButtonValidatableInput/RadioButtonValidatableInput"
import ChannelFormWrapper from "../ChannelFormWrapper/ChannelFormWrapper"

const tNamespace = "channel:form."

const FormikTelegramChannelFormAppearance: React.FC<ChannelFormProps & FormikProps<ChannelValues> & WithT> = props => {
    const { channel, t, handleSubmit, disabled } = props

    const asyncState = useSelector(channel ? selectUpdateChannelState : selectCreateChannelState, shallowEqual)

    return (
        <ChannelFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={asyncState.inProcess}>
            <div className="channel-form__text">{t(`${tNamespace}button-type`)}</div>
            <RadioButtonValidatableInput
                value="inline"
                id="formDefaultButtonsPlacement"
                name={nameof2<ChannelValues, ParamValues>("Params", "default_buttons_placement")}
                label={t(`${tNamespace}in-text`)}
            />
            <RadioButtonValidatableInput
                value="keyboard"
                id="formAuthorizationSettings2"
                name={nameof2<ChannelValues, ParamValues>("Params", "default_buttons_placement")}
                label={t(`${tNamespace}keyboard`)}
            />
            <div className="channel-form__section">
                <CheckBoxValidatableInput
                    id="formAlwaysHideKeyboard"
                    name={nameof2<ChannelValues, ParamValues>("Params", "always_hide_keyboard")}
                    label={t(`${tNamespace}auto-hide-keyboard`)}
                />
                <CheckBoxValidatableInput
                    id="formResizeKeyboard"
                    name={nameof2<ChannelValues, ParamValues>("Params", "resize_keyboard")}
                    label={t(`${tNamespace}resize-keyboard`)}
                />
            </div>
        </ChannelFormWrapper>
    )
}

export default FormikTelegramChannelFormAppearance
