import React from "react"
import * as Yup from "yup"
import { formTranslation } from "../../../../../locales/form"
import { Formik } from "formik"
import FormikMarkdownWidgetForm from "./FormikMarkdownWidgetForm"
import { useTranslation } from "react-i18next"

export interface Widget {
    id: string
    title: string
    description: string
    json: string
}

export const defaultWidget: Widget = {
    id: "",
    title: "",
    description: "",
    json: ""
}

interface Props {
    widget: Widget
    onChange: (widget: Widget) => void
    onClose: () => void
}

const MarkdownWidgetForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { widget, onChange, onClose } = props

    return (
        <Formik<Widget>
            initialValues={{ ...widget }}
            validationSchema={() =>
                Yup.object().shape({
                    id: Yup.string().required(formTranslation.idRequired),
                    title: Yup.string().required(formTranslation.titleRequired),
                    json: Yup.string().required()
                })
            }
            onSubmit={values => {
                onChange(values)
                onClose()
            }}
        >
            {formikProps => <FormikMarkdownWidgetForm {...formikProps} t={t} onCancel={onClose} />}
        </Formik>
    )
}

export default MarkdownWidgetForm
