import { Hub } from "../hub"
import { IHub } from "../interfaces/IHub"
import { Dispatch } from "../../utility/common/storeHelper"
import { setWebChatChannelReady } from "../../store/channels/thunks"
import { logError } from "../../utility/common/logError"
import { ChannelReadyEvent } from "../../models/channel"
import { Store } from "../../store/store"

const CHANNEL_READY = "ChannelReady"

class ChannelsHub {
    private _hub: IHub

    constructor(store: Store) {
        this._hub = new Hub(`/channels-hub`)
        this.registerServerEvents(store.dispatch)
    }

    async subscribe(projectId: string) {
        await this._hub.subscribe("Subscribe", projectId)
    }

    async unsubscribe(projectId: string) {
        await this._hub.unsubscribe("Unsubscribe", projectId)
    }

    private registerServerEvents(dispatch: Dispatch) {
        this._hub.registerEvent(CHANNEL_READY, (rawData: string) => {
            try {
                const event: ChannelReadyEvent = JSON.parse(rawData)
                dispatch(setWebChatChannelReady(event))
            } catch (e) {
                logError(e)
            }
        })
    }
}

export default ChannelsHub
