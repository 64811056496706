import React from "react"
import styles from "./MessageItemLoader.module.scss"
import useDelay from "../../utility/common/useDelay"
import Skeleton from "../Skeleton/Skeleton"
import cn from "classnames"

export interface MessageItemLoaderProps {
    isOutgoing?: boolean
}

const MessageItemLoader: React.FC<MessageItemLoaderProps> = props => {
    const { isOutgoing } = props

    const display = useDelay()

    if (!display) {
        return null
    }

    return (
        <div className={cn(styles.messageItemLoader, isOutgoing ? styles.messageItemLoader_out : undefined)}>
            <div className={styles.messageItemLoader__body}>
                <Skeleton />
            </div>
        </div>
    )
}

export default MessageItemLoader
