import { AppError, createSystemError, isError, SystemError } from "../../core/error"
import { isErrorReponse } from "../../models/errorResponse"
import { AxiosError } from "axios"
import { tNamespace } from "../../locales/error"

const isAxiosError = (error: any): error is AxiosError => error?.isAxiosError

const apiCallExceptionToAppError = (error: any): AppError => {
    if (isAxiosError(error)) {
        const errorResponse = error.response

        if (!errorResponse || !errorResponse.data) {
            return error
        }

        if (isErrorReponse(errorResponse.data)) {
            const error = errorResponse.data.error
            return {
                reason: error.message,
                reasonKey: `${tNamespace}${error.key}`
            }
        } else {
            return JSON.stringify(errorResponse.data)
        }
    }

    if (isError(error)) {
        return error
    }

    return JSON.stringify(error)
}

export const apiCallExceptionToSystemError = (messageKey: string, error: any): SystemError => {
    return createSystemError(messageKey, apiCallExceptionToAppError(error))
}
