import React from "react"
import "./MobileLockScreen.scss"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons/faInfoCircle"

const tNamespace = "project:"

const MobileLockScreen: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className="mobile-lock-screen">
            <FontAwesomeIcon icon={faInfoCircle} className="mobile-lock-screen__icon" />
            <h1 className="mobile-lock-screen__title">{t(`${tNamespace}page-is-not-available`)}</h1>
            <p className="mobile-lock-screen__message">{t(`${tNamespace}mobile-page-is-not-available`)}</p>
        </div>
    )
}

export default MobileLockScreen
