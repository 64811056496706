import React from "react"
import AgentFormWrapper from "../../AgentFormWrapper/AgentFormWrapper"
import { WithT } from "i18next"
import { TabEntry } from "../../../types/tabEntry"
import Tab from "react-bootstrap/esm/Tab"
import DocLink from "../../DocLink/DocLink"

const tNamespace = "agent:form."
const tLinkNamespace = "agent:link."

interface Props extends WithT {
    agentType: string
    tabEntry: TabEntry
}

const UnavailableAgentForm: React.FC<Props> = ({ agentType, tabEntry, t }) => (
    <Tab.Pane eventKey={tabEntry.key}>
        <AgentFormWrapper agentType={agentType} disabled={true}>
            <DocLink type={agentType}>{t(`${tLinkNamespace}creating-agent-instructions`)}</DocLink>
            <div className="agent-form__section">{t(`${tNamespace}agent-not-available`)}</div>
        </AgentFormWrapper>
    </Tab.Pane>
)

export default UnavailableAgentForm
