import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Formik } from "formik"
import * as Yup from "yup"
import FormikSurveyForm from "./FormikSurveyForm"
import { SurveyDto, SurveyValues } from "../../../models/survey"
import { createSurvey, updateSurvey } from "../../../store/surveys/thunks"
import {
    buildCreateRequest,
    buildUpdateRequest,
    getDefaultValues,
    getValuesFromSurvey
} from "../../../utility/knowledgeBase/survey"
import { formTranslation } from "../../../locales/form"

interface Props {
    projectId: string
    survey?: SurveyDto
    values?: SurveyValues
    onClose: () => void
    onAddSlot: (values: SurveyValues, survey?: SurveyDto) => void
}

const SurveyForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { projectId, survey, values, onClose, onAddSlot } = props

    const initialValues = values ? values : survey ? getValuesFromSurvey(survey) : getDefaultValues()

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={() =>
                Yup.object().shape({
                    Title: Yup.string().requiredExcludeEmpty(formTranslation.titleRequired),
                    Fields: Yup.array().of(
                        Yup.object().shape({
                            EditedComment: Yup.string().when("IsEditing", {
                                is: true,
                                then: Yup.string().requiredExcludeEmpty(formTranslation.titleRequired)
                            })
                        })
                    )
                })
            }
            onSubmit={(values: SurveyValues) => {
                if (survey) {
                    dispatch(updateSurvey(projectId, survey.Id, buildUpdateRequest(values), onClose))
                } else {
                    dispatch(createSurvey(projectId, buildCreateRequest(values), onClose))
                }
            }}
        >
            {formikProps => <FormikSurveyForm survey={survey} onAddSlot={onAddSlot} {...formikProps} t={t} />}
        </Formik>
    )
}

export default SurveyForm
