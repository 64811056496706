import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { selectGetRolesState, selectGetAllRoleSettingsState } from "../../store/roles/selectors"
import ProjectRoles from "../../components/ProjectRoles/ProjectRoles"
import { getAllRoleSettings, getRoles } from "../../store/roles/thunks"
import { AllRoleSettings, Role } from "../../models/role"
import Async from "../Async/Async"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import SettingsTableLoader from "../SettingsTable/SettingsTableLoader"
import AsyncState from "../../core/asyncState"
import useDelay from "../../utility/common/useDelay"

interface Props {
    projectId: string
    title: string
    onAddClick: (allRoleSettings: AllRoleSettings) => void
    onSettingsClick: (role: Role, allRoleSettings: AllRoleSettings) => void
    onDeleteClick: (role: Role) => void
}

const ProjectRolesContainer: React.FC<Props> = props => {
    const { projectId, title, onAddClick, onSettingsClick, onDeleteClick } = props

    const dispatch = useDispatch()
    const display = useDelay()

    const apiState = AsyncState.merge2(useSelector(selectGetRolesState), useSelector(selectGetAllRoleSettingsState))

    useEffect(() => {
        dispatch(getAllRoleSettings(projectId))
        dispatch(getRoles(projectId))
    }, [projectId, dispatch])

    if (!display) {
        return null
    }

    return (
        <Async
            dataState={apiState}
            processView={<SettingsTableLoader columns={2} rows={2} rowHeight={56} />}
            errorView={({ message }) => <ErrorMessage text={message} />}
        >
            {({ data: [roles, allRoleSettings] }) => (
                <ProjectRoles
                    projectId={projectId}
                    title={title}
                    roles={roles}
                    allRoleSettings={allRoleSettings}
                    onAddClick={() => onAddClick(allRoleSettings)}
                    onSettingsClick={v => onSettingsClick(v, allRoleSettings)}
                    onDeleteClick={onDeleteClick}
                />
            )}
        </Async>
    )
}

export default ProjectRolesContainer
