import React, { MouseEventHandler } from "react"
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import IconControl from "../IconControl/IconControl"
import { testId } from "../../utility/tests/testId"

interface Props extends ClassProps {
    onClick?: MouseEventHandler<HTMLDivElement>
    size?: "lg" | "xs" | "sm"
}

const CloseButton: React.FC<Props> = props => {
    const { className, onClick, size } = props

    return (
        <IconControl
            icon={faTimes}
            onClick={onClick}
            size={size}
            className={cn("btn-close", className)}
            testId={testId.btnClose}
        />
    )
}

export default CloseButton
