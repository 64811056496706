import React, { useMemo } from "react"
import { ChannelFormProps } from "../ChannelForm/ChannelForm"
import { FormikProps } from "formik"
import { WithT } from "i18next"
import { shallowEqual, useSelector } from "react-redux"
import { selectCreateChannelState, selectUpdateChannelState } from "../../store/channels/selectors"
import { selectAgents } from "../../store/agents/selectors"
import ChannelFormHeader from "../ChannelFormHeader/ChannelFormHeader"
import { nameof, nameof2 } from "../../utility/common/nameof"
import { BotSettingsValues, ChannelValues } from "../../models/channelValues"
import { ParamValues } from "../../models/parameterDeclaration"
import DebouncedValidatableInput from "../ValidatableInput/DebouncedValidatableInput"
import ChannelServerAddress from "../ChannelServerAddress/ChannelServerAddress"
import DocLink from "../DocLink/DocLink"
import { HELP_LINK_BASE, openExternalLink } from "../../utility/common/urlHelper"
import { useTranslation } from "react-i18next"
import ChannelFormWrapper from "../ChannelFormWrapper/ChannelFormWrapper"

const tNamespace = "channel:form."
const tLinkNamespace = "channel:link."

const FormikViberChannelFormGeneral: React.FC<ChannelFormProps & FormikProps<ChannelValues> & WithT> = props => {
    const { i18n } = useTranslation()
    const { channel, channelType, t, handleSubmit, disabled, values } = props

    const asyncState = useSelector(channel ? selectUpdateChannelState : selectCreateChannelState, shallowEqual)
    const agents = useSelector(selectAgents)

    const agentOptions = useMemo(
        () => (agents ? agents.map(agent => ({ name: agent.Id, value: agent.Id })) : []),
        [agents]
    )

    const goToTestPage = () => openExternalLink(`${HELP_LINK_BASE}${i18n.language}/viber-test`)

    return (
        <ChannelFormWrapper
            onSubmit={handleSubmit}
            onTest={goToTestPage}
            disabled={disabled}
            testDisabled={!channel}
            loading={asyncState.inProcess}
        >
            <ChannelFormHeader channelType={channelType} t={t} isActive={false} disabled />
            <DocLink type={channelType}>{t(`${tLinkNamespace}creating-viber-bot-instructions`)}</DocLink>
            <div className="channel-form__section">
                <DebouncedValidatableInput
                    id="formTitle"
                    type="text"
                    name={nameof<ChannelValues>("Title")}
                    label={t(`${tNamespace}title`)}
                />
            </div>
            <div className="channel-form__section">
                <div className="channel-form__section-subtitle">{t(`${tNamespace}call-routing`)}</div>
                <DebouncedValidatableInput
                    id="formAgent"
                    as="select"
                    name={nameof2<ChannelValues, ParamValues>("Params", "default_agent")}
                    label={t(`${tNamespace}call-processing-agent`)}
                >
                    <option value="" hidden />
                    {agentOptions.map(option => (
                        <option value={option.value} key={option.value}>
                            {option.name}
                        </option>
                    ))}
                </DebouncedValidatableInput>
            </div>
            <div className="channel-form__section">
                <div className="channel-form__section-subtitle">{t(`${tNamespace}bot-data-section`)}</div>
                <DebouncedValidatableInput
                    id="formBotId"
                    type="text"
                    name={nameof2<ChannelValues, ParamValues>("Params", "bot_id")}
                    label={t(`${tNamespace}bot-id`)}
                />
                <DebouncedValidatableInput
                    id="formToken"
                    type="password"
                    name={nameof2<ChannelValues, BotSettingsValues>("BotSettings", "Token")}
                    label={t(`${tNamespace}token`)}
                />
                <ChannelServerAddress channelId={values.BotSettings.Id} serverAddress={values.ServerAddress} />
            </div>
        </ChannelFormWrapper>
    )
}

export default FormikViberChannelFormGeneral
