import React, { useState } from "react"
import "./ProjectSelector.scss"
import { Project } from "../../models/project"
import { CallHistoryMethodAction } from "connected-react-router"
import ContentLoader from "react-content-loader"
import cn from "classnames"
import { WithT } from "i18next"

import ProjectCard from "../ProjectCard/ProjectCard"
import WizardRedirectButton from "../WizardRedirectButton/WizardRedirectButton"
import SidebarContextMenu from "../SIdebarContextMenu/SidebarContextMenu"
import { Supervisor } from "../../permissions"
import Can from "../Can/Can"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/pro-light-svg-icons/faSearch"
import { FormControl } from "react-bootstrap"
import { formTranslation } from "../../locales/form"
import { useTranslation } from "react-i18next"
import CopyWizardRedirectButton from "../WizardRedirectButton/CopyWizardRedirectButton"
import { testId } from "../../utility/tests/testId"

const WIDE_NAME_CHARS_COUNT = 14

export interface ProjectSelectorProps {
    selectedProject: Project
    nonSelectedProject: Project[]
    selectProject: (projectId: string) => CallHistoryMethodAction
}

const ProjectSelector: React.FC<ProjectSelectorProps> = props => {
    const { t } = useTranslation()
    const { selectedProject, nonSelectedProject } = props
    const [searchInput, setSearchInput] = useState("")

    const selectProject = async (project: Project) => {
        await props.selectProject(project.id)
    }
    const isWideName = selectedProject.name.length >= WIDE_NAME_CHARS_COUNT

    const handleSearchChange = (e: React.ChangeEvent<any>) => {
        setSearchInput(e.target.value)
    }
    const filteredNonSelectedProjects = searchInput
        ? nonSelectedProject.filter(p => p.name.toLowerCase().includes(searchInput.toLowerCase()))
        : nonSelectedProject

    return (
        <SidebarContextMenu
            placement="bottom-end"
            containerClassName="project-selector"
            items={
                <div className="project-selector__context-menu">
                    {nonSelectedProject.length > 10 && (
                        <div className="project-selector__search" onClick={e => e.stopPropagation()}>
                            <FontAwesomeIcon icon={faSearch} className="project-selector__search-icon" />
                            <FormControl
                                onChange={handleSearchChange}
                                value={searchInput}
                                className="project-selector__search-input"
                                placeholder={t(formTranslation.search)}
                                data-test-id={testId.searchProject}
                            />
                        </div>
                    )}
                    <div className="project-selector__projects">
                        {filteredNonSelectedProjects.map(project => (
                            <ProjectCard
                                key={project.id}
                                project={project}
                                onClick={() => selectProject(project)}
                                className="project-selector__project project-selector__dropdown-project"
                            />
                        ))}
                    </div>
                    {!!filteredNonSelectedProjects.length && <div className="project-selector__divider" />}
                    <Can permission={Supervisor}>
                        <WizardRedirectButton className="project-selector__wizard-redirect" />
                    </Can>
                    <Can permission={Supervisor}>
                        <CopyWizardRedirectButton className="project-selector__wizard-redirect" />
                    </Can>
                </div>
            }
        >
            <div className="project-selector__selected-container" data-test-id={testId.projectSelector}>
                <ProjectCard
                    project={selectedProject}
                    className={cn("project-selector__selected", isWideName && "project-selector__selected_wide-name")}
                    isShort
                />
            </div>
        </SidebarContextMenu>
    )
}

export const ProjectSelectorLoading: React.FC<WithT> = () => {
    return (
        <ContentLoader height={56} title={""} className="project-selector">
            <rect x="0" y="0" rx="3" ry="3" width="79" height="56" />
        </ContentLoader>
    )
}

export default ProjectSelector
