import React from "react"
import styles from "./FormSection.module.scss"
import cn from "classnames"

export interface FormSectionProps {
    border?: boolean
    indent?: boolean
}

const FormSection: React.FC<FormSectionProps> = ({ children, border, indent }) => {
    return (
        <div
            className={cn(styles.formSection, border && styles.formSection_border, indent && styles.formSection_indent)}
        >
            {children}
        </div>
    )
}

export default FormSection
