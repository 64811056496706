import React, { useEffect } from "react"
import { Column, useGlobalFilter, usePagination, useSortBy, useTable } from "react-table"
import SettingsTable, { SettingsTableProps } from "../SettingsTable"
import Pagination from "../../Pagination/Pagination"

export interface SettingsTableWithPaginationProps<T extends object> extends SettingsTableProps<T> {
    columns: Column<T>[]
    data: T[]
    recordsTotal: number
    pageSize: number
    pageIndex: number
    onPageIndexChange: (index: number) => void
    hideSearch?: boolean
}

function SettingsTableWithPagination<T extends object>(props: SettingsTableWithPaginationProps<T>) {
    const { btnAddProps, onRowClick, onPageIndexChange, recordsTotal, hideSearch, pageSize, pageIndex, columns, data } =
        props

    const tableInstance = useTable(
        {
            columns,
            data,
            pageCount: Math.ceil(recordsTotal / pageSize),
            manualPagination: true,
            initialState: { pageIndex }
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    )
    const { nextPage, canNextPage, previousPage, canPreviousPage } = tableInstance

    useEffect(() => {
        const pageIndex = tableInstance.state.pageIndex
        if (Number.isInteger(pageIndex)) {
            onPageIndexChange(pageIndex)
        }
    }, [tableInstance.state.pageIndex, onPageIndexChange])

    return (
        <SettingsTable
            tableInstance={tableInstance}
            onRowClick={onRowClick}
            btnAddProps={btnAddProps}
            hideSearch={hideSearch}
            rows={tableInstance.page}
        >
            <div className="settings-table__pagination">
                <Pagination
                    pageIndex={pageIndex}
                    total={recordsTotal}
                    onNextPage={nextPage}
                    canNextPage={canNextPage}
                    onPreviousPage={previousPage}
                    canPreviousPage={canPreviousPage}
                    resultsPerPage={pageSize}
                />
            </div>
        </SettingsTable>
    )
}

export default SettingsTableWithPagination
