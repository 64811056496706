import React from "react"
import { ClassProps } from "../../../utility/common/props"
import { ClassifierType } from "../../../models/classifier"
import { ReactComponent as WMDLogo } from "../../../assets/images/classifiers/wmd.svg"
import { ReactComponent as LOGREGLogo } from "../../../assets/images/classifiers/lreg.svg"
import { ReactComponent as GRULogo } from "../../../assets/images/classifiers/gru.svg"
import { ReactComponent as KNNLogo } from "../../../assets/images/classifiers/knn.svg"
import { ReactComponent as BERTLogo } from "../../../assets/images/classifiers/bert.svg"
import { ReactComponent as KNNElasticLogo } from "../../../assets/images/classifiers/knnElastic.svg"

export interface ClassifierIconProps extends ClassProps {
    type: ClassifierType
}

const ClassifierIcon: React.FC<ClassifierIconProps> = props => {
    const { type, className } = props

    switch (type) {
        case ClassifierType.WMD:
            return <WMDLogo className={className} />
        case ClassifierType.LOGREG:
            return <LOGREGLogo className={className} />
        case ClassifierType.GRU:
            return <GRULogo className={className} />
        case ClassifierType.KNN:
            return <KNNLogo className={className} />
        case ClassifierType.BERT:
            return <BERTLogo className={className} />
        case ClassifierType.KNN_ELASTIC:
            return <KNNElasticLogo className={className} />
        default:
            return null
    }
}

export default ClassifierIcon
