import React from "react"
import "./MenuItemRest.scss"
import { faGripHorizontal } from "@fortawesome/pro-light-svg-icons/faGripHorizontal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import { MenuItem } from "../../models/menu"
import MenuItemRow from "../MenuItemRow/MenuItemRow"
import MenuIcon from "../MenuIcon/MenuIcon"
import { useTranslation } from "react-i18next"
import SidebarContextMenu from "../SIdebarContextMenu/SidebarContextMenu"
import { testId } from "../../utility/tests/testId"

const tNamespace = "project:"

interface Props extends ClassProps {
    items: MenuItem[]
    onClick: (menuId: string) => void
    getSelectedMenu: (menuId: string) => boolean
    toggleSidebar?: Function
}

const MenuItemRest: React.FC<Props> = props => {
    const { className, items, onClick, getSelectedMenu } = props
    const { t } = useTranslation()
    return (
        <SidebarContextMenu
            placement="top-end"
            items={
                <>
                    {items.map(menuItem => (
                        <MenuItemRow
                            key={menuItem.id}
                            icon={<MenuIcon menuId={menuItem.id} />}
                            title={t(tNamespace + menuItem.title)}
                            selected={getSelectedMenu(menuItem.id)}
                            onClick={() => onClick(menuItem.id)}
                            className="menu-item-rest__item"
                            testId={menuItem.id}
                        />
                    ))}
                </>
            }
            containerClassName={cn("menu-item-rest__container", className)}
        >
            <div className="menu-item-rest" data-test-id={testId.additionalMenu}>
                <FontAwesomeIcon icon={faGripHorizontal} className="menu-item-rest__icon" />
            </div>
        </SidebarContextMenu>
    )
}

export default MenuItemRest
