import {
    AgentProjectSettings,
    AutoGreetingOperatorProjectSettings,
    ClientSurveyOperatorProjectSettings,
    CommonOperatorProjectSettings,
    CommonReportSlotsValue,
    DialogSurveyOperatorProjectSettings,
    NotificationsOperatorProjectSettings,
    OutgoingDialogOperatorProjectSettings,
    ProjectSettingsListValue,
    ResponseTemplateOperatorProjectSettings,
    SecurityProjectSettings,
    StatisticsCalculatingOptions,
    StatisticsPeriodOptions,
    SystemProjectSettings,
    WorkplaceOperatorProjectSettings
} from "./projectSettings"
import { DeepRequired } from "../types/utilityTypes"

export interface OperatorsWorkValuesBase {
    Common: CommonOperatorProjectSettings
    ClientSurvey: ClientSurveyOperatorProjectSettings
    DialogSurvey: DialogSurveyOperatorProjectSettingsValues
    KPI: KPIOperatorProjectSettingsValues
    Workplace: WorkplaceOperatorProjectSettings
    AutoGreeting: AutoGreetingOperatorProjectSettings
    ResponseTemplate: ResponseTemplateOperatorProjectSettingsValues
    Hold: HoldOperatorProjectSettingsValues
    Notifications: NotificationsOperatorProjectSettings
    OutgoingDialog: OutgoingDialogOperatorProjectSettings
}

export type OperatorsWorkValues = DeepRequired<OperatorsWorkValuesBase>

export enum ChooseTopicToFinishDialogType {
    Required = "required",
    Automatic = "automatic"
}

export interface DialogSurveyOperatorProjectSettingsValues
    extends Omit<DeepRequired<DialogSurveyOperatorProjectSettings>, "ChooseTopicToFinishDialogRequired" | ""> {
    ChooseTopicToFinishDialog: ChooseTopicToFinishDialogType
}

export interface ResponseTemplateOperatorProjectSettingsValues
    extends Omit<ResponseTemplateOperatorProjectSettings, "WaitingForClientResponseTimeout" | "ClientHoldingTimeout"> {
    WaitingForClientResponseTimeout: DurationSettingsValues
    ClientHoldingTimeout: DurationSettingsValues
}

export interface HoldOperatorProjectSettingsValues {
    AutoHoldingEnabled: boolean
    DropDialogTimeout: DurationSettingsValues
    FinishDialogWithoutClientResponseTimeout: DurationSettingsValues
}

// System project settings
export interface SystemSettingsValues {
    Security: DeepRequired<SecurityProjectSettings>
    System: DeepRequired<SystemProjectSettings>
}

// Dialog settings
export interface DialogSettingsValues {
    DialogTimeout: DialogTimeoutProjectSettingsValues
    Agent: DeepRequired<AgentProjectSettings>
}

export interface DialogTimeoutProjectSettingsValues {
    FirstTimeout: TimeoutSettingsValues
    SecondTimeout: TimeoutSettingsValues
    FinishDialogTimeout: TimeoutSettingsValues
    DialogInQueueTimeout: TimeoutSettingsValues
    DialogLifeTimeout: TimeoutSettingsValues
}

export interface TimeoutSettingsValues {
    Value: DurationSettingsValues
    Message: string
    MessageEnabled: boolean
}

export interface DurationSettingsValues {
    Days: number
    Hours: number
    Minutes: number
    Seconds: number
}

export interface StatisticsAndReportSettingsValues {
    Statistics: DeepRequired<StatisticsProjectSettingsValues>
    Report: DeepRequired<ReportProjectSettingsValues>
}

export interface StatisticsProjectSettingsValues {
    FunnelSlots: ProjectSettingsListValue
    UnproductiveAnswers: ProjectSettingsListValue
    TimeRangeStartDefiningField: StatisticsPeriodOptions
    TimeRangeEndDefiningField: StatisticsPeriodOptions
    HandledCountField: StatisticsPeriodOptions
    ExcludedOperatorIds: ProjectSettingsListValue
    FCR: DurationSettingsValues
    FCRSeparateDifferentTopics: boolean
    SLEndDefiningField: StatisticsCalculatingOptions
    SLPeriod: DurationSettingsValues
    AHTStartDefiningField: StatisticsCalculatingOptions
}

export interface ReportProjectSettingsValues {
    CommonReportTimeZone: string
    CommonReportSlots: CommonReportSlotsValue
}

export interface KPIOperatorProjectSettingsValues {
    DialogTimerEnabled: boolean
    GreenDialogTimerTimeout: DurationSettingsValues
    YellowDialogTimerTimeout: DurationSettingsValues
    DialogWaitingForClientAnswerTimerEnabled: boolean
    DialogWaitingForClientAnswerTimeout: DurationSettingsValues
}
