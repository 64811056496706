import React, { useState } from "react"
import styles from "./ImageUploadInput.module.scss"
import { Form } from "react-bootstrap"
import { FileUpload } from "../../models/file"
import ImageUploadModal from "../ImageUploadModal/ImageUploadModal"
import { useField } from "formik"
import filesController from "../../api/controllers/files"
import { useTranslation } from "react-i18next"
import AsyncState from "../../core/asyncState"
import { Dispatch } from "../../utility/common/storeHelper"
import ImageUploadPlaceholder from "../ImageUploadPlaceholder/ImageUploadPlaceholder"
import UploadButton from "./UploadButton"
import { logError } from "../../utility/common/logError"
import { handleHttpExceptionWithCallback } from "../../store/handleHttpException"
import { SystemError } from "../../core/error"

export interface ImageUploadInputProps {
    id: string
    name: string
    label?: string
    disabled?: boolean
    inline?: boolean
    dispatch?: Dispatch
}

const UPLOAD_IMAGE_FAILED_MESSAGE = "error:form.upload-image-failed"

const ImageUploadInput: React.FC<ImageUploadInputProps> = props => {
    const { t } = useTranslation()
    const { id, name, label, disabled, dispatch, inline = false } = props

    const [field, , { setValue }] = useField(name)
    const [show, setShow] = useState(false)
    const [uploadState, setUploadState] = useState<AsyncState<string>>(AsyncState.create())

    const handleImageModalClose = () => setShow(false)
    const handleImageModalOpen = () => setShow(true)

    const uploadImage = async (image?: FileUpload<Blob>) => {
        try {
            if (image) {
                setUploadState(AsyncState.createProcess())
                const url = await filesController.uploadFileB64(image)
                setValue(url)
                setUploadState(AsyncState.createSuccess(url))
            }
        } catch (e) {
            if (dispatch) {
                const callback = (e: SystemError) => setUploadState(AsyncState.createFailed(e))
                handleHttpExceptionWithCallback(e, UPLOAD_IMAGE_FAILED_MESSAGE, dispatch, callback)
            } else {
                logError(e)
            }
        } finally {
            setShow(false)
        }
    }

    return (
        <Form.Group controlId={id}>
            {label && <Form.Label>{label}</Form.Label>}
            <div className={styles.imageUploadInput}>
                <ImageUploadPlaceholder
                    src={field.value}
                    onClick={handleImageModalOpen}
                    disabled={disabled}
                    inline={inline}
                    uploadState={uploadState}
                    t={t}
                />
                {inline && (
                    <UploadButton disabled={disabled || uploadState.inProcess} onClick={handleImageModalOpen} t={t} />
                )}
                <ImageUploadModal
                    circularCrop={false}
                    show={show}
                    onClose={handleImageModalClose}
                    onUpload={uploadImage}
                    uploading={uploadState.inProcess}
                    pictureSrc={field.value}
                />
            </div>
        </Form.Group>
    )
}

export default ImageUploadInput
