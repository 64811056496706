import { KnowledgeBaseAccessType } from "./knowledgeBasePermission"
import { Queue } from "./queue"
import { ItemId } from "@atlaskit/tree/types"

export enum AllowedActionDto {
    None,
    View,
    Modify
}

export type AllowedRightsDto = {
    Id: string
    Action: AllowedActionDto
    Type: KnowledgeBaseAccessType
    ProjectId: string
}

export type QueueCategory = {
    Id: string
    Name: string
    Queues: Queue[]
    Access: AllowedRightsDto[]
}

export function isQueueCategory(value: any): value is QueueCategory {
    return (
        typeof value === "object" &&
        typeof value.Id === "string" &&
        typeof value.Name === "string" &&
        Array.isArray(value.Queues) &&
        Array.isArray(value.Access)
    )
}

export type QueueCategoryWithoutQueues = Omit<QueueCategory, "Queues">

export function isQueueCategoryWithoutQueues(value: any): value is QueueCategoryWithoutQueues {
    return (
        typeof value === "object" &&
        typeof value.Id === "string" &&
        typeof value.Name === "string" &&
        Array.isArray(value.Access)
    )
}

export type ToggleQueueCategoryPayload = {
    ToggleCategoryId: ItemId
    IsExpanded: boolean
}

export interface DeleteQueuePayload {
    queueId: string
    categoryId: string
}

export interface GetQueueCategoriesResponse {
    AllCategories: QueueCategory[]
    TaskOnBotsCategoryId?: string
}

export interface CreateQueueCategoryRequest {
    Name: string
    Queues: string[]
    Access: AllowedRightsDto[]
}

export interface CreateQueueCategoryResponse {
    Category: QueueCategory
}

export interface UpdateQueueCategoryRequest {
    Name: string
    Queues: string[]
    Access: AllowedRightsDto[]
}

export interface UpdateQueueCategoryResponse {
    Category: QueueCategory
}

export interface MoveQueueRequest {
    QueueIds: string[]
}

export interface MoveQueueResponse {
    CategorySource: QueueCategory
    CategoryDestination?: QueueCategory
}

export interface QueueCategoryRemoveResponse {
    CategoryId: string
}

export const isQueueCategoryRemoveResponse = (value: any): value is QueueCategoryRemoveResponse => {
    return typeof value === "object" && typeof value.CategoryId === "string"
}
