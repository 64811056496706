import React, { useEffect, useMemo } from "react"
import "./ClassifierForm.scss"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Dispatch } from "../../../utility/common/storeHelper"
import { ClassifierConfigDto, ClassifierConfigurationProcessed, ClassifierType } from "../../../models/classifier"
import { getClassifierModels } from "../../../store/classifiers/thunks"
import { Project } from "../../../models/project"
import FormikClassifierForm from "./FormikClassifierForm"
import { actions } from "../../../store/classifiers/slice"
import Tabs from "../../Tabs/Tabs"
import { selectClassifierModels } from "../../../store/classifiers/selectors"
import { updateCurrentClassifier } from "../../../store/classifiers/actions"
import { useClassifierEnabledCheck } from "../../../utility/classifier/classifierForm"
import UnavailableClassifierForm from "../UnavailableClassifierForm/UnavailableClassifierForm"

const tNamespace = "classifiers:"

export interface Props {
    project: Project
    classifier?: ClassifierConfigurationProcessed
    classifierType: ClassifierType
    submitCallback: () => void
    disabled?: boolean
    linkedClassifier?: ClassifierConfigDto
}

const ClassifierForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch<Dispatch>()
    const { classifier, project, classifierType } = props

    const tabEntries = useMemo(
        () => [
            { key: "general", value: t(`${tNamespace}general`) },
            { key: "training", value: t(`${tNamespace}training`) },
            { key: "trainingHistory", value: t(`${tNamespace}training-history`) }
        ],
        [t]
    )

    const allModels = useSelector(selectClassifierModels)
    const classifierModels = classifier ? allModels[classifier.ClassifierConfigId] ?? [] : []
    const isClassifierEnabled = useClassifierEnabledCheck(classifierType)

    useEffect(() => {
        if (classifier?.ClassifierConfigId) {
            !allModels[classifier.ClassifierConfigId] &&
                dispatch(getClassifierModels(project.id, classifier.ClassifierConfigId))
        }
        dispatch(actions.updateTrainingClassifier(null))
    }, [dispatch, classifier, project, allModels])

    useEffect(() => {
        updateCurrentClassifier(dispatch, classifier, true)
    }, [dispatch, classifier])

    const renderAgentForm = (): JSX.Element | null => {
        if (!isClassifierEnabled) {
            return <UnavailableClassifierForm classifierType={classifierType} t={t} tabEntry={tabEntries[0]} />
        }
        return <FormikClassifierForm {...props} t={t} tabEntries={tabEntries} classifierModels={classifierModels} />
    }

    return (
        <Tabs id="classifier-settings-tabs" alwaysReload={false} entries={classifier ? tabEntries : [tabEntries[0]]}>
            {renderAgentForm()}
        </Tabs>
    )
}

export default ClassifierForm
