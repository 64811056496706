import { useCallback, useRef, useState } from "react"

const useDownloadFile = (
    load: () => Promise<Blob | null>,
    onPreDownload: () => void,
    onError: (e: any) => void,
    fileName: string
) => {
    const ref = useRef<HTMLAnchorElement>(null)
    const [url, setFileUrl] = useState("")
    const [name, setFileName] = useState(fileName)

    const download = useCallback(async () => {
        try {
            onPreDownload()
            const data = await load()
            if (!data) return

            const url = URL.createObjectURL(new Blob([data]))

            setFileUrl(url)
            setFileName(fileName)

            ref.current?.click()
            URL.revokeObjectURL(url)
        } catch (e) {
            onError(e)
        }
    }, [load, onPreDownload, onError, fileName])

    return { download, ref, url, name }
}

export default useDownloadFile
