import { TFunction } from "i18next"

export type AppError = LocalizedError | Error | string

export const isError = (appError: AppError): appError is Error => {
    const error = appError as Error
    return error.name !== undefined && error.message !== undefined
}

export interface LocalizedError {
    reason: string
    reasonKey: string
}

export const isLocalizedError = (appError: AppError): appError is LocalizedError => {
    const locError = appError as LocalizedError
    return locError.reason !== undefined && locError.reasonKey !== undefined
}

export interface SystemError {
    contextKey: string
    error: AppError
}

export const createSystemError = (contextKey: string, error: AppError): SystemError => {
    return {
        contextKey,
        error
    }
}

export const getMessageWithTranslationKey = (error: AppError): [string, string?] => {
    if (isLocalizedError(error)) {
        return [error.reason, error.reasonKey]
    }

    if (isError(error)) {
        return [error.message, undefined]
    }

    return [error, undefined]
}

export const getAppErrorMessage = (error: AppError, t: TFunction): string => {
    if (isLocalizedError(error)) {
        return t(error.reasonKey)
    }

    if (isError(error)) {
        return error.message
    }

    return error
}

export const getSystemErrorMessage = (error: SystemError, t: TFunction): string => {
    return `${t(error.contextKey)}: ${getAppErrorMessage(error.error, t)}`
}
