import { MarkdownButton } from "./buttonsParser/buttonsParser"
import { EditMarkdownButtonFormValues } from "../../models/markdownButton"
import { ButtonVisible } from "./buttonsParser/buttonAttributes/buttonVisible"
import { ButtonAttribute } from "./buttonsParser/buttonAttributes/buttonAttribute"
import { ButtonType } from "./buttonsParser/buttonAttributes/buttonType"

export const getValuesFromButton = (button: MarkdownButton): EditMarkdownButtonFormValues => {
    return {
        ...button,
        invisible: button.visible === ButtonVisible.NotVisible,
        image_enabled: !!button.image,
        image_emoji_enabled: !!button.image_emoji,
        article: button.type === ButtonType.Action ? button.action : "",
        isExternal: button.is_external === "true",
        url: button.type === ButtonType.Url ? button.action : ""
    }
}

export const valuesToButton = (values: EditMarkdownButtonFormValues, button: MarkdownButton): MarkdownButton => ({
    BodyMarkdown: button.BodyMarkdown,
    Row: button.Row,
    [ButtonAttribute.Type]: values.type,
    [ButtonAttribute.Action]: values.type === ButtonType.Action ? values.article : values.url,
    [ButtonAttribute.IsExternal]: (values.type === ButtonType.Action && values.isExternal).toString(),
    [ButtonAttribute.Color]: values.color,
    [ButtonAttribute.Image]: values.image,
    [ButtonAttribute.ImageAlign]: values.image_align,
    [ButtonAttribute.Visible]: values.invisible ? ButtonVisible.NotVisible : ButtonVisible.Visible,
    [ButtonAttribute.Image]: values.image_enabled ? values.image : "",
    [ButtonAttribute.ImageEmoji]: values.image_emoji_enabled ? values.image_emoji : "",
    [ButtonAttribute.Id]: button.id
})
