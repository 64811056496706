import React from "react"
import "./ClassifierFormHeader.scss"
import Toggle from "../../Toggle/Toggle"
import { WithT } from "i18next"
import ClassifierStatusIcon from "../ClassifierStatusIcon/ClassifierStatusIcon"
import { ClassifierStatus, ClassifierType } from "../../../models/classifier"
import { useSelector } from "react-redux"
import { selectActivateClassifierState, selectDeactivateClassifierState } from "../../../store/classifiers/selectors"
import AsyncState from "../../../core/asyncState"
import { mapTypeToLabel } from "../../../utility/classifier/classifierForm"
import ClassifierIcon from "../ClassifierIcon/ClassifierIcon"

interface Props extends WithT {
    classifierType: ClassifierType
    isActive?: boolean
    onToggle?: (e: React.ChangeEvent<HTMLInputElement>) => void
    status?: ClassifierStatus
    disabled: boolean
}

const tNamespace = "common:activity-status."
const tStatusNamespace = "classifiers:classifier-status."

const ClassifierFormHeader: React.FC<Props> = props => {
    const { classifierType, isActive, onToggle, status, t, disabled } = props

    const apiState = AsyncState.merge2(
        useSelector(selectActivateClassifierState),
        useSelector(selectDeactivateClassifierState)
    )

    return (
        <div className="classifier-form-header">
            <div className="classifier-form-header__content">
                <div className="classifier-form-header__logo">
                    <ClassifierIcon type={classifierType} className="classifier-form-header__icon" />
                </div>
                {mapTypeToLabel[classifierType]}
                {isActive !== undefined && (
                    <div className="classifier-form-header__toggle">
                        <label htmlFor="isActive" className="toggle__label">
                            {isActive ? t(`${tNamespace}active`) : t(`${tNamespace}inactive`)}
                        </label>
                        <Toggle
                            checked={isActive}
                            id="isActive"
                            icons={false}
                            className="toggle"
                            onChange={onToggle}
                            disabled={disabled || apiState.inProcess}
                        />
                    </div>
                )}
            </div>
            {status && (
                <div className="classifier-form-header__status-container">
                    <ClassifierStatusIcon status={status} className="classifier-form-header__status">
                        {t(`${tStatusNamespace}${status}`)}
                    </ClassifierStatusIcon>
                </div>
            )}
        </div>
    )
}

export default ClassifierFormHeader
