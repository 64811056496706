import React from "react"
import { ChannelFormProps } from "../ChannelForm/ChannelForm"
import { FormikProps } from "formik"
import { ChannelValues } from "../../models/channelValues"
import { WithT } from "i18next"
import { shallowEqual, useSelector } from "react-redux"
import { selectCreateChannelState, selectUpdateChannelState } from "../../store/channels/selectors"
import { nameof2 } from "../../utility/common/nameof"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import { ParamValues } from "../../models/parameterDeclaration"
import ChannelFormWrapper from "../ChannelFormWrapper/ChannelFormWrapper"

const tNamespace = "channel:form."

const FormikOdnoklassnikiChannelFormAppearance: React.FC<ChannelFormProps & FormikProps<ChannelValues> & WithT> =
    props => {
        const { channel, t, handleSubmit, disabled } = props

        const asyncState = useSelector(channel ? selectUpdateChannelState : selectCreateChannelState, shallowEqual)

        return (
            <ChannelFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={asyncState.inProcess}>
                <div className="channel-form__section">
                    <ValidatableInput
                        id="formDialogScoreThankYou"
                        type="text"
                        name={nameof2<ChannelValues, ParamValues>("Params", "dialog_score_thank_you")}
                        label={t(`${tNamespace}text-after-dialog-rating`)}
                    />
                </div>
            </ChannelFormWrapper>
        )
    }

export default FormikOdnoklassnikiChannelFormAppearance
