import React from "react"
import styles from "./ImageUploadPlaceholder.module.scss"
import { Button, Image } from "react-bootstrap"
import cn from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faImage } from "@fortawesome/pro-light-svg-icons/faImage"
import { formTranslation } from "../../locales/form"
import { WithT } from "i18next"
import UploadButton from "../ImageUploadInput/UploadButton"
import AsyncState from "../../core/asyncState"

export interface ImageUploadPlaceholderProps<T = string> extends WithT {
    src?: string
    onClick: () => void
    inline?: boolean
    disabled?: boolean
    uploadState: AsyncState<T>
}

function ImageUploadPlaceholder<T>(props: ImageUploadPlaceholderProps<T>) {
    const { src, onClick, inline = false, disabled, uploadState, t } = props

    if (src) {
        return (
            <div className={cn(styles["imageUploadPlaceholder"], inline && styles["imageUploadPlaceholder_inline"])}>
                <Image
                    className={cn(
                        styles["imageUploadPlaceholder__img"],
                        inline && styles["imageUploadPlaceholder__img_inline"]
                    )}
                    src={src}
                />
                <div className={styles["imageUploadPlaceholder__overlay"]}>
                    {!disabled && !inline && (
                        <Button variant="light" className={styles["imageUploadPlaceholder__btn"]} onClick={onClick}>
                            {t(formTranslation.change)}
                        </Button>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className={cn(styles["imageUploadPlaceholder"], inline && styles["imageUploadPlaceholder_inline"])}>
            <FontAwesomeIcon
                icon={faImage}
                className={cn(
                    styles["imageUploadPlaceholder__icon"],
                    inline && styles["imageUploadPlaceholder__icon_inline"]
                )}
            />
            {!inline && <UploadButton disabled={disabled || uploadState.inProcess} onClick={onClick} t={t} />}
        </div>
    )
}

export default ImageUploadPlaceholder
