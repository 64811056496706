import React, { useCallback, useEffect } from "react"
import OnSubmitValidationError from "../../OnSubmitValidationError/OnSubmitValidationError"
import { Tab } from "react-bootstrap"
import SlotFormGeneral from "../SlotFormGeneral/SlotFormGeneral"
import SlotFormAdvanced from "../SlotFormAdvanced/SlotFormAdvanced"
import SlotFormDictionaryValues from "../SlotFormDictionaryValues/SlotFormDictionaryValues"
import { TabPaneElement } from "../../ValidatableFormTabs/ValidatableFormTabs"
import { useTranslation } from "react-i18next"
import { TabEntry } from "../../../types/tabEntry"
import { SlotDto, SlotType, SlotValues } from "../../../models/slot"
import { FormikProps } from "formik/dist/types"
import { shallowEqual, useSelector } from "react-redux"
import { selectCreateSlotState, selectUpdateSlotState } from "../../../store/slots/selectors"

interface Props {
    isDictionary: boolean
    setIsDictionary: (v: boolean) => void
    tabEntries: TabEntry[]
    validateTabs: Function
    formikProps: FormikProps<SlotValues>
    slot?: SlotDto
}

const FormikSlotForm: React.FC<Props> = props => {
    const { tabEntries, validateTabs, isDictionary, setIsDictionary, formikProps, slot } = props
    const { t } = useTranslation()

    const asyncState = useSelector(slot ? selectUpdateSlotState : selectCreateSlotState, shallowEqual)

    const mainRef = React.useRef<TabPaneElement>(null)
    const advancedRef = React.useRef<TabPaneElement>(null)
    const dictionaryRef = React.useRef<TabPaneElement>(null)

    const validateSlotFormTabs = useCallback(() => {
        validateTabs([mainRef, advancedRef, dictionaryRef])
    }, [validateTabs])

    useEffect(() => {
        if (formikProps.values.Type === SlotType.Dictionary) {
            !isDictionary && setIsDictionary(true)
        } else {
            isDictionary && setIsDictionary(false)
        }
    }, [formikProps.values.Type, isDictionary, setIsDictionary])

    return (
        <>
            <OnSubmitValidationError formikProps={formikProps} callback={validateSlotFormTabs} />
            <Tab.Pane eventKey={tabEntries[0].key} ref={mainRef}>
                <SlotFormGeneral {...formikProps} t={t} slot={slot} asyncState={asyncState} />
            </Tab.Pane>
            <Tab.Pane eventKey={tabEntries[1].key} ref={advancedRef}>
                <SlotFormAdvanced {...formikProps} t={t} slot={slot} asyncState={asyncState} />
            </Tab.Pane>
            {isDictionary && (
                <Tab.Pane eventKey={tabEntries[2].key} ref={dictionaryRef}>
                    <SlotFormDictionaryValues {...formikProps} t={t} slot={slot} asyncState={asyncState} />
                </Tab.Pane>
            )}
        </>
    )
}

export default FormikSlotForm
