export enum OperatorStatusValueDto {
    Online,
    Offline,
    DoNotRoute
}

export type SelectedOperatorStatusDto = {
    Value: OperatorStatusValueDto
    Description: string
    UpdatedAt: number
}

export const isSelectedOperatorStatusDto = (value: unknown): value is SelectedOperatorStatusDto => {
    return !!(
        value &&
        typeof value === "object" &&
        value.hasOwnProperty("Value") &&
        value.hasOwnProperty("Description") &&
        value.hasOwnProperty("UpdatedAt")
    )
}
