import React from "react"
import "./ColorInput.scss"
import { CirclePicker, InjectedColorProps, CustomPicker } from "react-color"
import ContextMenu from "../ContextMenu/ContextMenu"
import { EditableInput, Hue, Saturation } from "react-color/lib/components/common"
import cn from "classnames"
import { testId } from "../../utility/tests/testId"

const defaultColor = "#A3A3A3"

export type ColorInputType = "hsl" | "circles"
const defaultType = "hsl"

const colorList = [
    "#E0001B",
    "#F79F1F",
    "#1A9F29",
    "#1066A8",
    "#00BB8E",
    "#0EABDC",
    "#009ECE",
    "#A2A2A2",
    "#A3CB38",
    "#FFC312",
    "#ED4C67",
    "#5758BB",
    "#7B519D",
    "#F07F0E",
    "#C8C0EA",
    "#CF142B"
]

interface Props {
    simplified?: boolean
    contextMenuContainerClassName?: string
    type?: ColorInputType
}

const RootColorInput: React.FC<Props & InjectedColorProps> = props => {
    const { hex, onChange, simplified, contextMenuContainerClassName, type = defaultType } = props
    return (
        <ContextMenu
            placement={"bottom"}
            className={cn("color-input__context", `color-input__context_${type}`)}
            containerClassName={contextMenuContainerClassName}
            closeWhenClickInside={false}
            items={
                <div className="color-input__context-container">
                    {onChange && (
                        <>
                            {type === "hsl" ? (
                                <>
                                    <div className="color-input__saturation">
                                        <Saturation {...props} onChange={onChange} />
                                    </div>
                                    <div className="color-input__hue">
                                        <Hue {...props} onChange={onChange} />
                                    </div>
                                </>
                            ) : (
                                <CirclePicker
                                    className="color-input__circles"
                                    colors={colorList}
                                    circleSize={24}
                                    circleSpacing={16}
                                    width="350px"
                                    {...props}
                                    onChange={onChange}
                                />
                            )}
                        </>
                    )}
                </div>
            }
        >
            <div className={cn("color-input__container", "form-control")} data-test-id={testId.colorInputContainer}>
                <div className={cn("color-input__circle-container", simplified && "simplified")}>
                    <div className="color-input__circle" style={{ backgroundColor: hex || defaultColor }} />
                </div>
                <EditableInput
                    value={hex}
                    onChange={onChange}
                    style={{
                        wrap: { width: "100%" },
                        input: { width: "100%" }
                    }}
                />
            </div>
        </ContextMenu>
    )
}

export default CustomPicker(RootColorInput)
