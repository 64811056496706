import React, { useEffect } from "react"
import { ArticleType } from "../../models/articleType"
import { useDispatch, useSelector } from "react-redux"
import useDelay from "../../utility/common/useDelay"
import Async from "../Async/Async"
import SettingsTableLoader from "../SettingsTable/SettingsTableLoader"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import { selectGetArticleTypesState } from "../../store/knowledgeBase/selectors"
import { getArticleTypes } from "../../store/knowledgeBase/thunks"
import ProjectArticleTypes from "./ProjectArticleTypes"

interface Props {
    projectId: string
    title: string
    onAddClick: () => void
    onSettingsClick: (type: ArticleType) => void
    onDeleteClick: (type: ArticleType) => void
}

const ProjectArticleTypesContainer: React.FC<Props> = props => {
    const { projectId, title, onAddClick, onSettingsClick, onDeleteClick } = props

    const dispatch = useDispatch()
    const display = useDelay()

    const apiState = useSelector(selectGetArticleTypesState)

    useEffect(() => {
        dispatch(getArticleTypes(projectId))
    }, [projectId, dispatch])

    if (!display) {
        return null
    }

    return (
        <Async
            dataState={apiState}
            processView={<SettingsTableLoader columns={2} rows={2} rowHeight={56} />}
            errorView={({ message }) => <ErrorMessage text={message} />}
        >
            {({ data: articleTypes }) => (
                <ProjectArticleTypes
                    projectId={projectId}
                    title={title}
                    articleTypes={articleTypes.Types}
                    onAddClick={onAddClick}
                    onSettingsClick={onSettingsClick}
                    onDeleteClick={onDeleteClick}
                />
            )}
        </Async>
    )
}

export default ProjectArticleTypesContainer
