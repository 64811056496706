import { ClientTextColor, InitialMessageText, Language, OperatorNameTemplate, UserFormFields } from "./webChatConfig"
import { ChannelValuesBase } from "./channelValues"

export interface WebChatChannelValues extends ChannelValuesBase {
    AuthSettings: AuthSettingsValues
    AppearanceSettings: AppearanceValues
    MobileVersionEnabled: boolean
    HistorySettings: HistoryValues
    PushMessageSettings: PushMessageValues
    OperatorSettings: OperatorValues
    InterfaceSettings: InterfaceValues
    PromoSettings: PromoValues
    InitialMessageSettings: InitialMessageValues
    SyncSettings: SyncValues
    AuthAdvanced: AuthAdvancedValues
    FileSettings: FileValues
    DialogEvaluationSettings: DialogEvaluationValues
    BlockSettings: BlockValues
    SystemSettings: SystemValues
    MessageSettings: MessageValues
}

export interface AuthSettingsValues {
    type: AuthType
    useHash: boolean
    isShownSalt: boolean
    salt: string
    requireFirstnameField: boolean
    requireLastnameField: boolean
    requirePhoneField: boolean
}

export interface AppearanceValues {
    headerText: string
    headerDescription: string
    baseColor: string
    textColor: ClientTextColor
    language: Language
}

export interface SyncValues {
    enabled: boolean
    mediatorId: string
    namespace: string
    namespaceCobrowsing: string
}

export interface HistoryValues {
    showHistory: boolean
    historyMessageCount: number
    historyFromActiveDialog: boolean
}

export interface PushMessageValues {
    notificationNewMessages: boolean
    pushMessageTitle: string
    pushMessageBody: string
    pushMessageBodyFromMessageText: boolean
    pushMessageIcon: string
    pushMessageTag: string
    pushMessageTimeout: number
    swPublicKey: string
    externalPushServiceUrl: string
    subscriptionRequestDelay: number
}

export interface OperatorValues {
    operatorIsTypingTimeout: number
    showConnectedOperatorNotify: boolean
    showOperatorName: boolean
    operatorNameTemplate: OperatorNameTemplate
    showFinishDialogByOperatorMessage: boolean
    disableOperatorImage: boolean
}

export interface InterfaceValues {
    disableClosing: boolean
    disableHeader: boolean
    disableCompanyInformation: boolean
    useEmoji: boolean
    requireChatDraggable: boolean
    requireChatResizing: boolean
    logoColor: string
    alwaysOpen: boolean
    disableInitialOpen: boolean
}

export interface PromoValues {
    showPromo: boolean
    promoTimeoutMin: number
    promoTimeoutSec: number
    promoWidth: number
    promoHeight: number
    promoAllowedUrls: string[]
    promoWelcomeMessage: string
}

export interface InitialMessageValues {
    mode: InitialMessageMode
    initialMessageText: InitialMessageText
}

export interface AuthAdvancedValues {
    phoneNumberMask: string
    allowedSymbolsRegexp: string
    clearNonValidUserData: boolean
    requireAuthData: string
    userFormFields: UserFormFields[]
    usePersonalDataAgreement: boolean
    personalDataText: string
    authButtonText: string
}

export interface FileValues {
    showImageDownloadLink: boolean
    allowedFileNameRegex: string
    fileRegexErrorMessage: string
    oversizedFileErrorMessage: string
    uploadSizeLimitValue: number
    uploadSizeLimitUnit: string
    useCaptcha: boolean
    captchaSecretKey: string
    captchaSiteKey: string
    captchaFileCount: number
}

export interface DialogEvaluationValues {
    ratingAnswerText: string
    showRatingBox: boolean
    requireScoreRequest: boolean
    questionBeforeRatingDialog: boolean
    ratingAliasesEnabled: boolean
    ratingAliases: string[]
    requireDialogFinishWithEstimate: boolean
}

export interface BlockValues {
    blockMessage: string
}

export interface SystemValues {
    socketIOInitialLoad: boolean
    cobrowsingEnabled: boolean
    bottomPlacement: boolean
    enableMobileVersion: boolean
    rootElementValue: string
    rootElementType: ElementType
    openChatButtonSelectorValue: string
    openChatButtonSelectorType: ElementType
    customCss: string
    iFrameMode: string
    iFrameHost: string
    debug: string
    customTransport: TransportType
    chatAnnouncement: string
    useSentry: boolean
}

export interface MessageValues {
    sendUserIsTyping: boolean
    userTypingInterval: number
    forwardMessage: boolean
    showClientServiceMessage: boolean
    linkOpeningBehaviour: LinkOpeningType
    locationMessage: string
}

export enum InitialMessageMode {
    sendInitialMessageOnOpen = "sendInitialMessageOnOpen",
    sendInitialMessageOnStartDialog = "sendInitialMessageOnStartDialog",
    showInitialMessage = "showInitialMessage"
}

export enum IFrameMode {
    disabled = "",
    insecure = "*",
    specifiedHost = "specifiedHost"
}

export enum ElementType {
    id = "id",
    class = "class",
    tag = "tag",
    custom = "custom"
}

export enum AuthType {
    Anonymously = "anonymously",
    RequireAuthFields = "requireAuthFields",
    JavaScriptAuthorization = "JavaScriptAuthorization"
}

export enum LinkOpeningType {
    sameTab = "sameTab",
    newTab = "newTab",
    newWindow = "newWindow"
}

export enum TransportType {
    websocket = "websocket",
    polling = "polling"
}
