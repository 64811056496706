import React from "react"
import "./ArticleCommentThreadView.scss"
import ArticleCommentComponentLoader from "../ArticleComment/ArticleCommentComponentLoader"
import Skeleton from "../Skeleton/Skeleton"

const ArticleCommentThreadViewLoader: React.FC = () => {
    return (
        <div className="article-comment-thread-view article-comment-thread-view_loader">
            <div className="article-comment-thread-view__replies">
                {new Array<number>(2).fill(0).map((_, i) => (
                    <ArticleCommentComponentLoader key={i} />
                ))}
            </div>
            <div className="article-comment-thread-view__input-container">
                <Skeleton />
            </div>
        </div>
    )
}

export default ArticleCommentThreadViewLoader
