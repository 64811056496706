import React from "react"
import FileNameRegexControl from "./FileNameRegexControl"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import { nameof2 } from "../../../utility/common/nameof"
import { ChannelValues } from "../../../models/channelValues"
import { ParamValues } from "../../../models/parameterDeclaration"
import FormAccordion from "../../FormAccordion/FormAccordion"
import { WithT } from "i18next"
const tNamespace = "channel:form."

interface Props extends WithT {
    filenameRegex?: string
}

const FileControls: React.FC<Props> = ({ filenameRegex, t }) => {
    return (
        <FormAccordion title={t(`${tNamespace}files`)}>
            <FileNameRegexControl filenameRegex={filenameRegex} t={t} />
            <DebouncedValidatableInput
                id="formFileRegexErrorMessage"
                type="text"
                name={nameof2<ChannelValues, ParamValues>("Params", "invalid_file_name_error_message")}
                label={t(`${tNamespace}error-text-invalid-file`)}
            />
            <DebouncedValidatableInput
                id="formFileMaxSizeMB"
                type="number"
                name={nameof2<ChannelValues, ParamValues>("Params", "file_max_size_mb")}
                label={t(`${tNamespace}file-size-limit-mb`)}
            />
            <DebouncedValidatableInput
                id="formFileMaxSizeErrorMessage"
                type="text"
                name={nameof2<ChannelValues, ParamValues>("Params", "file_max_size_error_message")}
                label={t(`${tNamespace}error-text-large-file`)}
            />
        </FormAccordion>
    )
}

export default FileControls
