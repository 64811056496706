import React from "react"
import styles from "./WizardStage.module.scss"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { testId } from "../../utility/tests/testId"
import LoadingButton from "../LoadingButton/LoadingButton"

export interface WizardStageProps extends ClassProps {
    title: string
    isActive?: boolean
    description?: string
    backButtonText?: string
    nextButtonText?: string
    message?: string
    onBack?: Function
    onNext?: Function
    validationSchema?: any
    isComplete?: boolean
    setComplete?: Function
    isLoading?: boolean
}

const tNamespace = "wizard:"

const WizardStage: React.FC<WizardStageProps> = props => {
    const { t } = useTranslation()
    const {
        title,
        isActive,
        children,
        className,
        description,
        backButtonText,
        nextButtonText,
        message,
        onBack,
        onNext,
        isComplete,
        setComplete,
        isLoading
    } = props
    const handleSubmitButtonClick = () => (isComplete && setComplete ? setComplete() : onNext && onNext())
    return isActive ? (
        <div className={cn(styles.wizardFormStage, className)}>
            <div className={styles.wizardFormStage__header}>
                <div className={styles.wizardFormStage__title}>{title}</div>
                {description && <div className={styles.wizardFormStage__description}>{description}</div>}
            </div>
            <div className={styles.wizardFormStage__body}>
                {children}
                {message && <span className={styles.wizardFormStage__message}>{message}</span>}
            </div>
            <div className={styles.wizardFormStage__footer}>
                {onBack && (
                    <Button
                        variant="outline-primary"
                        onClick={() => onBack()}
                        className={styles.wizardFormStage__backButton}
                        data-test-id={testId.wizardStageBackBtn}
                    >
                        {backButtonText ? backButtonText : t(`${tNamespace}back`)}
                    </Button>
                )}
                <LoadingButton
                    loading={isLoading}
                    type={onNext || isComplete ? undefined : "submit"}
                    variant="primary"
                    onClick={() => handleSubmitButtonClick()}
                    className={styles.wizardFormStage__nextButton}
                    data-test-id={testId.wizardStageNextBtn}
                >
                    {nextButtonText ? nextButtonText : t(`${tNamespace}next`)}
                </LoadingButton>
            </div>
        </div>
    ) : null
}

export default WizardStage
