import React, { useCallback } from "react"
import "./AlertDialog.scss"
import { Button, Modal, ModalProps } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { formTranslation } from "../../locales/form"
import { ButtonVariant } from "react-bootstrap/types"
import { testId } from "../../utility/tests/testId"

export interface AlertDialogProps extends ModalProps {
    show: boolean
    title: string
    message?: string
    onClose: () => void
    onSubmit: () => void
    onExited?: (node: HTMLElement) => void
    variant?: ButtonVariant
    submitBtnText?: string
    cancelable?: boolean
}

const AlertDialog: React.FC<AlertDialogProps> = props => {
    const { t } = useTranslation()
    const {
        show,
        title,
        message,
        onClose,
        onHide,
        onSubmit,
        onExited,
        submitBtnText,
        variant = "primary",
        cancelable = true,
        ...modalProps
    } = props

    const handleSubmit = useCallback(() => {
        onSubmit()
        onClose()
    }, [onSubmit, onClose])

    return (
        <Modal {...modalProps} dialogClassName="alert-dialog" show={show} onHide={onClose} onExited={onExited}>
            <Modal.Body>
                <Modal.Title>{title}</Modal.Title>
                {message && <p>{message}</p>}
            </Modal.Body>

            <Modal.Footer>
                {cancelable && (
                    <Button variant="light" onClick={onClose} data-test-id={testId.alertDialogCancel}>
                        {t(formTranslation.cancel)}
                    </Button>
                )}
                <Button variant={variant} onClick={handleSubmit} data-test-id={testId.alertDialogSubmit}>
                    {submitBtnText || t(formTranslation.continue)}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AlertDialog
