import React from "react"
import "./MarkdownButtonBlockForm.scss"
import { Formik } from "formik"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"
import FormikMarkdownButtonBlockForm from "./FormikMarkdownButtonBlockForm"
import {
    ButtonWhatsappType,
    MarkdownButtonBlock,
    MarkdownButtonBlockValues
} from "../../../../../../models/markdownButton"
import { formTranslation } from "../../../../../../locales/form"
import { defaultBlockValues } from "../../../../../../utility/articleContentEditor/markdownButtons"
import { ButtonType } from "../../../../../../utility/articleContentEditor/buttonsParser/buttonAttributes/buttonType"

const tNamespace = "knowledgeBase:article-buttons.errors."

interface EditKeyboardButtonFormProps {
    block: MarkdownButtonBlock
    onEdit: (block: MarkdownButtonBlock) => void
    close: () => void
}

const defaultValidationSchema = {
    Action: Yup.string().when("Type", {
        is: ButtonType.Action,
        then: Yup.string().requiredExcludeEmpty(`${tNamespace}select-article`)
    }),
    Url: Yup.string().when("Type", {
        is: ButtonType.Url,
        then: Yup.string().requiredExcludeEmpty(`${tNamespace}enter-url`)
    })
}

const getValuesFromBlock = (block: MarkdownButtonBlock): MarkdownButtonBlockValues => {
    const { whatsapp_type, ...blockValues } = block
    return {
        ...defaultBlockValues,
        ...blockValues,
        whatsapp_template_enabled: block.whatsapp_type === ButtonWhatsappType.Template
    }
}

const MarkdownButtonBlockForm: React.FC<EditKeyboardButtonFormProps> = props => {
    const { block, onEdit, close } = props
    const { t } = useTranslation()

    return (
        <Formik<MarkdownButtonBlockValues>
            initialValues={getValuesFromBlock(block)}
            validationSchema={() =>
                Yup.object(defaultValidationSchema).when("whatsapp_template_enabled", {
                    is: true,
                    then: Yup.object().shape({
                        ...defaultValidationSchema,
                        template: Yup.string().requiredExcludeEmpty(formTranslation.enterText),
                        language: Yup.string().requiredExcludeEmpty(formTranslation.enterText),
                        additional_value: Yup.string().requiredExcludeEmpty(formTranslation.enterText)
                    })
                })
            }
            onSubmit={values => {
                const { whatsapp_template_enabled, language, additional_value, template, ...updatedBlockValues } =
                    values
                const updatedBlock: MarkdownButtonBlock = {
                    ...updatedBlockValues,
                    ...(whatsapp_template_enabled && {
                        whatsapp_type: ButtonWhatsappType.Template,
                        ...(template && { template }),
                        ...(language && { language }),
                        ...(additional_value && { additional_value })
                    })
                }

                onEdit(updatedBlock)
                close()
            }}
        >
            {formikProps => <FormikMarkdownButtonBlockForm {...formikProps} t={t} loading={false} onCancel={close} />}
        </Formik>
    )
}

export default MarkdownButtonBlockForm
