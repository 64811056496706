import React from "react"
import "./ClassifierList.scss"
import { useSelector } from "react-redux"
import ErrorMessage from "../../ErrorMessage/ErrorMessage"
import InfoCard from "../../InfoCard/InfoCard"
import { useTranslation } from "react-i18next"
import InfoCardListLoader from "../../InfoCardList/InfoCardListLoader"
import InfoCardList from "../../InfoCardList/InfoCardList"
import Async from "../../Async/Async"
import useDelay from "../../../utility/common/useDelay"
import { selectGetClassifierConfigurationsState } from "../../../store/classifiers/selectors"
import { ClassifierConfigurationProcessed, ClassifierType, CurrentClassifier } from "../../../models/classifier"
import ClassifierStatusIcon from "../ClassifierStatusIcon/ClassifierStatusIcon"
import ClassifierIcon from "../ClassifierIcon/ClassifierIcon"

const tNamespace = "classifiers:"
const tStatusNamespace = "classifiers:classifier-status."

const GRUForKNNDefaultName = "flat_cnn"

interface Props {
    onClick: (classifier: ClassifierConfigurationProcessed) => void
    selectedClassifier: CurrentClassifier | null
}

const loader = <InfoCardListLoader count={4} className="classifier-list" cardClassName="classifier-list__card" />

const ClassifierList: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { onClick, selectedClassifier } = props
    const apiState = useSelector(selectGetClassifierConfigurationsState)
    const display = useDelay()

    if (!display) {
        return loader
    }

    return (
        <Async dataState={apiState} processView={loader} errorView={({ message }) => <ErrorMessage text={message} />}>
            {({ data: classifiers }) => (
                <InfoCardList className="classifier-list">
                    {classifiers.map(classifier => (
                        <InfoCard
                            className="classifier-list__card"
                            iconClassName="classifier-list__card-icon-container"
                            key={`${classifier.ClassifierConfigId}${classifier.Status}`}
                            content={{
                                icon: <ClassifierIcon type={classifier.Type} className="classifier-list__card-icon" />,
                                title:
                                    classifier.Type === ClassifierType.GRU && classifier.Name === GRUForKNNDefaultName
                                        ? t(`${tNamespace}gru-for-knn`)
                                        : classifier.Name,
                                text: (
                                    <ClassifierStatusIcon status={classifier.Status}>
                                        {`${t(`${tStatusNamespace}${classifier.Status}`)}`}
                                    </ClassifierStatusIcon>
                                )
                            }}
                            onClick={() => onClick(classifier)}
                            selected={
                                !!selectedClassifier &&
                                selectedClassifier.ClassifierConfigId === classifier.ClassifierConfigId
                            }
                        >
                            <div className="classifier-list__card-tags">
                                {classifier.Tags.map((tag, index) => (
                                    <span key={index} className="classifier-list__card-tag">
                                        {tag}
                                    </span>
                                ))}
                            </div>
                        </InfoCard>
                    ))}
                </InfoCardList>
            )}
        </Async>
    )
}

export default ClassifierList
