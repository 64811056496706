import { ArticleUserView } from "../../models/articleUserViews"

export const extractViewsAndReadConfirmationsCount = (views: ArticleUserView[]) =>
    views.reduce(
        ({ viewsCount, readConfirmationsCount }, view) => {
            return {
                viewsCount: view.Viewed ? viewsCount + 1 : viewsCount,
                readConfirmationsCount: view.ReadConfirmed ? readConfirmationsCount + 1 : readConfirmationsCount
            }
        },
        { viewsCount: 0, readConfirmationsCount: 0 }
    )

export const viewsToMap = (views: ArticleUserView[]) => {
    let map: { [login: string]: ArticleUserView } = {}
    for (const view of views) {
        map[view.Login] = view
    }
    return map
}
