import React, { ReactNode } from "react"
import cn from "classnames"
import { ClassProps } from "../../utility/common/props"
import "./Article.scss"

export interface ArticleProps extends ClassProps {
    symbolCode: string
    headerTop: ReactNode
    headerRight?: ReactNode
    title: ReactNode | string
    widgets?: ReactNode
    body?: ReactNode | string
}

const Article: React.FC<ArticleProps> = props => {
    const { className, headerTop, headerRight, title, body, widgets } = props

    return (
        <div className={cn("article", className)}>
            <div className="article__header">
                <div className="article__header-section">{headerTop}</div>
                <div className="article__header-section">
                    <div className="article__title">{title}</div>
                    {headerRight && <div className="article__header-right">{headerRight}</div>}
                </div>
            </div>
            {widgets && <div className="article__parameters">{widgets}</div>}
            {body && <div className="article__body">{body}</div>}
        </div>
    )
}

export default Article
