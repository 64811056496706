import React, { useEffect, useMemo } from "react"
import FormAccordion from "../../FormAccordion/FormAccordion"
import { nameof2 } from "../../../utility/common/nameof"
import { PushMessageValues, WebChatChannelValues } from "../../../models/webChatValues"
import AlertIcon from "../../AlertIcon/AlertIcon"
import ImageUploadInput from "../../ImageUploadInput/ImageUploadInput"
import { Form } from "react-bootstrap"
import Slider from "../../Slider/Slider"
import { useTranslation } from "react-i18next"
import { useFormikContext } from "formik"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import DebouncedCheckBox from "../../CheckBoxValidatableInput/DebouncedCheckBox"
import { Dispatch } from "../../../utility/common/storeHelper"
import { useDispatch } from "react-redux"

const tNamespace = "channel:form."
const tTooltipNamespace = "channel:tooltip."
const tTimeNamespace = "common:time."

const WebChatNotificationSettings: React.FC = () => {
    const { t } = useTranslation()
    const { values, setFieldValue } = useFormikContext<WebChatChannelValues>()
    const dispatch = useDispatch<Dispatch>()

    const notificationEnabled = useMemo(
        () => values.PushMessageSettings.notificationNewMessages,
        [values.PushMessageSettings.notificationNewMessages]
    )

    const handlePushMessageTimeoutChange = (v: number) => {
        setFieldValue(
            nameof2<WebChatChannelValues, PushMessageValues>("PushMessageSettings", "pushMessageTimeout"),
            v,
            false
        )
    }

    const handleSubscriptionRequestDelayChange = (v: number) => {
        setFieldValue(
            nameof2<WebChatChannelValues, PushMessageValues>("PushMessageSettings", "subscriptionRequestDelay"),
            v,
            false
        )
    }

    useEffect(() => {
        if (!values.SystemSettings.socketIOInitialLoad) {
            setFieldValue(
                nameof2<WebChatChannelValues, PushMessageValues>("PushMessageSettings", "notificationNewMessages"),
                false,
                false
            )
        }
    }, [setFieldValue, values.SystemSettings.socketIOInitialLoad])

    return (
        <FormAccordion title={t(`${tNamespace}notifications`)} className="webchat-form__accordion-with-slider">
            <DebouncedCheckBox
                id="formNotificationNewMessages"
                name={nameof2<WebChatChannelValues, PushMessageValues>(
                    "PushMessageSettings",
                    "notificationNewMessages"
                )}
                label={t(`${tNamespace}push-messages`)}
                icon={
                    !values.SystemSettings.socketIOInitialLoad ? (
                        <AlertIcon
                            id="notificationMessagesAlert"
                            content={t(`${tTooltipNamespace}push-messages-blocked`)}
                        />
                    ) : undefined
                }
                disabled={!values.SystemSettings.socketIOInitialLoad}
            />
            <DebouncedValidatableInput
                id="formPushMessageTitle"
                type="text"
                name={nameof2<WebChatChannelValues, PushMessageValues>("PushMessageSettings", "pushMessageTitle")}
                label={t(`${tNamespace}push-message-title`)}
                disabled={!notificationEnabled}
            />
            <DebouncedValidatableInput
                id="formPushMessageBody"
                type="text"
                name={nameof2<WebChatChannelValues, PushMessageValues>("PushMessageSettings", "pushMessageBody")}
                label={t(`${tNamespace}push-message-content`)}
                disabled={!notificationEnabled || values.PushMessageSettings.pushMessageBodyFromMessageText}
            />
            <DebouncedCheckBox
                id="formPushMessageBodyFromMessageText"
                name={nameof2<WebChatChannelValues, PushMessageValues>(
                    "PushMessageSettings",
                    "pushMessageBodyFromMessageText"
                )}
                label={t(`${tNamespace}push-message-content-text`)}
                disabled={!notificationEnabled}
            />
            <ImageUploadInput
                id="formPushMessageIcon"
                name={nameof2<WebChatChannelValues, PushMessageValues>("PushMessageSettings", "pushMessageIcon")}
                label={t(`${tNamespace}push-message-icon`)}
                disabled={!notificationEnabled}
                dispatch={dispatch}
                inline
            />
            <Form.Group controlId="formPushMessageTimeout">
                <Form.Label>{t(`${tNamespace}time-until-push-message-close`)}</Form.Label>
                <Slider
                    min={0}
                    max={20}
                    step={1}
                    marks={{
                        0: `0 ${t(`${tTimeNamespace}sec`)}`,
                        20: `20 ${t(`${tTimeNamespace}sec`)}`
                    }}
                    onChange={handlePushMessageTimeoutChange}
                    value={values.PushMessageSettings.pushMessageTimeout}
                    disabled={!notificationEnabled}
                />
            </Form.Group>
            <DebouncedValidatableInput
                id="formPushMessageTag"
                type="text"
                name={nameof2<WebChatChannelValues, PushMessageValues>("PushMessageSettings", "pushMessageTag")}
                label={t(`${tNamespace}push-message-tag`)}
                disabled={!notificationEnabled}
            />
            <DebouncedValidatableInput
                id="formExternalPushServiceUrl"
                type="text"
                name={nameof2<WebChatChannelValues, PushMessageValues>("PushMessageSettings", "externalPushServiceUrl")}
                label={t(`${tNamespace}push-message-third-party-service-sending`)}
                disabled={!notificationEnabled}
            />
            <Form.Group controlId="formSubscriptionRequestDelay">
                <Form.Label>{t(`${tNamespace}delay-before-request-push-notifications-permission`)}</Form.Label>
                <Slider
                    min={0}
                    max={48}
                    step={1}
                    marks={{
                        0: `0 ${t(`${tTimeNamespace}hr`)}`,
                        48: `48 ${t(`${tTimeNamespace}hr`)}`
                    }}
                    onChange={handleSubscriptionRequestDelayChange}
                    value={values.PushMessageSettings.subscriptionRequestDelay}
                    disabled={!notificationEnabled}
                />
            </Form.Group>
        </FormAccordion>
    )
}

export default WebChatNotificationSettings
