import { KnowledgeBasePermission } from "../../models/knowledgeBasePermission"
import { createContext } from "react"

export interface KnowledgeBasePermissionsContextType {
    permissions: KnowledgeBasePermission[]
}

const KnowledgeBasePermissionsContext = createContext<KnowledgeBasePermissionsContextType>({
    permissions: []
})

export default KnowledgeBasePermissionsContext
