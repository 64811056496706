import React from "react"
import "./WizardRedirectButton.scss"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { selectProjectCopyWizardName, selectProjectCopyWizardReady } from "../../store/projects/selectors"
import { push } from "connected-react-router"
import { createProjectCopyPath } from "../../routerPaths"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faPlus } from "@fortawesome/pro-light-svg-icons"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import { testId } from "../../utility/tests/testId"

const tNamespace = "wizard:"

interface Props extends ClassProps {}

const CopyWizardRedirectButton: React.FC<Props> = props => {
    const { className } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const wizardReady = useSelector(selectProjectCopyWizardReady)
    const wizardProjectName = useSelector(selectProjectCopyWizardName)
    return (
        <div
            className={cn("wizard-redirect-button", className)}
            onClick={() => dispatch(push(createProjectCopyPath))}
            data-test-id={testId.createFromCopy}
        >
            <div className="wizard-redirect-button__icon">
                <FontAwesomeIcon
                    icon={wizardReady ? faChevronRight : faPlus}
                    className="wizard-redirect-button__icon-svg"
                />
            </div>
            <div className="wizard-redirect-button__text">
                <p className="wizard-redirect-button__text-title">
                    {wizardReady ? t(`${tNamespace}continue`) : t(`${tNamespace}create-from-copy`)}
                </p>
                {wizardReady && (
                    <span className="wizard-redirect-button__text-project-name">
                        {wizardProjectName
                            ? `${t(`${tNamespace}project`)} ${wizardProjectName}`
                            : t(`${tNamespace}from-copy`)}
                    </span>
                )}
            </div>
        </div>
    )
}

export default CopyWizardRedirectButton
