import React, { ReactNode } from "react"
import { Image } from "react-bootstrap"
import cn from "classnames"
import styles from "./UserCard.module.scss"
import { ClassProps } from "../../utility/common/props"
import { testId } from "../../utility/tests/testId"

export interface UserCardProps extends ClassProps {
    firstName: string
    lastName: string
    picture?: string
    isShort?: boolean
    onClick?: () => void
    indicator?: ReactNode
}

const UserCard: React.FC<UserCardProps> = props => {
    const { firstName, lastName, picture, isShort, onClick, indicator } = props
    return (
        <div
            className={cn(styles.userCard, props.className, isShort && styles.userCard_short)}
            onClick={onClick}
            data-test-id={testId.userCard}
        >
            <div className={styles.userCard__pictureContainer}>
                {picture ? (
                    <Image className={styles.userCard__picture} src={picture} />
                ) : lastName || firstName ? (
                    <div className={cn(styles.userCard__picture, styles.userCard__picture_default)}>
                        {(lastName && lastName[0]) + (firstName && firstName[0])}
                    </div>
                ) : null}
                {indicator && <div className={styles.userCard__indicator}>{indicator}</div>}
            </div>
            {!isShort && (
                <div className={styles.userCard__info}>
                    <div className={styles.userCard__introduction}>
                        <div className={styles.userCard__lastName}>{lastName}</div>
                        <div className={styles.userCard__firstName}>{firstName}</div>
                    </div>
                    {props.children}
                </div>
            )}
        </div>
    )
}

export default UserCard
