import React, { useContext } from "react"
import { Formik } from "formik"
import { useTranslation } from "react-i18next"
import { getDefaultArticleShareFormValues } from "../../utility/knowledgeBase/knowledgeBase"
import { KnowledgeBasePermittedActionSelectItem } from "../../models/knowledgeBasePermission"
import { ArticleShareFormValues } from "../../models/article"
import UserAccessForm from "../UserAccessForm/UserAccessForm"
import { nameof } from "../../utility/common/nameof"
import { Project } from "../../models/project"
import KnowledgeBasePermissionsContext from "../KnowledgeBasePermissionsProvider/KnowledgeBasePermissionsContext"
import { Button, Form } from "react-bootstrap"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import styles from "./ArticleShareForm.module.scss"
import { formTranslation } from "../../locales/form"
import LoadingButton from "../LoadingButton/LoadingButton"
import CreateSharingLinkForm from "../CreateSharingLinkForm/CreateSharingLinkForm"

const tNamespace = "knowledgeBase:"

export interface ArticleShareFormProps {
    userAccessTitle: string
    permittedActionOptions: KnowledgeBasePermittedActionSelectItem[]
    onShare: (values: ArticleShareFormValues, hidePopover: () => void) => void
    onCreateSharing: () => void
    onDeleteSharing: () => void
    onCancelSharing: () => void
    onSaveSharing: () => void
    updateArticleLoading?: boolean
    sharingLink?: string
    sharingLoading?: boolean
    removeSharingLoading?: boolean
    currentProject?: Project
    projects?: Project[]
    isSharingEnabled?: boolean
    isScenario?: boolean
}

const ArticleShareForm: React.FC<ArticleShareFormProps> = props => {
    const {
        userAccessTitle,
        permittedActionOptions,
        onCancelSharing,
        onSaveSharing,
        projects,
        currentProject,
        onShare,
        sharingLink,
        sharingLoading,
        onCreateSharing,
        onDeleteSharing,
        isSharingEnabled = false,
        isScenario = false,
        removeSharingLoading = false,
        updateArticleLoading = false
    } = props
    const { t } = useTranslation()
    const { permissions } = useContext(KnowledgeBasePermissionsContext)

    return (
        <Formik
            initialValues={getDefaultArticleShareFormValues(permissions)}
            onSubmit={(values: ArticleShareFormValues) => {
                onShare(values, onSaveSharing)
            }}
        >
            {formikProps => (
                <Form
                    className={styles.articleShareForm}
                    onSubmit={formikProps.handleSubmit}
                    onKeyPress={preventSubmitOnEnter}
                >
                    <UserAccessForm
                        type="permission"
                        title={userAccessTitle}
                        name={nameof<ArticleShareFormValues>("Permissions")}
                        permittedActionOptions={permittedActionOptions}
                        currentProject={currentProject}
                        projects={projects}
                        {...formikProps}
                        t={t}
                    />
                    {isSharingEnabled && !isScenario && (
                        <CreateSharingLinkForm
                            id="article-sharing-link-form"
                            className={styles.articleShareForm__sharingLink}
                            title={t(`${tNamespace}sharing.title`)}
                            warningTitle={t(`${tNamespace}remove-sharing-catalog-link.title`)}
                            warningMessage={t(`${tNamespace}remove-sharing-catalog-link.message`)}
                            value={sharingLink}
                            loading={sharingLoading}
                            deleteLoading={removeSharingLoading}
                            onCreate={onCreateSharing}
                            onDelete={onDeleteSharing}
                        />
                    )}
                    <div className={styles.articleShareForm__footer}>
                        <Button
                            className={styles.articleShareForm__cancel}
                            variant="light"
                            onClick={onCancelSharing}
                            disabled={sharingLoading || removeSharingLoading}
                        >
                            {t(formTranslation.cancel)}
                        </Button>
                        <LoadingButton
                            className={styles.articleShareForm__submit}
                            variant="primary"
                            loading={updateArticleLoading}
                            disabled={sharingLoading || removeSharingLoading}
                            type="submit"
                        >
                            {t(formTranslation.save)}
                        </LoadingButton>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default ArticleShareForm
