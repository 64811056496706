import React from "react"
import "./AgentFormLoader.scss"
import useDelay from "../../utility/common/useDelay"
import Skeleton from "../Skeleton/Skeleton"

interface Props {
    count: number
}

const AgentFormLoader: React.FC<Props> = props => {
    const { count } = props
    const display = useDelay()

    if (!display) {
        return null
    }

    return (
        <div className="agent-form-loader">
            <div className="agent-form-loader__header">
                <Skeleton />
            </div>
            {new Array<number>(count).fill(0).map((_, i) => (
                <div key={i} className="agent-form-loader__item">
                    <Skeleton />
                </div>
            ))}
        </div>
    )
}

export default AgentFormLoader
