import React, { memo, useCallback, useContext, useEffect, useState } from "react"
import "./FormNode.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd"
import {
    ArticleScenarioContext,
    ScenarioContext,
    UpdateConditionsCallback
} from "../../../ScenarioEditor/ScenarioContext"
import CommonNode from "../Common/CommonNode"
import { BlockTypeWithConditions, FormSlot, ScenarioBlockType } from "../../../../models/scenario"
import { Condition } from "../../../../models/scenarioCondition"
import { Props as FormFieldItemProps, renderFieldItem } from "./FormFieldItem"
import { reorder } from "../../../../utility/scenario/scenario"
import ConditionList from "../Conditions/ConditionList/ConditionList"
import { NodeComponentProps } from "react-flow-renderer/dist/nocss/types"
import { useStoreActions, useUpdateNodeInternals } from "react-flow-renderer"
import cn from "classnames"
import {
    handleUpdateCondition,
    handleUpdateConditions,
    handleUpdateFormFields
} from "../../../../utility/scenario/scenarioNode"

const tNamespace = "scenarioEditor:"

export interface FormNodeData {
    Slots: FormSlot[]
    Conditions: Condition[]
    AddBlock: (source?: string, sourceHandle?: string) => void
    AddFormConditions: (id: string, blockType: BlockTypeWithConditions, callback: UpdateConditionsCallback) => void
}

type Props = NodeComponentProps<FormNodeData>

const FormNode: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { id, data, isConnectable } = props
    const { selectedNode, setElements } = useContext(ScenarioContext)
    const { slotsMap, handleOpenConditionForm, selectedCondition, setSelectedCondition, handleAddSlot } =
        useContext(ArticleScenarioContext)
    const resetSelectedElements = useStoreActions(state => state.resetSelectedElements)

    const selected = selectedNode === id

    const [slots, setSlots] = useState(data.Slots)
    const [conditions, setConditions] = useState(data.Conditions)

    const updateNodeInternals = useUpdateNodeInternals()

    const updateConditions = useCallback(
        (conditions: Condition[], conditionIdToDelete?: string) =>
            handleUpdateConditions(setElements)(conditions, id, conditionIdToDelete),
        [setElements, id]
    )

    const updateLocalConditions = useCallback(
        (conditions: Condition[], conditionIdToDelete?: string) => {
            setConditions(conditions)
            updateConditions(conditions, conditionIdToDelete)
        },
        [updateConditions]
    )

    const handleDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination || result.destination.index === result.source.index) return

            setSlots(reorder(slots, result.source.index, result.destination.index))
        },
        [slots]
    )

    const handleAddField = (slotId: string) => {
        setSlots([
            ...slots,
            {
                SlotId: slotId,
                IsMandatory: true
            }
        ])
    }

    const handleRemoveCondition = (conditionId: string) => {
        const updatedConditions = conditions.filter(c => c.Id !== conditionId)
        updateLocalConditions(updatedConditions, conditionId)
    }

    const handleAddBlock = (sourceHandle: string) => data.AddBlock(id, sourceHandle)

    const handleChangeCondition = (condition: Condition) => () => {
        if (selectedCondition === condition.Id) return

        resetSelectedElements()
        setSelectedCondition(condition.Id)

        handleOpenConditionForm(
            handleUpdateCondition(setElements, id, condition.Id, conditions => setConditions(conditions)),
            ScenarioBlockType.Form,
            false,
            condition
        )
    }

    const formFieldItemProps: FormFieldItemProps = {
        slotsMap,
        slots,
        setSlots,
        blockId: id
    }

    useEffect(() => {
        updateNodeInternals(id)
    }, [id, conditions, slots, updateNodeInternals])

    useEffect(() => {
        handleUpdateFormFields(setElements)(slots, id)
    }, [slots, id, setElements])

    return (
        <>
            <CommonNode
                id={id}
                className={cn(
                    "form-node",
                    !isConnectable && "form-node_not-connectable",
                    selected && "form-node_selected"
                )}
                headerClassName="form-node__header"
                type={ScenarioBlockType.Form}
                isConnectable={isConnectable}
            >
                <div className="form-node__content">
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId={id} renderClone={renderFieldItem(formFieldItemProps)}>
                            {provided => (
                                <div {...provided.droppableProps} ref={provided.innerRef} className="form-node__fields">
                                    {slots.map((s, index) => (
                                        <Draggable
                                            key={`${id}-${s.SlotId}`}
                                            draggableId={`${id}-${s.SlotId}`}
                                            index={index}
                                            isDragDisabled={!selected}
                                        >
                                            {renderFieldItem(formFieldItemProps)}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
                {isConnectable && (
                    <div className="form-node__add-section">
                        <Button
                            block
                            variant="light"
                            className="form-node__add-btn"
                            onClick={() => handleAddSlot(handleAddField)}
                        >
                            <FontAwesomeIcon icon={faPlus} className="form-node__add-icon" />
                            {t(`${tNamespace}add-field`)}
                        </Button>
                    </div>
                )}
            </CommonNode>
            <ConditionList
                blockId={id}
                className={cn("form-node__condition-list", isConnectable && "form-node__condition-list_connectable")}
                conditions={conditions}
                onAddBlock={handleAddBlock}
                onAddCondition={() =>
                    data.AddFormConditions(id, ScenarioBlockType.Form, conditions => setConditions(conditions))
                }
                onRemoveCondition={handleRemoveCondition}
                isConnectable={isConnectable}
                selected={selected}
                onSelect={handleChangeCondition}
                updateConditions={updateLocalConditions}
            />
        </>
    )
}

export default memo(FormNode)
