import React, { useContext } from "react"
import { Form } from "react-bootstrap"
import { Formik, FormikProps } from "formik"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"
import "./GlobalUserForm.scss"
import { WithT } from "i18next"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import SettingsGroup, { getCheckedValues, initSettings, Settings } from "../SettingsGroup/SettingsGroup"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { updateGlobalUser } from "../../store/users/thunks"
import UserCard from "../UserCard/UserCard"
import { formTranslation } from "../../locales/form"
import { selectUpdateGlobalUser } from "../../store/users/selectors"
import { nameof } from "../../utility/common/nameof"
import { Dispatch } from "../../utility/common/storeHelper"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import { GlobalUser, UpdateGlobalUserRequest } from "../../models/globalUser"
import LoadingButton from "../LoadingButton/LoadingButton"
import ConfigContext from "../ConfigContext/ConfigContext"
import { handleSettingsLabelTranslation, SettingsType } from "../../utility/common/handleSettingsLabelTranslation"

const tRolesNamespace = "roles:"

interface GlobalUserValues {
    globalPermissions: Settings<string>
    maxCapacity: number
}

interface Props {
    user: GlobalUser
    allGlobalPermissions: string[]
    close: Function
}

const FormikGlobalUserForm: React.FC<Props & FormikProps<GlobalUserValues> & WithT> = props => {
    const { user, t, values, handleSubmit } = props

    const updateState = useSelector(selectUpdateGlobalUser, shallowEqual)
    const {
        WebConfig: {
            appSettings: { signUpEnabled }
        }
    } = useContext(ConfigContext)
    return (
        <Form className="global-user-form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <div className="global-user-form__content">
                <div className="section">
                    <div className="user-info">
                        <div className="user-info__section">
                            <UserCard firstName={user.FirstName} lastName={user.LastName} picture={user.Picture} />
                        </div>
                        <div className="user-info__section">
                            <div className="user-info__name">{t(formTranslation.login)}</div>
                            <div className="user-info__value">{user.Login}</div>
                        </div>
                        <div className="user-info__section">
                            <div className="user-info__name">{t(formTranslation.firstName)}</div>
                            <div className="user-info__value">{user.FirstName}</div>
                        </div>
                        <div className="user-info__section">
                            <div className="user-info__name">{t(formTranslation.lastName)}</div>
                            <div className="user-info__value">{user.LastName}</div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <SettingsGroup<string>
                        name={nameof<GlobalUserValues>("globalPermissions")}
                        title={t(`${tRolesNamespace}permissions`)}
                        values={values.globalPermissions}
                        disabled={!signUpEnabled}
                        getLabel={v => handleSettingsLabelTranslation(tRolesNamespace, v, SettingsType.Roles, t)}
                    />
                </div>
                <div className="section">
                    <ValidatableInput
                        id="formMaxCapacity"
                        type="number"
                        name={nameof<GlobalUserValues>("maxCapacity")}
                        label={t(formTranslation.maxConcurrentTasks)}
                    />
                </div>
            </div>
            <div className="global-user-form__footer">
                <LoadingButton type="submit" loading={updateState.inProcess} variant="primary" block>
                    {t(formTranslation.save)}
                </LoadingButton>
            </div>
        </Form>
    )
}

const getValuesFromUser = (user: GlobalUser, allGlobalPermissions: string[]): GlobalUserValues => {
    const globalPermissionsSettings: Settings<string> = {}
    initSettings(
        globalPermissionsSettings,
        allGlobalPermissions,
        user.GlobalPermissions,
        v => v,
        v => v
    )

    return {
        globalPermissions: globalPermissionsSettings,
        maxCapacity: user.MaxCapacity
    }
}

const buildRequest = (user: GlobalUser, values: GlobalUserValues): UpdateGlobalUserRequest => {
    return {
        Login: user.Login,
        GlobalPermissions: getCheckedValues(values.globalPermissions),
        MaxCapacity: values.maxCapacity
    }
}

const GlobalUserForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch<Dispatch>()
    const { user, allGlobalPermissions, close } = props

    return (
        <Formik
            enableReinitialize={true}
            initialValues={getValuesFromUser(user, allGlobalPermissions)}
            validationSchema={() => {
                return Yup.object().shape({})
            }}
            onSubmit={(values: GlobalUserValues) => {
                dispatch(updateGlobalUser(buildRequest(user, values), close))
            }}
        >
            {formikProps => <FormikGlobalUserForm {...props} {...formikProps} t={t} />}
        </Formik>
    )
}

export default GlobalUserForm
