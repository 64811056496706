import { CommandNodeData } from "../components/Graph/nodes/Command/CommandNode"
import { OpenConditionFormCallback } from "../components/ScenarioEditor/ScenarioContext"
import { Condition, ConditionBlock, ConditionPayload } from "./scenarioCondition"

export interface Scenario {
    Blocks: ScenarioBlock[]
}

export interface ChannelScenario {
    Blocks: ChannelScenarioBlock[]
}

export interface ScenarioBlockBase<T> {
    Type: T
    Id: string
    Position: ScenarioBlockPosition
    Inputs: ScenarioBlockInput[]
    Outputs: ScenarioBlockOutput[]
}

export type ScenarioBlock =
    | MessageBlock
    | StartBlock
    | ArticleBlock
    | ConditionBlock
    | FormBlock
    | AgentBlock
    | CommandBlock
    | ChannelBlock

export type ChannelScenarioBlock = ArticleBlock | ChannelBlock

export enum ScenarioBlockType {
    Message = "Message",
    Agent = "Agent",
    Condition = "Condition",
    Form = "Form",
    Article = "Article",
    Command = "Command",
    Start = "Start",
    Channel = "Channel"
}

export type BlockTypeWithConditions = ScenarioBlockType.Form | ScenarioBlockType.Condition

interface ScenarioBlockPosition {
    Left: number
    Top: number
}

interface ScenarioBlockInput {
    From: string
    FromOutput: ScenarioBlockOutput
}

interface ScenarioBlockOutput {
    Id: string
    Type: "Article" | "Text" | "Agent"
    To?: string
}

export interface StartBlock extends ScenarioBlockBase<ScenarioBlockType.Start> {}

// Message
export interface MessageBlock extends ScenarioBlockBase<ScenarioBlockType.Message> {
    BlockData: MessageBlockData
}

export interface MessageBlockData {
    Message: MessagePayload
}

export interface MessagePayload {
    Text: string
    Buttons: MessageBlockButton[]
}

export interface MessageBlockButton {
    Id: string
    Title: string
    Type: "Article" | "Url" | "Unknown"
    Payload: string
}

// Article
export interface ArticleBlock extends ScenarioBlockBase<ScenarioBlockType.Article> {
    BlockData: ArticleBlockData
}

export interface ArticleBlockData {
    Article: ArticlePayload
}

export interface ArticlePayload {
    ArticleId: string
    ArticleTitle: string
}

// Agent
export interface AgentBlock extends ScenarioBlockBase<ScenarioBlockType.Agent> {
    BlockData: AgentBlockData
}

export interface AgentBlockData {
    Agent: AgentPayload
}

export interface AgentPayload {
    AgentId: string
    AgentType: string
    OwnedByThisScenario: boolean
}

// Form
export interface FormBlock extends ScenarioBlockBase<ScenarioBlockType.Form> {
    BlockData: FormBlockData
}

export interface FormBlockData {
    Form: FormPayload
}

export interface FormPayload {
    FormSlots: FormSlots
    OutputConditions: ConditionPayload
}

export interface FormSlots {
    Slots: FormSlot[]
}

export interface FormSlot {
    SlotId: string
    IsMandatory: boolean
}

// Command
export interface CommandBlock extends ScenarioBlockBase<ScenarioBlockType.Command> {
    BlockData: CommandBlockData
}

export interface CommandBlockData {
    Command: CommandPayload
}

export interface CommandPayload {
    Text: string
}

export enum CommandType {
    SetSlotValue = "SET_SLOT_VALUE",
    SetSlotValueWithExpression = "SET_SLOT_VALUE_WITH_EXPRESSION",
    DeleteSlotValue = "DELETE_SLOT_VALUE",
    DeleteSlotValues = "DELETE_SLOT_VALUES"
}

export interface CommandNode {
    id: string
    data: CommandNodeData
}

// Channel
export interface ChannelBlock extends ScenarioBlockBase<ScenarioBlockType.Channel> {
    BlockData: ChannelBlockData
}

export interface ChannelBlockData {
    Channel: ChannelPayload
}

export interface ChannelPayload {
    ChannelId: string
    ChannelType: string
}

export type OpenConditionFormHandler = (
    callback: OpenConditionFormCallback,
    blockType: BlockTypeWithConditions,
    stepBack?: boolean,
    condition?: Condition,
    connection?: Connection
) => void

export interface Connection {
    source?: string
    sourceHandle?: string
}
