import React, { useContext } from "react"
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCommentAltDots, faCommentAltLines, IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { useTranslation } from "react-i18next"
import "./ArticleToolbar.scss"
import ArticleViewContext from "../ArticleViewProvider/ArticleViewContext"

const tNamespace = "knowledgeBase"

interface ArticleToolbarProps {
    answersCount?: number
    questionsCount?: number
    onOpenAnswers?: () => void
    onOpenQuestions?: () => void
}

interface ToolbarItemProps {
    icon: IconDefinition
    itemsCount: number
    titleTranslation: string
    onClick?: () => void
}

const ToolbarItem: React.FC<ToolbarItemProps> = props => {
    const { icon, itemsCount, titleTranslation, onClick } = props
    const { t } = useTranslation()

    return (
        <Button variant="link" className="article-toolbar__item" onClick={onClick}>
            <FontAwesomeIcon icon={icon} size="1x" className="article-toolbar__icon" />
            {`${t(`${tNamespace}:${titleTranslation}`)} (${itemsCount})`}
        </Button>
    )
}

const ArticleToolbar: React.FC<ArticleToolbarProps> = props => {
    const { answersCount = 0, questionsCount = 0, onOpenAnswers, onOpenQuestions } = props
    const { markedMessagesCount } = useContext(ArticleViewContext)

    return (
        <div className="article-toolbar">
            {onOpenAnswers && (
                <ToolbarItem
                    icon={faCommentAltDots}
                    itemsCount={answersCount}
                    titleTranslation="answer-options"
                    onClick={onOpenAnswers}
                />
            )}
            {onOpenQuestions && (
                <ToolbarItem
                    icon={faCommentAltLines}
                    itemsCount={questionsCount + markedMessagesCount}
                    titleTranslation="question-samples"
                    onClick={onOpenQuestions}
                />
            )}
        </div>
    )
}

export default ArticleToolbar
