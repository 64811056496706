import React from "react"
import styles from "./TaskContent.module.scss"
import MessageList from "../MessageList/MessageList"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import { Message } from "../../models/message"
import { useTranslation } from "react-i18next"

const tNamespace = "queues:task."

interface Props extends ClassProps {
    messages: Message[]
}

const TaskContent: React.FC<Props> = props => {
    const { className, messages } = props
    const { t } = useTranslation()

    return (
        <div className={cn(styles.taskContent, className)}>
            {messages.length === 0 ? (
                <div className={styles.taskContent__empty}>{t(`${tNamespace}no-messages`)}</div>
            ) : (
                <MessageList messages={messages} reverseInOut={false} />
            )}
        </div>
    )
}

export default TaskContent
