import React from "react"
import "./AlertIcon.scss"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons/faExclamationCircle"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"

export interface AlertIconProps extends ClassProps {
    id: string
    content?: string
}

const AlertIcon: React.FC<AlertIconProps> = props => {
    const { id, content, className } = props

    return (
        <TooltipTrigger id={id} content={content} placement="top">
            <FontAwesomeIcon icon={faExclamationCircle} className={cn("alert-icon", className)} />
        </TooltipTrigger>
    )
}

export default AlertIcon
