import React from "react"
import { nameof2 } from "../../../utility/common/nameof"
import { BlockValues, WebChatChannelValues } from "../../../models/webChatValues"
import FormAccordion from "../../FormAccordion/FormAccordion"
import { useTranslation } from "react-i18next"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"

const tNamespace = "channel:form."

const WebChatBlockSettings: React.FC = () => {
    const { t } = useTranslation()

    return (
        <FormAccordion title={t(`${tNamespace}block`)}>
            <DebouncedValidatableInput
                id="formBlockMessage"
                as="textarea"
                name={nameof2<WebChatChannelValues, BlockValues>("BlockSettings", "blockMessage")}
                label={t(`${tNamespace}message`)}
                className="webchat-form__block-message"
            />
        </FormAccordion>
    )
}

export default WebChatBlockSettings
