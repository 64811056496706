import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"
import { CatalogFormValues } from "../../models/catalog"
import { formTranslation } from "../../locales/form"
import { useSelector } from "react-redux"
import FormikCatalogForm from "./FormikCatalogForm"
import "./CatalogForm.module.scss"
import { selectAllProjects, selectCurrentProject } from "../../store/projects/selectors"
import { KnowledgeBasePermission, KnowledgeBasePermittedActionSelectItem } from "../../models/knowledgeBasePermission"

export interface CatalogFormProps {
    catalogTitle?: string
    isEdit?: boolean
    onCreateCatalog: (values: CatalogFormValues) => void
    onEditCatalog: (values: CatalogFormValues) => void
    onCancel: () => void
    loading?: boolean
    permissions: KnowledgeBasePermission[]
    permittedActionOptions: KnowledgeBasePermittedActionSelectItem[]
    disableProjectsSelector?: boolean
}

const CatalogForm: React.FC<CatalogFormProps> = props => {
    const {
        catalogTitle = "",
        isEdit,
        onCreateCatalog,
        onEditCatalog,
        onCancel,
        permissions,
        permittedActionOptions,
        loading = false,
        disableProjectsSelector
    } = props
    const { t } = useTranslation()
    const currentProject = useSelector(selectCurrentProject)
    const projects = useSelector(selectAllProjects)

    return (
        <Formik<CatalogFormValues>
            initialValues={{
                Title: catalogTitle,
                Permissions: permissions
            }}
            validationSchema={() =>
                Yup.object().shape({
                    Title: Yup.string().requiredExcludeEmpty(formTranslation.titleRequired)
                })
            }
            onSubmit={values => (isEdit ? onEditCatalog(values) : onCreateCatalog(values))}
        >
            {formikProps => (
                <FormikCatalogForm
                    loading={loading}
                    onCancel={onCancel}
                    currentProject={currentProject}
                    projects={projects}
                    permittedActionOptions={permittedActionOptions}
                    disableProjectsSelector={disableProjectsSelector}
                    {...formikProps}
                    t={t}
                />
            )}
        </Formik>
    )
}

export default CatalogForm
