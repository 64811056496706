import React from "react"
import "./Score.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/pro-light-svg-icons/faStar"
import cn from "classnames"

export interface ScoreProps {
    maxValue?: number
    value: number
}

const Score: React.FC<ScoreProps> = props => {
    const maxValue = props.maxValue || 5
    const { value } = props

    return (
        <div className="score">
            {new Array<number>(maxValue).fill(0).map((_, i) => (
                <FontAwesomeIcon
                    key={i}
                    className={cn("score__value", i + 1 <= value ? "score__value_filled" : undefined)}
                    icon={faStar}
                />
            ))}
        </div>
    )
}

export default Score
