import React, { useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import "./Project.scss"
import { Redirect, RouteComponentProps } from "react-router"
import { getProjects } from "../../store/projects/thunks"
import { getMainMenu } from "../../store/menu/thunks"
import { selectGetProjectsState } from "../../store/projects/selectors"
import ProjectView from "../../components/ProjectView/ProjectView"
import { selectGetMainMenuState } from "../../store/menu/selectors"

import Async from "../../components/Async/Async"
import FullscreenLoader from "../../components/FullscreenLoader/FullscreenLoader"
import FullscreenError from "../../components/FullscreenError/FullscreenError"
import { getOperatorStatus } from "../../store/userOperator/thunks"
import AsyncState from "../../core/asyncState"
import { welcomePath, accessDeniedPath } from "../../routerPaths"
import ConfigContext from "../../components/ConfigContext/ConfigContext"
import Can from "../../components/Can/Can"
import { Login } from "../../permissions"

interface Props extends RouteComponentProps {}

const ProjectPage: React.FC<Props> = () => {
    const dispatch = useDispatch()
    const {
        WebConfig: {
            appSettings: { authMode, signUpEnabled }
        }
    } = useContext(ConfigContext)

    useEffect(() => {
        dispatch(getProjects())
        dispatch(getMainMenu())
        dispatch(getOperatorStatus())
    }, [dispatch])

    const asyncState = AsyncState.merge2(useSelector(selectGetProjectsState), useSelector(selectGetMainMenuState))

    return (
        <div className="projects">
            <Async
                dataState={asyncState}
                processView={<FullscreenLoader />}
                errorView={({ message }) => <FullscreenError message={message} />}
            >
                {({ data: [projects, mainMenu] }) => (
                    <Can permission={Login}>
                        {can =>
                            !can ? (
                                <Redirect to={accessDeniedPath} />
                            ) : projects.length ? (
                                <ProjectView projects={projects} mainMenu={mainMenu} />
                            ) : authMode === "oidc" && !signUpEnabled ? (
                                <Redirect to={accessDeniedPath} />
                            ) : (
                                <Redirect to={welcomePath} />
                            )
                        }
                    </Can>
                )}
            </Async>
        </div>
    )
}

export default ProjectPage
