import { MenuItem } from "../../models/menu"
import { MenuPermissions } from "../menu/menuPermissions"
import { isPermissionExists } from "../common/usePermissionsCheck"

export const getAllowedMenuItems = (items: MenuItem[], currentPermissions: Set<string>) => {
    return items.filter(i => {
        const result = []
        const localMenuPermission = MenuPermissions[i.id]
        if (localMenuPermission) {
            result.push(localMenuPermission)
        }
        if (i.role) {
            result.push(i.role)
        }
        return result.every(p => isPermissionExists(p, currentPermissions))
    })
}
