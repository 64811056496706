import { isSelectedOperatorStatusDto, SelectedOperatorStatusDto } from "./operatorStatusDto"
import { Task } from "./task"
import { TenantQueues } from "./operator"

export type OperatorDto = {
    Id: string
    FirstName: string
    LastName: string
    MiddleName?: string
    Status: SelectedOperatorStatusDto
    MaxCapacity: number
    Capacity: number
    AssignmentWebHook?: string
    RoutingWebHook?: string
    TenantsQueues: TenantQueues[]
    Tasks: Task[]
}
export const isOperatorDto = (value: any): value is OperatorDto => {
    return !!(value && isSelectedOperatorStatusDto(value.Status) && value.Id && value.FirstName && value.LastName)
}
