import React from "react"
import "./EditMarkdownButtonForm.scss"
import { Formik } from "formik"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"
import FormikEditMarkdownButtonForm from "./FormikEditMarkdownButtonForm"
import { EditMarkdownButtonFormValues } from "../../../../../../models/markdownButton"
import { MarkdownButton } from "../../../../../../utility/articleContentEditor/buttonsParser/buttonsParser"
import { getValuesFromButton, valuesToButton } from "../../../../../../utility/articleContentEditor/markdownButtonForm"
import { ButtonType } from "../../../../../../utility/articleContentEditor/buttonsParser/buttonAttributes/buttonType"
import { Dispatch } from "../../../../../../utility/common/storeHelper"

const tNamespace = "knowledgeBase:article-buttons.errors."

interface EditKeyboardButtonFormProps {
    button: MarkdownButton
    onEdit: (updatedButton: MarkdownButton) => void
    close: () => void
    projectId: string
    dispatch: Dispatch
}

const EditMarkdownButtonForm: React.FC<EditKeyboardButtonFormProps> = props => {
    const { button, onEdit, close, projectId, dispatch } = props
    const { t } = useTranslation()

    return (
        <Formik<EditMarkdownButtonFormValues>
            initialValues={getValuesFromButton(button)}
            validationSchema={() =>
                Yup.object().shape({
                    article: Yup.string().when("type", {
                        is: ButtonType.Action,
                        then: Yup.string().requiredExcludeEmpty(`${tNamespace}select-article`)
                    }),
                    url: Yup.string().when("type", {
                        is: ButtonType.Url,
                        then: Yup.string().requiredExcludeEmpty(`${tNamespace}enter-url`)
                    })
                })
            }
            onSubmit={values => {
                onEdit(valuesToButton(values, button))
                close()
            }}
        >
            {formikProps => (
                <FormikEditMarkdownButtonForm
                    {...formikProps}
                    t={t}
                    projectId={projectId}
                    dispatch={dispatch}
                    onCancel={close}
                />
            )}
        </Formik>
    )
}

export default EditMarkdownButtonForm
