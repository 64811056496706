import { createContext } from "react"
import { KnowledgeBasePermittedActionSelectItem } from "../../models/knowledgeBasePermission"
import { onOpenSharingCatalogFunc } from "../../types/knowledgeBaseFunctions"

export interface KnowledgeBaseContextType {
    projectId?: string
    shareId?: string
    url: string
    canModifyKnowledgeBase: boolean
    permittedActionOptions: KnowledgeBasePermittedActionSelectItem[]
    onOpenSharingCatalog: onOpenSharingCatalogFunc
}

export const knowledgeBaseContextDefaultValue: KnowledgeBaseContextType = {
    url: "",
    canModifyKnowledgeBase: true,
    permittedActionOptions: [],
    onOpenSharingCatalog: () => {}
}

const KnowledgeBaseContext = createContext<KnowledgeBaseContextType>(knowledgeBaseContextDefaultValue)

export default KnowledgeBaseContext
