import { Dispatch } from "../../utility/common/storeHelper"
import * as constants from "./constants"
import { actions } from "./slice"
import { handleHttpException } from "../handleHttpException"
import stickersController from "../../api/controllers/stickers"
import { Sticker } from "../../models/sticker"

export function getStickers(projectId: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.getStickersProcess())
            const { Stickers } = await stickersController.getStickers({ CustomerId: projectId })
            dispatch(actions.getStickersSuccess(Stickers))
        } catch (e) {
            handleHttpException(
                e,
                constants.GET_STICKERS_FAILED_MESSAGE,
                err => actions.getStickersFailed(err),
                dispatch
            )
        }
    }
}

export function createSticker(createdSticker: Sticker, callback: () => void) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.createStickerProcess())
            await stickersController.create({ Sticker: createdSticker })
            dispatch(actions.createStickerSuccess(createdSticker))
            callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.CREATE_STICKER_FAILED_MESSAGE,
                err => actions.createStickerFailed(err),
                dispatch
            )
        }
    }
}

export function updateSticker(updatedSticker: Sticker, callback: () => void) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.updateStickerProcess())
            await stickersController.update({ Sticker: updatedSticker })
            dispatch(actions.updateStickerSuccess(updatedSticker))
            callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_STICKER_FAILED_MESSAGE,
                err => actions.updateStickerFailed(err),
                dispatch
            )
        }
    }
}

export function deleteSticker(projectId: string, stickerId: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.deleteStickerProcess())
            await stickersController.delete({ StickerId: stickerId, CustomerId: projectId })
            dispatch(actions.deleteStickerSuccess(stickerId))
        } catch (e) {
            handleHttpException(
                e,
                constants.DELETE_STICKER_FAILED_MESSAGE,
                err => actions.deleteStickerFailed(err),
                dispatch
            )
        }
    }
}
