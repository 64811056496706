import React from "react"
import { Form } from "react-bootstrap"
import { Formik, FormikProps } from "formik"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"
import "./InviteUsersForm.scss"
import { WithT } from "i18next"
import { nameof } from "../../utility/common/nameof"
import { Project, InviteUsersRequest } from "../../models/project"
import TagInput from "../TagInput/TagInput"
import { formTranslation } from "../../locales/form"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { inviteUsers } from "../../store/projects/thunks"
import { selectInviteUsersState } from "../../store/projects/selectors"
import LoadingButton from "../LoadingButton/LoadingButton"
import { Role } from "../../models/role"
import ValidatableInput from "../ValidatableInput/ValidatableInput"

const tNamespace = "project:"

interface InviteUsersValues {
    emails: string[]
    defaultRole: string
}

interface Props {
    project: Project
    roles: Role[]
    close: Function
}

const FormikInviteUsersForm: React.FC<Props & FormikProps<InviteUsersValues> & WithT> = props => {
    const { roles, t, handleSubmit } = props

    const inviteState = useSelector(selectInviteUsersState, shallowEqual)

    return (
        <Form className="invite-users-form" onSubmit={handleSubmit}>
            <div className="invite-users-form__content">
                <ValidatableInput
                    id="formRole"
                    as="select"
                    name={nameof<InviteUsersValues>("defaultRole")}
                    label={t(`${tNamespace}default-role`)}
                >
                    <option key="default" value={""}>
                        {t(formTranslation.select)}
                    </option>
                    {roles.map(v => (
                        <option value={v.Id} key={v.Id}>
                            {v.Title}
                        </option>
                    ))}
                </ValidatableInput>
                <TagInput name={nameof<InviteUsersValues>("emails")} placeholder={t(`${tNamespace}enter-emails`)} />
            </div>
            <div className="invite-users-form__footer">
                <LoadingButton type="submit" loading={inviteState.inProcess} variant="primary" block>
                    {t(`${tNamespace}invite`)}
                </LoadingButton>
            </div>
        </Form>
    )
}

const defaultValues: InviteUsersValues = {
    emails: [],
    defaultRole: ""
}

const createRequest = (project: Project, values: InviteUsersValues): InviteUsersRequest => {
    return {
        ProjectId: project.id,
        UsersEmails: values.emails,
        RoleId: values.defaultRole
    }
}

const InviteUsersForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { project, close } = props
    const dispatch = useDispatch()

    return (
        <Formik
            enableReinitialize={true}
            initialValues={defaultValues}
            validationSchema={() => {
                return Yup.object().shape({
                    emails: Yup.array()
                        .required(formTranslation.emailRequired)
                        .of(Yup.string().email(formTranslation.emailInvalid)),
                    defaultRole: Yup.string().requiredExcludeEmpty(`${tNamespace}role-required`)
                })
            }}
            onSubmit={(values: InviteUsersValues) => {
                dispatch(inviteUsers(createRequest(project, values), close))
            }}
        >
            {formikProps => <FormikInviteUsersForm {...props} {...formikProps} t={t} />}
        </Formik>
    )
}

export default InviteUsersForm
