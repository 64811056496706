import React, { ReactNode } from "react"
import { useAccordionToggle } from "react-bootstrap"
import { ClassProps } from "../../utility/common/props"

interface Props extends ClassProps {
    disabled: boolean
    eventKey: string
    onToggle: () => void
    children: ReactNode
}

const AccordionToggle: React.FC<Props> = props => {
    const { disabled, eventKey, onToggle, children, className } = props
    const onClick = useAccordionToggle(eventKey, onToggle)

    return (
        <div className={className} onClick={!disabled ? onClick : undefined}>
            {children}
        </div>
    )
}

export default AccordionToggle
