import React from "react"
import FormAccordion from "../../FormAccordion/FormAccordion"
import { tSlotFormNamespace } from "../SlotForm/SlotForm"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import { nameof2 } from "../../../utility/common/nameof"
import { CalendarQuestionValues, SlotValues } from "../../../models/slot"
import { WithT } from "i18next"

interface Props extends WithT {}

const CalenderSettings: React.FC<Props> = props => {
    const { t } = props

    return (
        <FormAccordion title={t(`${tSlotFormNamespace}calender-settings`)}>
            <DebouncedValidatableInput
                id="formDatetimeFailedQuestion"
                as="textarea"
                name={nameof2<SlotValues, CalendarQuestionValues>("CalendarQuestions", "DatetimeFailedQuestion")}
                label={t(`${tSlotFormNamespace}no-desired-datetime-question`)}
            />
            <DebouncedValidatableInput
                id="formTimeQuestion"
                as="textarea"
                name={nameof2<SlotValues, CalendarQuestionValues>("CalendarQuestions", "TimeQuestion")}
                label={t(`${tSlotFormNamespace}question-to-clarify-time`)}
            />
            <DebouncedValidatableInput
                id="formNoScheduleQuestion"
                as="textarea"
                name={nameof2<SlotValues, CalendarQuestionValues>("CalendarQuestions", "NoScheduleQuestion")}
                label={t(`${tSlotFormNamespace}no-schedule-question`)}
            />
        </FormAccordion>
    )
}

export default CalenderSettings
