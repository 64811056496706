import { DialogTimeoutProjectSettings, ProjectSettings } from "../../../models/projectSettings"
import { DialogSettingsValues, DialogTimeoutProjectSettingsValues } from "../../../models/projectSettingsValues"
import { durationSettingsValuesToMs, timeoutSettingsToValues } from "../../../utility/project/projectSettings"

export const defaultDialogProjectSettings = {
    DialogTimeout: {
        FirstTimeout: {
            Value: 3000000
        },
        SecondTimeout: {
            Value: 3000000
        },
        FinishDialogTimeout: {
            Value: 3000000
        },
        DialogInQueueTimeout: {
            Value: 3000000
        },
        DialogLifeTimeout: {
            Value: 60000000
        }
    },
    Agent: {
        DefaultAgent: "",
        DefaultAgentForOutgoingDialog: "",
        OperatorAgent: "",
        SystemEventsAgent: ""
    }
}

export const getDialogSettingsInitialValues = (settings: ProjectSettings): DialogSettingsValues => {
    const dialogTimeoutSettings = { ...defaultDialogProjectSettings.DialogTimeout, ...settings.DialogTimeout }
    const agentSettings = { ...defaultDialogProjectSettings.Agent, ...settings.Agent }

    return {
        DialogTimeout: {
            FirstTimeout: timeoutSettingsToValues(dialogTimeoutSettings.FirstTimeout),
            SecondTimeout: timeoutSettingsToValues(dialogTimeoutSettings.SecondTimeout),
            FinishDialogTimeout: timeoutSettingsToValues(dialogTimeoutSettings.FinishDialogTimeout),
            DialogInQueueTimeout: timeoutSettingsToValues(dialogTimeoutSettings.DialogInQueueTimeout),
            DialogLifeTimeout: timeoutSettingsToValues(dialogTimeoutSettings.DialogLifeTimeout)
        },
        Agent: agentSettings
    }
}

const dialogTimeoutSettingsNames: (keyof DialogTimeoutProjectSettingsValues)[] = [
    "FirstTimeout",
    "SecondTimeout",
    "FinishDialogTimeout",
    "DialogInQueueTimeout",
    "DialogLifeTimeout"
]

export const buildUpdateDialogProjectSettingsRequest = (values: DialogSettingsValues) => ({
    DialogTimeout: dialogTimeoutSettingsNames.reduce((dialogTimeoutSettings: DialogTimeoutProjectSettings, name) => {
        const dialogTimeout = values.DialogTimeout[name]
        dialogTimeoutSettings[name] = {
            Value: durationSettingsValuesToMs(dialogTimeout.Value),
            Message: dialogTimeout.MessageEnabled ? dialogTimeout.Message : ""
        }
        return dialogTimeoutSettings
    }, {}),
    Agent: values.Agent
})
