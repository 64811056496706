import { Channel, ChannelTypeString, CommonChannel, WhatsappChannelType } from "../../models/channel"
import { ChannelValues, WhatsappAuthType, WhatsAppChannelValues } from "../../models/channelValues"
import { Declarations, ParameterDeclaration, ParamValues } from "../../models/parameterDeclaration"
import { getDefaultParams } from "../common/parametersTranslator"
import { extractParams } from "./channelTranslator"
import { Time } from "../common/time"
import { getScorePage } from "./scorePage"
import { getScoreMessage } from "./scoreMessage"

export const getDefaultValues = (
    language: string,
    Id: string,
    channelType: string,
    declarations?: ParameterDeclaration[],
    webhookPrefix?: string
): ChannelValues => {
    const channel: Channel = {
        Id,
        Type: channelType,
        Title: "",
        ProjectId: "",
        Token: "",
        PreprocessorWebHook: "",
        Params: {},
        ServerAddress: webhookPrefix ? webhookPrefix + Id : undefined
    }

    return getValuesFromCommonChannel(language, channel, declarations)
}

export const getDefaultWhatsappValues = (Id: string, declarations: Declarations): WhatsAppChannelValues => {
    const baseChannel: CommonChannel = {
        Id,
        Type: WhatsappChannelType.InfoBIP,
        Title: "",
        ProjectId: "",
        Token: "",
        PreprocessorWebHook: "",
        Params: {}
    }

    return getValuesFromWhatsappChannel(baseChannel, declarations)
}

const processDefaultParams = (channelType: string, defaultParams: ParamValues, language: string): ParamValues => {
    switch (channelType) {
        case ChannelTypeString.Email:
            defaultParams.score_message = getScoreMessage(language)
            defaultParams.score_page = getScorePage(language)
            break
    }
    return defaultParams
}

export const getDefaultParamsFromDeclarations = (
    language: string,
    channelType: string,
    declarations?: ParameterDeclaration[]
) => processDefaultParams(channelType, declarations ? getDefaultParams(declarations) : {}, language)

export const processParams = (channelType: string, params: ParamValues): ParamValues => {
    switch (channelType) {
        case ChannelTypeString.Email:
            params.refresh_interval = Time.msToMinutes(params.refresh_interval)
            break
    }
    return params
}

export const getValuesFromCommonChannel = (
    language: string,
    channel: Channel,
    declarations?: ParameterDeclaration[]
): ChannelValues => {
    const params = extractParams(channel.Type, {
        ...getDefaultParamsFromDeclarations(language, channel.Type, declarations),
        ...channel.Params
    })

    const processedParams = processParams(channel.Type, params)

    return {
        BotSettings: {
            Id: channel.Id,
            BotId: channel.BotId,
            Token: channel.Token ?? ""
        },
        Title: channel.Title ?? "",
        PreprocessorWebHook: channel.PreprocessorWebHook ?? "",
        Params: processedParams,
        ServerAddress: channel.ServerAddress
    }
}

export const getValuesFromWhatsappChannel = (channel: Channel, declarations: Declarations): WhatsAppChannelValues => {
    const params = extractParams(ChannelTypeString.Whatsapp, {
        ...(declarations.whatsapp?.Params && getDefaultParams(declarations.whatsapp.Params)),
        ...(declarations.whatsapp_streamtel?.Params && getDefaultParams(declarations.whatsapp_streamtel.Params)),
        ...(declarations.whatsapp_mfms?.Params && getDefaultParams(declarations.whatsapp_mfms.Params)),
        ...channel.Params
    })
    return {
        BotSettings: {
            Id: channel.Id,
            Token: channel.Token ?? ""
        },
        Title: channel.Title,
        PreprocessorWebHook: channel.PreprocessorWebHook ?? "",
        Params: params,
        ProviderSettings: {
            provider: channel.Type,
            login: params.login,
            apipassword: params.apipassword,
            sourceAddress: params.sourceAddress,
            scenario_key: params.scenario_key,
            host_url: params.host_url,
            authType:
                channel.Params.username && channel.Params.password
                    ? WhatsappAuthType.LoginPassword
                    : WhatsappAuthType.ApiKey,
            username: params.username,
            password: params.password,
            api_key: params.api_key,
            subject: params.subject,
            apikey: params.apikey
        }
    }
}
