import React, { useCallback, useEffect, useMemo, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { Route, Switch } from "react-router"
import { useRouteMatch } from "react-router-dom"
import { useTranslation } from "react-i18next"
import PageLayout from "../../components/PageLayout/PageLayout"
import { getArticleTypes, getCatalogs } from "../../store/knowledgeBase/thunks"
import { selectCurrentProjectId } from "../../store/projects/selectors"
import { articlePath } from "../../routerPaths"
import Catalogs from "../../components/Catalogs/Catalogs"
import ArticleViewContainer from "../../components/ArticleView/ArticleViewContainer"
import SearchQueryArticlesContainer from "../../components/SearchQueryArticlesContainer/SearchQueryArticlesContainer"
import { selectCurrentArticleState, selectKnowledgeBaseWorkMode } from "../../store/knowledgeBase/selectors"
import { selectSlots } from "../../store/slots/selectors"
import { KnowledgeBaseWorkMode } from "../../models/knowledgeBaseWorkMode"
import { setCurrentArticlesFilter, setKnowledgeBaseWorkMode } from "../../store/knowledgeBase/slice"
import CatalogPopoverContainer from "../../components/CatalogPopover/CatalogPopoverContainer"
import SearchArticlesInputContainer from "../../components/SearchArticlesInput/SearchArticlesInputContainer"
import styles from "./KnowledgeBase.module.scss"
import { ArticleScenarioContext, ScenarioContext } from "../../components/ScenarioEditor/ScenarioContext"
import ArticleFilterPopoverContainer from "../../components/ArticleFilter/ArticleFilterPopover/ArticleFilterPopoverContainer"
import { ModifyKnowledgeBase } from "../../permissions"
import Can from "../../components/Can/Can"
import { knowledgeBaseHub } from "../../api/instances"
import { getSlotsMap } from "../../utility/scenario/scenarioGraph"
import { onChooseAnswerFunc, onSendReplyFunc } from "../../types/knowledgeBaseFunctions"
import KnowledgeBasePermissionsProvider from "../../components/KnowledgeBasePermissionsProvider/KnowledgeBasePermissionsProvider"
import KnowledgeBaseProvider from "../../components/KnowledgeBaseProvider/KnowledgeBaseProvider"
import useKnowledgeBaseSidebar, { SelectSlotFunction } from "./KnowledgeBaseSidebar"
import cn from "classnames"
import { getSlots } from "../../store/slots/thunks"
import { definedMenuItems } from "../../utility/menu/definedMenuItems"

const tProjectNamespace = "project:"

export interface ArticleContextType {
    onAddSlot: (onSelect: SelectSlotFunction) => void
    onThreadOpen: onSendReplyFunc
    onChooseAnswer: onChooseAnswerFunc
    onOpenQuestions: (isEditable: boolean) => void
}
export const articleContextDefaultValue: ArticleContextType = {
    onAddSlot: () => {},
    onThreadOpen: () => {},
    onChooseAnswer: () => {},
    onOpenQuestions: () => {}
}
export const ArticleContext = React.createContext<ArticleContextType>(articleContextDefaultValue)

const KnowledgeBase: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { path, url } = useRouteMatch()
    const projectId = useSelector(selectCurrentProjectId)
    const workMode = useSelector(selectKnowledgeBaseWorkMode)
    const articleState = useSelector(selectCurrentArticleState, shallowEqual)
    const [connected, setConnected] = useState<boolean>(false)
    const [leftSidebarScrollable, setLeftSidebarScrollable] = useState<boolean>(true)
    const [scenarioTouched, setScenarioTouched] = useState<boolean>(false)

    const {
        sidebarClosed,
        closeSidebar,
        onCloseSidebar,
        selectedCondition,
        sidebarContent,
        setSelectedCondition,
        selectedNode,
        setSelectedNode,
        elements,
        setElements,
        instance,
        setInstance,
        handleArticleCommentThreadOpen,
        handleAddBlock,
        handleAddSlot,
        handleAddSlotInScenario,
        handleOpenConditionForm,
        handleOpenAgentForm,
        handleOpenSetSlotValueForm,
        handleOpenDeleteSlotValueForm,
        handleChooseAnswer,
        handleOpenQuestions,
        handleOpenCatalogSharing,
        handleOpenArticleInfoForm,
        handleOpenSurveyForm,
        renderSidebarContent
    } = useKnowledgeBaseSidebar(dispatch, t, articleState, projectId)

    useEffect(() => {
        if (projectId) {
            dispatch(getCatalogs(projectId))
            dispatch(getArticleTypes(projectId))
            dispatch(getSlots(projectId))
        }
    }, [dispatch, projectId])

    useEffect(() => {
        return () => {
            workMode === KnowledgeBaseWorkMode.Search &&
                dispatch(setKnowledgeBaseWorkMode(KnowledgeBaseWorkMode.CatalogsView))
        }
    }, [dispatch, workMode])

    useEffect(() => {
        setCurrentArticlesFilter({})
    }, [])

    useEffect(() => {
        knowledgeBaseHub.connect().then(() => {
            setConnected(true)
            knowledgeBaseHub.setReconnectingCallback(() => setConnected(false))
            knowledgeBaseHub.setReconnectedCallback(() => setConnected(true))
        })
        return () => {
            knowledgeBaseHub.disconnect()
        }
    }, [])

    useEffect(() => {
        knowledgeBaseHub.setPageUrl(url)
    }, [url])

    const handleSubscribeToArticle = useCallback((articleCode: string) => {
        knowledgeBaseHub.subscribe(articleCode)
    }, [])

    const handleUnsubscribeFromArticle = useCallback((articleCode: string) => {
        knowledgeBaseHub.unsubscribe(articleCode)
    }, [])

    const slots = useSelector(selectSlots)
    const slotsMap = useMemo(() => getSlotsMap(slots), [slots])

    const handleEditCatalog = useCallback(() => setLeftSidebarScrollable(false), [])
    const handleCancelEditCatalog = useCallback(() => setLeftSidebarScrollable(true), [])

    const isCatalogsVisible = workMode === KnowledgeBaseWorkMode.CatalogsView

    return (
        // TODO: compose providers in HOC
        <KnowledgeBaseProvider onOpenSharingCatalog={handleOpenCatalogSharing} projectId={projectId} url={url} t={t}>
            <PageLayout isCollapsed={sidebarClosed}>
                <PageLayout.LeftSidebar
                    title={t(`${tProjectNamespace}${definedMenuItems.KnowledgeBase.title}`)}
                    actions={[
                        <ArticleFilterPopoverContainer key="addFilter" />,
                        <Can permission={ModifyKnowledgeBase} key="addCatalog">
                            <KnowledgeBasePermissionsProvider permissions={[]}>
                                <CatalogPopoverContainer projectId={projectId} isWideAddButton />
                            </KnowledgeBasePermissionsProvider>
                        </Can>
                    ]}
                    intermediate={<SearchArticlesInputContainer className={styles.knowledgeBase__search} />}
                    isScrollable={leftSidebarScrollable}
                >
                    {workMode === KnowledgeBaseWorkMode.Search && <SearchQueryArticlesContainer />}
                    <div
                        className={
                            isCatalogsVisible
                                ? cn(styles.knowledgeBase__catalogs, styles.knowledgeBase__catalogs_visible)
                                : cn(styles.knowledgeBase__catalogs, styles.knowledgeBase__catalogs_hidden)
                        }
                    >
                        <Catalogs
                            onEditCatalogClick={handleEditCatalog}
                            onCancelEditCatalogClick={handleCancelEditCatalog}
                        />
                    </div>
                </PageLayout.LeftSidebar>

                <ScenarioContext.Provider
                    value={{
                        elements,
                        setElements,
                        instance,
                        setInstance,
                        selectedNode,
                        scenarioTouched,
                        setSelectedNode,
                        setScenarioTouched,
                        handleAddBlock,
                        projectId,
                        closeSidebar
                    }}
                >
                    <ArticleScenarioContext.Provider
                        value={{
                            handleAddSlot: handleAddSlotInScenario,
                            handleOpenConditionForm,
                            handleOpenAgentForm,
                            handleOpenSetSlotValueForm,
                            handleOpenDeleteSlotValueForm,
                            slotsMap,
                            selectedCondition,
                            setSelectedCondition
                        }}
                    >
                        <PageLayout.Content overlappingSidebar>
                            <Switch>
                                <Route path={`${path}${articlePath}/:action/:code`}>
                                    <ArticleContext.Provider
                                        value={{
                                            onAddSlot: handleAddSlot,
                                            onThreadOpen: handleArticleCommentThreadOpen,
                                            onChooseAnswer: handleChooseAnswer,
                                            onOpenQuestions: handleOpenQuestions
                                        }}
                                    >
                                        <ArticleViewContainer
                                            onSubscribeToArticle={handleSubscribeToArticle}
                                            onUnsubscribeFromArticle={handleUnsubscribeFromArticle}
                                            onOpenArticleInfoForm={handleOpenArticleInfoForm}
                                            onOpenSurveyForm={handleOpenSurveyForm}
                                            disableAddBlockBtn={!sidebarClosed}
                                            connected={connected}
                                        />
                                    </ArticleContext.Provider>
                                </Route>
                            </Switch>
                        </PageLayout.Content>

                        <PageLayout.Sidebar
                            title={sidebarContent ? sidebarContent.title : ""}
                            onClose={onCloseSidebar}
                            stepBack={sidebarContent?.stepBack}
                            onBack={sidebarContent?.onBack}
                            extended={sidebarContent?.extended}
                        >
                            {renderSidebarContent()}
                        </PageLayout.Sidebar>
                    </ArticleScenarioContext.Provider>
                </ScenarioContext.Provider>
            </PageLayout>
        </KnowledgeBaseProvider>
    )
}

export default KnowledgeBase
