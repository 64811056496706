import ArticleFile from "../../components/ArticleFile/ArticleFile"
import "../../components/ArticleFile/ArticleFile.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaperclip } from "@fortawesome/pro-light-svg-icons/faPaperclip"
import { TFunction } from "i18next"
import { articleContentEditorTranslation } from "../../locales/articleContentEditor"
import useRefValues from "../common/useRefValues"

export const allowedFileExtensionsDefault = [
    "doc",
    "docx",
    "pdf",
    "xls",
    "xlsx",
    "json",
    "zip",
    "rar",
    "csv",
    "ppt",
    "pptx",
    "exe",
    "msi",
    "svg",
    "dmg",
    "txt",
    "mp4",
    "vsd",
    "vsdx"
]

const ALLOWED_FILE_EXTENSIONS_REF_VALUE = "KnowledgeBaseAllowedFileExtensions"

export const GetAllowedFileExtensions = () => {
    const allowedExtensionsRef = useRefValues(ALLOWED_FILE_EXTENSIONS_REF_VALUE)
    return Array.isArray(allowedExtensionsRef) && allowedExtensionsRef.length > 0
        ? allowedExtensionsRef
        : allowedFileExtensionsDefault
}

const fileEmbedKeyword = "file"

export const getArticleRedactorEmbeds = (t: TFunction, allowedFileExtensions: string[]) => {
    const extRegexp = new RegExp(
        "(?:[^\\/][\\d\\w-]+(?:" + allowedFileExtensions.map(e => `\\.${e}`).join("|") + "))$",
        "i"
    )
    return [
        {
            title: t(articleContentEditorTranslation[fileEmbedKeyword]),
            keywords: fileEmbedKeyword,
            defaultHidden: false,
            icon: () => <FontAwesomeIcon icon={faPaperclip} size="lg" className="article-file-menu-icon" />,
            matcher: (url: string) => {
                return url.match(extRegexp) || false
            },
            component: ArticleFile
        }
    ]
}
