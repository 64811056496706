import React, { useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectStartTrainingClassifierState } from "../../../store/classifiers/selectors"
import RetrainClassifierPopover from "./RetrainClassifierPopover"
import { RetrainClassifierOptions } from "../../../models/classifier"
import { ClassProps } from "../../../utility/common/props"
import { startTraining } from "../../../store/classifiers/thunks"
import { PopoverContext } from "../../PopoverContext/PopoverContext"

interface Props extends ClassProps {
    projectId: string
    configId: string
    isActive: boolean
    disabled?: boolean
    retrainEmbeddings?: boolean
}

const RetrainClassifierPopoverContainer: React.FC<Props> = props => {
    const { projectId, configId, isActive, className, disabled, retrainEmbeddings } = props
    const dispatch = useDispatch()
    const { shown, showPopover, hidePopover } = useContext(PopoverContext)

    const asyncState = useSelector(selectStartTrainingClassifierState)

    const handleRetrainClassifier = (values: RetrainClassifierOptions) => {
        dispatch(startTraining(projectId, configId, isActive, values, hidePopover))
    }

    return (
        <RetrainClassifierPopover
            className={className}
            show={shown}
            onShow={showPopover}
            onCancel={hidePopover}
            onSubmit={handleRetrainClassifier}
            asyncState={asyncState}
            disabled={disabled}
            retrainEmbeddings={retrainEmbeddings}
        />
    )
}

export default RetrainClassifierPopoverContainer
