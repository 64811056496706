import React, { useState, ReactNode, useCallback } from "react"
import "./FormAccordion.scss"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import { Accordion } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons"
import AccordionToggle from "./AccordionToggle"
import Spinner from "../Spinner/Spinner"

export interface FormAccordionProps extends ClassProps {
    disabled?: boolean
    title: string
    children: ReactNode
    headerElement?: JSX.Element
    loading?: boolean
    initialOpened?: boolean
    size?: "lg" | "md"
}

const FormAccordion: React.FC<FormAccordionProps> = props => {
    const {
        className,
        disabled,
        title,
        headerElement,
        children,
        loading = false,
        initialOpened = false,
        size = "md"
    } = props
    const [opened, setOpen] = useState(initialOpened)

    const handleToggle = useCallback(() => setOpen(!opened), [opened])

    return (
        <Accordion className={cn(className, "form-accordion")} defaultActiveKey={initialOpened ? "0" : undefined}>
            <AccordionToggle disabled={!!disabled} eventKey="0" onToggle={handleToggle}>
                <div className={cn("form-accordion__header", size === "lg" && "form-accordion__header_lg")}>
                    <div
                        className={cn("form-accordion__title", disabled ? "form-accordion__title_disabled" : undefined)}
                    >
                        {title}
                        {loading && <Spinner className="form-accordion__spinner" />}
                    </div>
                    {headerElement && <div className="form-accordion__header-element">{headerElement}</div>}
                    {!disabled ? (
                        <div className="form-accordion__toggle">
                            <FontAwesomeIcon icon={opened ? faChevronUp : faChevronDown} />
                        </div>
                    ) : undefined}
                </div>
            </AccordionToggle>
            <Accordion.Collapse eventKey="0">
                <div className="form-accordion__body">{children}</div>
            </Accordion.Collapse>
        </Accordion>
    )
}

export default FormAccordion
