import { Hub } from "../hub"
import { IHub } from "../interfaces/IHub"
import { Dispatch } from "../../utility/common/storeHelper"
import { logError } from "../../utility/common/logError"
import { Store } from "../../store/store"
import { processNewEvent } from "../../store/classifiers/actions"
import { isClassifierEventDto } from "../../models/classifierEvent"

const SEND_EVENT = "SendEvent"

class ClassifiersHub {
    private _hub: IHub

    constructor(store: Store) {
        this._hub = new Hub(`/classifiers-hub`)
        this.registerServerEvents(store.dispatch)
    }

    async subscribe(projectId: string) {
        await this._hub.subscribe("Subscribe", projectId)
    }

    async unsubscribe(projectId: string) {
        await this._hub.unsubscribe("Unsubscribe", projectId)
    }

    private registerServerEvents(dispatch: Dispatch) {
        this._hub.registerEvent(SEND_EVENT, (rawData: string) => {
            try {
                const data = JSON.parse(rawData)
                if (isClassifierEventDto(data)) {
                    processNewEvent(dispatch, data)
                } else {
                    logError("Invalid classifier event:", data)
                }
            } catch (e) {
                logError(e)
            }
        })
    }
}

export default ClassifiersHub
