import React, { ChangeEvent, useCallback, useRef, useState } from "react"
import styles from "./ConvertTemplateControl.module.scss"
import { Col, Form, Row } from "react-bootstrap"
import cn from "classnames"
import TooltipTrigger from "../../../TooltipTrigger/TooltipTrigger"
import IconControl from "../../../IconControl/IconControl"
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash"
import AsyncState from "../../../../core/asyncState"
import { SystemError } from "../../../../core/error"
import {
    handleHttpExceptionWithCallback,
    handleHttpExceptionWithoutAction
} from "../../../../store/handleHttpException"
import { useDispatch } from "react-redux"
import Spinner from "../../../Spinner/Spinner"
import { useTranslation } from "react-i18next"
import { faArrowToTop } from "@fortawesome/pro-light-svg-icons/faArrowToTop"
import { useField } from "formik"
import { nameof2 } from "../../../../utility/common/nameof"
import {
    KnowledgeBaseConvertTemplateModel,
    ProjectSettings,
    SystemProjectSettings
} from "../../../../models/projectSettings"
import useDownloadFile from "../../../../utility/common/useDownloadFile"
import knowledgeBaseConverterController from "../../../../api/controllers/knowledgeBaseConverter"

const tNamespace = "project:project-settings.system."
const tTooltipNamespace = `${tNamespace}tooltip.`

const DEFAULT_TEMPLATE_NAME = "crafttalk_export_template.dotx"
const DOWNLOAD_TEMPLATE_FAILED_MESSAGE = "error:download-template-failed-message"
const UPLOAD_TEMPLATE_FAILED_MESSAGE = "error:upload-template-failed-message"

const acceptFiles = ".doc, .docx, .dotx"

export const convertTemplateControlName = nameof2<ProjectSettings, SystemProjectSettings>(
    "System",
    "KnowledgeBaseConvertTemplate"
)
const convertTemplateFleUrlControlName = `${convertTemplateControlName}.FileUrl`

export interface ConvertTemplateControlProps {
    projectId: string
    onChange: (name: string, value: string) => void
}

const ConvertTemplateControl: React.FC<ConvertTemplateControlProps> = props => {
    const { t } = useTranslation()
    const { projectId, onChange } = props
    const dispatch = useDispatch()

    const uploadInputRef = useRef<HTMLInputElement>(null)
    const [field, , helper] = useField<KnowledgeBaseConvertTemplateModel>(convertTemplateControlName)
    const [uploadState, setUploadState] = useState<AsyncState<string>>(AsyncState.create())

    const handleLoadConvertTemplate = useCallback(
        () => knowledgeBaseConverterController.getConvertTemplate(projectId),
        [projectId]
    )

    const handlePreDownload = useCallback(() => {
        setUploadState(AsyncState.createProcess())
    }, [])

    const handleDownloadFileError = useCallback(
        e => {
            const callback = (e: SystemError) => setUploadState(AsyncState.createFailed(e))
            handleHttpExceptionWithCallback(e, DOWNLOAD_TEMPLATE_FAILED_MESSAGE, dispatch, callback)
        },
        [dispatch]
    )

    const {
        ref: downloadTemplateRef,
        url: templateUrl,
        download: handleDownloadTemplate
    } = useDownloadFile(handleLoadConvertTemplate, handlePreDownload, handleDownloadFileError, field.value.FileName)

    const handleUploadTemplate = async (templateFile: File) => {
        try {
            if (templateFile) {
                handlePreDownload()

                const response = await knowledgeBaseConverterController.uploadConvertTemplate(projectId, templateFile)
                const newValue = response.Diff?.System?.KnowledgeBaseConvertTemplate

                if (newValue) {
                    helper.setValue(newValue)
                    onChange(convertTemplateFleUrlControlName, newValue.FileUrl)
                    setUploadState(AsyncState.createSuccess(newValue.FileUrl))
                }
            }
        } catch (e) {
            handleHttpExceptionWithoutAction(e, UPLOAD_TEMPLATE_FAILED_MESSAGE, dispatch)
        }
    }

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const templateFile = event.target?.files?.[0]
        if (templateFile) {
            handleUploadTemplate(templateFile)
        }
    }

    const handleUploadNewTemplate = () => {
        uploadInputRef.current?.click()
    }

    const handleDeleteTemplate = () => {
        helper.setValue({
            FileName: DEFAULT_TEMPLATE_NAME,
            FileUrl: ""
        })
        onChange(convertTemplateFleUrlControlName, "")
    }

    const fileName = field.value.FileName
    const isDefaultTemplate = !field.value.FileUrl

    return (
        <Form.Group controlId="formConvertTemplateControl" className={styles.convertTemplateControl}>
            <Form.Label>{t(`${tNamespace}convert-template-word`)}</Form.Label>
            <Row className={styles.convertTemplateControl__row}>
                <Col md="auto">
                    <a href={templateUrl} download={fileName} className="hidden" ref={downloadTemplateRef}>
                        Download: {fileName}
                    </a>
                    <a
                        href={templateUrl}
                        className={styles.convertTemplateControl__fakeLink}
                        onClick={e => {
                            e.preventDefault()
                            handleDownloadTemplate().then(() => setUploadState(AsyncState.createSuccess("")))
                        }}
                    >
                        {fileName}
                    </a>
                </Col>
                <Col>
                    {uploadState.inProcess ? (
                        <div
                            className={cn(
                                styles.convertTemplateControl__iconControl,
                                styles.convertTemplateControl__spinner
                            )}
                        >
                            <Spinner />
                        </div>
                    ) : (
                        <Row className={styles.convertTemplateControl__upload}>
                            <input
                                type="file"
                                accept={acceptFiles}
                                className="hidden"
                                onChange={handleFileChange}
                                ref={uploadInputRef}
                            />
                            <TooltipTrigger id="deleteFile" content={t(`${tTooltipNamespace}upload-convert-template`)}>
                                <div
                                    className={styles.convertTemplateControl__iconControl}
                                    onClick={handleUploadNewTemplate}
                                >
                                    <IconControl icon={faArrowToTop} className={styles.convertTemplateControl__icon} />
                                </div>
                            </TooltipTrigger>

                            {!isDefaultTemplate && (
                                <TooltipTrigger
                                    id="deleteFile"
                                    content={t(`${tTooltipNamespace}delete-convert-template`)}
                                >
                                    <div className={styles.convertTemplateControl__iconControl}>
                                        <IconControl
                                            icon={faTrash}
                                            className={styles.convertTemplateControl__icon}
                                            onClick={handleDeleteTemplate}
                                        />
                                    </div>
                                </TooltipTrigger>
                            )}
                        </Row>
                    )}
                </Col>
            </Row>
        </Form.Group>
    )
}

export default ConvertTemplateControl
