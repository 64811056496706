import React, { useMemo } from "react"
import "./ValidatableInputWithControl.scss"
import { Form, InputGroup } from "react-bootstrap"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import Toggle from "../Toggle/Toggle"
import AppendDropdownButton from "../AppendDropdownButton/AppendDropdownButton"
import { DebouncedFormControl } from "../ValidatableInput/DebouncedValidatableInput"
import { useField } from "formik"
import { useTranslation } from "react-i18next"

export interface ValidatableInputWithControlProps extends ClassProps {
    id: string
    name: string
    type?: string
    placeholder?: string
    readonly?: boolean
    plaintext?: boolean
    label?: string
    isEnabled?: boolean
    options?: string[]
    onToggle?: (e: React.ChangeEvent<HTMLInputElement>) => void
    selectName?: string
    labelCol?: string
}

const ValidatableInputWithControl: React.FC<ValidatableInputWithControlProps> = props => {
    const { id, label, className, isEnabled, onToggle, selectName, options, labelCol, ...controlProps } = props
    const [field, meta] = useField<any>(props)

    const { t } = useTranslation()
    const disabled = useMemo(() => (isEnabled === undefined ? false : !isEnabled), [isEnabled])
    const error = meta.touched && meta.error ? t(meta.error) : undefined
    return (
        <Form.Group
            className={cn(
                "validatable-input-with-control",
                error && "validatable-input-with-control_invalid",
                className
            )}
            controlId={id}
        >
            {label && <Form.Label>{label}</Form.Label>}
            <InputGroup>
                {isEnabled !== undefined && onToggle && (
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <Toggle id={`${id}-toggle`} icons={false} checked={isEnabled} onChange={onToggle} small />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                )}
                <DebouncedFormControl
                    {...controlProps}
                    disabled={disabled}
                    value={field.value}
                    onChange={field.onChange}
                    isInvalid={!!error}
                />
                {options && selectName && (
                    <AppendDropdownButton id={id} name={selectName} options={options} disabled={disabled} />
                )}
                <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
            </InputGroup>
        </Form.Group>
    )
}

export default ValidatableInputWithControl
