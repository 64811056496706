import React from "react"
import styles from "./ChannelIconLarge.module.scss"
import { ChannelTypeString } from "../../models/channel"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookMessenger, faOdnoklassnikiSquare, faSkype, faTelegram } from "@fortawesome/free-brands-svg-icons"
import { faComment, faEnvelope, faPhoneAlt } from "@fortawesome/pro-light-svg-icons"
import msTeamsLogo from "../../assets/images/msTeams.png"
import viberLogo from "../../assets/images/viber.png"
import whatsappLogo from "../../assets/images/whatsapp.png"
import vkLogo from "../../assets/images/vk.png"
import { ReactComponent as YandexDialogsLogo } from "../../assets/images/yandexDialogs.svg"
import { ReactComponent as YandexAlisaLogo } from "../../assets/images/yandexAlisa.svg"
import cn from "classnames"
import instagramLogo from "../../assets/images/instagram.png"

export interface ChannelIconLargeProps {
    type: string
    className?: string
}

const baseClassName = styles.channelIconLarge

const ChannelIconLarge: React.FC<ChannelIconLargeProps> = props => {
    const { type = ChannelTypeString.WebChat, className } = props
    const mapTypeToClassName = (type: string) => `${type}-icon`

    const getIconClassName = (iconClassName?: string) =>
        cn(baseClassName, iconClassName ? iconClassName : mapTypeToClassName(type), className)
    const iconImageClasName = (iconClassName?: string) =>
        cn(getIconClassName(iconClassName), styles.channelIconLarge_img)

    if (type.startsWith(ChannelTypeString.Whatsapp)) {
        return <img src={whatsappLogo} alt="" className={iconImageClasName()} />
    }

    switch (type) {
        case ChannelTypeString.WebChat:
            return <FontAwesomeIcon icon={faComment} className={getIconClassName(styles.webchatIcon)} />
        case ChannelTypeString.Phone:
            return <FontAwesomeIcon icon={faPhoneAlt} className={getIconClassName()} />
        case ChannelTypeString.Telegram:
            return <FontAwesomeIcon icon={faTelegram} className={getIconClassName(styles.telegramIcon)} />
        case ChannelTypeString.Viber:
            return <img src={viberLogo} alt="" className={iconImageClasName()} />
        case ChannelTypeString.Skype:
            return <FontAwesomeIcon icon={faSkype} className={getIconClassName()} />
        case ChannelTypeString.Email:
            return <FontAwesomeIcon icon={faEnvelope} className={getIconClassName()} />
        case ChannelTypeString.VK:
            return <img src={vkLogo} alt="" className={iconImageClasName()} />
        case ChannelTypeString.Odnoklassniki:
            return (
                <FontAwesomeIcon icon={faOdnoklassnikiSquare} className={getIconClassName(styles.odnoklassnikiIcon)} />
            )
        case ChannelTypeString.Facebook:
            return <FontAwesomeIcon icon={faFacebookMessenger} className={getIconClassName(styles.facebookIcon)} />
        case ChannelTypeString.MSBot:
        case ChannelTypeString.Teams:
            return <img src={msTeamsLogo} alt="" className={iconImageClasName(styles.teamsIcon)} />
        case ChannelTypeString.YandexAlisa:
            return <YandexAlisaLogo className={getIconClassName()} />
        case ChannelTypeString.YandexDialogs:
            return <YandexDialogsLogo className={getIconClassName()} />
        case ChannelTypeString.Instagram:
            return <img src={instagramLogo} alt="" className={iconImageClasName(styles.instagramIcon)} />
        default:
            return <FontAwesomeIcon icon={faEnvelope} className={getIconClassName()} />
    }
}

export default ChannelIconLarge
