import React from "react"
import { ClassProps } from "../../utility/common/props"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faBallotCheck,
    faBracketsCurly,
    faCog,
    faComment,
    faCommentAlt,
    faFileInvoice,
    faUserFriends,
    faStickyNote,
    faUsers,
    faCommentAltExclamation,
    faUsersCog,
    faChartBar
} from "@fortawesome/pro-light-svg-icons"
import { SettingsType } from "../../pages/ProjectSettings/ProjectSettings"
import { ReactComponent as ClassifiersLogo } from "../../assets/images/classifiers/nlu.svg"

interface MenuIconProps extends ClassProps {
    menuId: string
}

const ProjectSettingsMenuIcon: React.FC<MenuIconProps> = props => {
    const { menuId, className } = props
    switch (menuId) {
        case SettingsType.general:
            return <FontAwesomeIcon icon={faCog} className={className} />
        case SettingsType.channels:
        case SettingsType.channelScenario:
            return <FontAwesomeIcon icon={faComment} className={className} />
        case SettingsType.agents:
            return <FontAwesomeIcon icon={faCommentAlt} className={className} />
        case SettingsType.classifiers:
            return <ClassifiersLogo className={className} />
        case SettingsType.users:
            return <FontAwesomeIcon icon={faUsers} className={className} />
        case SettingsType.roles:
            return <FontAwesomeIcon icon={faUserFriends} className={className} />
        case SettingsType.surveys:
            return <FontAwesomeIcon icon={faFileInvoice} className={className} />
        case SettingsType.slots:
            return <FontAwesomeIcon icon={faBracketsCurly} className={className} />
        case SettingsType.stickers:
            return <FontAwesomeIcon icon={faStickyNote} className={className} />
        case SettingsType.articleTypes:
            return <FontAwesomeIcon icon={faBallotCheck} className={className} />
        case SettingsType.dialog:
            return <FontAwesomeIcon icon={faCommentAltExclamation} className={className} />
        case SettingsType.operator:
            return <FontAwesomeIcon icon={faUsersCog} className={className} />
        case SettingsType.statistics:
            return <FontAwesomeIcon icon={faChartBar} className={className} />
        case SettingsType.system:
            return <FontAwesomeIcon icon={faCog} className={className} />
        default:
            return null
    }
}

export default ProjectSettingsMenuIcon
