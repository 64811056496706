import { format, formatDistance, formatDistanceStrict, formatDistanceToNow } from "date-fns"
import { ru, enGB } from "date-fns/locale"
import i18next from "i18next"
import { getDateLocale } from "../../locales"

const dateLocales: { [key: string]: Locale } = {
    enGB,
    ru,
    "ru-RU": ru,
    en: enGB
}

export const formatDistanceLocal = (date: Date | number, baseDate: Date | number) =>
    formatDistance(date, baseDate, {
        locale: dateLocales[i18next.language]
    })

export const formatDistanceToNowLocal = (date: Date | number) =>
    formatDistanceToNow(date, {
        locale: dateLocales[i18next.language]
    })

export const formatDistanceStrictLocal = (date: Date | number) =>
    formatDistanceStrict(date, Date.now(), {
        locale: dateLocales[i18next.language]
    })

export const formatMillisecondsToFullDate = (ms: number) => {
    const locale = { locale: getDateLocale(i18next.language) }
    return format(ms, "dd-MM-yyyy HH:mm:ss", locale)
}

export default formatDistanceLocal
