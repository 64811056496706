import React, { useCallback, useMemo } from "react"
import { nameof2 } from "../../../utility/common/nameof"
import { OperatorValues, WebChatChannelValues } from "../../../models/webChatValues"
import { Form } from "react-bootstrap"
import FormAccordion from "../../FormAccordion/FormAccordion"
import { useTranslation } from "react-i18next"
import Slider from "../../Slider/Slider"
import { ParamValues } from "../../../models/parameterDeclaration"
import { useFormikContext } from "formik"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import DebouncedCheckBox from "../../CheckBoxValidatableInput/DebouncedCheckBox"

const tNamespace = "channel:form."
const tTemplateNamespace = "channel:operator-name-template."
const tTimeNamespace = "common:time."

const WebChatOperatorSettings: React.FC = () => {
    const { t } = useTranslation()
    const { values, setFieldValue } = useFormikContext<WebChatChannelValues>()

    const operatorNameTemplateOptions = useMemo(
        () => [
            { value: "firstname", label: t(`${tTemplateNamespace}firstname`) },
            { value: "lastname", label: t(`${tTemplateNamespace}lastname`) },
            {
                value: "firstname+lastname",
                label: t(`${tTemplateNamespace}firstname+lastname`)
            },
            {
                value: "lastname+firstname",
                label: t(`${tTemplateNamespace}lastname+firstname`)
            }
        ],
        [t]
    )

    const handleOperatorIsTypingTimeoutChange = useCallback(
        (v: number) => {
            setFieldValue(
                nameof2<WebChatChannelValues, OperatorValues>("OperatorSettings", "operatorIsTypingTimeout"),
                v,
                false
            )
        },
        [setFieldValue]
    )

    return (
        <FormAccordion title={t(`${tNamespace}operator`)} className="webchat-form__accordion-with-slider">
            <DebouncedCheckBox
                id="formShowOperatorName"
                name={nameof2<WebChatChannelValues, OperatorValues>("OperatorSettings", "showOperatorName")}
                label={t(`${tNamespace}show-operator-name`)}
            />
            <DebouncedCheckBox
                id="formDisableOperatorImage"
                name={nameof2<WebChatChannelValues, OperatorValues>("OperatorSettings", "disableOperatorImage")}
                label={t(`${tNamespace}hide-operator-image`)}
            />
            <DebouncedValidatableInput
                id="formOperatorNameTemplate"
                as="select"
                name={nameof2<WebChatChannelValues, OperatorValues>("OperatorSettings", "operatorNameTemplate")}
                label={t(`${tNamespace}operator-data-display`)}
            >
                {operatorNameTemplateOptions.map(option => (
                    <option value={option.value} key={option.value}>
                        {option.label}
                    </option>
                ))}
            </DebouncedValidatableInput>
            <DebouncedCheckBox
                id="formShowConnectedOperatorNotify"
                name={nameof2<WebChatChannelValues, OperatorValues>("OperatorSettings", "showConnectedOperatorNotify")}
                label={t(`${tNamespace}operator-connection-notification`)}
            />
            <DebouncedCheckBox
                id="formShowFinishDialogByOperatorMessage"
                name={nameof2<WebChatChannelValues, OperatorValues>(
                    "OperatorSettings",
                    "showFinishDialogByOperatorMessage"
                )}
                label={t(`${tNamespace}operator-termination-notification`)}
            />
            <Form.Group controlId="formOperatorIsTypingTimeout">
                <Form.Label>{t(`${tNamespace}time-before-hiding-notification-operator-typing`)}</Form.Label>
                <Slider
                    min={0}
                    max={20}
                    step={1}
                    marks={{
                        0: `0 ${t(`${tTimeNamespace}sec`)}`,
                        20: `20 ${t(`${tTimeNamespace}sec`)}`
                    }}
                    onChange={handleOperatorIsTypingTimeoutChange}
                    value={values.OperatorSettings.operatorIsTypingTimeout}
                />
            </Form.Group>
            <DebouncedCheckBox
                id="formReplaceOperatorName"
                name={nameof2<WebChatChannelValues, ParamValues>("Params", "replace_operator_name")}
                label={t(`${tNamespace}not-change-operator-data-when-connecting-new`)}
            />
        </FormAccordion>
    )
}

export default WebChatOperatorSettings
