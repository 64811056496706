import React from "react"
import { ChannelFormProps } from "../ChannelForm/ChannelForm"
import { FormikProps } from "formik"
import { ChannelValues, WhatsAppChannelValues } from "../../models/channelValues"
import { WithT } from "i18next"
import { shallowEqual, useSelector } from "react-redux"
import { selectCreateChannelState, selectUpdateChannelState } from "../../store/channels/selectors"
import { nameof2 } from "../../utility/common/nameof"
import { ParamValues } from "../../models/parameterDeclaration"
import InfoIcon from "../InfoIcon/InfoIcon"
import DebouncedValidatableInput from "../ValidatableInput/DebouncedValidatableInput"
import ChannelFormWrapper from "../ChannelFormWrapper/ChannelFormWrapper"
import CheckBoxValidatableInput from "../CheckBoxValidatableInput/CheckBoxValidatableInput"

const tNamespace = "channel:form."
const tTooltipNamespace = "channel:tooltip."

const FormikEmailChannelFormAppearance: React.FC<ChannelFormProps & FormikProps<WhatsAppChannelValues> & WithT> =
    props => {
        const { channel, t, handleSubmit, disabled } = props

        const asyncState = useSelector(channel ? selectUpdateChannelState : selectCreateChannelState, shallowEqual)

        return (
            <ChannelFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={asyncState.inProcess}>
                <div className="channel-form__section">
                    <div className="channel-form__section-subtitle">{t(`${tNamespace}outgoing-dialogs`)}</div>
                    <DebouncedValidatableInput
                        id="formSenderName"
                        type="text"
                        name={nameof2<ChannelValues, ParamValues>("Params", "sender_name")}
                        label={t(`${tNamespace}sender-name`)}
                    />
                    <DebouncedValidatableInput
                        id="formCC"
                        type="text"
                        name={nameof2<ChannelValues, ParamValues>("Params", "CC")}
                        label="CC"
                    />
                    <DebouncedValidatableInput
                        id="formBCC"
                        type="text"
                        name={nameof2<ChannelValues, ParamValues>("Params", "BCC")}
                        label="BCC"
                    />
                    <CheckBoxValidatableInput
                        id="formMergeToEmailsWithCcInEmailSlot"
                        name={nameof2<ChannelValues, ParamValues>("Params", "merge_to_emails_with_cc_in_email_slot")}
                        label={t(`${tNamespace}merge-to-emails-with-cc-in-email-slot`)}
                    />
                    <DebouncedValidatableInput
                        id="formSubject"
                        type="text"
                        name={nameof2<ChannelValues, ParamValues>("Params", "subject")}
                        label={t(`${tNamespace}email-subject`)}
                    />
                    <DebouncedValidatableInput
                        id="formMessageTemplate"
                        as="textarea"
                        name={nameof2<ChannelValues, ParamValues>("Params", "message_template")}
                        label={t(`${tNamespace}message-template`)}
                        className="channel-form__textarea"
                        icon={
                            <InfoIcon
                                id="popoverPreprocessorWebHook"
                                title={t(`${tNamespace}message-template`)}
                                content={t(`${tTooltipNamespace}message-template`)}
                            />
                        }
                    />
                    <DebouncedValidatableInput
                        id="formSignature"
                        as="textarea"
                        name={nameof2<ChannelValues, ParamValues>("Params", "signature")}
                        label={t(`${tNamespace}automatic-signature`)}
                        className="channel-form__textarea"
                        icon={
                            <InfoIcon
                                id="popoverPreprocessorWebHook"
                                title={t(`${tNamespace}automatic-signature`)}
                                content={t(`${tTooltipNamespace}automatic-signature`)}
                            />
                        }
                    />
                </div>
            </ChannelFormWrapper>
        )
    }

export default FormikEmailChannelFormAppearance
