import { MoveTaskRequest, RerouteTaskRequest, TaskOfAutomaticProcessing, SwitchTaskRequest } from "../../models/task"
import { apiInstance, apiInstanceV1 } from "../instances"

const tasksController = {
    move: (tenantId: string, request: MoveTaskRequest): Promise<boolean> =>
        apiInstance.post(`/${tenantId}/tasks/move`, request).then(response => response.data),
    reroute: (request: RerouteTaskRequest): Promise<void> =>
        apiInstance.post(`/tasks/reroute`, request).then(response => response.data),
    getAutomaticProcessing: (tenantId: string): Promise<TaskOfAutomaticProcessing[]> =>
        apiInstance.get(`/${tenantId}/tasks/automatic-processing`).then(response => response.data),
    switch: (request: SwitchTaskRequest) =>
        apiInstanceV1
            .post(
                "dialogs/switch_to_operator",
                {},
                {
                    params: { dialogId: request.TaskId, operatorId: request.OperatorId, queueId: request.QueueId }
                }
            )
            .then(response => response.data)
}

export default tasksController
