import React, { useCallback, useState } from "react"
import { nameof2 } from "../../../utility/common/nameof"
import { ChannelValues } from "../../../models/channelValues"
import { ParamValues } from "../../../models/parameterDeclaration"
import { formTranslation } from "../../../locales/form"
import ValidatableInputWithControl from "../../ValidatableInputWithControl/ValidatableInputWithControl"
import { useFormikContext } from "formik"
import { WithT } from "i18next"

const tNamespace = "channel:form."

interface Props extends WithT {
    filenameRegex?: string
}

const FileNameRegexControl: React.FC<Props> = props => {
    const { filenameRegex, t } = props
    const { setFieldValue } = useFormikContext<ChannelValues>()

    const [filenameRegexEnabled, setFilenameRegexEnabled] = useState(!!filenameRegex)

    const handleAllowedFileNameRegexToggle = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const checked = e.target.checked
            setFilenameRegexEnabled(checked)

            if (!checked) {
                setFieldValue(nameof2<ChannelValues, ParamValues>("Params", "filename_regex"), "", false)
            }
        },
        [setFieldValue]
    )

    return (
        <ValidatableInputWithControl
            id="formFilenameRegex"
            type="text"
            name={nameof2<ChannelValues, ParamValues>("Params", "filename_regex")}
            label={t(`${tNamespace}files-allowed-for-upload`)}
            isEnabled={filenameRegexEnabled}
            onToggle={handleAllowedFileNameRegexToggle}
            placeholder={t(formTranslation.enterRegex)}
        />
    )
}

export default FileNameRegexControl
