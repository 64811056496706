import { ButtonAttribute } from "./buttonAttributes/buttonAttribute"
import { ButtonType, validateButtonType } from "./buttonAttributes/buttonType"
import { ButtonVisible, validateButtonVisible } from "./buttonAttributes/buttonVisible"
import { ButtonColor, validateButtonColor } from "./buttonAttributes/buttonColor"
import { ButtonImageAlign, validateButtonImageAlign } from "./buttonAttributes/buttonImageAlign"
import { getId } from "../../scenario/scenario"
import { KeyboardParser } from "../keyboardParser/keyboardParser"

type Attributes = {
    [ButtonAttribute.Type]: ButtonType
    [ButtonAttribute.Action]: string
    [ButtonAttribute.Visible]: ButtonVisible
    [ButtonAttribute.Color]: ButtonColor
    [ButtonAttribute.Image]: string
    [ButtonAttribute.ImageAlign]: ButtonImageAlign
    [ButtonAttribute.ImageEmoji]: string
    [ButtonAttribute.Id]: string
    [ButtonAttribute.IsExternal]: string
}

export interface MarkdownButton extends Attributes {
    BodyMarkdown: string
    Row: number
}

export const defaultAttributes = {
    [ButtonAttribute.Type]: ButtonType.Action,
    [ButtonAttribute.Action]: "",
    [ButtonAttribute.IsExternal]: "false",
    [ButtonAttribute.Visible]: ButtonVisible.Visible,
    [ButtonAttribute.Color]: ButtonColor.Primary,
    [ButtonAttribute.Image]: "",
    [ButtonAttribute.ImageAlign]: ButtonImageAlign.Left,
    [ButtonAttribute.ImageEmoji]: "",
    [ButtonAttribute.Id]: ""
}

export class ButtonsParser {
    static _buildAttributes(parsed: string[], attributes?: Attributes): Attributes {
        const [head, ...tail] = parsed

        if (!attributes) {
            attributes = {
                ...defaultAttributes,
                [ButtonAttribute.Id]: getId("btn")
            }
        }

        if (!head || !tail) return attributes

        const [attrType, ...attrValue] = head.split(":")
        const value = attrValue.join(":")
        if (!attrType || !value) return attributes

        const attributesCopy = Object.assign({}, attributes)
        switch (attrType) {
            case ButtonAttribute.Type:
                const t = validateButtonType(value)
                attributesCopy[ButtonAttribute.Type] = t
                break
            case ButtonAttribute.Action:
                attributesCopy[ButtonAttribute.Action] = value
                break
            case ButtonAttribute.Visible:
                attributesCopy[ButtonAttribute.Visible] = validateButtonVisible(value)
                break
            case ButtonAttribute.Color:
                attributesCopy[ButtonAttribute.Color] = validateButtonColor(value)
                break
            case ButtonAttribute.Image:
                attributesCopy[ButtonAttribute.Image] = value
                break
            case ButtonAttribute.ImageAlign:
                attributesCopy[ButtonAttribute.ImageAlign] = validateButtonImageAlign(value)
                break
            case ButtonAttribute.ImageEmoji:
                attributesCopy[ButtonAttribute.ImageEmoji] = value
                break
            case ButtonAttribute.Id:
                attributesCopy[ButtonAttribute.Id] = value
                break
            case ButtonAttribute.IsExternal:
                attributesCopy[ButtonAttribute.IsExternal] = value
                break
            default:
                break
        }
        return ButtonsParser._buildAttributes(tail, attributesCopy)
    }

    static getButtons(markdown?: string): MarkdownButton[] {
        return KeyboardParser.getBlocks(this._buildAttributes, markdown)
    }
}
