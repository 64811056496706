import React from "react"
import styles from "./InfoFieldLoader.module.scss"
import Skeleton from "../Skeleton/Skeleton"
import useDelay from "../../utility/common/useDelay"

const InfoFieldLoader: React.FC = () => {
    const display = useDelay()

    if (!display) {
        return null
    }

    return (
        <div className={styles.infoFieldLoader}>
            <div className={styles.infoFieldLoader__name}>{<Skeleton />}</div>
            <div className={styles.infoFieldLoader__value}>{<Skeleton />}</div>
        </div>
    )
}

export default InfoFieldLoader
