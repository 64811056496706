import React, { useCallback, useEffect, useState } from "react"
import styles from "./Slots.module.scss"
import SlotSettingsTable from "../../components/SlotSettings/SlotSettingsTable/SlotSettingsTable"
import { GetSlotPageRequest, SlotDto } from "../../models/slot"
import { useTranslation } from "react-i18next"
import AddButton from "../../components/AddButton/AddButton"
import SearchInput from "../../components/SearchInput/SearchInput"
import useAsyncState from "../../utility/common/useAsyncState"
import slotController from "../../api/controllers/slot"
import { GET_SLOT_PAGE_FAILED } from "../../store/slots/constants"

export const SLOTS_PER_PAGE = 50

interface Props {
    projectId: string
    title: string
    onCreate: (callback: () => void) => () => void
    onSettingsClick: (onUpdate: (slot: SlotDto) => void) => (slot: SlotDto) => void
    onDelete: (slotId: string, callback: () => void) => void
}

const Slots: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { projectId, title, onCreate, onSettingsClick, onDelete } = props

    const [currentPageIndex, setCurrentPageIndex] = useState(0)
    const [searchValue, setSearchValue] = useState("")

    const [data, setData] = useState<SlotDto[]>([])
    const [recordsTotal, setRecordsTotal] = useState(0)

    const handlePageChange = useCallback((pageIndex: number, searchValue = "") => {
        setCurrentPageIndex(pageIndex)
        setSearchValue(searchValue)
    }, [])

    const loadPage = useCallback(() => {
        const offset = SLOTS_PER_PAGE * currentPageIndex
        const request: GetSlotPageRequest = {
            PageFrom: offset,
            PageSize: SLOTS_PER_PAGE
        }
        if (searchValue) {
            request.Search = searchValue
        }
        return slotController.getSlotPage(projectId, request)
    }, [projectId, currentPageIndex, searchValue])

    const { asyncState: pageState, reload } = useAsyncState(loadPage, GET_SLOT_PAGE_FAILED)

    const handleUpdateSlot = (updatedSlot: SlotDto) => {
        setData(data.map(s => (s.Id === updatedSlot.Id ? updatedSlot : s)))
    }

    const handleDeleteSlot = (slotId: string) => {
        onDelete(slotId, reload)
    }

    useEffect(() => {
        const data = pageState.data
        if (data) {
            setData(data.Data)
            setRecordsTotal(data.Filtered)
        }
    }, [pageState])

    if (!projectId) return null

    return (
        <div className={styles.slots}>
            <div className={styles.slots__header}>
                <span className={styles.slots__title}>{title}</span>
                <AddButton
                    variant="outline-primary"
                    className={styles["slots__btn-add"]}
                    text={t("project:add-slot")}
                    onClick={onCreate(reload)}
                    disabled={pageState.inProcess}
                />
            </div>
            <SearchInput onChange={searchValue => handlePageChange(0, searchValue)} />
            <SlotSettingsTable
                slots={data}
                onSettingsClick={onSettingsClick(handleUpdateSlot)}
                onDelete={handleDeleteSlot}
                onPageChange={handlePageChange}
                recordsTotal={recordsTotal}
                pageIndex={currentPageIndex}
                dataState={pageState}
            />
        </div>
    )
}

export default Slots
