import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { GlobalUser } from "../../models/globalUser"
import Async from "../Async/Async"
import { selectAllUsersState } from "../../store/users/selectors"
import { getAllUsers } from "../../store/users/thunks"
import GlobalUsers from "./GlobalUsers"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import { selectAllGlobalPermissionsState } from "../../store/roles/selectors"
import { getAllGlobalPermissions } from "../../store/roles/thunks"
import SettingsTableLoader from "../SettingsTable/SettingsTableLoader"
import AsyncState from "../../core/asyncState"

interface Props {
    onSettingsClick: (user: GlobalUser, allGlobalPermissions: string[]) => void
}

const GlobalUsersContainer: React.FC<Props> = props => {
    const { onSettingsClick } = props

    const dispatch = useDispatch()

    const apiState = AsyncState.merge2(useSelector(selectAllUsersState), useSelector(selectAllGlobalPermissionsState))

    useEffect(() => {
        dispatch(getAllUsers())
        dispatch(getAllGlobalPermissions())
    }, [dispatch])

    return (
        <Async
            dataState={apiState}
            processView={<SettingsTableLoader columns={3} rows={2} rowHeight={65} />}
            errorView={({ message }) => <ErrorMessage text={message} />}
        >
            {({ data: [users, allGlobalPermissions] }) => (
                <GlobalUsers users={users} onSettingsClick={v => onSettingsClick(v, allGlobalPermissions)} />
            )}
        </Async>
    )
}

export default GlobalUsersContainer
