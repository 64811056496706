import React, { ChangeEvent, KeyboardEvent } from "react"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import { faSearch, faTimes } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LoadingButton from "../LoadingButton/LoadingButton"
import { Button, FormControl, FormCheck } from "react-bootstrap"
import { formTranslation } from "../../locales/form"
import "./SearchArticlesInput.scss"
import { WithT } from "i18next"

const tNamespace = "knowledgeBase:search-articles."

export interface SearchArticlesInputProps extends ClassProps, WithT {
    query: string
    enableRemoved: boolean
    loading: boolean
    onSearch: () => void
    onClear: () => void
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
    onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void
    onEnableRemoved: () => void
    isFiltersEnabled: boolean
}

const SearchArticlesInput: React.FC<SearchArticlesInputProps> = props => {
    const {
        t,
        query,
        loading,
        onSearch,
        onClear,
        onChange,
        onKeyDown,
        className,
        enableRemoved,
        onEnableRemoved,
        isFiltersEnabled
    } = props

    return (
        <div className="search-articles-input-container">
            <div className={cn("search-articles-input", className)}>
                <LoadingButton
                    className="search-articles-input__search"
                    loading={loading}
                    variant="link"
                    disabled={!query}
                    onClick={onSearch}
                >
                    <FontAwesomeIcon icon={faSearch} size="1x" />
                </LoadingButton>

                <FormControl
                    type="text"
                    name="search-articles"
                    value={query}
                    onKeyDown={onKeyDown}
                    onChange={onChange}
                    placeholder={t(formTranslation.search)}
                    className="search-articles-input__query"
                />

                {(query || isFiltersEnabled) && !loading && (
                    <Button className="search-articles-input__clear" variant="link" onClick={onClear}>
                        <div className="search-articles-input__clear-icon">
                            <FontAwesomeIcon icon={faTimes} size="1x" />
                        </div>
                    </Button>
                )}
            </div>
            {query && (
                <FormCheck
                    id="enable-removed"
                    label={t(`${tNamespace}enable-removed`)}
                    checked={enableRemoved}
                    onChange={onEnableRemoved}
                    className="search-articles-input-container__enable-removed"
                />
            )}
        </div>
    )
}

export default SearchArticlesInput
