import { Article, ArticleQuestion } from "../../models/article"
import { Dispatch } from "../../utility/common/storeHelper"
import { actions } from "./slice"

export const revertArticleChanges = (article: Article, dispatch: Dispatch, currentAnswerId?: string) => {
    dispatch(actions.updateArticleAnswersForm({ Answers: article.Answers ?? [] }))
    currentAnswerId && dispatch(actions.updateCurrentAnswerById(currentAnswerId))
    dispatch(actions.updateArticleFormQuestions(article.Questions ?? []))
}

export const handleAddArticleFormQuestions = (questions: string[], dispatch: Dispatch) =>
    dispatch(actions.addArticleFormQuestions(questions))

export const handleUpdateArticleFormQuestions = (questions: ArticleQuestion[], dispatch: Dispatch) =>
    dispatch(actions.updateArticleFormQuestions(questions))

export const handleRemoveArticleFormQuestions = (questionIds: string[], dispatch: Dispatch) =>
    dispatch(actions.removeArticleFormQuestions(questionIds))

export const setEmptyDefaultArticle = (dispatch: Dispatch) => dispatch(actions.getDefaultArticleSuccess(undefined))

export const setEditedArticleExtId = (dispatch: Dispatch, newExtId = "") =>
    dispatch(actions.updateEditedArticleExtId(newExtId))

export const setEditedArticleSurvey = (dispatch: Dispatch, newSurvey: string[] = []) =>
    dispatch(actions.updateEditedArticleSurvey(newSurvey))
