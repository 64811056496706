import { OperatorJoinedTheProjectMsg } from "../../models/projectUser"
import { reloadProjectUsers } from "../../store/users/thunks"
import { logError } from "../../utility/common/logError"
import { Hub } from "../hub"
import { IHub } from "../interfaces/IHub"
import { Dispatch } from "../../utility/common/storeHelper"
import { setOperatorStatusUpdated } from "../../store/userOperator/thunks"
import { reloadUserAfterSettingsUpdate } from "../../store/users/thunks"
import { reloadProjects } from "../../store/projects/thunks"
import i18n from "../../i18n"
import { deleteNotificationNewsWithoutLoading, saveNotificationNews } from "../../store/notifications/thunks"
import { isNotificationDto } from "../../models/notificationDto"
import { Store } from "../../store/store"
import { isSelectedOperatorStatusDto } from "../../models/operatorStatusDto"

const OPERATOR_STATUS_UPDATED = "OperatorStatusUpdated"
const OPERATORS_SETTINGS_UPDATED = "OperatorsSettingsUpdated"
const OPERATOR_REMOVED_FROM_PROJECT = "OperatorRemovedFromProject"
const OPERATOR_ADDED_TO_PROJECT = "OperatorAddedToProject"
const OPERATOR_JOINED_THE_PROJECT = "OperatorJoinedTheProject"
const OPERATOR_GOT_NEWS = "OperatorGotNews"
const OPERATOR_APPROVE_NEWS = "OperatorApproveNews"

const REMOVED_FROM_PROJECT_KEY = "info:users:removed-from-project"
const ADDED_TO_PROJECT_KEY = "info:users:added-to-project"
const OPERATOR_JOINED_THE_PROJECT_KEY = "info:users:user-joined-the-project"

class UserHub {
    private _hub: IHub

    constructor(store: Store) {
        this._hub = new Hub(`/users-hub`)
        this.registerServerEvents(store.dispatch)
    }

    async connect() {
        await this._hub.connect()
        await this._hub.subscribe("Subscribe")
        this._hub.reconnectedCallback = async () => {
            await this._hub.subscribe("Subscribe")
        }
    }

    async disconnect() {
        await this._hub.unsubscribe("Unsubscribe")
        await this._hub.disconnect()
    }

    private registerServerEvents(dispatch: Dispatch) {
        this._hub.registerEvent(OPERATOR_STATUS_UPDATED, (updatedStatusRow: string) => {
            const data = JSON.parse(updatedStatusRow)
            if (isSelectedOperatorStatusDto(data)) {
                dispatch(setOperatorStatusUpdated(data))
            } else {
                logError("Received operator status is not belong to the type. Data:", data)
            }
        })
        this._hub.registerEvent(OPERATORS_SETTINGS_UPDATED, (projectId?: string) => {
            dispatch(reloadUserAfterSettingsUpdate(projectId))
        })
        this._hub.registerEvent(OPERATOR_REMOVED_FROM_PROJECT, (projectName: string) => {
            dispatch(
                reloadProjects({
                    Title: {
                        Value: `${i18n.t(REMOVED_FROM_PROJECT_KEY)} "${projectName}"`,
                        NeedLocalization: false
                    }
                })
            )
        })
        this._hub.registerEvent(OPERATOR_ADDED_TO_PROJECT, (projectName: string) => {
            dispatch(
                reloadProjects({
                    Title: {
                        Value: `${i18n.t(ADDED_TO_PROJECT_KEY)} "${projectName}"`,
                        NeedLocalization: false
                    }
                })
            )
        })
        this._hub.registerEvent(OPERATOR_JOINED_THE_PROJECT, (msgStr: string) => {
            const msg = JSON.parse(msgStr) as OperatorJoinedTheProjectMsg
            dispatch(
                reloadProjectUsers(msg.ProjectId, {
                    Title: {
                        Value: `${msg.OperatorEmail} ${i18n.t(OPERATOR_JOINED_THE_PROJECT_KEY)} "${msg.ProjectName}"`,
                        NeedLocalization: false
                    }
                })
            )
        })
        this._hub.registerEvent(OPERATOR_GOT_NEWS, (data: string) => {
            let news = JSON.parse(data)
            if (isNotificationDto(news)) {
                saveNotificationNews(dispatch, news)
            } else {
                logError("Received notification data is not belong to news type. Data:", news)
            }
        })
        this._hub.registerEvent(OPERATOR_APPROVE_NEWS, (newsId: string) => {
            deleteNotificationNewsWithoutLoading(dispatch, newsId)
        })
    }
}

export default UserHub
