import React, { ReactNode } from "react"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import { useTranslation } from "react-i18next"
import { Form } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import LoadingButton from "../LoadingButton/LoadingButton"
import { formTranslation } from "../../locales/form"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import { testId } from "../../utility/tests/testId"

const tNamespace = "channel:form."

interface Props extends ClassProps {
    onSubmit: (e?: React.FormEvent<HTMLFormElement>) => void
    disabled?: boolean
    testDisabled?: boolean
    loading?: boolean
    onTest?: () => void
    footer?: ReactNode
}

const ChannelFormWrapper: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { onSubmit, onTest, disabled, testDisabled, loading, className, children, footer } = props

    return (
        <Form className={cn("channel-form", className)} onSubmit={onSubmit} onKeyPress={preventSubmitOnEnter}>
            <div className="channel-form__content">{children}</div>
            <div className="channel-form__footer">
                {footer}
                {onTest && (
                    <Button
                        variant="light"
                        onClick={onTest}
                        disabled={testDisabled}
                        className="channel-form__test-chat-button"
                        data-test-id={testId.testChat}
                    >
                        {t(`${tNamespace}test-chat`)}
                    </Button>
                )}
                <LoadingButton
                    type="submit"
                    loading={loading}
                    variant="primary"
                    disabled={disabled}
                    block
                    data-test-id={testId.saveChannel}
                >
                    {t(formTranslation.save)}
                </LoadingButton>
            </div>
        </Form>
    )
}

export default ChannelFormWrapper
