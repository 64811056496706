import React from "react"
import styles from "./FormikRouteTaskForm.module.scss"
import { useFormikContext } from "formik"
import { FormikValues } from "formik/dist/types"
import { Button, Form } from "react-bootstrap"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import { WithT } from "i18next"
import { formTranslation } from "../../locales/form"
import AsyncState from "../../core/asyncState"

const tNamespace = "task:form."

interface RouteTaskFormBaseProps extends ClassProps, WithT {
    state: AsyncState<void | boolean>
    children: React.ReactNodeArray | React.ReactNode
    onCancel: () => void
}

function RouteTaskFormBase<TValues extends FormikValues>(props: RouteTaskFormBaseProps) {
    const { children, className, t, state, onCancel } = props
    const { handleSubmit } = useFormikContext<TValues>()

    return (
        <Form className={cn(styles.routeTaskForm, className)} onSubmit={handleSubmit}>
            <div className={styles.routeTaskForm__options}>{children}</div>
            <div className={styles.routeTaskForm__buttons}>
                <Button
                    className={styles.routeTaskForm__button}
                    variant="light"
                    onClick={onCancel}
                    disabled={state.inProcess}
                >
                    {t(formTranslation.cancel)}
                </Button>
                <Button
                    className={styles.routeTaskForm__button}
                    type="submit"
                    variant="primary"
                    disabled={state.inProcess}
                >
                    {t(`${tNamespace}route`)}
                </Button>
            </div>
        </Form>
    )
}

export default RouteTaskFormBase
