import { QuickAction, QuickActionAttribute, QuickActionType } from "./quickActionAttribute"
import { KeyboardParser } from "../keyboardParser/keyboardParser"
import { isChannelTypeExists } from "../../channels/channelValidation"
import { ChannelTypeString } from "../../../models/channel"

type Attributes = {
    [QuickActionAttribute.Action]?: string
    [QuickActionAttribute.Url]?: string
    [QuickActionAttribute.SubTitle]: string
    [QuickActionAttribute.ButtonTitle]: string
    [QuickActionAttribute.Icon]?: string
    [QuickActionAttribute.ChannelType]?: string
    [QuickActionAttribute.ChannelId]?: string
}

export interface ParsedQuickAction extends Attributes {
    BodyMarkdown: string
    Row: number
}

const defaultAttributes: Attributes = {
    [QuickActionAttribute.SubTitle]: "",
    [QuickActionAttribute.ButtonTitle]: ""
}

export class QuickActionParser {
    static _buildAttributes(parsedAttributes: string[], attributes?: Attributes): Attributes {
        const [head, ...tail] = parsedAttributes

        if (!attributes) {
            attributes = { ...defaultAttributes }
        }

        if (!head || !tail) return attributes

        const attrType = head.slice(0, head.indexOf(":"))
        const rawValue = head.slice(head.indexOf(":") + 1)

        if (!attrType || !rawValue) return attributes

        const value = rawValue.replaceAll(/^"|"$/g, "")

        let attributesCopy = Object.assign({}, attributes)
        switch (attrType) {
            case QuickActionAttribute.Action:
            case QuickActionAttribute.SubTitle:
            case QuickActionAttribute.ButtonTitle:
            case QuickActionAttribute.Icon:
            case QuickActionAttribute.Url:
            case QuickActionAttribute.ChannelId:
                attributesCopy[attrType] = value
                break
            case QuickActionAttribute.ChannelType:
                attributesCopy[attrType] = value.toLowerCase()
                break
            default:
                break
        }

        return QuickActionParser._buildAttributes(tail, attributesCopy)
    }

    static _processQuickAction(quickAction: ParsedQuickAction): QuickAction | null {
        const base = {
            Row: quickAction.Row,
            Title: quickAction.BodyMarkdown,
            SubTitle: quickAction[QuickActionAttribute.SubTitle],
            ButtonTitle: quickAction[QuickActionAttribute.ButtonTitle],
            Icon: quickAction[QuickActionAttribute.Icon]
        }

        const action = quickAction[QuickActionAttribute.Action]

        switch (action) {
            case QuickActionType.AddUser:
                return {
                    ...base,
                    Type: QuickActionType.AddUser
                }
            case QuickActionType.OpenURL:
                return {
                    ...base,
                    Type: QuickActionType.OpenURL,
                    Url: quickAction[QuickActionAttribute.Url] ?? ""
                }
            case QuickActionType.OpenChannel:
            case QuickActionType.AddChannel:
                const channelType = quickAction[QuickActionAttribute.ChannelType]
                const channelId = quickAction[QuickActionAttribute.ChannelId]
                return {
                    ...base,
                    Type: action,
                    ChannelType:
                        channelType && isChannelTypeExists(channelType) ? channelType : ChannelTypeString.WebChat,
                    ChannelId: channelId ?? ""
                }
            default:
                return null
        }
    }

    static getQuickActions(markdown?: string): QuickAction[] {
        const parsed: ParsedQuickAction[] = KeyboardParser.getBlocks(this._buildAttributes, markdown)
        return parsed.map(this._processQuickAction).filter((a): a is QuickAction => !!a)
    }
}
