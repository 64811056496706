import React, { useEffect } from "react"
import SettingsTableLoader from "../SettingsTable/SettingsTableLoader"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import Async from "../Async/Async"
import { useDispatch, useSelector } from "react-redux"
import useDelay from "../../utility/common/useDelay"
import ProjectStickers from "./ProjectStickers"
import { getStickers } from "../../store/stickers/thunks"
import { selectGetStickersState } from "../../store/stickers/selectors"
import { Sticker } from "../../models/sticker"

interface Props {
    projectId: string
    title: string
    onAddClick: () => void
    onSettingsClick: (sticker: Sticker) => void
}

const ProjectStickersContainer: React.FC<Props> = props => {
    const { projectId, title, onAddClick, onSettingsClick } = props

    const dispatch = useDispatch()
    const display = useDelay()

    const apiState = useSelector(selectGetStickersState)

    useEffect(() => {
        dispatch(getStickers(projectId))
    }, [projectId, dispatch])

    if (!display) {
        return null
    }

    return (
        <Async
            dataState={apiState}
            processView={<SettingsTableLoader columns={2} rows={2} rowHeight={56} />}
            errorView={({ message }) => <ErrorMessage text={message} />}
        >
            {({ data: stickers }) => (
                <ProjectStickers
                    projectId={projectId}
                    title={title}
                    stickers={stickers}
                    onAddClick={onAddClick}
                    onSettingsClick={v => onSettingsClick(v)}
                />
            )}
        </Async>
    )
}

export default ProjectStickersContainer
