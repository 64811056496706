import React, { useRef, useState } from "react"
import "./KeyboardButton.scss"
import { ClassProps } from "../../../../../utility/common/props"
import cn from "classnames"
import { Button } from "react-bootstrap"
import { faCog } from "@fortawesome/pro-light-svg-icons/faCog"
import IconControl from "../../../../IconControl/IconControl"
import PopoverTrigger from "../../../../PopoverTrigger/PopoverTrigger"
import { usePopoverShown } from "../../../../../utility/knowledgeBase/usePopoverShown"
import EditMarkdownButtonForm from "../MarkdownButtonForm/EditMarkdownButtonForm/EditMarkdownButtonForm"
import TextareaAutosize from "react-textarea-autosize"
import { renderToPlainHtml } from "rich-markdown-editor"
import { MarkdownButton } from "../../../../../utility/articleContentEditor/buttonsParser/buttonsParser"
import { openExternalLink } from "../../../../../utility/common/urlHelper"
import { ButtonType } from "../../../../../utility/articleContentEditor/buttonsParser/buttonAttributes/buttonType"
import CloseButton from "../../../../CloseButton/CloseButton"
import { Dispatch } from "../../../../../utility/common/storeHelper"

interface Props extends ClassProps {
    button: MarkdownButton
    onEdit: (updatedButton: MarkdownButton) => void
    disabled?: boolean
    projectId: string
    onDelete: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    isEditable: boolean
    handleOpenArticle: (articleCode: string) => void
    dispatch: Dispatch
}

const KeyboardButton: React.FC<Props> = props => {
    const { className, button, onEdit, projectId, disabled, onDelete, isEditable, handleOpenArticle, dispatch } = props

    const { shown, showPopover, hidePopover } = usePopoverShown()
    const ref = useRef<HTMLDivElement>(null)

    const [buttonIsEditing, setButtonIsEditing] = useState(false)

    const handleClick = () => {
        if (!button.action) return
        return button.type === ButtonType.Action ? handleOpenArticle(button.action) : openExternalLink(button.action)
    }

    return (
        <div
            ref={ref}
            className={cn(
                "keyboard-button",
                className,
                `keyboard-button_${button.color}`,
                disabled && "keyboard-button_disabled"
            )}
        >
            <PopoverTrigger
                className="keyboard-button__popover"
                id="add-keyboard-button"
                content={
                    <EditMarkdownButtonForm
                        close={hidePopover}
                        button={button}
                        onEdit={onEdit}
                        projectId={projectId}
                        dispatch={dispatch}
                    />
                }
                placement="bottom-start"
                show={shown}
                container={ref}
                containerPadding={20}
            >
                <Button
                    variant="outline-light"
                    className="keyboard-button__btn"
                    onBlur={() => setButtonIsEditing(false)}
                    onClick={isEditable ? undefined : handleClick}
                >
                    <TextareaAutosize
                        className={cn(
                            "keyboard-button__textarea",
                            !buttonIsEditing && "keyboard-button__textarea_hidden"
                        )}
                        defaultValue={button.BodyMarkdown}
                        disabled={disabled}
                        onBlur={e => {
                            e.target.value !== button.BodyMarkdown &&
                                onEdit({ ...button, BodyMarkdown: e.target.value })
                        }}
                        maxRows={2}
                    />
                    {!buttonIsEditing && (
                        <div
                            onMouseDown={() => {
                                setButtonIsEditing(true)
                            }}
                            className="keyboard-button__content"
                            dangerouslySetInnerHTML={{
                                __html: renderToPlainHtml(button.BodyMarkdown)
                            }}
                        />
                    )}
                    <div className="keyboard-button__settings-buttons">
                        <IconControl
                            icon={faCog}
                            className="keyboard-button__settings-btn"
                            onClick={shown ? hidePopover : showPopover}
                        />
                        <CloseButton className="keyboard-button__settings-btn" onClick={onDelete} />
                    </div>
                </Button>
            </PopoverTrigger>
        </div>
    )
}

export default KeyboardButton
