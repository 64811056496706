import { DeepPartial } from "redux"

export interface ProjectSettings {
    DialogTimeout?: DialogTimeoutProjectSettings
    Operator?: OperatorProjectSettings
    Statistics?: StatisticsProjectSettings
    Report?: ReportProjectSettings
    Agent?: AgentProjectSettings
    Security?: SecurityProjectSettings
    System?: SystemProjectSettings
}

export const isProjectSettings = (rawData: any): rawData is ProjectSettings => {
    return (
        rawData &&
        (!rawData.DialogTimeout || typeof rawData.DialogTimeout === "object") &&
        (!rawData.Operator || typeof rawData.Operator === "object") &&
        (!rawData.Statistics || typeof rawData.Statistics === "object") &&
        (!rawData.Report || typeof rawData.Report === "object") &&
        (!rawData.Agent || typeof rawData.Agent === "object") &&
        (!rawData.Security || typeof rawData.Security === "object") &&
        (!rawData.System || typeof rawData.System === "object")
    )
}

export interface DialogTimeoutProjectSettings {
    FirstTimeout?: TimeoutSettings
    SecondTimeout?: TimeoutSettings
    FinishDialogTimeout?: TimeoutSettings
    DialogInQueueTimeout?: TimeoutSettings
    DialogLifeTimeout?: TimeoutSettings
}

export interface TimeoutSettings {
    Value: number
    Message?: string
}

export interface OperatorProjectSettings {
    Common?: CommonOperatorProjectSettings
    ClientSurvey?: ClientSurveyOperatorProjectSettings
    DialogSurvey?: DialogSurveyOperatorProjectSettings
    KPI?: KPIOperatorProjectSettings
    Workplace?: WorkplaceOperatorProjectSettings
    AutoGreeting?: AutoGreetingOperatorProjectSettings
    ResponseTemplate?: ResponseTemplateOperatorProjectSettings
    Hold?: HoldOperatorProjectSettings
    Notifications?: NotificationsOperatorProjectSettings
    OutgoingDialog?: OutgoingDialogOperatorProjectSettings
}

export interface CommonOperatorProjectSettings {
    AllowChatJoinRequests?: boolean
    AuditorDashboardSurveyId?: string
}

export interface ClientSurveyOperatorProjectSettings {
    MainSurveyId?: string
    ShortSurveyId?: string
    PhoneNumberMask?: string
}

export interface DialogSurveyOperatorProjectSettings {
    ChooseTopicToFinishDialogRequired?: boolean
    DialogTopicCatalogId?: string
    DialogProgramCatalogId?: string
    DialogSurveyId?: string
    UserDissatisfaction?: ProjectSettingsListValue
    DefaultDialogTopicIdFinishingByTimeout?: string
    ChooseProgramFieldName?: string
}

export interface ProjectSettingsListValue {
    Values: string[]
}

export interface KPIOperatorProjectSettings {
    DialogTimerEnabled?: boolean
    GreenDialogTimerTimeout?: number
    YellowDialogTimerTimeout?: number
    DialogWaitingForClientAnswerTimerEnabled?: boolean
    DialogWaitingForClientAnswerTimeout?: number
}

export interface WorkplaceOperatorProjectSettings {
    ShowDialogHistory?: boolean
    ForwardingMessage?: boolean
    RespondWithArticleEnabled?: boolean
    UseSpellChecker?: boolean
    UseEmoji?: boolean
    SetPreviousTopicsAfterFinishDialog?: boolean
    LoadHistoryByEmail?: boolean
    LoadHistoryByPhoneNumber?: boolean
}

export interface AutoGreetingOperatorProjectSettings {
    OperatorAutoGreetingEnabled: boolean
    OperatorAutoGreetingMale: string
    OperatorAutoGreetingFemale: string
    OperatorAutoGreetingNoGender: string
}

export interface ResponseTemplateOperatorProjectSettings {
    FastReplyTemplates: string
    FastReplyHoldTemplates: ProjectSettingsListValue
    WaitingForClientResponseTimeout: number
    WaitingForClientResponseTimeoutMessages: ProjectSettingsListValue
    TriggerMessagesForRunClientHolding: ProjectSettingsListValue
    ClientHoldingTimeout: number
    ClientHoldingMessages: ProjectSettingsListValue
}

export interface HoldOperatorProjectSettings {
    AutoHoldingEnabled?: boolean
    DropDialogTimeout?: number
    FinishDialogWithoutClientResponseTimeout?: number
}

export interface NotificationsOperatorProjectSettings {
    PushNotificationEnabled?: boolean
    PushNotificationVolumeLevel?: number
    PushNotificationTimeout?: number
}

export interface OutgoingDialogOperatorProjectSettings {
    FinishOutgoingDialogAfterStart?: boolean
    OutgoingMessageIntentsRecordTypes?: ProjectSettingsListValue
}

export interface StatisticsProjectSettings {
    FunnelSlots: ProjectSettingsListValue
    UnproductiveAnswers: ProjectSettingsListValue
    TimeRangeStartDefiningField: StatisticsPeriodOptions
    TimeRangeEndDefiningField: StatisticsPeriodOptions
    HandledCountField: StatisticsPeriodOptions
    ExcludedOperatorIds: ProjectSettingsListValue
    FCR: number
    FCRSeparateDifferentTopics: boolean
    SLEndDefiningField: StatisticsCalculatingOptions
    SLPeriod: number
    AHTStartDefiningField: StatisticsCalculatingOptions
}

export enum StatisticsPeriodOptions {
    Started = "started",
    Finished = "finished"
}

export enum StatisticsCalculatingOptions {
    FirstReply = "first_reply",
    Routed = "routed"
}

export interface ReportProjectSettings {
    CommonReportTimeZone: number
    CommonReportSlots: CommonReportSlotsValue
}

export interface CommonReportSlotsValue {
    Values: CommonReportSlotValue[]
}

export interface CommonReportSlotValue {
    SlotId: string
    ColumnTitle: string
}

export interface AgentProjectSettings {
    DefaultAgent: string
    DefaultAgentForOutgoingDialog: string
    OperatorAgent: string
    SystemEventsAgent: string
}

export interface SecurityProjectSettings {
    InformationMaskingPatterns: ProjectSettingsListValue
}

export interface KnowledgeBaseConvertTemplateModel {
    FileName: string
    FileUrl: string
}

export interface SystemProjectSettings {
    UpdateClientDataFromChannel: boolean
    ChannelOnDialogPanel: boolean
    DialogSwitchNotificationWebhook: string
    SwitchToBotWindowTitle: string
    SwitchToBotWindowMessage: string
    DisableMarkupModal: boolean
    KnowledgeBaseConvertTemplate: KnowledgeBaseConvertTemplateModel
}

export interface GetSettingsResponse {
    Settings: ProjectSettings
}

export interface DiffUpdateSettingsResponse {
    Diff: DeepPartial<ProjectSettings>
}
