import React from "react"
import styles from "./StickerItem.module.scss"
import { Sticker } from "../../../models/sticker"

interface Props {
    sticker: Sticker
    onClick: (sticker: Sticker) => void
}

const StickerItem: React.FC<Props> = props => {
    const { sticker, onClick } = props
    return (
        <div className={styles.stickerItem} onClick={() => onClick(sticker)}>
            <img className={styles.stickerItem__img} src={sticker.StickerUrl} alt={`Sticker ${sticker.Name}`} />
        </div>
    )
}

export default StickerItem
