import React from "react"
import "./RetrainClassifierPopover.scss"
import PopoverTrigger from "../../PopoverTrigger/PopoverTrigger"
import RetrainClassifierPopoverForm from "../RetrainClassifierPopoverForm/RetrainClassifierPopoverForm"
import { RetrainClassifierOptions } from "../../../models/classifier"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import AsyncState from "../../../core/asyncState"
import { ClassProps } from "../../../utility/common/props"

const tNamespace = "classifiers:train."

interface Props extends ClassProps {
    show: boolean
    onShow: () => void
    onCancel: () => void
    onSubmit: (values: RetrainClassifierOptions) => void
    asyncState: AsyncState<void>
    disabled?: boolean
    retrainEmbeddings?: boolean
}

const RetrainClassifierPopover: React.FC<Props> = props => {
    const { show, onShow, onCancel, onSubmit, asyncState, className, disabled, retrainEmbeddings } = props
    const { t } = useTranslation()

    return (
        <PopoverTrigger
            rootClose
            className="retrain-classifier-popover"
            id="retrain-classifier"
            content={
                <RetrainClassifierPopoverForm
                    asyncState={asyncState}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    retrainEmbeddings={retrainEmbeddings}
                />
            }
            trigger="click"
            placement="bottom"
            show={show}
            onExited={onCancel}
        >
            <Button variant="light" onClick={onShow} className={className} disabled={disabled}>
                {t(`${tNamespace}train`)}
            </Button>
        </PopoverTrigger>
    )
}

export default RetrainClassifierPopover
