import React, { useCallback, useEffect, useRef, useState } from "react"
import "./ChannelSettings.scss"
import cn from "classnames"
import { useTranslation } from "react-i18next"
import { WithTitle } from "../../utility/common/withTitle"
import { Channel, ChannelTypeString } from "../../models/channel"
import PageLayout from "../../components/PageLayout/PageLayout"
import ChannelList from "../../components/ChannelList/ChannelList"
import AddButton from "../../components/AddButton/AddButton"
import ChannelForm from "../../components/ChannelForm/ChannelForm"
import {
    deleteChannel,
    getChannels,
    getChannelsDeclarations,
    getWebhooksPrefixes,
    subscribeOnChannelsReady,
    unsubscribeOnChannelsReady
} from "../../store/channels/thunks"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentProject } from "../../store/projects/selectors"
import { getAgents } from "../../store/agents/thunks"
import ChannelTypeForm from "../../components/ChannelTypeForm/ChannelTypeForm"
import { selectChannels, selectChannelsDeclarations } from "../../store/channels/selectors"
import InfoIcon from "../../components/InfoIcon/InfoIcon"
import SettingsContextMenuItem from "../../components/SettingsContextMenuItem/SettingsContextMenuItem"
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash"
import { formTranslation } from "../../locales/form"
import { useModal } from "../../utility/common/useModal"
import AlertDialog from "../../components/AlertDialog/AlertDialog"
import Can from "../../components/Can/Can"
import { ModifyChannels } from "../../permissions"
import usePermissionsCheck from "../../utility/common/usePermissionsCheck"
import { getSurveys } from "../../store/surveys/thunks"
import { testId } from "../../utility/tests/testId"
import { useParams } from "react-router-dom"
import { isChannelTypeExists } from "../../utility/channels/channelValidation"
import { FormPathParams, FormType, getAddPath, getEditPath } from "../../utility/common/urlFormHelper"
import { FormChangeCallback } from "../ProjectSettings/ProjectSettings"

const tNamespace = "channel:"

enum SidebarContentType {
    chooseChannelTypeForm,
    addChannelForm,
    updateChannelForm
}

interface SidebarContent extends WithTitle {
    type: SidebarContentType
    channelType: string
    channel?: Channel
    settings?: JSX.Element[]
    extended?: boolean
    stepBack?: boolean
    onBack?: () => void
}

interface Props {
    onFormChange: FormChangeCallback
}

const isSidebarExtended = (channelType: string) => channelType === ChannelTypeString.Email

const ChannelSettings: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { onFormChange } = props
    const dispatch = useDispatch()
    const editAllowed = usePermissionsCheck([ModifyChannels])

    const {
        current: { formId, param }
    } = useRef(useParams<FormPathParams>())

    const project = useSelector(selectCurrentProject)
    const channelDeclarations = useSelector(selectChannelsDeclarations)
    const channels = useSelector(selectChannels)

    const [selectedChannelId, setSelectedChannelId] = useState<string | null>(null)
    const [sidebarClosed, setSidebarClosed] = useState(true)
    const [sidebarContent, setSidebarContent] = useState<SidebarContent | null>(null)

    const [channelToDelete, setChannelToDelete] = useState<string | null>(null)
    const { modalOpen, openModal, closeModal, onExited } = useModal(() => (
        <AlertDialog
            show={modalOpen}
            title={t(`${tNamespace}delete-confirmation.title`)}
            message={t(`${tNamespace}delete-confirmation.message`)}
            submitBtnText={t(formTranslation.delete)}
            onClose={closeModal}
            onSubmit={handleDeleteChannel}
            variant="danger"
            onExited={onExited}
        />
    ))

    useEffect(() => {
        project && channelDeclarations && dispatch(getChannels(project.id, channelDeclarations))
    }, [dispatch, project, channelDeclarations])

    useEffect(() => {
        if (project) {
            dispatch(getAgents(project.id))
            dispatch(subscribeOnChannelsReady(project.id))
            dispatch(getSurveys(project.id))
        }
        return () => {
            project && dispatch(unsubscribeOnChannelsReady(project.id))
        }
    }, [dispatch, project])

    useEffect(() => {
        if (!channelDeclarations) {
            dispatch(getChannelsDeclarations())
        }
    }, [project, channelDeclarations, dispatch])

    useEffect(() => {
        dispatch(getWebhooksPrefixes())
    }, [dispatch])

    const goToRoot = useCallback(() => {
        onFormChange()
    }, [onFormChange])

    const closeSidebar = useCallback(() => {
        setSidebarClosed(true)
        setSelectedChannelId(null)
    }, [])

    const closeSidebarWithRouting = useCallback(() => {
        closeSidebar()
        goToRoot()
    }, [closeSidebar, goToRoot])

    const openSidebar = useCallback(() => setSidebarClosed(false), [])

    const handleChooseChannelType = useCallback(
        (updateLocationState = false) => {
            closeSidebar()
            if (updateLocationState) {
                onFormChange(getAddPath())
            }
            setSidebarContent({
                type: SidebarContentType.chooseChannelTypeForm,
                channelType: ChannelTypeString.Unknown,
                title: t(`${tNamespace}add-channel`)
            })
            openSidebar()
        },
        [closeSidebar, openSidebar, t, onFormChange]
    )

    const handleDeleteChannel = useCallback(() => {
        if (project && channelToDelete) {
            dispatch(deleteChannel(project.id, channelToDelete, closeSidebar))
        }
    }, [project, dispatch, closeSidebar, channelToDelete])

    const handleUpdateChannel = useCallback(
        (channel: Channel, updateLocationState = false) => {
            closeSidebar()
            if (updateLocationState) {
                onFormChange(getEditPath(channel.Id))
            }
            setSelectedChannelId(channel.Id)
            const settings = [
                <SettingsContextMenuItem
                    key={channel.Id}
                    id={channel.Id}
                    icon={faTrash}
                    text={t(formTranslation.delete)}
                    danger
                    onClick={() => {
                        setChannelToDelete(channel.Id)
                        openModal()
                    }}
                    testId={testId.deleteChannel}
                />
            ]
            setSidebarContent({
                type: SidebarContentType.updateChannelForm,
                channelType: channel.Type,
                title: t(`${tNamespace}channel-settings`),
                channel,
                settings,
                extended: isSidebarExtended(channel.Type)
            })
            openSidebar()
        },
        [closeSidebar, openSidebar, t, openModal, onFormChange]
    )

    const handleAddChannel = useCallback(
        (type: string, updateLocationState = false) => {
            closeSidebar()
            if (updateLocationState) {
                onFormChange(getAddPath(type))
            }
            setSidebarContent({
                type: SidebarContentType.addChannelForm,
                channelType: type,
                title: t(`${tNamespace}add-channel`),
                extended: isSidebarExtended(type),
                stepBack: true,
                onBack: () => handleChooseChannelType(true)
            })
            openSidebar()
        },
        [closeSidebar, openSidebar, t, onFormChange, handleChooseChannelType]
    )

    const renderSidebarContent = useCallback(() => {
        if (!sidebarContent) return null

        switch (sidebarContent.type) {
            case SidebarContentType.chooseChannelTypeForm:
                return <ChannelTypeForm onSelect={(type: string) => handleAddChannel(type, true)} />
            case SidebarContentType.addChannelForm:
                return (
                    <ChannelForm
                        channel={sidebarContent.channel}
                        channelType={sidebarContent.channelType}
                        submitCallback={closeSidebarWithRouting}
                    />
                )
            case SidebarContentType.updateChannelForm:
                return (
                    <ChannelForm
                        channel={sidebarContent.channel}
                        channelType={sidebarContent.channelType}
                        submitCallback={closeSidebarWithRouting}
                        disabled={!editAllowed}
                    />
                )
        }
    }, [sidebarContent, handleAddChannel, closeSidebarWithRouting, editAllowed])

    useEffect(() => {
        if (!channels || !formId) return

        switch (formId) {
            case FormType.Add:
                param ? (isChannelTypeExists(param) ? handleAddChannel(param) : goToRoot()) : handleChooseChannelType()
                break
            case FormType.Edit:
                if (!param) goToRoot()

                const channel = channels?.find(c => c.Id === param)
                channel ? handleUpdateChannel(channel) : goToRoot()
                break
        }
    }, [handleUpdateChannel, handleAddChannel, handleChooseChannelType, channels, goToRoot, formId, param])

    return (
        <PageLayout isCollapsed={sidebarClosed}>
            <PageLayout.Content className="channels">
                <div className="channels__header">
                    <span className="channels__title">{t(`${tNamespace}channels`)}</span>
                    <InfoIcon placement="bottom" id="popoverChannels" content={t(`${tNamespace}page-desc`)} />
                    <Can permission={ModifyChannels}>
                        <AddButton
                            variant="outline-primary"
                            onClick={() => handleChooseChannelType(true)}
                            className={cn("channels__btn-add", !sidebarClosed ? "hidden" : undefined)}
                            text={t(`${tNamespace}add-channel`)}
                            testId={testId.addChannel}
                        />
                    </Can>
                </div>
                <ChannelList
                    onSettingsClick={(channel: Channel) => handleUpdateChannel(channel, true)}
                    selectedChannel={selectedChannelId}
                />
            </PageLayout.Content>
            <PageLayout.Sidebar
                title={sidebarContent ? sidebarContent.title : ""}
                onClose={closeSidebar}
                onCloseClick={closeSidebarWithRouting}
                settings={sidebarContent && editAllowed ? sidebarContent.settings : undefined}
                extended={sidebarContent?.extended}
                stepBack={sidebarContent?.stepBack}
                onBack={sidebarContent?.onBack}
            >
                {renderSidebarContent()}
            </PageLayout.Sidebar>
        </PageLayout>
    )
}

export default ChannelSettings
