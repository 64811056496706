import React, { useRef, useState } from "react"
import { Modal, Button } from "react-bootstrap"
import styles from "./ImageUploadModal.module.scss"
import ReactCrop from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import { useTranslation } from "react-i18next"
import { formTranslation } from "../../locales/form"
import { logError } from "../../utility/common/logError"
import { FileUpload } from "../../models/file"

const tNamespace = "common:image."

export interface ImageUploadModalProps {
    circularCrop: boolean
    show: boolean
    onClose: () => void
    onUpload: (picture?: FileUpload<Blob>) => Promise<void>
    uploading: boolean
    pictureSrc?: string
}

const defaultCrop: ReactCrop.Crop = { aspect: 1, width: 112 }

const ImageUploadModal: React.FC<ImageUploadModalProps> = props => {
    const { t } = useTranslation()
    const { circularCrop, show, onClose, onUpload, uploading, pictureSrc } = props
    const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null)
    const [selectedImageName, setSelectedImageName] = useState<string | null>(null)
    const [croppedImage, setCroppedImage] = useState<FileUpload<Blob> | null>(null)
    const [selectedImageRef, setSelectedImageRef] = useState<HTMLImageElement | null>(null)
    const [crop, setCrop] = useState<ReactCrop.Crop>(defaultCrop)
    const inputFileRef = useRef<HTMLInputElement>(null)

    const resetState = () => {
        setSelectedImageUrl(null)
        setSelectedImageName(null)
        setCroppedImage(null)
        setSelectedImageRef(null)
        setCrop(defaultCrop)
    }

    const handleSave = async () => {
        if (croppedImage != null) {
            await onUpload(croppedImage)
        }
        resetState()
    }

    const handleDefaultImage = async () => {
        await onUpload(undefined)
        resetState()
    }

    const handleClose = () => {
        resetState()
        onClose()
    }

    const handleInputFileRedirect = () => {
        if (inputFileRef && inputFileRef.current) {
            inputFileRef.current.click()
        }
    }

    const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files == null || e.target.files.length === 0) {
            return
        }
        let reader = new FileReader()
        const selectedFile = e.target.files[0]
        reader.onloadend = () => setSelectedImageUrl(reader.result as string)
        setSelectedImageName(selectedFile.name)
        reader.readAsDataURL(selectedFile)
    }

    const handleCropChange = (newCrop: ReactCrop.Crop) => setCrop(newCrop)

    const handleImageLoaded = (img: HTMLImageElement) => {
        setSelectedImageRef(img)
        const width = img.width / 2
        const halfWidth = width / 2
        const x = img.width / 2 - halfWidth
        const y = img.height / 2 - halfWidth
        setCrop({ ...crop, width, x, y })

        return false
    }

    const createCroppedImg = (image: HTMLImageElement, crop: ReactCrop.Crop, name: string) => {
        const canvas = document.createElement("canvas")
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        const cropWidth = crop.width as number
        const cropHeight = crop.height as number
        const cropX = crop.x as number
        const cropY = crop.y as number
        canvas.width = crop.width as number
        canvas.height = crop.height as number
        const ctx = canvas.getContext("2d")

        if (ctx == null) {
            return
        }

        ctx.drawImage(
            image,
            cropX * scaleX,
            cropY * scaleY,
            cropWidth * scaleX,
            cropHeight * scaleY,
            0,
            0,
            cropWidth,
            cropHeight
        )

        canvas.toBlob(blob => {
            if (blob == null) {
                logError("Can't create a cropped image")
                return
            }
            setCroppedImage({ Name: name, Content: blob })
        })
    }

    const handleCropComplete = (crop: ReactCrop.Crop) => {
        if (selectedImageRef && selectedImageName && crop.width && crop.height) {
            createCroppedImg(selectedImageRef, crop, selectedImageName)
        }
    }

    return (
        <Modal className={styles.imageUploadModal} show={show} onHide={handleClose}>
            <Modal.Header closeButton>{t(`${tNamespace}upload-new-photo`)}</Modal.Header>
            <Modal.Body className={styles.imageUploadModal__body}>
                <input
                    type="file"
                    className={styles.imageUploadModal__fileInput}
                    accept="image/png, image/jpeg"
                    ref={inputFileRef}
                    onChange={handleSelectFile}
                />
                <Button
                    className={styles.imageUploadModal__btnSelect}
                    disabled={uploading}
                    variant="primary"
                    onClick={handleInputFileRedirect}
                >
                    {t(`${tNamespace}select-file`)}
                </Button>
                {pictureSrc && (
                    <Button
                        className={styles.imageUploadModal__btnDefault}
                        disabled={uploading}
                        variant="primary"
                        onClick={handleDefaultImage}
                    >
                        {t(`${tNamespace}default`)}
                    </Button>
                )}
                {selectedImageUrl && (
                    <ReactCrop
                        circularCrop={circularCrop}
                        src={selectedImageUrl}
                        crop={crop}
                        onChange={handleCropChange}
                        onImageLoaded={handleImageLoaded}
                        onComplete={handleCropComplete}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button className={styles.imageUploadModal__btnClose} variant="light" onClick={handleClose}>
                    {t(formTranslation.cancel)}
                </Button>
                <Button disabled={croppedImage == null || uploading} variant="primary" onClick={handleSave}>
                    {" "}
                    {t(formTranslation.save)}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ImageUploadModal
