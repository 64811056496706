import { useEffect, useMemo } from "react"
import { FormikProps } from "formik"

interface Props<T> {
    callback: Function
    formikProps: FormikProps<T>
}

function OnSubmitValidationError<T>(props: Props<T>) {
    const { callback, formikProps } = props
    const submitCount = useMemo(() => formikProps.submitCount, [formikProps.submitCount])
    const isSubmitting = useMemo(() => formikProps.isSubmitting, [formikProps.isSubmitting])
    const isValid = useMemo(() => formikProps.isValid, [formikProps.isValid])

    useEffect(() => {
        if (submitCount > 0 && !isSubmitting && !isValid) {
            callback()
        }
    }, [submitCount, isSubmitting, isValid, callback])

    return null
}

export default OnSubmitValidationError
