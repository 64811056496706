export interface Sticker {
    Id: string
    Name: string
    CustomerId: string
    ChannelStickers: ChannelStickersType
    StickerUrl: string
    IsAnimated: boolean
}

export type ChannelStickersType = {
    [key: string]: ChannelSticker
}

export enum StickerChannel {
    Telegram = "Telegram",
    WebChat = "Webchat",
    Viber = "Viber",
    Odnoklassniki = "Odnoklassniki",
    Vkontakte = "Vkontakte"
}

export interface ChannelSticker {
    StickerId: string
}

export interface GetAllStickersRequest {
    CustomerId: string
}

export interface AddStickerRequest {
    Sticker: Sticker
}

export interface EditStickerRequest {
    Sticker: Sticker
}

export interface DeleteStickerRequest {
    StickerId: string
    CustomerId: string
}

export interface GetAllStickersResponse {
    Stickers: Sticker[]
}

export interface AddStickerResponse {
    Success: boolean
}

export interface EditStickerResponse {
    Success: boolean
}

export interface DeleteStickerResponse {
    Success: boolean
}

export interface StickerValues {
    Id: string
    Name: string
    ChannelStickers: ChannelStickerValuesType
    StickerUrl: string
}

export type ChannelStickerValuesType = {
    [key: string]: string
}
