import React, { useRef } from "react"
import styles from "./WidgetBlock.module.scss"
import IdField from "../../../../IdField/IdField"
import InfoCard from "../../../../InfoCard/InfoCard"
import cn from "classnames"
import { Popover } from "react-bootstrap"
import Overlay from "react-bootstrap/Overlay"
import { usePopoverShown } from "../../../../../utility/knowledgeBase/usePopoverShown"
import MarkdownWidgetForm, { Widget } from "../MarkdownWidgetForm/MarkdownWidgetForm"
import IconControl from "../../../../IconControl/IconControl"
import { faPen } from "@fortawesome/pro-light-svg-icons/faPen"
import CloseButton from "../../../../CloseButton/CloseButton"
import { useForceUpdate } from "../../../../../utility/common/useForceUpdate"

export interface WidgetBlockProps {
    widget: Widget
    onSelect: () => void
    onChange: (widget: Widget) => void
    onDelete: () => void
    isEditable?: boolean
}

const WidgetBlock: React.FC<WidgetBlockProps> = props => {
    const { widget, onSelect, onChange, onDelete, isEditable } = props
    const { shown: settingShown, showPopover: showSettingPopover, hidePopover: hideSettingPopover } = usePopoverShown()

    const ref = useRef<HTMLDivElement>(null)
    const target = useRef<HTMLDivElement>(null)
    const forceUpdate = useForceUpdate()

    const handleUpdateWidget = (widget: Widget) => {
        onSelect()
        onChange(widget)
        forceUpdate()
    }

    const handleDelete = () => {
        onSelect()
        onDelete()
    }

    return (
        <div className={styles.widget} ref={ref}>
            <InfoCard
                className={styles.widget__card}
                headerClassName={styles.widget__header}
                content={{
                    header: (
                        <>
                            Widget
                            {isEditable && (
                                <div className={styles.widget__controls}>
                                    <IconControl
                                        icon={faPen}
                                        className={styles.widget__settingsIcon}
                                        onClick={settingShown ? hideSettingPopover : showSettingPopover}
                                    />
                                    <CloseButton className={styles.widget__settingsIcon} onClick={handleDelete} />
                                </div>
                            )}
                        </>
                    ),
                    text: (
                        <>
                            <IdField id={widget.id} />
                            <div className={styles.widget__line} ref={target}>
                                {widget.title}
                            </div>
                            <div className={styles.widget__line}>{widget.description}</div>
                            <div className={cn(styles.widget__line, styles.widget__line_json)}>{widget.json}</div>
                        </>
                    )
                }}
            />
            <Overlay target={target.current} show={settingShown} placement="bottom-start" container={ref.current}>
                {props => (
                    <Popover
                        id="edit-widget-button"
                        className={cn("popover-trigger", styles.widget__popover)}
                        {...props}
                    >
                        <Popover.Content>
                            <MarkdownWidgetForm
                                widget={widget}
                                onChange={handleUpdateWidget}
                                onClose={hideSettingPopover}
                            />
                        </Popover.Content>
                    </Popover>
                )}
            </Overlay>
        </div>
    )
}

export default WidgetBlock
