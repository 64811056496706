import React from "react"
import styles from "./FullscreenLoader.module.scss"
import PublicPage from "../PublicPage/PublicPage"
import { useTranslation } from "react-i18next"
import Spinner from "../Spinner/Spinner"

const tNamespace = "common:loading."

export interface FullscreenLoaderProps {
    message?: string
    hideLogo?: boolean
}

const FullscreenLoader: React.FC<FullscreenLoaderProps> = props => {
    const { t } = useTranslation()
    const { message, hideLogo = false } = props
    return (
        <PublicPage
            hideLogo={hideLogo}
            hideLanguageSelector={true}
            hidePrivacyPolicy={true}
            hideTermOfUse={true}
            className={styles.fullscreenLoader}
            contentInCenter
        >
            <div className={styles.fullscreenLoader__content}>
                <Spinner className={styles.fullscreenLoader__spinner} />
                <h1 className={styles.fullscreenLoader__title}>{message ? message : t(tNamespace + "default")}</h1>
            </div>
        </PublicPage>
    )
}

export default FullscreenLoader
