import { createAction } from "@reduxjs/toolkit"
import { SystemError } from "../../core/error"
import { User } from "../../models/user"
import { Dispatch } from "../../utility/common/storeHelper"
import { actions } from "./slice"

export const loginProcess = createAction("auth/loginProcess")
export const loginSuccess = createAction<User>("auth/loginSuccess")
export const loginFailed = createAction<SystemError>("auth/loginFailed")
export const setUnauthorized = createAction("auth/setUnauthorized")
