import React from "react"
import { useTranslation } from "react-i18next"
import CopyInput from "../CopyInput/CopyInput"

const tNamespace = "channel:form."

export interface ChannelServerAddressProps {
    channelId: string
    serverAddress?: string
}

const ChannelServerAddress: React.FC<ChannelServerAddressProps> = props => {
    const { channelId, serverAddress } = props
    const { t } = useTranslation()

    if (!serverAddress) return null

    return <CopyInput id={`${channelId}_copy`} label={t(`${tNamespace}server-address-for-api`)} value={serverAddress} />
}

export default ChannelServerAddress
