import { CommandNode, CommandType, Connection, ScenarioBlockType } from "../../models/scenario"
import { getBlockId, NodeData } from "./scenario"
import { addEdge, ArrowHeadType, Connection as ReactFlowConnection, Elements, isNode, Node } from "react-flow-renderer"
import { OnLoadParams } from "react-flow-renderer/dist/types"
import { SetElementsType } from "../../components/ScenarioEditor/ScenarioContext"
import knowledgeBaseController from "../../api/controllers/knowledgeBase"
import { logError } from "../common/logError"
import { TFunction } from "i18next"
import { DomainMapType, SlotDto, SlotsMapType } from "../../models/slot"
import { Agent } from "../../models/agent"
import { AgentNodeData } from "../../components/Graph/nodes/Agent/AgentNode"
import { CommandNodeData } from "../../components/Graph/nodes/Command/CommandNode"
import { Channel } from "../../models/channel"
import { ChannelNodeData } from "../../components/Graph/nodes/Channel/ChannelNode"
import { ArticleView } from "../../models/article"

export const isSelectable = (type: ScenarioBlockType) => type !== ScenarioBlockType.Start

export const getPosition = (node: Node, type: ScenarioBlockType) => {
    const x = node.position.x - (node.type === ScenarioBlockType.Start ? 200 : 0) + 500
    switch (type) {
        case ScenarioBlockType.Condition:
            return {
                x,
                y: node.position.y + 230
            }
        default:
            return {
                x,
                y: node.position.y
            }
    }
}

export const addNode = (
    type: ScenarioBlockType,
    setElements: SetElementsType,
    nodeData: NodeData,
    instance: OnLoadParams,
    source?: string
): string => {
    const position = instance.project({
        x: 0,
        y: 0
    })
    const id = getBlockId()

    setElements((els: Elements) => {
        const refNode = instance && instance.getElements().find(elm => elm.id === source)

        const newNode: Node<NodeData> = {
            id,
            type,
            position: refNode && isNode(refNode) ? getPosition(refNode, type) : position,
            data: nodeData,
            selectable: isSelectable(type)
        }

        return els.concat(newNode)
    })

    return id
}

export const addConnection = (
    setElements: SetElementsType,
    id: string,
    source?: string,
    sourceHandle?: string,
    type = "custom"
) => {
    if (source && sourceHandle) {
        const newEdge: ReactFlowConnection = {
            source,
            target: id,
            sourceHandle,
            targetHandle: id
        }
        setElements(els =>
            addEdge(
                {
                    ...newEdge,
                    arrowHeadType: ArrowHeadType.ArrowClosed,
                    type
                },
                els
            )
        )
    }
}

export const getArticles = async (projectId: string): Promise<ArticleView[]> => {
    try {
        const response = await knowledgeBaseController.searchArticles(projectId, {})
        return response?.Articles ?? []
    } catch (e) {
        logError(e)
        return []
    }
}

export async function getArticleOptions(projectId: string, t: TFunction) {
    const articles = await getArticles(projectId)
    return articles
        .filter(a => a.Answers)
        .map(a => ({
            label: a.Title || t("scenarioEditor:untitled"),
            value: a.SymbolCode
        }))
}

export const getSlotsMap = (slots?: SlotDto[]): SlotsMapType =>
    (slots || []).reduce((obj: SlotsMapType, s) => {
        obj[s.ExternalId] = {
            ...s,
            DomainMap: (s.Domain ?? []).reduce((domainMap: DomainMapType, v) => {
                domainMap[v.Id] = v
                return domainMap
            }, {})
        }
        return obj
    }, {})

export const addAgentBlock = (
    agent: Agent,
    onAddBlock: (source?: string, sourceHandle?: string) => void,
    setElements: SetElementsType,
    instance: OnLoadParams,
    connection: Connection
) => {
    if (instance !== null) {
        const nodeData: AgentNodeData = {
            AgentId: agent.Id,
            AgentType: agent.Type,
            Agent: agent,
            OwnedByThisScenario: true,
            AddBlock: onAddBlock
        }

        const id = addNode(ScenarioBlockType.Agent, setElements, nodeData, instance, connection.source)
        addConnection(setElements, id, connection.source, connection.sourceHandle)
    }
}

export const updateAgentBlock = (agent: Agent, setElements: SetElementsType) => {
    setElements(els =>
        els.map(e => {
            if (isNode(e) && e.data.AgentId === agent.Id) {
                return {
                    ...e,
                    data: { ...e.data, Agent: agent }
                }
            }

            return e
        })
    )
}

export const addCommandBlock = (
    commandType: CommandType,
    slotId: string | null,
    text: string | null,
    setElements: SetElementsType,
    instance: OnLoadParams,
    connection: Connection,
    onAddBlock: (source?: string, sourceHandle?: string) => void
) => {
    if (instance) {
        const nodeData: CommandNodeData = {
            Command: commandType,
            SlotId: slotId,
            Value: text,
            AddBlock: onAddBlock
        }

        const id = addNode(ScenarioBlockType.Command, setElements, nodeData, instance, connection.source)
        addConnection(setElements, id, connection.source, connection.sourceHandle)
    }
}

export const updateCommandBlock = (
    commandType: CommandType,
    slotId: string | null,
    text: string | null,
    setElements: SetElementsType,
    commandNode: CommandNode
) => {
    const nodeData = {
        Command: commandType,
        SlotId: slotId,
        Value: text
    }

    setElements(els =>
        els.map(e => {
            return e.id === commandNode.id
                ? {
                      ...e,
                      data: { ...e.data, ...nodeData }
                  }
                : e
        })
    )
}

export const addChannelBlock = (
    channel: Channel,
    onAddBlock: (source?: string, sourceHandle?: string) => void,
    setElements: SetElementsType,
    instance: OnLoadParams,
    connection: Connection
) => {
    if (instance) {
        const nodeData: ChannelNodeData = {
            ChannelId: channel.Id,
            ChannelType: channel.Type,
            Channel: channel,
            AddBlock: onAddBlock
        }

        const id = addNode(ScenarioBlockType.Channel, setElements, nodeData, instance, connection.source)
        addConnection(setElements, id, connection.source, connection.sourceHandle)
    }
}

export const updateChannelBlock = (channel: Channel, setElements: SetElementsType) => {
    setElements(els =>
        els.map(e => {
            if (isNode(e) && e.data.ChannelId === channel.Id) {
                return {
                    ...e,
                    data: { ...e.data, Channel: channel }
                }
            }

            return e
        })
    )
}
