import { Project } from "../../models/project"
import { Menu } from "../../models/menu"
import { Queue, QueueType } from "../../models/queue"
import { SimpleUser, User } from "../../models/user"
import { Message, MessageDirection, MessageType } from "../../models/message"
import { Notification, NotificationPayload } from "../../models/notification"
import notificationTranslations from "../../locales/notification"
import toNotificationPayload from "../notifications/toNotificationPayload"
import getInternalId from "../notifications/getInternalId"
import { ArticleComment, ArticleCommentType } from "../../models/articleComment"
import { ArticleUserView } from "../../models/articleUserViews"
import {
    KnowledgeBaseArticleSubscriptions,
    KnowledgeBaseSubscriptionType,
    KnowledgeBaseSubscriptionValue
} from "../../models/knowledgeBaseSubscription"
import {
    KnowledgeBaseAccessType,
    KnowledgeBasePermission,
    KnowledgeBasePermissionWeight,
    KnowledgeBasePermittedAction
} from "../../models/knowledgeBasePermission"
import {
    Article as KnowledgeBaseArticle,
    ArticleAnswer,
    ArticleAnswerSlot,
    ArticleKind,
    ArticleStatus,
    ArticleView,
    SearchArticlesHighlights,
    SearchArticlesScores
} from "../../models/article"
import { SlotDto, SlotKind, SlotType } from "../../models/slot"
import { TabEntry } from "../../types/tabEntry"
import { OperatorStatus, OperatorStatusValue, SelectedOperatorStatus } from "../../models/operatorStatus"
import { Operator } from "../../models/operator"
import { SystemError } from "../../core/error"
import { Task, TaskStatus } from "../../models/task"
import { ChannelType } from "../../models/channel"
import { ViewClient } from "../../permissions"

export const mockedTranslation = {
    i18n: {
        language: "ru",
        languages: ["ru", "en"]
    },
    t: (key: any) => key
}

export const mockedi18n = {
    use: (_: any) => mockedi18n,
    init: (_: any) => mockedi18n
}

export const mockedProjects: Project[] = [
    {
        id: "test1",
        name: "TEST1",
        language: "ru",
        logo: ""
    },
    {
        id: "test2",
        name: "TEST2",
        language: "en",
        logo: ""
    },
    {
        id: "test3",
        name: "TEST3",
        language: "ru",
        logo: ""
    }
]

export const mockMenu: Menu = {
    id: "test",
    title: "test",
    items: [
        {
            id: "dialogs",
            title: "menu-item-dialogs",
            role: ViewClient,
            submenus: []
        },
        {
            id: "testMenu1",
            title: "testMenu1",
            submenus: []
        },
        {
            id: "testMenu2",
            title: "testMenu2",
            submenus: []
        },
        {
            id: "testMenu3",
            title: "testMenu3",
            submenus: []
        }
    ]
}

export const mockedQueue: Queue = {
    Id: "test",
    TenantId: "test",
    Type: QueueType.Auto,
    Name: "test",
    CategoryId: "test",
    TaskSize: 0,
    Priority: 0,
    IndividualPriority: 0,
    IndividualTimeoutSettings: { TimeoutMSec: 0 },
    LastPickTime: 0,
    Sl: 0,
    Awt: {},
    FinishedDialogs: {},
    FinishedDialogsDaily: {},
    CSI: 0,
    OperatorsIds: [],
    OperatorsTasksCount: {},
    PendingTasks: [],
    PendingIndividualTasks: []
}

export const mockedUser: User = {
    Login: "test",
    FirstName: "Test1",
    LastName: "Test2",
    MaxDialogs: 1,
    Permissions: [],
    Locale: "ru",
    Projects: ["test1"]
}

export const mockedMessage: Message = {
    MsgId: "8907ab2f-e405-4cab-80d4-58e420c24f8b",
    MsgType: MessageType.Message,
    Direction: MessageDirection.Incoming,
    Text: "text",
    Actions: [],
    IdInChannel: "140107392",
    ChannelId: "telegram",
    ClientId: 0,
    OperatorName: "",
    DialogID: "1bd3ae35-62d4-43ac-ad80-53a7f40f4227",
    Context: [],
    Flags: [],
    IsServiceMessage: false,
    IsClientServiceMessage: false,
    Added: "2020-10-01T07:51:43.647+00:00",
    Meta: {
        OCRM_FIRSTNAME: "Test",
        OCRM_LASTNAME: "Test",
        OCRM_PATRNAME: "Test",
        routing_hint: "3",
        sys_phone: "79999999999",
        location: '{"lat":52.997178,"lon":36.065835}',
        channel_id: "telegram"
    }
}

export const mockNotificationPayloads: NotificationPayload = {
    Id: getInternalId(),
    UserId: "",
    NeedApprove: false,
    Title: {
        Value: "Test notification",
        NeedLocalization: false
    },
    Description: {
        Value: "Common notification message. Just for test",
        NeedLocalization: false
    },
    Timestamp: 0,
    Priority: 0
}

export const mockNewsNotification: NotificationPayload[] = [
    {
        Priority: 0,
        Title: {
            Value: "Test article",
            NeedLocalization: false
        },
        Link: "/project/base/knowledge-base/article/view/article-d703b847-52ad-43f1-84d6-ec6d8adbf847",
        Description: {
            Value: notificationTranslations.descriptionNewArticle,
            NeedLocalization: true
        }
    },
    {
        Priority: 0,
        NeedApprove: true,
        Title: {
            Value: "Test article",
            NeedLocalization: false
        },
        Link: "http://localhost:3000/app/project/base/knowledge-base-old",
        Description: {
            Value: notificationTranslations.descriptionArticleEdited,
            NeedLocalization: true
        }
    },
    {
        Priority: 0,
        Title: {
            Value: "Test article",
            NeedLocalization: false
        },
        Link: "http://localhost:3000/app/project/base/knowledge-base-old",
        Description: {
            Value: notificationTranslations.descriptionNewCommentAdded,
            NeedLocalization: true
        }
    },
    {
        Priority: 0,
        Title: {
            Value: "Test article",
            NeedLocalization: false
        },
        Link: "http://localhost:3000/app/project/base/knowledge-base-old",
        Description: {
            Value: notificationTranslations.descriptionNewsAdded,
            NeedLocalization: true
        }
    }
].map(toNotificationPayload)

export const mockNotifications: Notification[] = [
    {
        Id: "1",
        UserId: "",
        NeedApprove: false,
        Priority: 0,
        Type: "error",
        Title: {
            Value: "Test error notification",
            NeedLocalization: false
        },
        Description: {
            Value: "Error notification message. Just for test",
            NeedLocalization: false
        },
        Timestamp: 0
    },
    {
        Id: "2",
        UserId: "",
        NeedApprove: false,
        Priority: 10,
        Type: "info",
        Title: {
            Value: "Test notification",
            NeedLocalization: false
        },
        Description: {
            Value: "Common notification message. Just for test",
            NeedLocalization: false
        },
        Timestamp: 1
    },
    {
        Id: "3",
        UserId: "",
        NeedApprove: false,
        Priority: 0,
        Type: "message",
        Title: {
            Value: "Message notification",
            NeedLocalization: false
        },
        Description: {
            Value: "Notification message description",
            NeedLocalization: false
        },
        Timestamp: 20
    },
    {
        Id: "4",
        UserId: "",
        NeedApprove: false,
        Priority: 1,
        Type: "message",
        Title: {
            Value: "Message notification",
            NeedLocalization: false
        },
        Description: {
            Value: "Notification message description",
            NeedLocalization: false
        },
        Timestamp: 5
    }
]

export const mockArticleComments: ArticleComment[] = [
    {
        Id: "1",
        ArticleCode: "12",
        Type: ArticleCommentType.Comment,
        User: mockedUser,
        Message: {
            Text: 'Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил без заметных изменений пять веков, но и перешагнул в электронный дизайн. Его популяризации в новое время послужили публикация листов Letraset с образцами Lorem Ipsum в 60-х годах и, в более недавнее время, программы электронной вёрстки типа Aldus PageMaker, в шаблонах которых используется Lorem Ipsum.'
        },
        AddedTimestamp: 0,
        ReplyCount: 3,
        ProjectId: "test"
    }
]

export const mockArticleCommentThread: ArticleComment[] = [
    {
        Id: "2",
        ArticleCode: "12",
        ParentId: "1",
        Type: ArticleCommentType.ThreadItem,
        User: mockedUser,
        Message: {
            Text: "KFLKJLJDLFDLFLJLK"
        },
        AddedTimestamp: 0,
        ReplyCount: 0,
        ProjectId: "test"
    },
    {
        Id: "3",
        ArticleCode: "12",
        ParentId: "1",
        Type: ArticleCommentType.ThreadItem,
        User: mockedUser,
        Message: {
            Text: "KLFJIERUWEXCN<MXC<B"
        },
        AddedTimestamp: 0,
        ReplyCount: 0,
        ProjectId: "test"
    },
    {
        Id: "4",
        ArticleCode: "12",
        ParentId: "1",
        Type: ArticleCommentType.ThreadItem,
        User: mockedUser,
        Message: {
            Text: 'Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил без заметных изменений пять веков, но и перешагнул в электронный дизайн. Его популяризации в новое время послужили публикация листов Letraset с образцами Lorem Ipsum в 60-х годах и, в более недавнее время, программы электронной вёрстки типа Aldus PageMaker, в шаблонах которых используется Lorem Ipsum.'
        },
        AddedTimestamp: 0,
        ReplyCount: 0,
        ProjectId: "test"
    }
]

export const simpleUser: SimpleUser = {
    Login: "test",
    FirstName: "FirstName",
    LastName: "LastName",
    Picture: ""
}

export const articleUserViews: ArticleUserView[] = [
    {
        Login: "test",
        Viewed: true,
        ReadConfirmed: false
    },
    {
        Login: "test2",
        Viewed: true,
        ReadConfirmed: true
    }
]

export const mockedArticleSubscriptionValues: KnowledgeBaseSubscriptionValue[] = [
    {
        Type: KnowledgeBaseAccessType.All,
        SubscriptionType: KnowledgeBaseSubscriptionType.Notify,
        ProjectId: "test1",
        Value: ""
    },
    {
        Type: KnowledgeBaseAccessType.Login,
        SubscriptionType: KnowledgeBaseSubscriptionType.WithConfirmation,
        ProjectId: "test1",
        Value: "test_user"
    },
    {
        Type: KnowledgeBaseAccessType.Role,
        SubscriptionType: KnowledgeBaseSubscriptionType.WithConfirmation,
        ProjectId: "test1",
        Value: "test_role"
    }
]

export const mockedArticlePermissionValues: KnowledgeBasePermission[] = [
    {
        Type: KnowledgeBaseAccessType.All,
        Action: KnowledgeBasePermittedAction.Edit,
        ProjectId: "test1",
        Value: "",
        SearchValue: "",
        Weight: KnowledgeBasePermissionWeight.All
    },
    {
        Type: KnowledgeBaseAccessType.Login,
        Action: KnowledgeBasePermittedAction.View,
        ProjectId: "test1",
        Value: "test_user",
        SearchValue: "",
        Weight: KnowledgeBasePermissionWeight.Login
    }
]

export const mockedArticleSubscriptions: KnowledgeBaseArticleSubscriptions = {
    Id: "test123",
    ArticleCode: "test_article",
    Subscriptions: mockedArticleSubscriptionValues
}

export const mockedAnswers: ArticleAnswer[] = [
    {
        Id: "1",
        Text: "Test text 1",
        Slots: []
    },
    {
        Id: "2",
        Text: "Test text 2",
        Slots: [
            {
                SlotId: "22",
                Values: ["test value"]
            }
        ]
    }
]

export const mockedAnswerSlots: ArticleAnswerSlot[] = [
    {
        SlotId: "slot1",
        Values: []
    },
    {
        SlotId: "slot2",
        Values: ["value3", "value4"]
    }
]

export const mockedSlots: SlotDto[] = [
    {
        ProjectId: "test",
        Id: "slot1",
        Title: "First slot",
        ExternalId: "slot1",
        Type: SlotType.Text,
        Kind: SlotKind.Default,
        ModifiedData: 1616659854307,
        ModifiedUserLogin: "test"
    },
    {
        ProjectId: "test",
        Id: "slot2",
        Title: "Second slot",
        ExternalId: "slot1",
        Type: SlotType.Text,
        Kind: SlotKind.Default,
        ModifiedData: 1616659854307,
        ModifiedUserLogin: "test"
    }
]

export const mockedArticle: KnowledgeBaseArticle = {
    ProjectId: "test",
    Id: "test1",
    Kind: ArticleKind.Common,
    SymbolCode: "test1",
    Title: "Test1",
    Type: "Type",
    Status: ArticleStatus.Draft,
    Questions: [{ Sentence: "Test question" }],
    Answers: [
        { Id: "a1", Text: "Answer1", Slots: [] },
        { Id: "a2", Text: "Answer2", Slots: [] }
    ],
    Expire: 0,
    PrevVersion: 637522566529658812,
    FirstVersion: 637522566450894286,
    ModifiedDate: 1616659854307,
    ModifiedUserLogin: "test",
    Permissions: [],
    Tags: [],
    Parameters: []
}

export const minimalHtml =
    "<!DOCTYPE html>\n" +
    '<html lang="en">\n' +
    "  <head>\n" +
    '    <meta charset="utf-8">\n' +
    "    <title>title</title>\n" +
    '    <link rel="stylesheet" href="style.css">\n' +
    '    <script src="script.js"></script>\n' +
    "  </head>\n" +
    "  <body>\n" +
    "    Test content\n" +
    "  </body>\n" +
    "</html>"

export const mockTabEntries: TabEntry[] = [
    { key: "1", value: 1 },
    { key: "2", value: 2 }
]

export const mockedOperatorStatuses: OperatorStatus[] = [
    {
        Value: OperatorStatusValue.Online,
        Description: "online",
        Title: {
            Value: "menu:statuses.online",
            NeedLocalization: true
        }
    },
    {
        Value: OperatorStatusValue.Offline,
        Description: "offline",
        Title: {
            Value: "menu:statuses.offline",
            NeedLocalization: true
        }
    },
    {
        Value: OperatorStatusValue.DoNotRoute,
        Description: "donotroute",
        Title: {
            Value: "menu:statuses.invisible",
            NeedLocalization: true
        }
    },
    {
        Value: OperatorStatusValue.Offline,
        Description: "lunch",
        Title: {
            Value: "menu:statuses.lunch",
            NeedLocalization: true
        }
    },
    {
        Value: OperatorStatusValue.Offline,
        Description: "training",
        Title: {
            Value: "menu:statuses.training",
            NeedLocalization: true
        }
    },
    {
        Value: OperatorStatusValue.Online,
        Description: "custom-online",
        Title: {
            Value: "Custom Online",
            NeedLocalization: false
        }
    }
]

export const mockedSelectedOperatorStatus: SelectedOperatorStatus = {
    Value: OperatorStatusValue.Online,
    Description: "online",
    Title: {
        Value: "Ready to work",
        NeedLocalization: false
    },
    UpdatedAt: 0
}

export const mockedAllSelectedOperatorStatus = {
    online: mockedSelectedOperatorStatus,
    doNotRoute: {
        Value: OperatorStatusValue.DoNotRoute,
        Description: "donotroute",
        Title: {
            Value: "Do Not Route",
            NeedLocalization: false
        },
        UpdatedAt: 0
    },
    offline: {
        Value: OperatorStatusValue.Offline,
        Description: "offline",
        Title: {
            Value: "AFK",
            NeedLocalization: false
        },
        UpdatedAt: 0
    }
}

export const mockedTreeItemData = {
    title: "Test article",
    symbolCode: "test_code",
    parentCategoryCode: "test_parent_category_code"
}

export const jsxCodeSample = `(function someDemo() {
  var test = "Hello World!";
  console.log(test);
})();

return () => <App />;
`

export const mockedTasks: Task[] = [
    {
        Id: "task1",
        Type: "Conversation",
        TenantId: "test",
        Status: TaskStatus.Assigned,
        CreatedAt: Date.now() - 136000,
        Channel: {
            Id: "webchat1",
            Type: ChannelType.WebChat,
            Title: "WebChat for clients"
        },
        Client: {
            Id: "client1",
            FirstName: "Stepan",
            LastName: "Galagan"
        }
    },
    {
        Id: "task2",
        Type: "Conversation",
        TenantId: "test",
        Status: TaskStatus.Pending,
        CreatedAt: Date.now() - 34543450,
        Channel: {
            Id: "telegram1",
            Type: ChannelType.Telegram,
            Title: "Messenger"
        },
        Client: {
            Id: "client2",
            FirstName: "Mark",
            LastName: "Mark"
        }
    },
    {
        Id: "task3",
        Type: "Call",
        TenantId: "test",
        Status: TaskStatus.Pending,
        CreatedAt: Date.now() - 63789,
        Channel: {
            Id: "phone1",
            Type: ChannelType.Phone,
            Title: "Private line"
        },
        Client: {
            Id: "client3",
            FirstName: "Yulia",
            LastName: "Op"
        }
    }
]

export const mockedOperator: Operator = {
    Id: "1",
    FirstName: "TestFirstName",
    LastName: "TestLastName",
    Status: mockedSelectedOperatorStatus,
    Capacity: 0,
    MaxCapacity: 10,
    Tasks: [mockedTasks[0]],
    TenantsQueues: []
}

export const mockedArticleViews: ArticleView[] = [
    {
        ProjectId: "test",
        Id: "1fd43056-a439-4c73-b319-5a2c1b5eed13",
        SymbolCode: "kp_000327",
        Title: "Регистрация установления отцовства. Где оформить Куда обратиться Где оформить Куда обратиться",
        Answers: [
            {
                Id: "a1",
                Text: "Где оформить Куда обратиться ВАРИАНТ 2.",
                Slots: []
            }
        ],
        ModifiedDate: 1616659854307
    },
    {
        ProjectId: "test",
        Id: "d5b3a9b0-1f05-4d60-a1dd-5d0162579b5a",
        SymbolCode: "kp_003050",
        Title: "Электронный сертификат. Где оформить Куда обратиться",
        Answers: [
            {
                Id: "a2",
                Text: "РЕЧЕВОЙ МОДУЛЬ: Оформить сертификат можно в Территориальных центрах социального.",
                Slots: []
            }
        ],
        ModifiedDate: 1616662375529
    },
    {
        ProjectId: "test",
        Id: "77e57ed5-10df-431f-a938-c61bd38c5f5a",
        SymbolCode: "kp_006433",
        Title: "Паспорт болельщика. Где оформить Куда обратиться",
        Answers: [
            {
                Id: "a2",
                Text: "Паспорт болельщика.",
                Slots: []
            }
        ],
        ModifiedDate: 1616676609122
    }
]

export const mockedSearchArticlesHighlights: SearchArticlesHighlights = {
    "1fd43056-a439-4c73-b319-5a2c1b5eed13": {
        "answers.answer": ["Где <em>оформить</em> Куда обратиться ВАРИАНТ 2."],
        title: ["Где <em>оформить</em> Куда обратиться"],
        "inputs.questions.sentence": ["Где <em>оформить</em>?"]
    },
    "d5b3a9b0-1f05-4d60-a1dd-5d0162579b5a": {
        "answers.answer": ["РЕЧЕВОЙ МОДУЛЬ: <em>Оформить</em> сертификат можно в Территориальных центрах социального"],
        title: ["Где <em>оформить</em> Куда обратиться"],
        "inputs.questions.sentence": ["Где <em>оформить</em> Куда обратиться"]
    },
    "77e57ed5-10df-431f-a938-c61bd38c5f5a": {
        title: ["Где <em>оформить</em> Куда обратиться"],
        "inputs.questions.sentence": [
            "Где <em>оформить</em> паспорт болельщика на кубок конфедераций?",
            "Где <em>оформить</em> fan id (фан ай ди)?"
        ]
    }
}

export const mockedSearchArticlesScores: SearchArticlesScores = {
    "1fd43056-a439-4c73-b319-5a2c1b5eed13": 14.140487,
    "d5b3a9b0-1f05-4d60-a1dd-5d0162579b5a": 13.037295,
    "77e57ed5-10df-431f-a938-c61bd38c5f5a": 12.424701
}

export const mockedSlot: SlotDto = {
    Id: "test",
    ExternalId: "test-id",
    ProjectId: "string",
    Title: "Title",
    Type: SlotType.Dictionary,
    Kind: SlotKind.Default,
    ModifiedData: 0,
    ModifiedUserLogin: "test",
    Domain: [
        {
            Id: "test",
            Title: "",
            Synonyms: [
                {
                    Id: "6972351b-cc44-47d2-9e7b-cfefdaa3b99s",
                    Value: "synonym1"
                },
                {
                    Id: "6972351b-cc94-47d2-9e7b-cfefdaa3b99a",
                    Value: "synonym2"
                }
            ]
        }
    ]
}

export const mockedSystemError: SystemError = {
    error: "Test error",
    contextKey: "some:key"
}

export const mockedNotification: NotificationPayload = {
    Id: "notification1",
    UserId: "test",
    Timestamp: Date.now(),
    Priority: 0,
    NeedApprove: false,
    Title: {
        NeedLocalization: false,
        Value: "Notification Info"
    }
}
