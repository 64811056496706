import { useEffect, useState } from "react"
import { Queue } from "../../models/queue"
import { nameof } from "../common/nameof"
import { OperatorSummary } from "../../models/operator"
import { useFormikContext } from "formik"

type OperatorQueuesValues = {
    operatorId: string
    queueId: string
}

const getQueues = (ids: string[], queues: Queue[]) => {
    return queues.filter(v => ids.findIndex(i => v.Id === i) !== -1)
}

function useOperatorQueues<Values extends OperatorQueuesValues>(operators: OperatorSummary[], queues: Queue[]) {
    const { values, setFieldValue } = useFormikContext<Values>()
    const [operatorQueues, setOperatorQueues] = useState<Queue[]>([])

    useEffect(() => {
        if (!values.operatorId) return

        setFieldValue(nameof<Values>("queueId"), "", false)
        const operator = operators.find(v => v.Id === values.operatorId)
        setOperatorQueues(operator?.Queues ? getQueues(operator.Queues, queues) : [])
    }, [operators, queues, setFieldValue, values.operatorId])

    return operatorQueues
}

export default useOperatorQueues
