import React from "react"
import styles from "./MenuItem.module.scss"
import cn from "classnames"
import MenuIcon from "../MenuIcon/MenuIcon"

export interface MenuItemComponentProps {
    id: string
    title: string
    isSelected: boolean
    onClick: Function
    testId?: string
}

const MenuItemComponent: React.FC<MenuItemComponentProps> = props => {
    const { id, title, isSelected, onClick, testId } = props
    return (
        <div
            className={cn(styles.menuItem, isSelected && styles.menuItem_selected)}
            onClick={() => onClick()}
            data-test-id={testId}
        >
            <div className={styles.menuItem__container}>
                <MenuIcon menuId={id} className={styles.menuItem__menuIcon} />
                <div className={styles.menuItem__title}>{title}</div>
            </div>
        </div>
    )
}

export default MenuItemComponent
