import {
    ProjectSettings,
    ReportProjectSettings,
    StatisticsCalculatingOptions,
    StatisticsPeriodOptions,
    StatisticsProjectSettings
} from "../../../models/projectSettings"
import { StatisticsAndReportSettingsValues } from "../../../models/projectSettingsValues"
import { durationSettingsValuesToMs, msToDurationSettingsValues } from "../../../utility/project/projectSettings"
import { Time } from "../../../utility/common/time"
import { logError } from "../../../utility/common/logError"

interface DefaultStatisticsSettings {
    Statistics: Required<StatisticsProjectSettings>
    Report: Required<ReportProjectSettings>
}

const DEFAULT_COMMON_REPORT_TIME_ZONE = 10800000

export const getDefaultStatisticsSettings = (): DefaultStatisticsSettings => ({
    Statistics: {
        FunnelSlots: {
            Values: []
        },
        UnproductiveAnswers: {
            Values: []
        },
        TimeRangeStartDefiningField: StatisticsPeriodOptions.Finished,
        TimeRangeEndDefiningField: StatisticsPeriodOptions.Finished,
        HandledCountField: StatisticsPeriodOptions.Finished,
        ExcludedOperatorIds: {
            Values: []
        },
        FCR: 604800000,
        FCRSeparateDifferentTopics: false,
        SLEndDefiningField: StatisticsCalculatingOptions.FirstReply,
        SLPeriod: 86400000,
        AHTStartDefiningField: StatisticsCalculatingOptions.FirstReply
    },
    Report: {
        CommonReportTimeZone: DEFAULT_COMMON_REPORT_TIME_ZONE,
        CommonReportSlots: {
            Values: []
        }
    }
})

const _pad = (value: number) => (value < 10 ? "0" + value : value)

const createOffset = (timezoneOffset: number) => {
    timezoneOffset = -Time.msToMinutes(timezoneOffset)
    const sign = timezoneOffset < 0 ? "-" : "+"

    const offset = Math.abs(timezoneOffset)
    const hours = _pad(Math.floor(offset / 60))
    const minutes = _pad(offset % 60)

    return sign + hours + ":" + minutes
}

const getCommonReportTimeZoneValue = (commonReportTimeZone: number) => {
    try {
        return createOffset(commonReportTimeZone)
    } catch {
        return createOffset(DEFAULT_COMMON_REPORT_TIME_ZONE)
    }
}

export const getStatisticsInitialValues = (settings: ProjectSettings): StatisticsAndReportSettingsValues => {
    const defaultSettings = getDefaultStatisticsSettings()
    const statisticsSettings = { ...defaultSettings.Statistics, ...settings?.Statistics }
    const reportSettings = { ...defaultSettings.Report, ...settings?.Report }

    return {
        Statistics: {
            ...statisticsSettings,
            FCR: msToDurationSettingsValues(statisticsSettings.FCR),
            SLPeriod: msToDurationSettingsValues(statisticsSettings.SLPeriod)
        },
        Report: {
            CommonReportTimeZone: getCommonReportTimeZoneValue(reportSettings.CommonReportTimeZone),
            CommonReportSlots: reportSettings.CommonReportSlots
        }
    }
}

const valueToCommonReportTimeZoneOffset = (value: string): number => {
    if (!value) return DEFAULT_COMMON_REPORT_TIME_ZONE

    const match = value.match(/^([+-])([0-9]+):([0-9]+)$/)
    if (match) {
        try {
            const [, sign, hours, minutes] = match
            const hoursInMs = Time.hoursToMs(parseInt(hours, 10))
            const minutesInMs = Time.minutesToMs(parseInt(minutes, 10))

            return (hoursInMs + minutesInMs) * (sign === "+" ? +1 : -1)
        } catch {
            logError("Failed to parse timezone value in report project settings")
        }
    }

    return DEFAULT_COMMON_REPORT_TIME_ZONE
}

export const buildStatisticsSettingsRequest = (values: StatisticsAndReportSettingsValues): ProjectSettings => {
    return {
        Statistics: {
            ...values.Statistics,
            FCR: durationSettingsValuesToMs(values.Statistics.FCR),
            SLPeriod: durationSettingsValuesToMs(values.Statistics.SLPeriod)
        },
        Report: {
            CommonReportTimeZone: valueToCommonReportTimeZoneOffset(values.Report.CommonReportTimeZone),
            CommonReportSlots: values.Report.CommonReportSlots
        }
    }
}
