import React from "react"
import { preventSubmitOnEnter } from "../../../../../../utility/common/preventSubmitOnEnter"
import { FormikProps } from "formik/dist/types"
import { WithT } from "i18next"
import { Button, Form } from "react-bootstrap"
import { nameof } from "../../../../../../utility/common/nameof"
import { formTranslation } from "../../../../../../locales/form"
import ValidatableInput from "../../../../../ValidatableInput/ValidatableInput"
import LoadingButton from "../../../../../LoadingButton/LoadingButton"
import { ButtonFacebookType, ButtonPlacement, MarkdownButtonBlockValues } from "../../../../../../models/markdownButton"
import RadioButtonValidatableInput from "../../../../../RadioButtonValidatableInput/RadioButtonValidatableInput"
import DebouncedCheckBox from "../../../../../CheckBoxValidatableInput/DebouncedCheckBox"

const tNamespace = "knowledgeBase:article-button-block."

interface Props {
    loading: boolean
    onCancel: () => void
}

const FormikMarkdownButtonBlockForm: React.FC<Props & FormikProps<MarkdownButtonBlockValues> & WithT> = props => {
    const { t, loading, handleSubmit, onCancel, values } = props

    return (
        <Form className="markdown-button-block-form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <div className="markdown-button-block-form__content">
                <div className="markdown-button-block-form__title">{t(`${tNamespace}block-settings`)}</div>
                <div className="markdown-button-block-form__text">{t(`${tNamespace}placement`)}</div>
                <RadioButtonValidatableInput
                    value={ButtonPlacement.Inline}
                    id="formInline"
                    name={nameof<MarkdownButtonBlockValues>("placement")}
                    label={t(`${tNamespace}in-the-message`)}
                />
                <RadioButtonValidatableInput
                    value={ButtonPlacement.Keyboard}
                    id="formKeyboard"
                    name={nameof<MarkdownButtonBlockValues>("placement")}
                    label={t(`${tNamespace}keyboard`)}
                />
                <div className="markdown-button-block-form__text">{t(`${tNamespace}button-type-for-facebook`)}</div>
                <RadioButtonValidatableInput
                    value={ButtonFacebookType.Button}
                    id="formButton"
                    name={nameof<MarkdownButtonBlockValues>("fb_type")}
                    label={t(`${tNamespace}buttons`)}
                />
                <RadioButtonValidatableInput
                    value={ButtonFacebookType.QuickReply}
                    id="formQuickReply"
                    name={nameof<MarkdownButtonBlockValues>("fb_type")}
                    label={t(`${tNamespace}quick-replies`)}
                />
                <DebouncedCheckBox
                    id="formWhatsAppTemplate"
                    className="markdown-button-block-form__checkbox"
                    name={nameof<MarkdownButtonBlockValues>("whatsapp_template_enabled")}
                    label={t(`${tNamespace}whatsapp-template`)}
                />
                <div className="markdown-button-block-form__subsection">
                    <ValidatableInput
                        id="formTemplate"
                        type="text"
                        name={nameof<MarkdownButtonBlockValues>("template")}
                        label={t(`${tNamespace}template-name`)}
                        placeholder={t(formTranslation.enterText)}
                        disabled={!values.whatsapp_template_enabled}
                    />
                    <ValidatableInput
                        id="formLanguage"
                        type="text"
                        name={nameof<MarkdownButtonBlockValues>("language")}
                        label={t(`${tNamespace}language`)}
                        placeholder={t(formTranslation.enterText)}
                        disabled={!values.whatsapp_template_enabled}
                    />
                    <ValidatableInput
                        id="formVariables"
                        type="text"
                        name={nameof<MarkdownButtonBlockValues>("additional_value")}
                        label={t(`${tNamespace}variables-for-the-template`)}
                        placeholder={t(formTranslation.enterText)}
                        disabled={!values.whatsapp_template_enabled}
                    />
                </div>
            </div>
            <div className="markdown-button-block-form__footer">
                <Button className="markdown-button-block-form__cancel" variant="light" onClick={onCancel}>
                    {t(formTranslation.cancel)}
                </Button>
                <LoadingButton
                    className="markdown-button-block-form__submit"
                    variant="primary"
                    loading={loading}
                    type="submit"
                >
                    {t(formTranslation.save)}
                </LoadingButton>
            </div>
        </Form>
    )
}

export default FormikMarkdownButtonBlockForm
