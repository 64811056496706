import React, { useState } from "react"
import "./ProjectRoles.scss"
import { useTranslation } from "react-i18next"
import { AllRoleSettings, Role } from "../../models/role"
import { CellProps, Column } from "react-table"
import SettingsButton from "../SettingsButton/SettingsButton"
import { ModifyPositions } from "../../permissions"
import Can from "../Can/Can"
import { faPen, faTrash } from "@fortawesome/pro-light-svg-icons"
import SettingsContextMenu from "../SettingsContextMenu/SettingsContextMenu"
import SettingsContextMenuItem from "../SettingsContextMenuItem/SettingsContextMenuItem"
import { formTranslation } from "../../locales/form"
import AddButton from "../AddButton/AddButton"
import { useModal } from "../../utility/common/useModal"
import AlertDialog from "../AlertDialog/AlertDialog"
import { testId } from "../../utility/tests/testId"
import SettingsTableWithoutPagination from "../SettingsTable/SettingsTableWithoutPagination/SettingsTableWithoutPagination"

const tNamespace = "roles:"

interface Props {
    projectId: string
    title: string
    roles: Role[]
    allRoleSettings: AllRoleSettings
    onAddClick: () => void
    onSettingsClick: (role: Role) => void
    onDeleteClick: (role: Role) => void
}

const ProjectRoles: React.FC<Props> = props => {
    const { title, roles, onAddClick, onSettingsClick, onDeleteClick } = props
    const { t } = useTranslation()

    const [roleToDelete, setRoleToDelete] = useState<Role | null>(null)
    const { modalOpen, openModal, closeModal, onExited } = useModal(() => (
        <AlertDialog
            show={modalOpen}
            title={t(`${tNamespace}delete-confirmation.title`)}
            message={t(`${tNamespace}delete-confirmation.message`)}
            submitBtnText={t(formTranslation.delete)}
            onClose={closeModal}
            onSubmit={() => {
                roleToDelete && onDeleteClick(roleToDelete)
            }}
            variant="danger"
            onExited={onExited}
        />
    ))

    const getItems = (role: Role) => {
        return (
            <>
                <SettingsContextMenuItem
                    id="project-role-edit"
                    icon={faPen}
                    text={t(formTranslation.edit)}
                    onClick={() => onSettingsClick(role)}
                />
                <SettingsContextMenuItem
                    id="project-role-delete"
                    icon={faTrash}
                    text={t(formTranslation.delete)}
                    useSeparator={true}
                    danger={true}
                    onClick={() => {
                        setRoleToDelete(role)
                        openModal()
                    }}
                />
            </>
        )
    }

    const columns: Column<Role>[] = [
        {
            Header: t(`${tNamespace}roles`).toString(),
            accessor: "Title"
        },
        {
            id: "Settings",
            disableSortBy: true,
            Cell: (props: CellProps<Role>) => (
                <Can permission={ModifyPositions}>
                    <SettingsContextMenu items={getItems(props.row.original)}>
                        <SettingsButton />
                    </SettingsContextMenu>
                </Can>
            )
        }
    ]

    return (
        <div className="project-roles">
            <Can permission={ModifyPositions}>
                {can => (
                    <>
                        <div className="project-roles__header">
                            <span className="project-roles__title">{title}</span>
                            {can && (
                                <AddButton
                                    variant="outline-primary"
                                    className="project-roles__btn-add"
                                    text={t(`${tNamespace}add-role`)}
                                    onClick={onAddClick}
                                    data-test-id={testId.addRole}
                                />
                            )}
                        </div>
                        <SettingsTableWithoutPagination columns={columns} data={roles} onRowClick={onSettingsClick} />
                    </>
                )}
            </Can>
        </div>
    )
}

export default ProjectRoles
