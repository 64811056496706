import React from "react"
import "./ColorInput.scss"
import { Form } from "react-bootstrap"
import cn from "classnames"
import { useTranslation } from "react-i18next"
import { useField } from "formik"

import ValidatableInput from "../ValidatableInput/ValidatableInput"
import RootColorInput, { ColorInputType } from "./RootColorInput"
const tNamespace = "common:color."

interface FormikInputProps extends React.ComponentPropsWithoutRef<typeof ValidatableInput> {}

export interface ColorInputProps extends FormikInputProps {
    simplified?: boolean
    type?: ColorInputType
}

const ColorInput: React.FC<ColorInputProps> = props => {
    const [field, , helpers] = useField<any>(props)
    const { className, id, simplified = false, type } = props
    const { setValue } = helpers
    const { t } = useTranslation()
    return (
        <Form.Group className={cn("color-input", className)} controlId={id}>
            {!simplified && <Form.Label className="color-input__label">{t(`${tNamespace}label`)}</Form.Label>}
            <RootColorInput
                simplified={simplified}
                color={field.value}
                onChange={({ hex }) => setValue(hex.toUpperCase())}
                type={type}
            />
        </Form.Group>
    )
}

export default ColorInput
