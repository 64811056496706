import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import Async from "../Async/Async"
import { selectProjectUsersState } from "../../store/users/selectors"
import { getUsersByProject } from "../../store/users/thunks"
import ProjectUsers from "./ProjectUsers"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import { AllRoleSettings, Role } from "../../models/role"
import { getAllRoleSettings, getRoles } from "../../store/roles/thunks"
import { selectGetAllRoleSettingsState, selectGetRolesState } from "../../store/roles/selectors"
import SettingsTableLoader from "../SettingsTable/SettingsTableLoader"
import { ProjectUser } from "../../models/projectUser"
import AsyncState from "../../core/asyncState"
import useDelay from "../../utility/common/useDelay"
import { FormChangeCallback } from "../../pages/ProjectSettings/ProjectSettings"

interface Props {
    projectId: string
    title: string
    onInviteClick: (roles: Role[]) => void
    onSettingsClick: (user: ProjectUser, allRoleSettings: AllRoleSettings, roles: Role[]) => void
    onBlockClick: (user: ProjectUser) => void
    onFormChange: FormChangeCallback
}

const ProjectUsersContainer: React.FC<Props> = props => {
    const { projectId, title, onInviteClick, onSettingsClick, onBlockClick, onFormChange } = props

    const dispatch = useDispatch()
    const display = useDelay()

    const asyncState = AsyncState.merge3(
        useSelector(selectProjectUsersState),
        useSelector(selectGetAllRoleSettingsState),
        useSelector(selectGetRolesState)
    )

    useEffect(() => {
        dispatch(getUsersByProject(projectId))
        dispatch(getAllRoleSettings(projectId))
        dispatch(getRoles(projectId))
    }, [projectId, dispatch])

    if (!display) {
        return null
    }

    return (
        <Async
            dataState={asyncState}
            processView={<SettingsTableLoader columns={4} rows={3} rowHeight={65} />}
            errorView={({ message }) => <ErrorMessage text={message} />}
        >
            {({ data: [users, allRoleSettings, roles] }) => (
                <ProjectUsers
                    projectId={projectId}
                    title={title}
                    users={users}
                    roles={roles}
                    onInviteClick={onInviteClick}
                    onSettingsClick={v => onSettingsClick(v, allRoleSettings, roles)}
                    onBlockClick={onBlockClick}
                    onFormChange={onFormChange}
                />
            )}
        </Async>
    )
}

export default ProjectUsersContainer
