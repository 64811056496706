import React from "react"
import styles from "./SettingsButton.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisHAlt } from "@fortawesome/pro-light-svg-icons/faEllipsisHAlt"
import cn from "classnames"
import { ClassProps } from "../../utility/common/props"
import { testId } from "../../utility/tests/testId"

export interface SettingsButtonProps extends ClassProps {
    onClick?: () => void
}

const SettingsButton: React.FC<SettingsButtonProps> = props => {
    const { className, onClick } = props

    return (
        <div className={cn(styles.btnSettings, className)} onClick={onClick} data-test-id={testId.settingsButton}>
            <FontAwesomeIcon icon={faEllipsisHAlt} size="lg" className={styles.btnSettings__icon} />
        </div>
    )
}

export default SettingsButton
