import React, { useCallback, useEffect } from "react"
import "./TaskView.scss"
import { Tab } from "react-bootstrap"
import { isTask, TaskCommon } from "../../models/task"
import { useTranslation } from "react-i18next"
import { Operator } from "../../models/operator"
import TaskInfo from "../TaskInfo/TaskInfo"
import Tabs from "../Tabs/Tabs"
import TaskInfoLoader from "../TaskInfo/TaskInfoLoader"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import Async from "../Async/Async"
import dialogsController from "../../api/controllers/dialogs"
import TaskContent from "../TaskContent/TaskContent"
import useAsyncState from "../../utility/common/useAsyncState"
import messagesController from "../../api/controllers/messages"
import MessageListLoader from "../MessageList/MessageListLoader"
import { Queue } from "../../models/queue"
import operatorsController from "../../api/controllers/operators"
import AsyncState from "../../core/asyncState"
import { ChannelNames } from "../../models/channel"
import { useDispatch, useSelector } from "react-redux"
import { selectGetAgentsState } from "../../store/agents/selectors"
import { getAgents } from "../../store/agents/thunks"
import { selectCurrentProject } from "../../store/projects/selectors"

const tNamespace = "queues:task."

interface Props {
    task: TaskCommon
    channelNames?: ChannelNames
    operator?: Operator
    queue?: Queue
    queues: Queue[]
    close: Function
}

const FETCH_DIALOG_FAILED_MESSAGE = "error:task.fetch-dialog-failed"
const FETCH_MESSAGES_FAILED_MESSAGE = "error:task.fetch-messages-failed"
export const FETCH_OPERATORS_MESSAGE = "error:task.fetch-operators-failed"
const automaticProcessingQueueId = "automatic_processing"

const TaskView: React.FC<Props> = props => {
    const { task, channelNames, operator, queue, queues, close } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const project = useSelector(selectCurrentProject)
    const agentsState = useSelector(selectGetAgentsState)
    useEffect(() => {
        if (project && !agentsState.data) {
            dispatch(getAgents(project.id))
        }
    }, [agentsState.data, dispatch, project, task])
    const tabEntries = [
        { key: "info", value: t(`${tNamespace}info`) },
        { key: "content", value: t(`${tNamespace}content`) }
    ]

    const loadDialog = useCallback(() => dialogsController.get(task.Id), [task])
    const { asyncState: dialogState } = useAsyncState(loadDialog, FETCH_DIALOG_FAILED_MESSAGE)

    const channelId = isTask(task) ? task.Channel.Id : task.ChannelId
    const queueId = isTask(task) ? queue?.Id || task.QueueId : automaticProcessingQueueId
    const loadOperatorsNames = useCallback(
        () => (queueId ? operatorsController.getAllSummaryByTenant(task.TenantId) : Promise.resolve([])),
        [task.TenantId, queueId]
    )
    const { asyncState: operatorsNamesState } = useAsyncState(loadOperatorsNames, FETCH_OPERATORS_MESSAGE)

    const taskInfoState = AsyncState.merge3(dialogState, operatorsNamesState, agentsState)

    const loadMessages = useCallback(() => messagesController.get(task.Id), [task])
    const { asyncState: messagesState } = useAsyncState(loadMessages, FETCH_MESSAGES_FAILED_MESSAGE)

    return (
        <div className="task-view">
            <Tabs id="task-view-tabs" alwaysReload={false} entries={tabEntries}>
                <Tab.Pane eventKey={tabEntries[0].key}>
                    <Async
                        dataState={taskInfoState}
                        processView={<TaskInfoLoader count={5} />}
                        errorView={({ message }) => <ErrorMessage text={message} />}
                    >
                        {({ data: [dialog, operatorsNames, agents] }) => (
                            <>
                                <TaskInfo
                                    className="task-view__content"
                                    task={task}
                                    channelName={channelNames?.[channelId]}
                                    dialog={dialog}
                                    agents={agents}
                                    operator={operator}
                                    operatorsNames={operatorsNames}
                                    queues={queues}
                                    close={close}
                                />
                            </>
                        )}
                    </Async>
                </Tab.Pane>
                <Tab.Pane eventKey={tabEntries[1].key}>
                    <Async
                        dataState={messagesState}
                        processView={<MessageListLoader count={5} />}
                        errorView={({ message }) => <ErrorMessage text={message} />}
                    >
                        {({ data: messages }) => (
                            <>
                                <TaskContent className="task-view__content" messages={messages} />
                            </>
                        )}
                    </Async>
                </Tab.Pane>
            </Tabs>
        </div>
    )
}

export default TaskView
