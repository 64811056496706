import React from "react"
import "./ClassifierStatusIcon.scss"
import { ClassifierStatus } from "../../../models/classifier"
import cn from "classnames"
import { ClassProps } from "../../../utility/common/props"
import { ClassifierEventType } from "../../../models/classifierEvent"

interface Props extends ClassProps {
    status?: ClassifierStatus | ClassifierEventType
    children: string
}

const ClassifierStatusIcon: React.FC<Props> = props => {
    const { status, className, children } = props

    if (!status) return null

    return (
        <span className={cn("classifier-status-icon", `classifier-status-icon_${status.toLowerCase()}`, className)}>
            {children}
        </span>
    )
}

export default ClassifierStatusIcon
