import React from "react"
import FileAttachment from "../FileAttachment/FileAttachment"
import "./ArticleFile.scss"

type ArticleFileAttrs = {
    href: string
    payload?: {
        fileName: string
        fileType: string
    }
}

interface Props {
    attrs: ArticleFileAttrs
    isEditable: boolean
    isSelected: boolean
}

const ArticleFile: React.FC<Props> = props => {
    const {
        attrs: { href, payload }
    } = props

    return (
        <div className="article-file">
            {payload && (
                <FileAttachment
                    className="article-file__attachment"
                    attachment={{
                        Url: href,
                        ContentType: payload.fileType,
                        Name: payload.fileName
                    }}
                />
            )}
        </div>
    )
}

export default ArticleFile
