import React from "react"
import type { NotificationContentFunction } from "../../utility/notifications/notificationContentType"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getIconByType } from "../../utility/notifications/getIcon"
import styles from "./NotificationItem.module.scss"
import { Button } from "react-bootstrap"
import { formTranslation } from "../../locales/form"

const tNamespace = "notification:invite-operator."
export const inviteOperatorContextKeys = {
    dialogId: "dialogId",
    operatorId: "operatorId",
    operatorFirstName: "operatorFirstName",
    operatorLastName: "operatorLastName"
}

const NotificationInviteOperatorItem: NotificationContentFunction = (notification, t, onAcceptInviteOperator) => {
    const operatorFirstName = notification.Context?.[inviteOperatorContextKeys.operatorFirstName] ?? ""
    const operatorLastName = notification.Context?.[inviteOperatorContextKeys.operatorLastName] ?? ""
    const dialogId = notification.Context?.[inviteOperatorContextKeys.dialogId]
    const operatorId = notification.Context?.[inviteOperatorContextKeys.operatorId]
    const titleMessage = t(`${tNamespace}title`, { operatorName: `${operatorFirstName} ${operatorLastName}` })
    const descriptionMessage = notification.Description?.Value ?? ""

    const iconContent = <FontAwesomeIcon icon={getIconByType(notification.Type)} />
    const mainContent = (
        <>
            <div className={styles["notificationItemContainer__title"]}>{titleMessage}</div>
            {descriptionMessage && (
                <div className={styles["notificationItemContainer__description"]}>{descriptionMessage}</div>
            )}
        </>
    )
    const buttons =
        dialogId && operatorId && onAcceptInviteOperator ? (
            <Button onClick={() => onAcceptInviteOperator(operatorId, dialogId, notification.Id)}>
                {t(formTranslation.connect)}
            </Button>
        ) : (
            <></>
        )
    return {
        iconContent,
        mainContent,
        buttons
    }
}

export default NotificationInviteOperatorItem
