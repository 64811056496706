import React, { useCallback, useContext, useEffect } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { selectCurrentArticleState, selectCurrentBranch } from "../../../store/knowledgeBase/selectors"
import Async from "../../Async/Async"
import ArticleViewLoader from "../ArticleViewLoader"
import FullscreenError from "../../FullscreenError/FullscreenError"
import { useLocation, useParams, useRouteMatch } from "react-router-dom"
import { actions as storeActions, clearCurrentBranch, mutateCurrentBranch } from "../../../store/knowledgeBase/slice"
import type { TreeItem } from "@atlaskit/tree/types"
import { push } from "connected-react-router"
import { buildSharedArticleActionUrl } from "../../../utility/knowledgeBase/articleUrlHelper"
import { ArticleKind } from "../../../models/article"
import {
    getSharedArticle,
    getSharedRelatedCategories,
    getSharingArticleByCode
} from "../../../store/knowledgeBase/sharedThunks"
import { ArticleViewContextType } from "../../ArticleViewProvider/ArticleViewContext"
import ArticleViewProvider from "../../ArticleViewProvider/ArticleViewProvider"
import { KnowledgeBasePermittedAction } from "../../../models/knowledgeBasePermission"
import ArticleViewProcessor from "../ArticleViewProcessor"
import ArticleBlocked from "../../ArticleBlocked/ArticleBlocked"
import ScenarioArticle from "../../ScenarioArticle/ScenarioArticle"
import ArticleView from "../ArticleView"
import KnowledgeBaseContext from "../../KnowledgeBaseProvider/KnowledgeBaseContext"
import { sharedKnowledgeBasePath } from "../../../routerPaths"

interface SharedArticleViewContainerProps {
    onSubscribeToArticle: (articleCode: string) => void
    onUnsubscribeFromArticle: (articleCode: string) => void
    connected: boolean
}

const permittedAction = KnowledgeBasePermittedAction.View
const setMarkedMessagesCount = () => {}

const SharedArticleViewContainer: React.FC<SharedArticleViewContainerProps> = props => {
    const { connected, onSubscribeToArticle, onUnsubscribeFromArticle } = props
    const { code } = useParams<{ code: string }>()
    const { search } = useLocation()
    const { url } = useRouteMatch()
    const dispatch = useDispatch()
    const articleState = useSelector(selectCurrentArticleState)
    const { CurrentCatalogCode, CurrentCategoryId } = useSelector(selectCurrentBranch, shallowEqual)
    const { shareId } = useContext(KnowledgeBaseContext)

    useEffect(() => {
        shareId && code && dispatch(getSharingArticleByCode(shareId, code))
    }, [dispatch, code, shareId])

    useEffect(() => {
        connected && code && onSubscribeToArticle(code)
        return () => {
            connected && code && onUnsubscribeFromArticle(code)
        }
    }, [code, onSubscribeToArticle, onUnsubscribeFromArticle, connected])

    useEffect(() => {
        if (shareId && code) {
            dispatch(getSharedRelatedCategories(shareId, CurrentCategoryId, code, CurrentCatalogCode))
        }
    }, [shareId, code, CurrentCatalogCode, CurrentCategoryId, dispatch])

    useEffect(() => {
        return () => {
            dispatch(clearCurrentBranch())
        }
    }, [dispatch])

    const handleReloadArticle = useCallback(
        () => shareId && code && dispatch(getSharedArticle(shareId, code)),
        [dispatch, code, shareId]
    )

    const handleRelatedArticleClick = useCallback(
        (category: TreeItem) => {
            const {
                id,
                data: { symbolCode, catalogCode }
            } = category

            dispatch(push(buildSharedArticleActionUrl(`${sharedKnowledgeBasePath}/${shareId}`, symbolCode)))
            dispatch(
                mutateCurrentBranch({
                    CurrentCategoryId: id as string,
                    CurrentCatalogCode: catalogCode
                })
            )
        },
        [dispatch, shareId]
    )

    const handleSelectAnswer = useCallback(
        (answerId: string) => {
            dispatch(push(`${url}/${answerId}${search}`))
        },
        [dispatch, search, url]
    )

    const handleChangeCurrentAnswer = useCallback(
        (answerId: string) => {
            dispatch(storeActions.updateCurrentAnswerById(answerId))
        },
        [dispatch]
    )

    const articleViewContextValue: ArticleViewContextType = {
        permittedAction,
        availableKinds: [ArticleKind.Common, ArticleKind.Scenario],
        connected,
        onShare: () => {},
        markedMessagesCount: 0,
        setMarkedMessagesCount
    }

    return (
        <Async
            dataState={articleState}
            processView={<ArticleViewLoader />}
            errorView={({ message }) => <FullscreenError message={message} hideLogo />}
        >
            {({ data: { Article } }) => (
                <ArticleViewProvider {...articleViewContextValue}>
                    <ArticleViewProcessor
                        article={Article}
                        articleCode={code}
                        permittedAction={permittedAction}
                        transformToScenario={false}
                        blocked={<ArticleBlocked onReloadArticle={handleReloadArticle} />}
                        setMarkedMessagesCount={setMarkedMessagesCount}
                        shareId={shareId}
                        scenario={(article, _) => (
                            <ScenarioArticle
                                article={article}
                                isEdit={false}
                                canEdit={false}
                                transformToScenario={false}
                                onRelatedArticleClick={handleRelatedArticleClick}
                            />
                        )}
                        classic={(article, _) => (
                            <ArticleView
                                article={article}
                                isEdit={false}
                                canEdit={false}
                                notificationExists={false}
                                onChangeCurrentAnswer={handleChangeCurrentAnswer}
                                onSelectAnswer={handleSelectAnswer}
                                onRelatedArticleClick={handleRelatedArticleClick}
                            />
                        )}
                    />
                </ArticleViewProvider>
            )}
        </Async>
    )
}

export default SharedArticleViewContainer
