import React from "react"
import "./ArticleKindSelect.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/pro-light-svg-icons/faAngleDown"
import { useTranslation } from "react-i18next"
import { ArticleKind } from "../../models/article"
import SidebarContextMenu from "../SIdebarContextMenu/SidebarContextMenu"
import MenuItemRow from "../MenuItemRow/MenuItemRow"

const tNamespace = "knowledgeBase:"

interface SelectProps {
    kinds: ArticleKind[]
    currentKind: ArticleKind
    onSelect: (kind: ArticleKind) => void
}

const ArticleKindSelect: React.FC<SelectProps> = props => {
    const { t } = useTranslation()
    const { currentKind, onSelect, kinds } = props

    return (
        <SidebarContextMenu
            placement="bottom-start"
            containerClassName="article-kind-select"
            className="article-kind-select__menu"
            items={kinds.map(k => (
                <MenuItemRow
                    key={k}
                    className="article-kind-select__item"
                    title={t(`${tNamespace}${k.toLowerCase()}`)}
                    onClick={() => onSelect(k)}
                />
            ))}
        >
            <div className="article-kind-select__toggle">
                <span className="article-kind-select__text">{t(`${tNamespace}${currentKind.toLowerCase()}`)}</span>
                <FontAwesomeIcon icon={faAngleDown} className="article-kind-select__icon" />
            </div>
        </SidebarContextMenu>
    )
}

export default ArticleKindSelect
