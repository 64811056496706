import React, { ChangeEvent, useCallback } from "react"
import cn from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes"
import { faGripLinesVertical } from "@fortawesome/pro-light-svg-icons/faGripLinesVertical"
import { Form } from "react-bootstrap"
import {
    DraggableProvided,
    DraggableProvidedDragHandleProps,
    DraggableRubric,
    DraggableStateSnapshot
} from "react-beautiful-dnd"
import { FormSlot } from "../../../../models/scenario"
import { SlotsMapType } from "../../../../models/slot"
import { ElementId } from "react-flow-renderer/dist/nocss/types"
import TooltipTrigger from "../../../TooltipTrigger/TooltipTrigger"

export interface Props {
    slotsMap: SlotsMapType
    slots: FormSlot[]
    setSlots: (updated: FormSlot[]) => void
    blockId: ElementId
}

export const renderFieldItem =
    (params: Props) => (provided: DraggableProvided, snapshot: DraggableStateSnapshot, rubric: DraggableRubric) => {
        const { slotsMap, slots, setSlots, blockId } = params
        const slot = slots[rubric.source.index]

        const handleMandatoryToggle = useCallback(
            (e: ChangeEvent<HTMLInputElement>) =>
                setSlots(
                    slots.map(field =>
                        field.SlotId === slot.SlotId ? { SlotId: slot.SlotId, IsMandatory: e.target.checked } : field
                    )
                ),
            [slots, setSlots, slot.SlotId]
        )

        const handleDelete = useCallback(
            () => setSlots(slots.filter(field => field.SlotId !== slot.SlotId)),
            [slots, setSlots, slot.SlotId]
        )

        return (
            <div ref={provided.innerRef} {...provided.draggableProps} className="form-node__item-container">
                <FormFieldItem
                    id={`${blockId}-${slot.SlotId}`}
                    title={slotsMap[slot.SlotId]?.Title ? slotsMap[slot.SlotId].Title : `{{ ${slot.SlotId} }}`}
                    isDragging={snapshot.isDragging}
                    dragHandleProps={provided.dragHandleProps}
                    onMandatoryToggle={handleMandatoryToggle}
                    onDelete={handleDelete}
                    checked={slot.IsMandatory}
                />
            </div>
        )
    }

interface ItemProps {
    id: string
    title: string
    isDragging: boolean
    dragHandleProps?: DraggableProvidedDragHandleProps
    onMandatoryToggle: (e: ChangeEvent<HTMLInputElement>) => void
    onDelete: () => void
    checked: boolean
}

const tooltipDelay = { show: 500, hide: 0 }

const FormFieldItem: React.FC<ItemProps> = props => {
    const { id, title, isDragging, dragHandleProps, onMandatoryToggle, onDelete, checked } = props
    return (
        <div className={cn("form-node__item", isDragging && "form-node__item_dragging")}>
            <span className="form-node__drag-icon" {...dragHandleProps}>
                <FontAwesomeIcon icon={faGripLinesVertical} />
            </span>
            <TooltipTrigger id={`popover${id}`} content={title} delay={tooltipDelay}>
                <span className="form-node__title">{title}</span>
            </TooltipTrigger>
            <Form.Check
                checked={checked}
                id={`checkbox-${id}`}
                custom
                inline
                className="form-node__checkbox"
                onChange={onMandatoryToggle}
            />
            <FontAwesomeIcon icon={faTimes} className="form-node__remove-icon" onClick={onDelete} />
        </div>
    )
}

export default FormFieldItem
