import { Hub } from "../hub"
import { IHub } from "../interfaces/IHub"
import { Dispatch } from "../../utility/common/storeHelper"
import { logError } from "../../utility/common/logError"
import { Store } from "../../store/store"
import { isProjectSettings } from "../../models/projectSettings"
import { updateProjectSettings } from "../../store/projects/actions"

const DIFF_UPDATE = "DiffUpdate"

class ProjectSettingsHub {
    private _hub: IHub

    constructor(store: Store) {
        this._hub = new Hub("/project-settings-hub")
        this.registerServerEvents(store.dispatch)
    }

    async subscribe(projectId: string) {
        await this._hub.subscribe("Subscribe", projectId)
    }

    async unsubscribe(projectId: string) {
        await this._hub.unsubscribe("Unsubscribe", projectId)
    }

    private registerServerEvents(dispatch: Dispatch) {
        this._hub.registerEvent(DIFF_UPDATE, (rawData: string) => {
            try {
                const settings = JSON.parse(rawData)
                if (isProjectSettings(settings)) {
                    updateProjectSettings(dispatch, settings)
                } else {
                    logError("Invalid project settings data:", settings)
                }
            } catch (e) {
                logError(e)
            }
        })
    }
}

export default ProjectSettingsHub
