export type NotificationDtoText = {
    Value: string
    NeedLocalization: boolean
}

export type NotificationDtoType = "KnowledgeBase" | "InviteOperator"

export type NotificationDtoContext = {
    [key: string]: string
}

export type NotificationDto = {
    Id: string
    UserId: string
    Title: NotificationDtoText
    Priority: number
    Description?: NotificationDtoText
    Link?: string
    NeedApprove: boolean
    Icon?: string
    Timestamp: number
    Type?: NotificationDtoType
    Context?: NotificationDtoContext
}

export const isNotificationDto = (value: unknown): value is NotificationDto => {
    return !!(
        value &&
        typeof value === "object" &&
        value.hasOwnProperty("Id") &&
        value.hasOwnProperty("UserId") &&
        value.hasOwnProperty("NeedApprove")
    )
}
