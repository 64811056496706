import React, { useState } from "react"
import styles from "./PasswordControl.module.scss"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons"
import { formTranslation } from "../../locales/form"
import { useTranslation } from "react-i18next"

interface Props {
    name: string
    label: string
    testId?: string
    autocomplete?: "current-password" | "new-password"
}

const PasswordControl: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { name, label, autocomplete, testId } = props
    const [showPassword, setShowPassword] = useState(false)

    return (
        <div className={styles.passwordControl}>
            <ValidatableInput
                id="formPassword"
                type={showPassword ? "text" : "password"}
                name={name}
                label={label}
                className={styles.passwordControl__input}
                placeholder={t(formTranslation.enterPassword)}
                autocomplete={autocomplete}
                testId={testId}
            />
            <span className={styles.passwordControl__iconContainer}>
                <FontAwesomeIcon
                    icon={showPassword ? faEye : faEyeSlash}
                    className={styles.passwordControl__icon}
                    onClick={() => setShowPassword(!showPassword)}
                />
            </span>
        </div>
    )
}

export default PasswordControl
