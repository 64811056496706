import { getChangeRouteMessage, getFrameUrl, getLanguageMessage } from "../../utility/project/frameHelper"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { selectFrameForceUnloaded, selectFrameLoadingState } from "../../store/projects/selectors"
import { getLanguage } from "../../utility/common/language"
import { logDebug, logError } from "../../utility/common/logError"
import { reloadFrame } from "../../store/projects/thunks"
import { actions } from "../../store/projects/slice"
import Frame from "../Frame/Frame"
import styles from "./OldWorkplaceFrame.module.scss"
import FullscreenLoader from "../FullscreenLoader/FullscreenLoader"

const frameName = "crafttalk-workplace-old-frame"
const allowedHref = getFrameUrl()

export interface OldWorkplaceFrameProps {
    selectedProject?: string
    selectedMenu?: string
    menuId?: string
}

const OldWorkplaceFrame: React.FC<OldWorkplaceFrameProps> = props => {
    const { selectedMenu, selectedProject, menuId } = props
    const { i18n } = useTranslation()
    const frameState = useSelector(selectFrameLoadingState)
    const frameForceUnloaded = useSelector(selectFrameForceUnloaded)
    const [postMessageData, setPostMessageData] = useState<string | object | undefined>(undefined)

    const [lastProjectId, setLastProjectId] = useState<string | undefined>(undefined)
    const dispatch = useDispatch()

    useEffect(() => {
        if (selectedProject !== lastProjectId) {
            if (selectedProject && lastProjectId && menuId) {
                setPostMessageData(getChangeRouteMessage(menuId))
            }
            if (selectedProject) {
                setLastProjectId(selectedProject)
            }
        }
    }, [selectedProject, lastProjectId, menuId])

    useEffect(() => {
        if (menuId) {
            setPostMessageData(getChangeRouteMessage(menuId))
        }
    }, [dispatch, menuId, selectedMenu])

    useEffect(() => {
        if (!frameState.inProcess) {
            const language = getLanguage(i18n)
            setPostMessageData(getLanguageMessage(language))
        }
    }, [frameState, i18n])

    const handleFrameLoad = (event: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
        try {
            if (event.currentTarget.contentWindow?.location.href !== allowedHref) {
                logDebug("Frame location changing detected")
                dispatch(reloadFrame())
            }
        } catch (e) {
            logError(e)
            dispatch(reloadFrame())
        }
    }
    const handleReady = () => dispatch(actions.frameLoadedSuccess())

    return (
        <>
            {!frameForceUnloaded && (
                <Frame
                    isMounted={!!menuId && !frameState.inProcess}
                    src={getFrameUrl()}
                    name={frameName}
                    frameClassName={styles.oldWorkplaceFrame}
                    postMessageData={postMessageData}
                    onReady={handleReady}
                    onLoad={handleFrameLoad}
                />
            )}
            {!!menuId && frameState.inProcess && <FullscreenLoader hideLogo />}
        </>
    )
}

export default OldWorkplaceFrame
