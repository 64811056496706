import React from "react"
import "./ProjectCard.scss"
import { ClassProps } from "../../utility/common/props"
import { Project } from "../../models/project"
import cn from "classnames"

interface Props extends ClassProps {
    project: Project
    onClick?: Function
    isShort?: boolean
}

const ProjectCard: React.FC<Props> = props => {
    const { project, onClick, className, isShort } = props
    return (
        <div
            className={cn("project-card", className, isShort && "project-card_short")}
            onClick={() => onClick && onClick()}
            data-project-id={project.id}
            style={onClick && { cursor: "pointer" }}
        >
            <div className="project-card__image">
                {project.logo && <img src={project.logo} alt="" className="project-card__image-item" />}
            </div>
            <div className="project-card__name">
                <span className="project-card__name-item">{project.name}</span>
            </div>
        </div>
    )
}

export default ProjectCard
