import React, { useState } from "react"
import { SelectCallback } from "react-bootstrap/helpers"
import { useParams } from "react-router-dom"
import TabsBase from "../TabsBase/TabsBase"
import { TabEntry } from "../../types/tabEntry"

export interface TabsProps {
    id: string
    alwaysReload: boolean
    entries: TabEntry[]
    useUrl?: boolean
    onSelect?: SelectCallback
}

const Tabs: React.FC<TabsProps> = props => {
    const { id, alwaysReload, entries, useUrl, onSelect } = props

    const { tabId } = useParams<{ tabId?: string }>()
    const defaultTab = useUrl && tabId ? tabId : entries[0].key

    const [key, setKey] = useState<string>(defaultTab)

    const handleSelect = (key: string | null, e: React.SyntheticEvent<unknown>) => {
        if (key === null) return
        setKey(key)
        if (onSelect) onSelect(key, e)
    }

    return (
        <TabsBase id={id} activeKey={key} entries={entries} alwaysReload={alwaysReload} handleSelect={handleSelect}>
            {props.children}
        </TabsBase>
    )
}

export default Tabs
