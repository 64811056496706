import React, { ReactElement } from "react"
import usePermissionsCheck from "../../utility/common/usePermissionsCheck"
import { isFunction } from "../../utility/common/isFunction"

interface Props {
    permission: string
    children: ((can: boolean) => ReactElement | null) | ReactElement | null
    no?: () => ReactElement | null
}

const Can: React.FC<Props> = props => {
    const { permission, children, no } = props
    const allowed = usePermissionsCheck([permission])

    return isFunction(children) ? children(allowed) : allowed ? children : no ? no() : null
}

export default Can
