import React, { useMemo, useState } from "react"
import "./SlotForm.scss"
import { SlotDto, SlotValues } from "../../../models/slot"
import { Formik } from "formik"
import { useTranslation } from "react-i18next"
import ValidatableFormTabs from "../../ValidatableFormTabs/ValidatableFormTabs"
import { FormikProps } from "formik/dist/types"
import { WithT } from "i18next"
import FormikSlotForm from "./FormikSlotForm"
import {
    buildCreateSlotRequest,
    buildUpdateSlotRequest,
    getInitialValues
} from "../../../utility/knowledgeBase/slotForm"
import { createSlot, updateSlot } from "../../../store/slots/thunks"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentProjectId } from "../../../store/projects/selectors"
import * as Yup from "yup"
import AsyncState from "../../../core/asyncState"
import { projectIdRegex } from "../../../utility/common/projectIdRegex"
import { formTranslation } from "../../../locales/form"

const tNamespace = "slot:"
const tErrorNamespace = "slot:error."
export const tSlotFormNamespace = "slot:form."

export interface SlotFormProps {
    slot?: SlotDto
    onAddSlot?: (slotId: string, slotTitle: string) => void
    onUpdateSlot?: (slot: SlotDto) => void
}

export interface FormikSlotFormProps extends FormikProps<SlotValues>, WithT {
    slot?: SlotDto
    asyncState: AsyncState<void>
}

const SlotForm: React.FC<SlotFormProps> = props => {
    const { t } = useTranslation()
    const { slot, onAddSlot, onUpdateSlot } = props
    const projectId = useSelector(selectCurrentProjectId)
    const dispatch = useDispatch()

    const [isDictionary, setIsDictionary] = useState(false)

    const tabEntries = useMemo(() => {
        const tabs = [
            { key: "general", value: t(`${tNamespace}general`) },
            { key: "advanced", value: t(`${tNamespace}advanced`) }
        ]
        isDictionary && tabs.push({ key: "dictionary", value: t(`${tNamespace}dictionary-values`) })
        return tabs
    }, [isDictionary, t])

    const handleSubmit = (values: SlotValues) => {
        if (!projectId) return

        if (slot) {
            dispatch(
                updateSlot(projectId, slot.Id, buildUpdateSlotRequest(values), (slot: SlotDto) => {
                    onUpdateSlot && onUpdateSlot(slot)
                })
            )
        } else {
            dispatch(
                createSlot(projectId, buildCreateSlotRequest(values), (slot: SlotDto) => {
                    onAddSlot && onAddSlot(slot.ExternalId, slot.Title)
                })
            )
        }
    }

    const initialValues = useMemo(() => getInitialValues(slot), [slot])

    if (!projectId || !initialValues) return null

    return (
        <ValidatableFormTabs id="slot-settings-tabs" alwaysReload={false} entries={tabEntries}>
            {validateTabs => (
                <Formik
                    validationSchema={Yup.object().shape({
                        Title: Yup.string().requiredExcludeEmpty(`${tErrorNamespace}title-required`),
                        ...(!slot?.ExternalId && {
                            ExternalId: Yup.string()
                                .requiredExcludeEmpty(`${tErrorNamespace}id-required`)
                                .matches(projectIdRegex, {
                                    message: `${tErrorNamespace}invalid-id`,
                                    excludeEmptyString: true
                                })
                        }),
                        Domain: Yup.array().of(
                            Yup.object().shape({
                                Id: Yup.string().when("IsEmpty", {
                                    is: value => !value,
                                    then: Yup.string()
                                        .requiredExcludeEmpty(formTranslation.idRequired)
                                        .matches(projectIdRegex, {
                                            message: `${tErrorNamespace}invalid-id`,
                                            excludeEmptyString: true
                                        })
                                }),
                                Title: Yup.string().when("IsEmpty", {
                                    is: value => !value,
                                    then: Yup.string().requiredExcludeEmpty(formTranslation.titleRequired)
                                })
                            })
                        )
                    })}
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {formikProps => (
                        <FormikSlotForm
                            formikProps={formikProps}
                            isDictionary={isDictionary}
                            setIsDictionary={setIsDictionary}
                            tabEntries={tabEntries}
                            slot={slot}
                            validateTabs={validateTabs}
                        />
                    )}
                </Formik>
            )}
        </ValidatableFormTabs>
    )
}

export default SlotForm
