import React, { useState } from "react"
import "./ChooseSlotForm.scss"
import { useSelector } from "react-redux"
import { selectSlots } from "../../store/slots/selectors"
import SearchInput from "../SearchInput/SearchInput"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"

const tNamespace = "knowledgeBase:slot."

export interface ChooseSlotFormProps {
    onCreate?: () => void
    onSelect: (slotId: string, slotTitle: string) => void
}

const ChooseSlotForm: React.FC<ChooseSlotFormProps> = props => {
    const { t } = useTranslation()
    const { onCreate, onSelect } = props
    const slots = useSelector(selectSlots)

    const [query, setQuery] = useState("")
    const handleSearchChange = (searchValue: string) => setQuery(searchValue.toLowerCase())

    const filteredSlots = query
        ? slots.filter(s => s.Title.toLowerCase().includes(query) || s.Id.toLowerCase().includes(query))
        : slots

    return (
        <div className="choose-slot-form">
            {onCreate && (
                <Button onClick={onCreate} variant="light" className="choose-slot-form__create-btn">
                    {t(`${tNamespace}create-new-slot`)}
                </Button>
            )}
            <SearchInput
                onChange={handleSearchChange}
                className="choose-slot-form__search"
                disabled={!filteredSlots.length}
            />
            <div className="choose-slot-form__content">
                {filteredSlots.map(slot => (
                    <div
                        key={slot.Id}
                        className="choose-slot-form__item"
                        onClick={() => onSelect(slot.ExternalId, slot.Title)}
                    >
                        {slot.Title || `{{ ${slot.ExternalId} }}`}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ChooseSlotForm
