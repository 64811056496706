import { Formik, FormikProps } from "formik"
import { WithT } from "i18next"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Queue } from "../../models/queue"
import { RerouteTaskRequest, Task } from "../../models/task"
import { selectRerouteTaskState } from "../../store/tasks/selectors"
import { nameof } from "../../utility/common/nameof"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import * as Yup from "yup"
import { rerouteTask } from "../../store/tasks/thunks"
import { ClassProps } from "../../utility/common/props"
import { OperatorSummary } from "../../models/operator"
import RouteTaskFormBase from "../RouteTaskFormBase/RouteTaskFormBase"
import useOperatorQueues from "../../utility/queues/useOperatorQueues"

const tNamespace = "task:form."

interface RouteAssignedTaskValues {
    operatorId: string
    queueId: string
    message?: string
}

interface Props extends ClassProps {
    task: Task
    operatorId?: string
    queues: Queue[]
    operators: OperatorSummary[]
    onCancel: () => void
    afterRoute: Function
}

const defaultValues = (): RouteAssignedTaskValues => ({
    operatorId: "",
    queueId: ""
})

const getRemainingOperators = (operators: OperatorSummary[], currentOperatorId?: string) => {
    if (!currentOperatorId) {
        return operators
    }
    return operators.filter(v => v.Id !== currentOperatorId)
}

const FormikRouteTaskForm: React.FC<Props & FormikProps<RouteAssignedTaskValues> & WithT> = props => {
    const { className, t, operatorId, queues, operators, onCancel } = props

    const remainingOperators = useMemo(() => getRemainingOperators(operators, operatorId), [operatorId, operators])

    const operatorQueues = useOperatorQueues<RouteAssignedTaskValues>(remainingOperators, queues)

    const rerouteTaskState = useSelector(selectRerouteTaskState)

    return (
        <RouteTaskFormBase t={t} className={className} onCancel={onCancel} state={rerouteTaskState}>
            <ValidatableInput
                id="formTitle"
                as="select"
                type="text"
                name={nameof<RouteAssignedTaskValues>("operatorId")}
            >
                <option hidden value="">
                    {t(`${tNamespace}select-operator`)}
                </option>
                {remainingOperators.map(v => (
                    <option value={v.Id} key={v.Id}>{`${v.FirstName} ${v.LastName}`}</option>
                ))}
            </ValidatableInput>
            <ValidatableInput
                id="formTitle"
                as="select"
                type="text"
                name={nameof<RouteAssignedTaskValues>("queueId")}
                label={t(`${tNamespace}to-operator-queue`)}
            >
                <option hidden value="">
                    {t(`${tNamespace}select-queue`)}
                </option>
                {operatorQueues.map(q => (
                    <option value={q.Id} key={q.Id}>
                        {q.Name}
                    </option>
                ))}
            </ValidatableInput>
            <ValidatableInput
                id="greeting"
                type="text"
                as="textarea"
                name={nameof<RouteAssignedTaskValues>("message")}
                placeholder={t(`${tNamespace}to-operator-message`)}
                rows="3"
            />
        </RouteTaskFormBase>
    )
}

const createAssignedTaskRequest = (task: Task, values: RouteAssignedTaskValues): RerouteTaskRequest => {
    return {
        TaskId: task.Id,
        OperatorId: values.operatorId,
        QueueId: values.queueId,
        Message: values.message
    }
}

const RouteTaskForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { task, afterRoute } = props

    return (
        <Formik
            enableReinitialize={true}
            initialValues={defaultValues()}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={() => {
                return Yup.object().shape({
                    queueId: Yup.string().requiredExcludeEmpty(t(`${tNamespace}queue-required`)),
                    operatorId: Yup.string().requiredExcludeEmpty(t(`${tNamespace}operator-required`))
                })
            }}
            onSubmit={(values: RouteAssignedTaskValues) => {
                dispatch(rerouteTask(createAssignedTaskRequest(task, values), afterRoute))
            }}
        >
            {formikProps => <FormikRouteTaskForm {...props} {...formikProps} t={t} />}
        </Formik>
    )
}

export default RouteTaskForm
