import React, { useCallback, useContext } from "react"
import "./ConditionList.scss"
import ConditionNode from "../ConditionNode/ConditionNode"
import AddButtonNode from "../../AddButton/AddButtonNode"
import { Condition, SlotSpecialValue } from "../../../../../models/scenarioCondition"
import cn from "classnames"
import { ClassProps } from "../../../../../utility/common/props"
import { ArticleScenarioContext } from "../../../../ScenarioEditor/ScenarioContext"
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd"
import ConditionNodeLines from "../ConditionNodeLines/ConditionNodeLines"
import { reorder } from "../../../../../utility/scenario/scenario"

interface Props extends ClassProps {
    blockId: string
    conditions: Condition[]
    onAddBlock: (sourceHandle: string) => void
    onAddCondition: () => void
    onRemoveCondition: (conditionId: string) => void
    isConnectable: boolean
    selected: boolean
    onSelect: (condition: Condition) => () => void
    updateConditions: (conditions: Condition[]) => void
}

const ConditionList: React.FC<Props> = props => {
    const {
        blockId,
        conditions,
        onAddBlock,
        onAddCondition,
        onRemoveCondition,
        isConnectable,
        selected,
        onSelect,
        className,
        updateConditions
    } = props

    const { selectedCondition } = useContext(ArticleScenarioContext)

    const handleDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination || result.destination.index === result.source.index) return
            updateConditions(reorder(conditions, result.source.index, result.destination.index))
        },
        [updateConditions, conditions]
    )

    return (
        <>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable
                    droppableId={blockId}
                    renderClone={(provided, snapshot, rubric) => (
                        <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className="condition-node-list__clone"
                        >
                            <ConditionNodeLines conditions={conditions[rubric.source.index].And} />
                        </div>
                    )}
                >
                    {provided => (
                        <div
                            ref={provided.innerRef}
                            className={cn("condition-node-list", className)}
                            {...provided.droppableProps}
                        >
                            {conditions.map((c, index) => (
                                <Draggable draggableId={c.Id} index={index} key={c.Id} isDragDisabled={!selected}>
                                    {provided => (
                                        <div {...provided.draggableProps} ref={provided.innerRef}>
                                            <ConditionNode
                                                id={
                                                    c.And.length === 1 &&
                                                    c.And[0].Slot[0] === SlotSpecialValue.WithoutConditions
                                                        ? blockId
                                                        : c.Id
                                                }
                                                className={cn(
                                                    "condition-node-list__item",
                                                    (selected || c.Id === selectedCondition) &&
                                                        "condition-node-list__item_selected"
                                                )}
                                                conditions={c.And}
                                                onAddBlock={onAddBlock}
                                                onRemove={onRemoveCondition}
                                                isConnectable={isConnectable}
                                                selected={selected}
                                                onSelect={onSelect(c)}
                                                dragHandleProps={provided.dragHandleProps}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                            <AddButtonNode
                                onClick={onAddCondition}
                                className={cn(
                                    "condition-node-list__add-btn",
                                    !isConnectable && "condition-node-list__add-btn_hidden"
                                )}
                            />
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <span>&nbsp;</span>
        </>
    )
}

export default ConditionList
