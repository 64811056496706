import React, { useState } from "react"
import styles from "./CopyInput.module.scss"
import { Button, FormControl, InputGroup } from "react-bootstrap"
import { faCopy, faSync, faTrash } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"
import { formTranslation } from "../../locales/form"
import { useTranslation } from "react-i18next"
import copy from "copy-to-clipboard"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import LoadingButton from "../LoadingButton/LoadingButton"

export interface CopyInputProps extends ClassProps {
    id: string
    label?: string
    value?: string
    as?: React.ElementType
    blocked?: boolean
    loading?: boolean
    onUpdate?: () => void
    deleteLoading?: boolean
    onDelete?: () => void
}

const CopyInput: React.FC<CopyInputProps> = props => {
    const { id, label, value = "", className, as, blocked, loading, onUpdate, onDelete, deleteLoading } = props
    const { t } = useTranslation()
    const [show, setShow] = useState(false)
    const onClick = () => {
        copy(value)
        setShow(true)
        setTimeout(() => {
            setShow(false)
        }, 1000)
    }

    return (
        <>
            {label && <label>{label}</label>}
            <TooltipTrigger id={id} placement="top" content={t(formTranslation.copied)} show={show}>
                <InputGroup>
                    <FormControl className={cn(styles.copyInput, className)} readOnly value={value} as={as} />
                    <InputGroup.Prepend>
                        <Button
                            as="div"
                            className={cn(styles.copyInputControl, blocked && "blocked")}
                            onClick={onClick}
                        >
                            <FontAwesomeIcon className={styles.copyInputControl__icon} icon={faCopy} />
                        </Button>
                        {onUpdate && (
                            <LoadingButton
                                as="div"
                                className={cn(styles.copyInputControl)}
                                onClick={onUpdate}
                                loading={loading}
                            >
                                <FontAwesomeIcon className={cn(styles.copyInputControl__icon)} icon={faSync} />
                            </LoadingButton>
                        )}
                        {onDelete && (
                            <LoadingButton
                                as="div"
                                className={cn(styles.copyInputControl)}
                                onClick={onDelete}
                                loading={deleteLoading}
                            >
                                <FontAwesomeIcon className={cn(styles.copyInputControl__icon)} icon={faTrash} />
                            </LoadingButton>
                        )}
                    </InputGroup.Prepend>
                </InputGroup>
            </TooltipTrigger>
        </>
    )
}

export default CopyInput
