import React from "react"
import { ChannelFormProps } from "../ChannelForm/ChannelForm"
import { FormikProps } from "formik"
import { ChannelValues } from "../../models/channelValues"
import { WithT } from "i18next"
import { shallowEqual, useSelector } from "react-redux"
import { selectCreateChannelState, selectUpdateChannelState } from "../../store/channels/selectors"
import { nameof2 } from "../../utility/common/nameof"
import { ParamValues } from "../../models/parameterDeclaration"
import RadioButtonValidatableInput from "../RadioButtonValidatableInput/RadioButtonValidatableInput"
import DebouncedValidatableInput from "../ValidatableInput/DebouncedValidatableInput"
import ChannelFormWrapper from "../ChannelFormWrapper/ChannelFormWrapper"

const tNamespace = "channel:form."

const FormikFacebookChannelFormAppearance: React.FC<ChannelFormProps & FormikProps<ChannelValues> & WithT> = props => {
    const { channel, t, handleSubmit, disabled } = props

    const asyncState = useSelector(channel ? selectUpdateChannelState : selectCreateChannelState, shallowEqual)

    return (
        <ChannelFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={asyncState.inProcess}>
            <div className="channel-form__section">
                <div className="channel-form__text">{t(`${tNamespace}button-type`)}</div>
                <RadioButtonValidatableInput
                    value="button"
                    id="formButtonType1"
                    name={nameof2<ChannelValues, ParamValues>("Params", "keyboard_type")}
                    label={t(`${tNamespace}buttons`)}
                />
                <RadioButtonValidatableInput
                    value="quick_reply"
                    id="formButtonType2"
                    name={nameof2<ChannelValues, ParamValues>("Params", "keyboard_type")}
                    label={t(`${tNamespace}quick-replies`)}
                />
            </div>
            <div className="channel-form__section">
                <DebouncedValidatableInput
                    id="formDialogScoreThankYou"
                    type="text"
                    name={nameof2<ChannelValues, ParamValues>("Params", "dialog_score_thank_you")}
                    label={t(`${tNamespace}text-after-dialog-rating`)}
                />
            </div>
        </ChannelFormWrapper>
    )
}

export default FormikFacebookChannelFormAppearance
