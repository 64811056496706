import { HELP_LINK_BASE } from "../common/urlHelper"
import { QuickActionParser } from "./quickActionParser/quickActionParser"
import { QuickAction } from "./quickActionParser/quickActionAttribute"
import { TFunction } from "i18next"

const tNamespace = "knowledgeBase:quick-actions."

export const getDefaultQuickActions = (t: TFunction) => `::
[${t(`${tNamespace}add-user`)}](action:AddUser subtitle:"${t(`${tNamespace}add-users-description`)}" buttonTitle:"${t(
    `${tNamespace}add`
)}")
[${t(`${tNamespace}test-chat`)}](action:OpenChannel channelType:"Webchat" channelId:"test WebChat" subtitle:"${t(
    `${tNamespace}test-chat-description`
)}" buttonTitle:"${t(`${tNamespace}test`)}")

::
[${t(`${tNamespace}create-telegram-bot`)}](action:AddChannel channelType:"Telegram" subtitle:"${t(
    `${tNamespace}create-telegram-bot-description`
)}" buttonTitle:"${t(`${tNamespace}create`)}")
[${t(`${tNamespace}check-documentation`)}](action:OpenURL url:"${HELP_LINK_BASE}" icon:envelope-open subtitle:"${t(
    `${tNamespace}check-documentation-description`
)}" buttonTitle:"${t(`${tNamespace}check`)}")       
`

const groupIntoRows = (quickActions: QuickAction[]): QuickAction[][] => {
    return quickActions.reduce((result: QuickAction[][], a) => {
        const row = a.Row - 1
        result[row] ? result[row].push(a) : (result[row] = [a])
        return result
    }, [])
}

export const getQuickActions = (markdown: string): QuickAction[][] => {
    const quickActions = QuickActionParser.getQuickActions(markdown)
    return groupIntoRows(quickActions)
}
