import React from "react"
import styles from "./StickerForm.module.scss"
import { FormikProps } from "formik"
import { WithT } from "i18next"
import { ChannelStickerValuesType, Sticker, StickerValues } from "../../../models/sticker"
import { Form } from "react-bootstrap"
import LoadingButton from "../../LoadingButton/LoadingButton"
import { formTranslation } from "../../../locales/form"
import { shallowEqual, useSelector } from "react-redux"
import { selectCreateStickerState, selectUpdateStickerState } from "../../../store/stickers/selectors"
import { nameof, nameof2 } from "../../../utility/common/nameof"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import { allStickersChannels } from "./StickerForm"
import { preventSubmitOnEnter } from "../../../utility/common/preventSubmitOnEnter"
import IdField from "../../IdField/IdField"
import DocLink from "../../DocLink/DocLink"

const tNamespace = "stickers:form."

interface Props {
    sticker?: Sticker
}

const FormikStickerForm: React.FC<Props & FormikProps<StickerValues> & WithT> = props => {
    const { sticker, handleSubmit, t, values } = props
    const asyncState = useSelector(sticker ? selectUpdateStickerState : selectCreateStickerState, shallowEqual)

    const idDisabled = !!sticker

    return (
        <Form className={styles.stickerForm} onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <div className={styles.stickerForm__content}>
                <div className={styles.stickerForm__section}>
                    <DocLink type="stickers">{t(`${tNamespace}doc-link`)}</DocLink>
                </div>
                {idDisabled && (
                    <div className={styles.stickerForm__section}>
                        <IdField id={values.Id} />
                    </div>
                )}
                <div className={styles.stickerForm__section}>
                    {!idDisabled && (
                        <DebouncedValidatableInput
                            id="formId"
                            type="text"
                            name={nameof<StickerValues>("Id")}
                            label={t(`${tNamespace}id`)}
                        />
                    )}
                    <DebouncedValidatableInput
                        id="formName"
                        type="text"
                        name={nameof<StickerValues>("Name")}
                        label={t(`${tNamespace}title`)}
                    />
                    <DebouncedValidatableInput
                        id="formId"
                        type="text"
                        name={nameof<StickerValues>("StickerUrl")}
                        label={t(`${tNamespace}image-url`)}
                    />
                    {allStickersChannels.map(stickersChannel => (
                        <DebouncedValidatableInput
                            key={stickersChannel}
                            id={stickersChannel}
                            type="text"
                            name={nameof2<StickerValues, ChannelStickerValuesType>("ChannelStickers", stickersChannel)}
                            label={t(`${tNamespace}${stickersChannel.toLowerCase()}-id`)}
                        />
                    ))}
                </div>
            </div>
            <div className={styles.stickerForm__footer}>
                <LoadingButton type="submit" loading={asyncState.inProcess} variant="primary" block>
                    {t(formTranslation.save)}
                </LoadingButton>
            </div>
        </Form>
    )
}

export default FormikStickerForm
