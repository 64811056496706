import { useDispatch, useSelector } from "react-redux"
import { selectCurrentUser } from "../../store/users/selectors"
import { saveNotificationError, saveNotificationInfo } from "../../store/notifications/thunks"
import React, { useCallback } from "react"
import { CatalogToDocRequest, DocType } from "../../models/knowledgeBaseConverter"
import useDownloadFile from "../common/useDownloadFile"
import knowledgeBaseConverterController from "../../api/controllers/knowledgeBaseConverter"
import { createSystemError } from "../../core/error"

const GENERATING_FILE_TITLE_KEY = "info:knowledge-base:generating-file"
const GENERATING_FILE_FOR_ARTICLE_INFO_KEY = "info:knowledge-base:generating-file-for-article-info"
const GENERATING_FILE_FOR_CATALOG_INFO_KEY = "info:knowledge-base:generating-file-for-catalog-info"
const GENERATING_FILE_FAILED_MESSAGE_KEY = "error:knowledgeBase:generating-file-failed"

const useDownloadKBCatalogFile = (
    type: DocType,
    projectId?: string,
    catalogCode?: string,
    articleSymbolCode?: string
) => {
    const dispatch = useDispatch()
    const user = useSelector(selectCurrentUser)

    const handlePreDownload = useCallback(() => {
        saveNotificationInfo(dispatch, {
            Title: {
                Value: GENERATING_FILE_TITLE_KEY,
                NeedLocalization: true
            },
            Description: {
                Value: articleSymbolCode ? GENERATING_FILE_FOR_ARTICLE_INFO_KEY : GENERATING_FILE_FOR_CATALOG_INFO_KEY,
                NeedLocalization: true
            }
        })
    }, [dispatch])

    const handleDownloadFileError = useCallback(
        (e: any) => {
            const error = createSystemError(GENERATING_FILE_FAILED_MESSAGE_KEY, e)
            saveNotificationError(dispatch, error)
        },
        [dispatch]
    )

    const loadCatalog = useCallback(async (): Promise<Blob | null> => {
        if (!catalogCode || !user || !projectId) return null

        const request: CatalogToDocRequest = {
            CatalogSymbolCode: catalogCode,
            ArticleSymbolCode: articleSymbolCode,
            Login: user.Login,
            Type: type
        }

        return knowledgeBaseConverterController.catalogToDoc(projectId, request)
    }, [projectId, catalogCode, articleSymbolCode, user])

    const {
        ref,
        url,
        download: handleDownloadCatalog,
        name
    } = useDownloadFile(loadCatalog, handlePreDownload, handleDownloadFileError, `catalog_${catalogCode}.docx`)

    return {
        handleDownloadCatalog,
        downloadLink: <a href={url} download={name} className="hidden" ref={ref} />
    }
}

export default useDownloadKBCatalogFile
