const tNamespace = "knowledgeBase:article-content-editor."

export const articleContentEditorTranslation: { [key: string]: string } = {
    addColumnAfter: `${tNamespace}add-column-after`,
    addColumnBefore: `${tNamespace}add-column-before`,
    addRowAfter: `${tNamespace}add-row-after`,
    addRowBefore: `${tNamespace}add-row-before`,
    alignCenter: `${tNamespace}align-center`,
    alignLeft: `${tNamespace}align-left`,
    alignRight: `${tNamespace}align-right`,
    bulletList: `${tNamespace}bullet-list`,
    checkboxList: `${tNamespace}checkbox-list`,
    codeBlock: `${tNamespace}code-block`,
    codeCopied: `${tNamespace}code-copied`,
    codeInline: `${tNamespace}code-inline`,
    createLink: `${tNamespace}create-link`,
    createLinkError: `${tNamespace}create-link-error`,
    createNewDoc: `${tNamespace}create-new-doc`,
    deleteColumn: `${tNamespace}delete-column`,
    deleteRow: `${tNamespace}delete-row`,
    deleteTable: `${tNamespace}delete-table`,
    deleteImage: `${tNamespace}delete-image`,
    downloadImage: `${tNamespace}download-image`,
    alignImageLeft: `${tNamespace}align-image-left`,
    alignImageRight: `${tNamespace}align-image-right`,
    alignImageDefault: `${tNamespace}align-image-default`,
    em: `${tNamespace}em`,
    embedInvalidLink: `${tNamespace}embed-invalid-link`,
    findOrCreateDoc: `${tNamespace}find-or-create-doc`,
    h1: `${tNamespace}h1`,
    h2: `${tNamespace}h2`,
    h3: `${tNamespace}h3`,
    heading: `${tNamespace}heading`,
    hr: `${tNamespace}hr`,
    image: `${tNamespace}image`,
    file: `${tNamespace}file`,
    imageUploadError: `${tNamespace}image-upload-error`,
    imageCaptionPlaceholder: `${tNamespace}image-caption-placeholder`,
    info: `${tNamespace}info`,
    infoNotice: `${tNamespace}info-notice`,
    link: `${tNamespace}link`,
    linkCopied: `${tNamespace}link-copied`,
    mark: `${tNamespace}mark`,
    newLineEmpty: `${tNamespace}new-line-empty`,
    newLineWithSlash: `${tNamespace}new-line-with-slash`,
    noResults: `${tNamespace}no-results`,
    openLink: `${tNamespace}open-link`,
    orderedList: `${tNamespace}ordered-list`,
    pageBreak: `${tNamespace}page-break`,
    pasteLink: `${tNamespace}paste-link`,
    placeholder: `${tNamespace}placeholder`,
    quickActions: `${tNamespace}quick-actions`,
    quote: `${tNamespace}quote`,
    buttons: `${tNamespace}buttons`,
    removeLink: `${tNamespace}remove-link`,
    searchOrPasteLink: `${tNamespace}search-or-paste-link`,
    underline: `${tNamespace}underline`,
    strikethrough: `${tNamespace}strikethrough`,
    strong: `${tNamespace}strong`,
    subheading: `${tNamespace}subheading`,
    table: `${tNamespace}table`,
    warning: `${tNamespace}warning`,
    warningNotice: `${tNamespace}warning-notice`,
    widget: `${tNamespace}widget`
}
