import React from "react"
import "./InfoCardList.scss"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"

interface Props extends ClassProps {}

const InfoCardList: React.FC<Props> = props => {
    const { className, children } = props

    return <div className={cn("info-card-list", className)}>{children}</div>
}

export default InfoCardList
