import React from "react"
import "./ArticleTags.scss"
import TextareaAutosize from "react-textarea-autosize"
import { useField } from "formik"
import { ArticleEditFormValues, ArticleTag } from "../../models/article"
import { nameof } from "../../utility/common/nameof"
import { useTranslation } from "react-i18next"
import cn from "classnames"

const tNamespace = "knowledgeBase:article-edit-form."

interface Props {
    tags: ArticleTag[]
}

const ArticleTags: React.FC<Props> = props => {
    const { tags } = props
    const value = tags.length ? `#${tags.join(" #")}` : ""

    return <TextareaAutosize className="article-tags" value={value} />
}

const FormikArticleTags: React.FC = () => {
    const [field, meta] = useField<any>(nameof<ArticleEditFormValues>("Tags"))
    const { t } = useTranslation()

    return (
        <TextareaAutosize
            onKeyPress={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
                if (e.key === "Enter") e.preventDefault()
            }}
            id="article-tags"
            name={field.name}
            onChange={field.onChange}
            placeholder={`#${t(`${tNamespace}add-tag`)}`}
            className={cn("article-tags", meta.touched && !!meta.error && "is-invalid")}
            value={field.value}
            onBlur={field.onBlur}
        />
    )
}

export { ArticleTags, FormikArticleTags }
