import { IHub } from "../interfaces/IHub"
import { Dispatch } from "../../utility/common/storeHelper"
import { Hub } from "../hub"
import { ArticleComment, NewArticleReplyAddedPayload } from "../../models/articleComment"
import { saveArticleComment, saveArticleReply } from "../../store/knowledgeBaseComments/thunks"
import * as knowledgeBaseCommentsConstants from "../../store/knowledgeBaseComments/constants"
import { handleHttpExceptionWithoutAction } from "../../store/handleHttpException"
import { ArticleUserViews } from "../../models/articleUserViews"
import { Article } from "../../models/article"
import { actions } from "../../store/knowledgeBase/slice"
import { CategoryAddedEvent, isCategoryMutations } from "../../models/category"
import {
    handleArticleUpdatedEvent,
    handleCategoriesUpdatedEvent,
    handleCategoryAddedEvent,
    reloadCatalogs,
    reloadCurrentArticle
} from "../../store/knowledgeBase/thunks"
import store, { Store } from "../../store/store"
import { saveNotificationInfo } from "../../store/notifications/thunks"
import { logError } from "../../utility/common/logError"

const ADD_NEW_COMMENT = "AddNewComment"
const ADD_NEW_REPLY = "AddNewReply"
const UPDATE_ARTICLE_USER_VIEWS = "UpdateArticleUserViews"
const UPDATE_ARTICLE = "UpdateArticle"
const UPDATE_CATEGORIES = "UpdateCategories"
const ADD_CATEGORY = "AddCategory"
const SUCCESSFULLY_RECONNECTED_KEY = "info:hub:successfully-reconnected"

class KnowledgeBaseHub {
    protected _hub: IHub
    private readonly _dispatch: Dispatch
    private pageUrl: string

    constructor(store: Store, hubUrl: string = "/knowledge-base-hub") {
        this._hub = new Hub(hubUrl, true)
        this._dispatch = store.dispatch
        this.pageUrl = ""
        this.registerServerEvents(store.dispatch)
    }

    public get isConnected() {
        return this._hub.isConnected
    }

    async connect() {
        await this._hub.connect()
    }

    async disconnect() {
        await this._hub.disconnect()
    }

    async subscribe(articleCode: string) {
        if (this._hub.isConnected) {
            await this._hub.invoke("Subscribe", articleCode).catch(e => {
                handleHttpExceptionWithoutAction(
                    e,
                    knowledgeBaseCommentsConstants.CONNECT_TO_HUB_FAILED,
                    this._dispatch
                )
            })
        } else {
            return Promise.resolve()
        }
    }

    async unsubscribe(articleCode: string) {
        if (this._hub.isConnected) {
            await this._hub.invoke("Unsubscribe", articleCode).catch(e => {
                handleHttpExceptionWithoutAction(
                    e,
                    knowledgeBaseCommentsConstants.DISCONNECT_FROM_HUB_FAILED,
                    this._dispatch
                )
            })
        } else {
            return Promise.resolve()
        }
    }

    setPageUrl(url: string) {
        this.pageUrl = url
    }

    setReconnectingCallback(reconnectingCallback: () => void) {
        this._hub.reconnectingCallback = reconnectingCallback
    }

    setReconnectedCallback(reconnectedCallback: () => void) {
        this._hub.reconnectedCallback = () => {
            const { dispatch } = store
            saveNotificationInfo(dispatch, {
                Title: {
                    Value: SUCCESSFULLY_RECONNECTED_KEY,
                    NeedLocalization: true
                }
            })
            dispatch(reloadCatalogs())
            dispatch(reloadCurrentArticle(this.pageUrl))
            reconnectedCallback()
        }
    }

    private registerServerEvents(dispatch: Dispatch) {
        this._hub.registerEvent(ADD_NEW_COMMENT, (event: string) => {
            const data = JSON.parse(event) as ArticleComment
            saveArticleComment(dispatch, data)
        })
        this._hub.registerEvent(ADD_NEW_REPLY, (event: string) => {
            const data = JSON.parse(event) as NewArticleReplyAddedPayload
            saveArticleReply(dispatch, data)
        })
        this._hub.registerEvent(UPDATE_ARTICLE_USER_VIEWS, (event: string) => {
            const data = JSON.parse(event) as ArticleUserViews
            dispatch(actions.updateArticleUserViewsSuccess(data))
        })
        this._hub.registerEvent(UPDATE_ARTICLE, (event: string) => {
            const data = JSON.parse(event) as Article
            dispatch(handleArticleUpdatedEvent(data, this.pageUrl))
        })
        this._hub.registerEvent(UPDATE_CATEGORIES, (event: string) => {
            const data = JSON.parse(event)
            if (isCategoryMutations(data)) {
                dispatch(handleCategoriesUpdatedEvent(data))
            } else {
                logError("Invalid category mutations:", data)
            }
        })
        this._hub.registerEvent(ADD_CATEGORY, (event: string) => {
            const data = JSON.parse(event) as CategoryAddedEvent
            dispatch(handleCategoryAddedEvent(data))
        })
    }
}

export default KnowledgeBaseHub
