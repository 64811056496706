import React, { ReactNode } from "react"
import "./PopoverTrigger.scss"
import { OverlayTrigger, Popover } from "react-bootstrap"
import { CustomOverlayTriggerProps } from "../../utility/common/customOverlayTypes"
import cn from "classnames"

interface Props extends CustomOverlayTriggerProps {
    id: string
    condition?: boolean
    title?: string
    content: ReactNode | string
}

const popperConfig = {
    modifiers: [
        {
            name: "preventOverflow",
            options: {
                boundary: "window",
                padding: { bottom: 220 }
            }
        },
        {
            name: "offset",
            options: {
                offset: [10, 0]
            }
        }
    ]
}

const PopoverTrigger: React.FC<Props> = props => {
    const { id, condition, title, content, className, children, ...overlayTriggerProps } = props

    return (
        <>
            {condition === undefined || condition ? (
                <OverlayTrigger
                    {...overlayTriggerProps}
                    popperConfig={popperConfig}
                    overlay={
                        <Popover id={id} className={cn("popover-trigger", className)}>
                            {title && <Popover.Title as="h3">{title}</Popover.Title>}
                            <Popover.Content>{content}</Popover.Content>
                        </Popover>
                    }
                >
                    {children}
                </OverlayTrigger>
            ) : (
                children
            )}
        </>
    )
}

export default PopoverTrigger
