import { ActionResult, Dispatch } from "../../utility/common/storeHelper"
import * as constants from "./constants"
import { RootState } from "../rootReducer"
import { getArticlePermittedAction } from "../../utility/knowledgeBase/knowledgeBase"
import { buildArticleActionUrl, buildSharedArticleActionUrl } from "../../utility/knowledgeBase/articleUrlHelper"
import knowledgeBaseController from "../../api/controllers/knowledgeBase"
import { actions, getArticleProcess, mutateCurrentBranch, updateArticleProcess } from "./slice"
import { handleHttpException } from "../handleHttpException"
import { push } from "connected-react-router"
import {
    CategoryAddedEvent,
    CategoryMutation,
    CreateCategoryRequest,
    GetRelatedCategoriesRequest,
    MoveCategoryRequest
} from "../../models/category"
import {
    Article,
    ArticleAction,
    ArticleEditFormValues,
    SearchArticlesRequest,
    UpdateActualArticlePermissionsRequest,
    UpdateArticleRequest,
    UpdateScenarioRequest
} from "../../models/article"
import { CatalogFormValues, CreateCatalogRequest, UpdateCatalogRequest } from "../../models/catalog"
import { KnowledgeBasePermission } from "../../models/knowledgeBasePermission"
import { Scenario } from "../../models/scenario"
import notificationController from "../../api/controllers/notification"
import usersController from "../../api/controllers/users"
import { apiCallExceptionToSystemError } from "../../utility/common/apiCallExceptionToSystemError"
import {
    selectAnswersFrom,
    selectArticleFormQuestions,
    selectArticleWorkMode,
    selectCurrentArticle,
    selectCurrentBranch,
    selectEditedArticleExtId,
    selectEditedArticleSurvey,
    selectSharedTypeState
} from "./selectors"
import { toArticleQuestions } from "../../utility/knowledgeBase/sampleQuestions"
import { ItemId, TreeItem } from "@atlaskit/tree/types"
import { TreeData } from "@atlaskit/tree"
import { convertCategoryToTreeItem } from "../../utility/knowledgeBase/categoriesTree"
import { treeItemToMoveCategoryDTO } from "../../utility/knowledgeBase/categoriesTree"
import { ArticleType } from "../../models/articleType"
import { toIntentRecordType } from "../../utility/knowledgeBase/articleTypes"
import { selectCurrentUserLogin } from "../users/selectors"
import { selectCurrentProjectId } from "../projects/selectors"
import { ArticleWorkMode } from "../../models/knowledgeBaseWorkMode"
import { isDraft, isPublished } from "../../utility/knowledgeBase/articleStatus"
import { isModifyPermitted } from "../../utility/knowledgeBase/useModifyPermitted"
import { getArticleTypesBase, getCategoriesBase, getRelatedCategoriesBase, searchArticleBase } from "./rootThunks"
import { SharedActualEntityRequest } from "../../models/sharing"

export const getCatalogs =
    (projectId: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.getCatalogsProcess())
        try {
            const { Catalogs } = await knowledgeBaseController.getCatalogs(projectId)
            dispatch(actions.getCatalogsSuccess(Catalogs))
        } catch (e) {
            handleHttpException(
                e,
                constants.GET_CATALOGS_FAILED_MESSAGE,
                err => actions.getCatalogsFailed(err),
                dispatch
            )
        }
    }

export const getCategories = (
    projectId: string,
    parentCategoryId: ItemId,
    catalogId: ItemId
): ActionResult<Promise<void>> => {
    const func = () => knowledgeBaseController.getCategories(projectId, catalogId as string, parentCategoryId as string)
    return getCategoriesBase(parentCategoryId, catalogId, func)
}

export const getRelatedCategories = (
    projectId: string,
    categoryId?: string,
    categoryCode?: string,
    catalogCode?: string
): ActionResult<Promise<void>> => {
    const func = () => {
        const request: GetRelatedCategoriesRequest = {
            Id: categoryId,
            CatalogCode: catalogCode,
            CategoryCode: categoryCode
        }
        return knowledgeBaseController.getRelatedCategories(projectId, request)
    }
    return getRelatedCategoriesBase(func)
}

export const createCategory =
    (projectId: string, parentItem: TreeItem, url: string, nextItem?: TreeItem): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(actions.createCategoryProcess())
        try {
            const {
                id,
                data: { catalogCode, permissions }
            } = parentItem

            const request: CreateCategoryRequest = {
                ParentId: id as string,
                Permissions: permissions,
                NextItem: nextItem ? treeItemToMoveCategoryDTO(nextItem) : undefined
            }

            const { CategoryId, Article } = await knowledgeBaseController.createCategory(
                projectId,
                catalogCode,
                request
            )
            dispatch(actions.createCategorySuccess(Article.SymbolCode))

            const state = getState()
            const login = state.users.currentUser.data?.Login
            const roleId = await usersController.getCurrentUserRoleId()

            dispatch(
                actions.getArticleSuccess({
                    Article,
                    Login: login,
                    RoleId: roleId,
                    ProjectId: projectId
                })
            )
            dispatch(
                mutateCurrentBranch({
                    CurrentCatalogCode: catalogCode,
                    CurrentCategoryId: CategoryId
                })
            )
            dispatch(push(buildArticleActionUrl(url, ArticleAction.Edit, Article.SymbolCode)))
        } catch (e) {
            handleHttpException(
                e,
                constants.CREATE_CATEGORY_FAILED_MESSAGE,
                err => actions.createCategoryFailed(err),
                dispatch
            )
        }
    }

export const moveCategory =
    (
        projectId: string,
        newTree: TreeData,
        sourceParent: TreeItem,
        destinationParent: TreeItem,
        draggableItem: TreeItem,
        prevItem?: TreeItem,
        nextItem?: TreeItem
    ) =>
    async (dispatch: Dispatch) => {
        try {
            dispatch(actions.moveCategoryProcess())

            const request: MoveCategoryRequest = {
                SourceParent: treeItemToMoveCategoryDTO(sourceParent),
                DestinationParent: treeItemToMoveCategoryDTO(destinationParent),
                DraggableItem: treeItemToMoveCategoryDTO(draggableItem),
                PrevItem: prevItem ? treeItemToMoveCategoryDTO(prevItem) : undefined,
                NextItem: nextItem ? treeItemToMoveCategoryDTO(nextItem) : undefined
            }
            dispatch(actions.updateCategoriesTree(newTree))
            const response = await knowledgeBaseController.moveCategory(projectId, request)
            dispatch(actions.moveCategorySuccess(response))
            const { ParentCategory } = response
            dispatch(
                actions.mutateRelatedCategories({
                    ParentCategory: ParentCategory ? convertCategoryToTreeItem(ParentCategory) : undefined
                })
            )
        } catch (e) {
            handleHttpException(
                e,
                constants.MOVE_CATEGORY_FAILED_MESSAGE,
                err => actions.moveCategoryFailed(err),
                dispatch
            )
        }
    }

export const createCatalog =
    (projectId: string, fields: CatalogFormValues, closeCatalogModal: () => void): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.createCatalogProcess())
        try {
            const request: CreateCatalogRequest = {
                Title: fields.Title,
                Permissions: fields.Permissions
            }

            const response = await knowledgeBaseController.createCatalog(projectId, request)

            dispatch(actions.createCatalogSuccess(response.Catalog))
            closeCatalogModal()
        } catch (e) {
            handleHttpException(
                e,
                constants.CREATE_CATALOG_FAILED_MESSAGE,
                err => actions.createCatalogFailed(err),
                dispatch
            )
        }
    }

export const updateCatalog =
    (
        projectId: string,
        catalogCode: string,
        fields: CatalogFormValues,
        closeCatalogModal: () => void
    ): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.updateCatalogProcess())
        try {
            const request: UpdateCatalogRequest = {
                Title: fields.Title,
                Permissions: fields.Permissions
            }

            const catalog = await knowledgeBaseController.updateCatalog(projectId, catalogCode, request)

            dispatch(actions.updateCatalogSuccess(catalog))
            closeCatalogModal()
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_CATALOG_FAILED_MESSAGE,
                err => actions.updateCatalogFailed(err),
                dispatch
            )
        }
    }

export const deleteCatalog =
    (projectId: string, catalogCode: string, closeCatalogModal: () => void): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.deleteCatalogProcess())
        try {
            const symbolCode = await knowledgeBaseController.deleteCatalog(projectId, catalogCode)
            dispatch(actions.deleteCatalogSuccess(symbolCode))
            closeCatalogModal()
        } catch (e) {
            handleHttpException(
                e,
                constants.DELETE_CATALOG_FAILED_MESSAGE,
                err => actions.deleteCatalogFailed(err),
                dispatch
            )
        }
    }

export const getCatalogSharing = (projectId: string, catalogCode: string): ActionResult<Promise<void>> => {
    return async (dispatch: Dispatch) => {
        dispatch(actions.shareCatalogProcess())
        try {
            const response = await knowledgeBaseController.getCatalogSharing(projectId, catalogCode)
            dispatch(actions.shareCatalogSuccess(response.SharedUrl))
        } catch (e) {
            handleHttpException(e, constants.GET_CATALOG_SHARING_FAILED_MESSAGE, actions.shareCatalogFailed, dispatch)
        }
    }
}

export const shareCatalog = (projectId: string, catalogCode: string): ActionResult<Promise<void>> => {
    return async (dispatch: Dispatch) => {
        dispatch(actions.shareCatalogProcess())
        try {
            const response = await knowledgeBaseController.shareCatalog(projectId, catalogCode)
            dispatch(actions.shareCatalogSuccess(response.SharedUrl))
        } catch (e) {
            handleHttpException(e, constants.SHARE_CATALOG_FAILED_MESSAGE, actions.shareCatalogFailed, dispatch)
        }
    }
}

export const removeCatalogSharing = (projectId: string, catalogCode: string): ActionResult<Promise<void>> => {
    return async (dispatch: Dispatch) => {
        dispatch(actions.shareCatalogProcess())
        try {
            await knowledgeBaseController.removeCatalogSharing(projectId, catalogCode)
            dispatch(actions.removeCatalogSharingSuccess())
        } catch (e) {
            handleHttpException(
                e,
                constants.REMOVE_CATALOG_SHARING_FAILED_MESSAGE,
                actions.shareCatalogFailed,
                dispatch
            )
        }
    }
}

export const getArticle =
    (
        projectId: string,
        login: string,
        articleCode: string,
        cancelIfAlreadyLoaded: boolean
    ): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const state = getState()
            const currentArticleData = selectCurrentArticle(state)

            if (
                cancelIfAlreadyLoaded &&
                currentArticleData &&
                currentArticleData.Article &&
                articleCode === currentArticleData.Article.SymbolCode
            ) {
                return
            }

            dispatch(getArticleProcess())
            const { Article, Views, Mutations } = await knowledgeBaseController.getArticle(projectId, articleCode)
            const roleId = await usersController.getCurrentUserRoleId()
            dispatch(
                actions.getArticleSuccess({
                    Article: Article,
                    Login: login,
                    RoleId: roleId,
                    ProjectId: projectId
                })
            )
            dispatch(
                actions.updateArticleUserViewsSuccess({
                    ArticleCode: Article.SymbolCode,
                    Views: Views
                })
            )
            if (Mutations) {
                await dispatch(handleCategoriesUpdatedEvent(Mutations))
            }
        } catch (e) {
            handleHttpException(e, constants.GET_ARTICLE_FAILED_MESSAGE, err => actions.getArticleFailed(err), dispatch)
        }
    }

export const searchArticles = (projectId: string, request: SearchArticlesRequest): ActionResult<Promise<void>> => {
    const func = () =>
        knowledgeBaseController.searchArticles(projectId, {
            ...request,
            EnablePayload: true
        })
    return searchArticleBase(func)
}

export const updateArticle =
    (
        projectId: string,
        url: string,
        code: string,
        fields: ArticleEditFormValues,
        versionId: string
    ): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState()
        const answersForm = selectAnswersFrom(state)
        const questions = selectArticleFormQuestions(state)
        const editedExtId = selectEditedArticleExtId(state)
        const editedSurvey = selectEditedArticleSurvey(state)

        try {
            const tagsTrimmed = fields.Tags.trim().substring(1)
            const request: UpdateArticleRequest = {
                Title: fields.Title,
                Answers: answersForm.Answers,
                Parameters: fields.Parameters.map(p => ({
                    SlotId: p.SlotId,
                    Value: p.Value
                })),
                Type: fields.Type,
                Tags: tagsTrimmed ? tagsTrimmed.split(/\s+#/) : [],
                Id: versionId,
                ExtId: editedExtId,
                Survey: editedSurvey,
                Questions: questions.map(toArticleQuestions)
            }
            const response = await knowledgeBaseController.updateArticle(projectId, code, request)
            dispatch(actions.updateArticleSuccess(response))
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_ARTICLE_FAILED_MESSAGE,
                err => actions.updateArticleFailed(err),
                dispatch
            )
            dispatch(
                actions.getArticleFailed(apiCallExceptionToSystemError(constants.UPDATE_ARTICLE_FAILED_MESSAGE, e))
            )
        }
    }

export const updateArticlePermissions =
    (
        projectId: string,
        url: string,
        code: string,
        permissions: KnowledgeBasePermission[],
        versionId: string,
        hidePopover: () => void
    ): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(updateArticleProcess())
        try {
            const request: UpdateActualArticlePermissionsRequest = {
                Permissions: permissions,
                Id: versionId
            }
            const response = await knowledgeBaseController.updateArticlePermissions(projectId, code, request)
            dispatch(actions.updateArticleSuccess(response))
            hidePopover()
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_ARTICLE_FAILED_MESSAGE,
                err => actions.updateArticleFailed(err),
                dispatch
            )
        }
    }

const changeArticleStatus =
    (errorMessage: string, waitResponse: Promise<string>): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(updateArticleProcess())
        try {
            const response = await waitResponse
            dispatch(actions.updateArticleSuccess(response))
        } catch (e) {
            handleHttpException(e, errorMessage, err => actions.updateArticleFailed(err), dispatch)
        }
    }

export const publishArticle = (
    projectId: string,
    url: string,
    code: string,
    versionId: string
): ActionResult<Promise<void>> => {
    const errorMessage = constants.PUBLISH_ARTICLE_FAILED_MESSAGE
    const waitResponse = knowledgeBaseController.publishArticle(projectId, code, { Id: versionId })
    return changeArticleStatus(errorMessage, waitResponse)
}

export const removeArticle = (
    projectId: string,
    url: string,
    code: string,
    versionId: string
): ActionResult<Promise<void>> => {
    const errorMessage = constants.REMOVE_ARTICLE_FAILED_MESSAGE
    const waitResponse = knowledgeBaseController.removeArticle(projectId, code, { Id: versionId })
    return changeArticleStatus(errorMessage, waitResponse)
}

export const setUnpublishedStatusForArticle = (
    projectId: string,
    url: string,
    code: string,
    versionId: string
): ActionResult<Promise<void>> => {
    const errorMessage = constants.SET_UNPUBLISHED_STATUS_FOR_ARTICLE_FAILED_MESSAGE
    const waitResponse = knowledgeBaseController.setUnpublishedStatusFortArticle(projectId, code, { Id: versionId })
    return changeArticleStatus(errorMessage, waitResponse)
}

export const sendArticleForApproval =
    (projectId: string, url: string, code: string, versionId: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(updateArticleProcess())
        try {
            const article = await knowledgeBaseController.sendArticleForApproval(projectId, code, { Id: versionId })
            dispatch(actions.updateArticleSuccess(article))
        } catch (e) {
            handleHttpException(
                e,
                constants.SEND_ARTICLE_FOR_APPROVAL_FAILED_MESSAGE,
                err => actions.updateArticleFailed(err),
                dispatch
            )
        }
    }

export const shareArticle = (
    projectId: string,
    articleCode: string,
    articleVersionId: string,
    login: string
): ActionResult<Promise<void>> => {
    return async (dispatch: Dispatch) => {
        dispatch(actions.shareArticleProcess())
        try {
            const roleId = await usersController.getCurrentUserRoleId()
            const request: SharedActualEntityRequest = {
                Id: articleVersionId,
                Login: login,
                RoleId: roleId
            }
            const response = await knowledgeBaseController.shareArticle(projectId, articleCode, request)
            dispatch(actions.shareArticleSuccess(response.SharedUrl))
        } catch (e) {
            handleHttpException(e, constants.SHARE_ARTICLE_FAILED_MESSAGE, actions.shareArticleFailed, dispatch)
        }
    }
}

export const getArticleSharing = (projectId: string, articleCode: string): ActionResult<Promise<void>> => {
    return async (dispatch: Dispatch) => {
        dispatch(actions.shareArticleProcess())
        try {
            const response = await knowledgeBaseController.getArticleSharing(projectId, articleCode)
            dispatch(actions.shareArticleSuccess(response.SharedUrl))
        } catch (e) {
            handleHttpException(e, constants.GET_ARTICLE_SHARING_FAILED_MESSAGE, actions.shareArticleFailed, dispatch)
        }
    }
}

export const removeArticleSharing = (
    projectId: string,
    articleCode: string,
    articleVersionId: string,
    login: string
): ActionResult<Promise<void>> => {
    return async (dispatch: Dispatch) => {
        dispatch(actions.removeArticleSharingProcess())
        try {
            const roleId = await usersController.getCurrentUserRoleId()
            const request: SharedActualEntityRequest = {
                Id: articleVersionId,
                Login: login,
                RoleId: roleId
            }
            await knowledgeBaseController.removeArticleSharing(projectId, articleCode, request)
            dispatch(actions.removeArticleSharingSuccess())
        } catch (e) {
            handleHttpException(
                e,
                constants.REMOVE_ARTICLE_SHARING_FAILED_MESSAGE,
                actions.removeArticleSharingFailed,
                dispatch
            )
        }
    }
}

export function getArticleTypes(projectId: string) {
    const func = () => knowledgeBaseController.getArticleTypes(projectId)
    return getArticleTypesBase(func)
}

export function createArticleType(projectId: string, createdArticleType: ArticleType, callback: () => void) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.createArticleTypeProcess())
            await knowledgeBaseController.createArticleType(toIntentRecordType(createdArticleType, projectId))
            dispatch(actions.createArticleTypeSuccess(createdArticleType))
            callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.CREATE_ARTICLE_TYPE_FAILED_MESSAGE,
                err => actions.createArticleTypeFailed(err),
                dispatch
            )
        }
    }
}

export function updateArticleType(projectId: string, updatedArticleType: ArticleType, callback: () => void) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.updateArticleTypeProcess())
            await knowledgeBaseController.updateArticleType(toIntentRecordType(updatedArticleType, projectId))
            dispatch(actions.updateArticleTypeSuccess(updatedArticleType))
            callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_ARTICLE_TYPE_FAILED_MESSAGE,
                err => actions.updateArticleTypeFailed(err),
                dispatch
            )
        }
    }
}

export function deleteArticleType(articleTypeId: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.deleteArticleTypeProcess())
            await knowledgeBaseController.deleteArticleType(articleTypeId)
            dispatch(actions.deleteArticleTypeSuccess(articleTypeId))
        } catch (e) {
            handleHttpException(
                e,
                constants.DELETE_ARTICLE_TYPE_FAILED_MESSAGE,
                err => actions.deleteArticleTypeFailed(err),
                dispatch
            )
        }
    }
}

export const updateScenario =
    (
        projectId: string,
        url: string,
        code: string,
        fields: ArticleEditFormValues,
        scenario: Scenario,
        versionId: string
    ): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(updateArticleProcess())

        const state = getState()
        const questions = selectArticleFormQuestions(state)
        const editedExtId = selectEditedArticleExtId(state)
        const editedSurvey = selectEditedArticleSurvey(state)

        try {
            const tagsTrimmed = fields && fields.Tags.trim().substring(1)
            const request: UpdateScenarioRequest = {
                Title: fields && fields.Title,
                Tags: tagsTrimmed ? tagsTrimmed.split(/\s+#/) : [],
                Type: fields && fields.Type,
                Scenario: scenario,
                Id: versionId,
                ExtId: editedExtId,
                Survey: editedSurvey,
                Questions: questions.map(toArticleQuestions)
            }
            const response = await knowledgeBaseController.updateScenario(projectId, code, request)
            dispatch(actions.updateArticleSuccess(response))
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_ARTICLE_FAILED_MESSAGE,
                err => actions.updateArticleFailed(err),
                dispatch
            )
        }
    }

export const refreshArticle =
    (projectId: string, url: string, code: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(updateArticleProcess())
        try {
            const response = await knowledgeBaseController.refreshArticle(projectId, code)
            dispatch(actions.updateArticleSuccess(response))
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_ARTICLE_FAILED_MESSAGE,
                err => actions.updateArticleFailed(err),
                dispatch
            )
        }
    }

export const confirmArticleRead =
    (projectId: string, url: string, code: string, notificationId: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.updateArticleUserViewsProcess())
        try {
            const articleUserViews = await knowledgeBaseController.confirmArticleRead(projectId, code)
            await notificationController.approve(notificationId)
            if (articleUserViews) {
                dispatch(
                    actions.updateArticleUserViewsSuccess({
                        ArticleCode: code,
                        Views: articleUserViews
                    })
                )
                dispatch(push(buildArticleActionUrl(url, ArticleAction.View, code)))
            }
        } catch (e) {
            handleHttpException(
                e,
                constants.SAVE_ARTICLE_READ_CONFIRMATION_FAILED_MESSAGE,
                err => actions.updateArticleUserViewsFailed(err),
                dispatch
            )
        }
    }

export const handleArticleUpdatedEvent =
    (updatedArticle: Article, pageUrl: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState()
        const shareType = selectSharedTypeState(state)

        // if the article is shared, we need to update the shared article and interrupt further logic
        if (shareType.data) {
            dispatch(actions.getSharedArticleSuccess({ Article: updatedArticle }))
            dispatch(push(buildSharedArticleActionUrl(pageUrl, updatedArticle.SymbolCode)))
            return
        }

        const login = selectCurrentUserLogin(state)
        const roleId = await usersController.getCurrentUserRoleId()
        const projectId = selectCurrentProjectId(state)
        const { Article } = selectCurrentArticle(state) || {
            Article: undefined,
            PermittedAction: undefined
        }

        // we may not update the article (don't show the version reload screen) if the current version is published,
        // and the received one is a draft with view permitted action
        const { Permissions, Status } = updatedArticle
        const updatedArticlePermittedAction =
            projectId && login ? getArticlePermittedAction(Permissions, Status, projectId, login, roleId) : undefined
        if (
            Article &&
            isPublished(Article.Status) &&
            isDraft(updatedArticle.Status) &&
            !isModifyPermitted(updatedArticlePermittedAction)
        ) {
            return
        }

        dispatch(
            actions.getArticleSuccess({
                Article: updatedArticle,
                PermittedAction: updatedArticlePermittedAction,
                Login: login,
                RoleId: roleId,
                ProjectId: projectId
            })
        )
        dispatch(push(buildArticleActionUrl(pageUrl, ArticleAction.View, updatedArticle.SymbolCode)))
    }

export const handleCategoryAddedEvent =
    (event: CategoryAddedEvent): ActionResult<void> =>
    (dispatch: Dispatch, getState: () => RootState) => {
        const { Category, ParentItemId } = event
        const state = getState()

        const catalog = state.knowledgeBase.catalogsMap[Category.CatalogCode]
        if (!catalog) return
        const parentItem = catalog.items[ParentItemId]
        if (!parentItem) return

        if (parentItem.children.length > 0 || Category.ParentId === Category.CatalogCode) {
            dispatch(actions.addCategory({ ParentItemId, Category }))
        }
    }

export const handleCategoriesUpdatedEvent =
    (data: CategoryMutation[]): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState()
        const projectId = selectCurrentProjectId(state)
        const login = selectCurrentUserLogin(state)
        const roleId = await usersController.getCurrentUserRoleId()
        const mutations =
            projectId && login
                ? data.map(m => {
                      if (m.Permissions && m.Status) {
                          m.PermittedAction = getArticlePermittedAction(
                              m.Permissions || [],
                              m.Status,
                              projectId,
                              login,
                              roleId
                          )
                      }
                      return m
                  })
                : data
        dispatch(actions.updateCategories(mutations))
    }

export const reloadCatalogs =
    (): ActionResult<Promise<void>> => async (dispatch: Dispatch, getState: () => RootState) => {
        const projectId = selectCurrentProjectId(getState())
        if (projectId) {
            await dispatch(getCatalogs(projectId))
        }
    }

export const reloadCurrentArticle =
    (pageUrl: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState()
        const { CurrentCatalogCode, CurrentCategoryId } = selectCurrentBranch(state)
        const currentArticleData = selectCurrentArticle(state)
        const articleWorkMode = selectArticleWorkMode(state)
        const login = selectCurrentUserLogin(state)
        const projectId = selectCurrentProjectId(state)

        if (!login || !projectId || !currentArticleData || !currentArticleData.Article) {
            return
        }

        const {
            Article: { SymbolCode }
        } = currentArticleData

        if (articleWorkMode === ArticleWorkMode.View) {
            await dispatch(getArticle(projectId, login, SymbolCode, false))
        }
        await dispatch(getRelatedCategories(projectId, CurrentCategoryId, undefined, CurrentCatalogCode))
    }

export const getDefaultArticle = (projectId: string) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.getDefaultArticleProcess())
            const { Article } = await knowledgeBaseController.getDefaultArticleByRole(projectId)
            dispatch(actions.getDefaultArticleSuccess(Article ? Article.SymbolCode : undefined))
        } catch (e) {
            handleHttpException(
                e,
                constants.GET_DEFAULT_ARTICLE_FAILED_MESSAGE,
                err => actions.getDefaultArticleFailed(err),
                dispatch
            )
        }
    }
}
