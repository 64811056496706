import { ButtonType } from "../utility/articleContentEditor/buttonsParser/buttonAttributes/buttonType"
import { ButtonColor } from "../utility/articleContentEditor/buttonsParser/buttonAttributes/buttonColor"
import { ButtonImageAlign } from "../utility/articleContentEditor/buttonsParser/buttonAttributes/buttonImageAlign"

export interface EditMarkdownButtonFormValues {
    type: ButtonType
    article: string
    isExternal: boolean
    url: string
    invisible: boolean
    color: ButtonColor
    image: string
    image_enabled: boolean
    image_align: ButtonImageAlign
    image_emoji: string
    image_emoji_enabled: boolean
}

export enum ButtonPlacement {
    Inline = "inline",
    Keyboard = "keyboard"
}

export enum ButtonFacebookType {
    Button = "button",
    QuickReply = "quick_reply"
}

export enum ButtonWhatsappType {
    Template = "template"
}

export interface MarkdownButtonBlock {
    placement: ButtonPlacement
    fb_type: ButtonFacebookType
    whatsapp_type?: ButtonWhatsappType
    language?: string
    template?: string
    additional_value?: string
}

export interface MarkdownButtonBlockValues extends Required<Omit<MarkdownButtonBlock, "whatsapp_type">> {
    whatsapp_template_enabled: boolean
}
