import { RootState } from "../rootReducer"

export const selectGetChannelsState = (state: RootState) => state.channels.channels
export const selectChannels = (state: RootState) => state.channels.channels.data
export const selectGetChannelsDeclarationsState = (state: RootState) => state.channels.channelsDeclarations
export const selectChannelsDeclarations = (state: RootState) => state.channels.channelsDeclarations.data
export const selectCreateChannelState = (state: RootState) => state.channels.createChannel
export const selectUpdateChannelState = (state: RootState) => state.channels.updateChannel
export const selectChannelsState = (state: RootState) => state.channels.channelsState
export const selectChannelAdditionalData = (state: RootState) => state.channels.additionalData
export const selectWebhooksPrefixes = (state: RootState) => state.channels.webhooksPrefixes.data
export const selectWebhookPrefix = (state: RootState, channelType: string) => {
    const prefixes = state.channels.webhooksPrefixes.data
    if (!prefixes) return undefined

    return prefixes[channelType]
}
export const selectGetChannelsNamesState = (state: RootState) => state.channels.channelNames
export const selectGetChannelScenarioState = (state: RootState) => state.channels.channelScenario
export const selectChannelScenario = (state: RootState) => state.channels.channelScenario.data
