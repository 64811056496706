import React from "react"
import { AsyncDataProps } from "../../utility/common/asyncHelper"
import AsyncState from "../../core/asyncState"

interface Props<T> {
    dataState: AsyncState<T>
    processView: JSX.Element
    children: (props: AsyncDataProps<T>) => JSX.Element
}

function AsyncWithoutError<T>(props: Props<T>): JSX.Element {
    const { dataState, processView, children } = props
    if (dataState.inProcess) {
        return processView
    }
    return <>{dataState.data ? children({ data: dataState.data }) : null}</>
}

export default AsyncWithoutError
