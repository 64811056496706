import { push } from "connected-react-router"
import { ActionResult, Dispatch } from "../../utility/common/storeHelper"
import * as constants from "./constants"
import { SignUpRequest, SendSignUpEmailRequest, SignUpRequestOidc } from "../../models/signUp"
import accountController from "../../api/controllers/account"
import { actions } from "./slice"
import { handleHttpException } from "../handleHttpException"
import { redirectToRoot } from "../../utility/auth/credentialHelper"

export const sendSignUpEmail =
    (request: SendSignUpEmailRequest): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.sendSignUpEmailProcess())
        try {
            await accountController.sendSignUpEmail(request)
            dispatch(actions.sendSignUpEmailSuccess(request.Email))
        } catch (e) {
            handleHttpException(
                e,
                constants.SEND_SIGN_UP_EMAIL_FAILED_MESSAGE,
                err => actions.sendSignUpEmailFailed(err),
                dispatch
            )
        }
    }

export const verifySignUpCode =
    (code: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        try {
            dispatch(actions.verifySignUpCodeProcess())
            let response = await accountController.verifySignUpCode(code)
            dispatch(actions.verifySignUpCodeSuccess(response))
            if (response.IsAlreadyExisted) {
                dispatch(push("/"))
            }
        } catch (e) {
            handleHttpException(
                e,
                constants.VERIFY_SIGN_UP_CODE_FAILED_MESSAGE,
                err => actions.verifySignUpCodeFailed(err),
                dispatch
            )
        }
    }

export const signUpByEmail =
    (request: SignUpRequest): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.signUpByEmailProcess())
        try {
            const user = await accountController.signUp(request)
            dispatch(actions.signUpByEmailSuccess(user))
            dispatch(push("/"))
        } catch (e) {
            handleHttpException(
                e,
                constants.SIGN_UP_BY_EMAIL_FAILED_MESSAGE,
                err => actions.signUpByEmailFailed(err),
                dispatch
            )
        }
    }

export const signUpOidc =
    (request: SignUpRequestOidc): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.signUpByEmailProcess())
        try {
            const user = await accountController.signUpOidc(request)
            dispatch(actions.signUpByEmailSuccess(user))
            redirectToRoot()
        } catch (e) {
            handleHttpException(
                e,
                constants.SIGN_UP_BY_EMAIL_FAILED_MESSAGE,
                err => actions.signUpByEmailFailed(err),
                dispatch
            )
        }
    }
