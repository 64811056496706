import React from "react"
import { FormikProps } from "formik"
import { WithT } from "i18next"
import { shallowEqual, useSelector } from "react-redux"
import { WebChatChannelValues } from "../../models/webChatValues"
import { ChannelFormProps } from "../ChannelForm/ChannelForm"
import { selectCreateChannelState, selectUpdateChannelState } from "../../store/channels/selectors"
import WebChatHistorySettings from "./WebChatAdvancedSettings/WebChatHistorySettings"
import WebChatNotificationSettings from "./WebChatAdvancedSettings/WebChatNotificationSettings"
import WebChatOperatorSettings from "./WebChatAdvancedSettings/WebChatOperatorSettings"
import WebChatInterfaceSettings from "./WebChatAdvancedSettings/WebChatInterfaceSettings"
import WebChatPromoSettings from "./WebChatAdvancedSettings/WebChatPromoSettings"
import WebChatInitialMessageSettings from "./WebChatAdvancedSettings/WebChatInitialMessageSettings"
import WebChatAuthorizationSettings from "./WebChatAdvancedSettings/WebChatAuthorizationSettings"
import WebChatFilesSettings from "./WebChatAdvancedSettings/WebChatFilesSettings"
import WebChatDialogEvaluationSettings from "./WebChatAdvancedSettings/WebChatDialogEvaluationSettings"
import WebChatBlockSettings from "./WebChatAdvancedSettings/WebChatBlockSettings"
import WebChatSystemSettings from "./WebChatAdvancedSettings/WebChatSystemSettings"
import IdField from "../IdField/IdField"
import ChannelFormWrapper from "../ChannelFormWrapper/ChannelFormWrapper"
import WebChatMessageSettings from "./WebChatAdvancedSettings/WebChatMessageSettings/WebChatMessageSettings"

const FormikWebChatChannelFormAdvanced: React.FC<ChannelFormProps & FormikProps<WebChatChannelValues> & WithT> =
    props => {
        const { handleSubmit, channel, values, disabled } = props
        const asyncState = useSelector(channel ? selectUpdateChannelState : selectCreateChannelState, shallowEqual)

        return (
            <ChannelFormWrapper
                className="webchat-form"
                onSubmit={handleSubmit}
                disabled={disabled}
                loading={asyncState.inProcess}
            >
                <IdField id={values.BotSettings.Id} className="channel-form__section" />
                <WebChatHistorySettings />
                <WebChatNotificationSettings />
                <WebChatOperatorSettings />
                <WebChatInterfaceSettings />
                <WebChatPromoSettings />
                <WebChatInitialMessageSettings />
                <WebChatAuthorizationSettings />
                <WebChatFilesSettings />
                <WebChatDialogEvaluationSettings />
                <WebChatBlockSettings />
                <WebChatSystemSettings />
                <WebChatMessageSettings />
            </ChannelFormWrapper>
        )
    }

export default FormikWebChatChannelFormAdvanced
