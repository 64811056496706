import loadable from "@loadable/component"
import { promiseDelay } from "../../utility/common/promiseDelay"
import Skeleton from "../Skeleton/Skeleton"

export const LazyContentEditorLoading = () => {
    return (
        <div style={{ width: "100%", height: 100 }}>
            <Skeleton />
        </div>
    )
}

const LazyContentEditor = loadable(() => promiseDelay(import("./ContentEditor"), 200), {
    fallback: <LazyContentEditorLoading />
})

export default LazyContentEditor
