import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import { SystemError } from "../../core/error"
import { GlobalUser } from "../../models/globalUser"
import { ProjectUser } from "../../models/projectUser"
import { User, UserType } from "../../models/user"
import { loginFailed, loginSuccess, setUnauthorized } from "../auth/actions"
import { selectProjectSuccess, SelectProjectSuccessPayload } from "../projects/actions"
import { actions as signUpActions } from "../signUp/slice"
import { actions as projectActions } from "../projects/slice"
import { getCurrentUserFailed, getCurrentUserProcess, getCurrentUserSuccess } from "./actions"

export type UsersState = Readonly<{
    currentUser: AsyncState<User>
    currentPermissions: Set<string>
    projectUsers: AsyncState<ProjectUser[]>
    allUsers: AsyncState<GlobalUser[]>
    updateUser: AsyncState<void>
    updateAvatar: AsyncState<void>
    updateProjectUser: AsyncState<void>
    updateGlobalUser: AsyncState<void>
    remoteWorkplaceAuthToken: AsyncState<string>
    clientTypes: AsyncState<UserType[]>
}>

const initialState: UsersState = {
    currentUser: AsyncState.create(),
    currentPermissions: new Set(),
    projectUsers: AsyncState.create(),
    allUsers: AsyncState.create(),
    updateUser: AsyncState.create(),
    updateAvatar: AsyncState.create(),
    updateProjectUser: AsyncState.create(),
    updateGlobalUser: AsyncState.create(),
    remoteWorkplaceAuthToken: AsyncState.create(),
    clientTypes: AsyncState.create()
}

const users = createSlice({
    name: "users",
    initialState,
    reducers: {
        getProjectUsersProcess(state) {
            state.projectUsers = state.projectUsers.toProcess()
        },
        getProjectUsersSuccess(state, action: PayloadAction<ProjectUser[]>) {
            state.projectUsers = state.projectUsers.toSuccess(action.payload)
        },
        getProjectUsersFailed(state, action: PayloadAction<SystemError>) {
            state.projectUsers = state.projectUsers.toFailed(action.payload)
        },
        getAllUsersProcess(state) {
            state.allUsers = state.allUsers.toProcess()
        },
        getAllUsersSuccess(state, action: PayloadAction<GlobalUser[]>) {
            state.allUsers = state.allUsers.toSuccess(action.payload)
        },
        getAllUsersFailed(state, action: PayloadAction<SystemError>) {
            state.allUsers = state.allUsers.toFailed(action.payload)
        },
        updateUserProcess(state) {
            state.updateUser = state.updateUser.toProcess()
        },
        updateUserSuccess(state, action: PayloadAction<User>) {
            state.updateUser = state.updateUser.toSuccess()
            state.currentUser = state.currentUser.map(_ => action.payload)
        },
        updateUserFailed(state, action: PayloadAction<SystemError>) {
            state.updateUser = state.updateUser.toFailed(action.payload)
        },
        updateProjectUserProcess(state) {
            state.updateProjectUser = state.updateProjectUser.toProcess()
        },
        updateProjectUserSuccess(state, action: PayloadAction<ProjectUser>) {
            state.updateProjectUser = state.updateProjectUser.toSuccess()
            state.projectUsers = state.projectUsers.map(v =>
                v.map(u => (u.Login === action.payload.Login ? action.payload : u))
            )
            state.currentUser = state.currentUser.map(v =>
                v.Login === action.payload.Login ? { ...v, MaxDialogs: action.payload.MaxDialogs } : v
            )
        },
        updateProjectUserFailed(state, action: PayloadAction<SystemError>) {
            state.updateProjectUser = state.updateProjectUser.toFailed(action.payload)
        },
        updateGlobalUserProcess(state) {
            state.updateGlobalUser = state.updateGlobalUser.toProcess()
        },
        updateGlobalUserSuccess(state, action: PayloadAction<GlobalUser>) {
            state.updateGlobalUser = state.updateGlobalUser.toSuccess()
            state.allUsers = state.allUsers.map(v =>
                v.map(u => (u.Login === action.payload.Login ? action.payload : u))
            )
        },
        updateGlobalUserFailed(state, action: PayloadAction<SystemError>) {
            state.updateGlobalUser = state.updateGlobalUser.toFailed(action.payload)
        },
        updateAvatarProcess(state) {
            state.updateAvatar = state.updateAvatar.toProcess()
        },
        updateAvatarSuccess(state, action: PayloadAction<User>) {
            state.updateAvatar = state.updateAvatar.toSuccess()
            state.currentUser = state.currentUser.map(_ => action.payload)
        },
        updateAvatarFailed(state, action: PayloadAction<SystemError>) {
            state.updateAvatar = state.updateAvatar.toFailed(action.payload)
        },
        getRemoteWorkplaceAuthTokenProcess(state) {
            state.remoteWorkplaceAuthToken = state.remoteWorkplaceAuthToken.toProcess()
        },
        getRemoteWorkplaceAuthTokenSuccess(state, action: PayloadAction<string>) {
            state.remoteWorkplaceAuthToken = state.remoteWorkplaceAuthToken.toSuccess(action.payload)
        },
        getRemoteWorkplaceAuthTokenFailed(state, action: PayloadAction<SystemError>) {
            state.remoteWorkplaceAuthToken = state.remoteWorkplaceAuthToken.toFailed(action.payload)
        },
        refreshRemoteWorkplaceAuthTokenProcess(state) {
            state.remoteWorkplaceAuthToken = state.remoteWorkplaceAuthToken.toProcess()
        },
        refreshRemoteWorkplaceAuthTokenSuccess(state, action: PayloadAction<string>) {
            state.remoteWorkplaceAuthToken = state.remoteWorkplaceAuthToken.toSuccess(action.payload)
        },
        refreshRemoteWorkplaceAuthTokenFailed(state, action: PayloadAction<SystemError>) {
            state.remoteWorkplaceAuthToken = state.remoteWorkplaceAuthToken.toFailed(action.payload)
        },
        getClientTypesProcess(state) {
            state.clientTypes = state.clientTypes.toProcess()
        },
        getClientTypesSuccess(state, action: PayloadAction<UserType[]>) {
            state.clientTypes = state.clientTypes.toSuccess(action.payload)
        },
        getClientTypesFailed(state, action: PayloadAction<SystemError>) {
            state.clientTypes = state.clientTypes.toFailed(action.payload)
        }
    },
    extraReducers: {
        [getCurrentUserProcess.type]: state => {
            state.currentUser = state.currentUser.toProcess()
        },
        [getCurrentUserSuccess.type]: (state, action: PayloadAction<User>) => {
            state.currentUser = state.currentUser.toSuccess(action.payload)
            state.currentPermissions = new Set(action.payload.Permissions)
        },
        [getCurrentUserFailed.type]: (state, action: PayloadAction<SystemError>) => {
            state.currentUser = state.currentUser.toFailed(action.payload)
        },
        [setUnauthorized.type]: state => {
            state.currentUser = AsyncState.create()
        },
        [loginSuccess.type]: (state, action: PayloadAction<User>) => {
            state.currentUser = state.currentUser.toSuccess(action.payload)
            state.currentPermissions = new Set(action.payload.Permissions)
        },
        [loginFailed.type]: (state, action: PayloadAction<SystemError>) => {
            state.currentUser = state.currentUser.toFailed(action.payload)
        },
        [signUpActions.signUpByEmailSuccess.type]: (state, action: PayloadAction<User>) => {
            state.currentUser = state.currentUser.toSuccess(action.payload)
            state.currentPermissions = new Set(action.payload.Permissions)
        },
        [selectProjectSuccess.type]: (state, action: PayloadAction<SelectProjectSuccessPayload>) => {
            state.currentUser = state.currentUser.toSuccess(action.payload.user)
            state.currentPermissions = new Set(action.payload.user.Permissions)
        },
        [projectActions.blockUserSuccess.type]: (state, action: PayloadAction<string>) => {
            state.projectUsers = state.projectUsers.map(v => v.filter(u => u.Login !== action.payload))
        },
        [projectActions.inviteUsersSuccess.type]: (state, action: PayloadAction<ProjectUser[]>) => {
            state.projectUsers = state.projectUsers.map(v => [...v, ...action.payload])
        }
    }
})

export default users.reducer

export const actions = users.actions
