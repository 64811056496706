import React, { useCallback, useMemo } from "react"
import { ChannelFormProps } from "../ChannelForm/ChannelForm"
import { FormikProps } from "formik"
import { BotSettingsValues, ChannelValues } from "../../models/channelValues"
import { WithT } from "i18next"
import { shallowEqual, useSelector } from "react-redux"
import { selectCreateChannelState, selectUpdateChannelState } from "../../store/channels/selectors"
import { selectAgents } from "../../store/agents/selectors"
import ChannelFormHeader from "../ChannelFormHeader/ChannelFormHeader"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import { nameof, nameof2 } from "../../utility/common/nameof"
import { ParamValues } from "../../models/parameterDeclaration"
import DocLink from "../DocLink/DocLink"
import { getTestChannelLink } from "../../utility/channels/testChannelLink"
import { openExternalLink } from "../../utility/common/urlHelper"
import ChannelFormWrapper from "../ChannelFormWrapper/ChannelFormWrapper"

const tNamespace = "channel:form."
const tLinkNamespace = "channel:link."

const FormikTelegramChannelFormGeneral: React.FC<ChannelFormProps & FormikProps<ChannelValues> & WithT> = props => {
    const { channel, channelType, t, handleSubmit, disabled } = props

    const asyncState = useSelector(channel ? selectUpdateChannelState : selectCreateChannelState, shallowEqual)
    const agents = useSelector(selectAgents)

    const agentOptions = useMemo(
        () => (agents ? agents.map(agent => ({ name: agent.Id, value: agent.Id })) : []),
        [agents]
    )

    const botId = useMemo(() => channel?.BotId, [channel])
    const goToTestPage = useCallback(() => {
        if (botId) {
            openExternalLink(getTestChannelLink(channelType, botId))
        }
    }, [botId, channelType])

    return (
        <ChannelFormWrapper
            onSubmit={handleSubmit}
            onTest={goToTestPage}
            disabled={disabled}
            testDisabled={!botId}
            loading={asyncState.inProcess}
        >
            <ChannelFormHeader channelType={channelType} t={t} isActive={false} disabled />
            <DocLink type={channelType}>{t(`${tLinkNamespace}creating-telegram-bot-instructions`)}</DocLink>
            <div className="channel-form__section">
                <ValidatableInput
                    id="formTitle"
                    type="text"
                    name={nameof<ChannelValues>("Title")}
                    label={t(`${tNamespace}title`)}
                />
            </div>
            <div className="channel-form__section">
                <div className="channel-form__section-subtitle">{t(`${tNamespace}bot-data-section`)}</div>
                <ValidatableInput
                    id="formId"
                    type="text"
                    name={nameof2<ChannelValues, BotSettingsValues>("BotSettings", "BotId")}
                    label={t(`${tNamespace}id`)}
                />
                <ValidatableInput
                    id="formToken"
                    type="password"
                    name={nameof2<ChannelValues, BotSettingsValues>("BotSettings", "Token")}
                    label={t(`${tNamespace}token`)}
                />
            </div>
            <div className="channel-form__section">
                <div className="channel-form__section-subtitle">{t(`${tNamespace}call-routing`)}</div>
                <ValidatableInput
                    id="formAgent"
                    as="select"
                    name={nameof2<ChannelValues, ParamValues>("Params", "default_agent")}
                    label={t(`${tNamespace}call-processing-agent`)}
                >
                    <option value="" hidden />
                    {agentOptions.map(option => (
                        <option value={option.value} key={option.value}>
                            {option.name}
                        </option>
                    ))}
                </ValidatableInput>
            </div>
        </ChannelFormWrapper>
    )
}

export default FormikTelegramChannelFormGeneral
