import React from "react"
import { formTranslation } from "../../locales/form"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import { useSelector } from "react-redux"
import { selectAgents } from "../../store/agents/selectors"
import { useTranslation } from "react-i18next"

interface Props {
    id: string
    name: string
    label: string
    icon?: JSX.Element
}

const AgentSelectControl: React.FC<Props> = props => {
    const { t } = useTranslation()
    const agents = useSelector(selectAgents)

    return (
        <ValidatableInput as="select" disabled={!agents?.length} {...props}>
            <option value="">{t(formTranslation.nothingSelected)}</option>
            {agents?.map(({ Id }) => (
                <option value={Id} key={Id}>
                    {Id}
                </option>
            ))}
        </ValidatableInput>
    )
}

export default AgentSelectControl
