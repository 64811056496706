import React from "react"
import { ChannelFormProps } from "../ChannelForm/ChannelForm"
import { FormikProps } from "formik"
import { ChannelValues } from "../../models/channelValues"
import { WithT } from "i18next"
import { shallowEqual, useSelector } from "react-redux"
import { selectCreateChannelState, selectUpdateChannelState } from "../../store/channels/selectors"
import { nameof2 } from "../../utility/common/nameof"
import DebouncedValidatableInput from "../ValidatableInput/DebouncedValidatableInput"
import ChannelFormWrapper from "../ChannelFormWrapper/ChannelFormWrapper"
import DebouncedCheckBox from "../CheckBoxValidatableInput/DebouncedCheckBox"
import { ParamValues } from "../../models/parameterDeclaration"

const tNamespace = "channel:form."

const FormikInstagramChannelFormAppearance: React.FC<ChannelFormProps & FormikProps<ChannelValues> & WithT> = props => {
    const { channel, t, handleSubmit, disabled, values } = props

    const asyncState = useSelector(channel ? selectUpdateChannelState : selectCreateChannelState, shallowEqual)

    return (
        <ChannelFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={asyncState.inProcess}>
            <div className="channel-form__section">
                <DebouncedCheckBox
                    id="formDialogRating"
                    name={nameof2<ChannelValues, ParamValues>("Params", "dialog_score")}
                    label={t(`${tNamespace}rating-dialog`)}
                />
                <div className="channel-form__subfield">
                    <DebouncedValidatableInput
                        id="formDialogScoreThankYou"
                        type="text"
                        as="textarea"
                        className="channel-form__message"
                        name={nameof2<ChannelValues, ParamValues>("Params", "dialog_score_thank_you")}
                        label={t(`${tNamespace}text-after-dialog-rating`)}
                        disabled={!values.Params.dialog_score}
                    />
                </div>
            </div>
        </ChannelFormWrapper>
    )
}

export default FormikInstagramChannelFormAppearance
