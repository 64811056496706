import React, { useMemo } from "react"
import { nameof2 } from "../../../utility/common/nameof"
import { InitialMessageMode, InitialMessageValues, WebChatChannelValues } from "../../../models/webChatValues"
import FormAccordion from "../../FormAccordion/FormAccordion"
import { useTranslation } from "react-i18next"
import RadioButtonValidatableInput from "../../RadioButtonValidatableInput/RadioButtonValidatableInput"
import { useFormikContext } from "formik"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"

const tNamespace = "channel:form."

const WebChatInitialMessageSettings: React.FC = () => {
    const { t } = useTranslation()
    const { values } = useFormikContext<WebChatChannelValues>()

    const initialMessageTextDisabled = useMemo(
        () => values.InitialMessageSettings.mode !== InitialMessageMode.showInitialMessage,
        [values.InitialMessageSettings.mode]
    )

    return (
        <FormAccordion title={t(`${tNamespace}initial-message`)}>
            <RadioButtonValidatableInput
                value={InitialMessageMode.sendInitialMessageOnOpen}
                id="formInitialMessageSettings1"
                name={nameof2<WebChatChannelValues, InitialMessageValues>("InitialMessageSettings", "mode")}
                label={t(`${tNamespace}initial-message-when-opening-chat`)}
            />
            <RadioButtonValidatableInput
                value={InitialMessageMode.sendInitialMessageOnStartDialog}
                id="formInitialMessageSettings2"
                name={nameof2<WebChatChannelValues, InitialMessageValues>("InitialMessageSettings", "mode")}
                label={t(`${tNamespace}initial-message-on-dialog-start`)}
            />
            <RadioButtonValidatableInput
                value={InitialMessageMode.showInitialMessage}
                id="formInitialMessageSettings3"
                name={nameof2<WebChatChannelValues, InitialMessageValues>("InitialMessageSettings", "mode")}
                label={t(`${tNamespace}static-initial-message`)}
            />
            <div className="extra-settings">
                <DebouncedValidatableInput
                    id="formPromoWelcomeMessage"
                    as="textarea"
                    name={nameof2<WebChatChannelValues, InitialMessageValues>(
                        "InitialMessageSettings",
                        "initialMessageText"
                    )}
                    label={t(`${tNamespace}static-welcome-message-text`)}
                    className="webchat-form__welcome-message-text"
                    disabled={initialMessageTextDisabled}
                />
            </div>
        </FormAccordion>
    )
}

export default WebChatInitialMessageSettings
