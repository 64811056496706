import React, { memo, useCallback, useContext, useEffect, useState } from "react"
import "./ConditionsNode.scss"
import { Handle, Position, useStoreActions, useUpdateNodeInternals } from "react-flow-renderer"
import { BlockTypeWithConditions, ScenarioBlockType } from "../../../../../models/scenario"
import { Condition } from "../../../../../models/scenarioCondition"
import ConditionList from "../ConditionList/ConditionList"
import { NodeComponentProps } from "react-flow-renderer/dist/nocss/types"
import cn from "classnames"
import {
    ArticleScenarioContext,
    ScenarioContext,
    UpdateConditionsCallback
} from "../../../../ScenarioEditor/ScenarioContext"
import { handleRemoveNode } from "../../../../../utility/scenario/scenario"
import { useTranslation } from "react-i18next"
import CommonNodeHeader from "../../Common/CommonNodeHeader"
import { handleUpdateCondition, handleUpdateConditions } from "../../../../../utility/scenario/scenarioNode"

const tNamespace = "scenarioEditor:"

export interface ConditionsNodeData {
    AddBlock: (source?: string, sourceHandle?: string) => void
    AddCondition: (id: string, blockType: BlockTypeWithConditions, callback: UpdateConditionsCallback) => void
    Conditions: Condition[]
}

type Props = NodeComponentProps<ConditionsNodeData>

const ConditionsNode: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { id, data, isConnectable } = props
    const { setElements, selectedNode } = useContext(ScenarioContext)
    const { handleOpenConditionForm, selectedCondition, setSelectedCondition } = useContext(ArticleScenarioContext)
    const updateNodeInternals = useUpdateNodeInternals()
    const [conditions, setConditions] = useState(data.Conditions)
    const resetSelectedElements = useStoreActions(state => state.resetSelectedElements)

    const selected = selectedNode === id

    const handleAddBlock = (sourceHandle: string) => {
        data.AddBlock(id, sourceHandle)
    }

    const updateConditions = useCallback(
        (conditions: Condition[], conditionIdToDelete?: string) =>
            handleUpdateConditions(setElements)(conditions, id, conditionIdToDelete),
        [setElements, id]
    )

    const handleRemoveCondition = (conditionId: string) => {
        const updatedCondition = conditions.filter(c => c.Id !== conditionId)
        setConditions(updatedCondition)
        updateConditions(updatedCondition, conditionId)
    }

    const handleChangeCondition = (condition: Condition) => () => {
        if (selectedCondition === condition.Id) return

        resetSelectedElements()
        setSelectedCondition(condition.Id)

        handleOpenConditionForm(
            handleUpdateCondition(setElements, id, condition.Id, conditions => setConditions(conditions)),
            ScenarioBlockType.Condition,
            false,
            condition
        )
    }

    const handleUpdateLocalConditions = useCallback(
        (conditions: Condition[]) => {
            setConditions(conditions)
            updateConditions(conditions)
        },
        [updateConditions]
    )

    useEffect(() => {
        updateNodeInternals(id)
    }, [id, conditions, updateNodeInternals])

    return (
        <div className={cn("conditions-node", selected && "conditions-node_selected")}>
            <Handle
                id={id}
                type="target"
                position={Position.Top}
                className="conditions-node__target-handle"
                isConnectable={isConnectable}
            />
            <CommonNodeHeader
                id={id}
                type={ScenarioBlockType.Condition}
                title={t(`${tNamespace}block-title.${ScenarioBlockType.Condition}`)}
                className={cn(
                    "conditions-node__header",
                    !(conditions.length || isConnectable) && "conditions-node__header_empty"
                )}
                onClick={handleRemoveNode(id, setElements)}
            />
            <ConditionList
                blockId={id}
                className={cn(
                    "conditions-node__condition-list",
                    isConnectable && "conditions-node__condition-list_connectable"
                )}
                conditions={conditions}
                onAddBlock={handleAddBlock}
                onAddCondition={() => {
                    setSelectedCondition(null)
                    data.AddCondition(id, ScenarioBlockType.Condition, conditions => setConditions(conditions))
                }}
                onRemoveCondition={handleRemoveCondition}
                isConnectable={isConnectable}
                selected={selected}
                onSelect={handleChangeCondition}
                updateConditions={handleUpdateLocalConditions}
            />
        </div>
    )
}

export default memo(ConditionsNode)
