import React from "react"
import "./ArticleCommentsView.scss"
import { ArticleCommentControlProps } from "../../utility/knowledgeBase/articleCommentProps"
import { faCommentLines } from "@fortawesome/pro-light-svg-icons/faCommentLines"
import { useTranslation } from "react-i18next"
import Async from "../Async/Async"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import ArticleCommentsViewLoader from "./ArticleCommentsViewLoader"
import { ArticleComment } from "../../models/articleComment"
import ArticleCommentComponent from "../ArticleComment/ArticleCommentComponent"
import IconButton from "../IconButton/IconButton"
import MessageInput from "../MessageInput/MessageInput"

const tNamespace = "knowledgeBase:comment."

interface Props extends ArticleCommentControlProps {
    articleCode: string
    articleTitle: string
}

const ArticleCommentsView: React.FC<Props> = props => {
    const {
        articleCode,
        onEnableInputMode,
        onCancelInput,
        articleCommentState,
        articleCommentSendState,
        inputStates,
        onThreadOpen,
        user,
        onCommentSend,
        articleTitle
    } = props
    const { t } = useTranslation()
    const inputState = inputStates[articleCode]
    const handleEnableInputMode = () => onEnableInputMode(articleCode)
    const handleCancelInput = () => onCancelInput(articleCode)
    const handleSendComment = (message: string) => onCommentSend({ MessageText: message })
    const handleThreadOpen = (articleComment: ArticleComment) => onThreadOpen(articleComment, articleTitle)
    return (
        <Async<ArticleComment[]>
            dataState={articleCommentState}
            processView={<ArticleCommentsViewLoader />}
            errorView={({ message }) => <ErrorMessage text={message} />}
        >
            {({ data: comments }) => (
                <div className="article-comments-view">
                    <div className="article-comments-view__input-container">
                        {!inputState ? (
                            <IconButton
                                variant="link"
                                className="article-comments-view__enable-input-mode"
                                onClick={handleEnableInputMode}
                                icon={faCommentLines}
                            >
                                {t(`${tNamespace}add-comment`)}
                            </IconButton>
                        ) : (
                            <MessageInput
                                onSend={handleSendComment}
                                onCancel={handleCancelInput}
                                user={user}
                                loading={articleCommentSendState.inProcess}
                            />
                        )}
                    </div>
                    {comments.map(c => (
                        <ArticleCommentComponent key={c.Id} data={c} onThreadOpen={handleThreadOpen} />
                    ))}
                </div>
            )}
        </Async>
    )
}

export default ArticleCommentsView
