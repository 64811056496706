export const testId = {
    activeTasksCount: "active-tasks-count",
    addAgent: "add-agent",
    addChannel: "add-channel",
    addQueue: "add-queue",
    addRole: "add-role",
    additionalMenu: "additional-menu",
    agents: "agents",
    alertDialogCancel: "alert-dialog-cancel",
    alertDialogSubmit: "alert-dialog-submit",
    btnClose: "btn-close",
    channelScenario: "channel-scenario",
    channels: "channels",
    chatBot: "chat-bot",
    clearAll: "clear-all",
    colorInputContainer: "color-input-container",
    createFromCopy: "create-from-copy",
    createProject: "create-project",
    currentStatus: "current-status",
    deleteAgent: "delete-agent",
    deleteChannel: "delete-channel",
    dialogs: "dialogs",
    editQueue: "edit-queue",
    email: "email",
    forgotPassword: "forgot-password",
    forgotPasswordSubmit: "forgot-password-submit",
    general: "general",
    history: "history",
    inviteUsers: "invite-users",
    knowledgeBase: "knowledge-base",
    knowledgeBaseOld: "knowledge-base-old",
    logOut: "log-out",
    logging: "logging",
    login: "login",
    notificationList: "notification-list",
    operators: "operators",
    password: "password",
    permissions: "permissions",
    priorityValue: "priority-value",
    projectSelector: "project-selector",
    projectSettings: "project-settings",
    queueName: "queue-name",
    queuedTasksCount: "queued-tasks-count",
    queues: "queues",
    roles: "roles",
    saveAgent: "save-agent",
    saveChannel: "save-channel",
    saveProjectSettings: "save-project-settings",
    saveQueue: "save-queue",
    searchInput: "search-input",
    searchProject: "search-project",
    selectAll: "select-all",
    selectFile: "select-file",
    settingsButton: "settings-button",
    stats: "stats",
    submitEnter: "submit-enter",
    tagInput: "tag-input",
    taskSizeValue: "task-size-value",
    testChat: "test-chat",
    toggleChannelActivity: "toggle-channel-activity",
    user: "user",
    userCard: "user-card",
    userManagement: "user-management",
    users: "users",
    wizardStageBackBtn: "wizard-stage-back-btn",
    wizardStageNextBtn: "wizard-stage-next-btn"
}
