import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import { SystemError } from "../../core/error"
import { SlotDto } from "../../models/slot"

export type SlotsState = Readonly<{
    slots: AsyncState<SlotDto[]>
    createSlot: AsyncState<void>
    updateSlot: AsyncState<void>
    deleteSlot: AsyncState<void>
}>
const initialState: SlotsState = {
    slots: AsyncState.create(),
    createSlot: AsyncState.create(),
    updateSlot: AsyncState.create(),
    deleteSlot: AsyncState.create()
}

const config = createSlice({
    name: "slots",
    initialState,
    reducers: {
        getSlotsProcess(state) {
            state.slots = state.slots.toProcess()
        },
        getSlotsSuccess(state, action: PayloadAction<SlotDto[]>) {
            state.slots = state.slots.toSuccess(action.payload)
        },
        getSlotsFailed(state, action: PayloadAction<SystemError>) {
            state.slots = state.slots.toFailed(action.payload)
        },
        createSlotProcess(state) {
            state.createSlot = state.createSlot.toProcess()
        },
        createSlotSuccess(state, action: PayloadAction<SlotDto>) {
            state.createSlot = state.createSlot.toSuccess()
            if (state.slots.data) {
                state.slots = state.slots.map(v => [...v, action.payload])
            }
        },
        createSlotFailed(state, action: PayloadAction<SystemError>) {
            state.createSlot = state.createSlot.toFailed(action.payload)
        },
        updateSlotProcess(state) {
            state.updateSlot = state.updateSlot.toProcess()
        },
        updateSlotSuccess(state, action: PayloadAction<SlotDto>) {
            state.updateSlot = state.updateSlot.toSuccess()
            if (state.slots.data) {
                state.slots = state.slots.map(v => v.map(s => (s.Id === action.payload.Id ? action.payload : s)))
            }
        },
        updateSlotFailed(state, action: PayloadAction<SystemError>) {
            state.updateSlot = state.updateSlot.toFailed(action.payload)
        },
        deleteSlotProcess(state) {
            state.deleteSlot = state.deleteSlot.toProcess()
        },
        deleteSlotSuccess(state, action: PayloadAction<string>) {
            state.deleteSlot = state.deleteSlot.toSuccess()
            if (state.slots.data) {
                state.slots = state.slots.map(v => v.filter(s => s.Id !== action.payload))
            }
        },
        deleteSlotFailed(state, action: PayloadAction<SystemError>) {
            state.deleteSlot = state.deleteSlot.toFailed(action.payload)
        }
    }
})

export default config.reducer

export const actions = config.actions
