import React from "react"
import "./AddMarkdownButtonForm.scss"
import { Formik } from "formik"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"
import { formTranslation } from "../../../../../../locales/form"
import FormikAddMarkdownButtonForm from "./FormikAddMarkdownButtonForm"
import { getId } from "../../../../../../utility/scenario/scenario"
import {
    defaultAttributes,
    MarkdownButton
} from "../../../../../../utility/articleContentEditor/buttonsParser/buttonsParser"
import { ButtonType } from "../../../../../../utility/articleContentEditor/buttonsParser/buttonAttributes/buttonType"
import { Dispatch } from "../../../../../../utility/common/storeHelper"

const tNamespace = "knowledgeBase:article-buttons.errors."

export interface KeyboardButtonFormValues {
    Title: string
    Type: ButtonType
    Action: string
    Url: string
}

interface Props {
    onAdd: (button: MarkdownButton, row: number) => void
    onClose: () => void
    row: number
    projectId: string
    dispatch: Dispatch
}

const valuesToButton = (values: KeyboardButtonFormValues, row: number): MarkdownButton => ({
    ...defaultAttributes,
    BodyMarkdown: values.Title,
    Row: row,
    id: getId("btn"),
    type: values.Type,
    action: values.Type === ButtonType.Action ? values.Action : values.Url
})

const AddMarkdownButtonForm: React.FC<Props> = props => {
    const { onClose, onAdd, row, projectId, dispatch } = props
    const { t } = useTranslation()

    return (
        <Formik<KeyboardButtonFormValues>
            initialValues={{
                Title: "",
                Type: ButtonType.Action,
                Action: "",
                Url: ""
            }}
            validationSchema={() =>
                Yup.object().shape({
                    Title: Yup.string().requiredExcludeEmpty(formTranslation.titleRequired),
                    Action: Yup.string().when("Type", {
                        is: ButtonType.Action,
                        then: Yup.string().requiredExcludeEmpty(`${tNamespace}select-article`)
                    }),
                    Url: Yup.string().when("Type", {
                        is: ButtonType.Url,
                        then: Yup.string().requiredExcludeEmpty(`${tNamespace}enter-url`)
                    })
                })
            }
            onSubmit={values => {
                const button = valuesToButton(values, row)
                onAdd(button, row)
                onClose()
            }}
        >
            {formikProps => (
                <FormikAddMarkdownButtonForm
                    {...formikProps}
                    t={t}
                    projectId={projectId}
                    dispatch={dispatch}
                    onCancel={onClose}
                />
            )}
        </Formik>
    )
}

export default AddMarkdownButtonForm
