import React from "react"
import { useTranslation } from "react-i18next"
import { AsyncDataProps, AsyncErrorProps } from "../../utility/common/asyncHelper"
import AsyncState from "../../core/asyncState"
import { getSystemErrorMessage } from "../../core/error"

interface Props<T> {
    dataState: AsyncState<T>
    processView: JSX.Element
    errorView: (props: AsyncErrorProps) => JSX.Element
    children: (props: AsyncDataProps<T>) => JSX.Element
}

function Async<T>(props: Props<T>): JSX.Element {
    const { dataState, errorView, processView, children } = props
    const { t } = useTranslation()
    if (dataState.inProcess) {
        return processView
    }
    if (dataState.error) {
        return errorView({ message: getSystemErrorMessage(dataState.error, t) })
    }
    return <>{dataState.data ? children({ data: dataState.data }) : null}</>
}

export default Async
