const MAX_RECURSION_COUNT = 1000000
const stop = (recCount: number) => recCount >= MAX_RECURSION_COUNT

type BuildAttributes<T> = (attributesList: string[]) => T
type KeyboardBlockOfType<T> = T & {
    BodyMarkdown: string
    Row: number
}

export class KeyboardParser<T> {
    static _parseAttributeList<T>(
        markdown: string,
        attributesStringStartIndex: number,
        buildAttributes: (attributesList: string[]) => T
    ): [number, T | undefined] {
        if (attributesStringStartIndex === markdown.length) return [attributesStringStartIndex, undefined]

        const attributesStringEndIndex = markdown.indexOf(")", attributesStringStartIndex)

        const nextIndex = attributesStringEndIndex + 1
        if (attributesStringEndIndex === -1) return [attributesStringStartIndex, undefined]

        const attributesString = markdown.slice(attributesStringStartIndex, attributesStringEndIndex)
        const attributesList = attributesString.trim().match(/(?:[^\s"]+|"[^"]*")+/g)
        const attributes = buildAttributes(attributesList ?? [])
        return [nextIndex, attributes]
    }

    static _parseMarkdownAttributes<T>(
        markdown: string,
        currentElementIndex: number,
        recCount: number,
        buildAttributes: BuildAttributes<T>
    ): [number, T | undefined] {
        if (currentElementIndex === markdown.length || stop(recCount)) return [currentElementIndex, undefined]

        const nextIndex = currentElementIndex + 1
        switch (markdown[currentElementIndex]) {
            case "(":
                const [newIndex, attributes] = KeyboardParser._parseAttributeList(markdown, nextIndex, buildAttributes)
                return [newIndex, attributes]
            default:
                return [nextIndex, undefined]
        }
    }

    static _parseMarkdownBlock<Attributes>(
        markdown: string,
        currentElementIndex: number,
        recCount: number,
        rowIndex: number,
        blockTitle: string,
        blocks: KeyboardBlockOfType<Attributes>[],
        buildAttributes: BuildAttributes<Attributes>
    ): number {
        if (currentElementIndex === markdown.length || stop(recCount)) return currentElementIndex

        const nextIndex = currentElementIndex + 1
        recCount++
        switch (markdown[currentElementIndex]) {
            case "]":
                const [newIndex, attributes] = KeyboardParser._parseMarkdownAttributes<Attributes>(
                    markdown,
                    nextIndex,
                    recCount,
                    buildAttributes
                )
                if (attributes) {
                    const block = {
                        BodyMarkdown: blockTitle,
                        Row: rowIndex,
                        ...attributes
                    }
                    blocks.push(block)
                }
                return newIndex
            default:
                const _blockTitle = blockTitle + markdown[currentElementIndex]
                return KeyboardParser._parseMarkdownBlock(
                    markdown,
                    nextIndex,
                    recCount,
                    rowIndex,
                    _blockTitle,
                    blocks,
                    buildAttributes
                )
        }
    }

    static _parseMarkdownBlocks<Attributes>(
        markdown: string,
        currentElementIndex: number,
        recCount: number,
        rowIndex: number,
        blocks: KeyboardBlockOfType<Attributes>[],
        buildAttributes: BuildAttributes<Attributes>
    ): number {
        if (currentElementIndex === markdown.length || stop(recCount)) return currentElementIndex

        const nextIndex = currentElementIndex + 1
        recCount++

        switch (markdown[currentElementIndex]) {
            case "\n":
            case "\t":
            case " ":
                return KeyboardParser._parseMarkdownBlocks(
                    markdown,
                    currentElementIndex + 1,
                    recCount,
                    rowIndex,
                    blocks,
                    buildAttributes
                )
            case "[":
                const newIndex = KeyboardParser._parseMarkdownBlock(
                    markdown,
                    nextIndex,
                    recCount,
                    rowIndex,
                    "",
                    blocks,
                    buildAttributes
                )
                return KeyboardParser._parseMarkdownBlocks(
                    markdown,
                    newIndex,
                    recCount,
                    rowIndex,
                    blocks,
                    buildAttributes
                )
            case ":":
                return currentElementIndex
            default:
                return nextIndex
        }
    }

    static _parseMarkdownRow<Attributes>(
        markdown: string,
        currentElementIndex: number,
        recCount: number,
        rowIndex: number,
        blocks: KeyboardBlockOfType<Attributes>[],
        buildAttributes: BuildAttributes<Attributes>
    ): [number, number] {
        if (currentElementIndex === markdown.length || stop(recCount)) return [currentElementIndex, rowIndex]

        const nextIndex = currentElementIndex + 1
        recCount++
        switch (markdown[currentElementIndex]) {
            case ":":
                const nextRowIndex = rowIndex + 1
                const newIndex = KeyboardParser._parseMarkdownBlocks(
                    markdown,
                    nextIndex,
                    recCount,
                    nextRowIndex,
                    blocks,
                    buildAttributes
                )
                return [newIndex, nextRowIndex]
            default:
                return [nextIndex, rowIndex]
        }
    }

    static _parseMarkdownString<Attributes>(
        currentElementIndex: number,
        recCount: number,
        rowIndex: number,
        blocks: KeyboardBlockOfType<Attributes>[],
        buildAttributes: BuildAttributes<Attributes>,
        markdown?: string
    ): KeyboardBlockOfType<Attributes>[] {
        if (!markdown || currentElementIndex === markdown.length || stop(recCount)) return blocks

        const nextIndex = currentElementIndex + 1
        recCount++
        switch (markdown[currentElementIndex]) {
            case "\n":
            case "\t":
            case " ":
                return KeyboardParser._parseMarkdownString(
                    nextIndex,
                    recCount,
                    rowIndex,
                    blocks,
                    buildAttributes,
                    markdown
                )
            case ":":
                const [newIndex, newRowIndex] = KeyboardParser._parseMarkdownRow(
                    markdown,
                    nextIndex,
                    recCount,
                    rowIndex,
                    blocks,
                    buildAttributes
                )
                return KeyboardParser._parseMarkdownString(
                    newIndex,
                    recCount,
                    newRowIndex,
                    blocks,
                    buildAttributes,
                    markdown
                )
            default:
                return KeyboardParser._parseMarkdownString(
                    nextIndex,
                    recCount,
                    rowIndex,
                    blocks,
                    buildAttributes,
                    markdown
                )
        }
    }

    static getBlocks<Attributes>(
        buildAttributes: BuildAttributes<Attributes>,
        markdown?: string
    ): KeyboardBlockOfType<Attributes>[] {
        return KeyboardParser._parseMarkdownString(0, 0, 0, [], buildAttributes, markdown)
    }
}
