import React from "react"
import { Message } from "../../models/message"
import MessageItem from "../MessageItem/MessageItem"
import styles from "./MessageList.module.scss"

export interface MessageListProps {
    messages: Message[]
    reverseInOut?: boolean
}

const MessageList: React.FC<MessageListProps> = props => {
    const { messages, reverseInOut } = props

    return (
        <div className={styles.messageList}>
            {messages.map(v => (
                <MessageItem
                    key={v.MsgId}
                    className={styles.messageList__item}
                    message={v}
                    reverseInOut={reverseInOut}
                />
            ))}
        </div>
    )
}

export default MessageList
