import React, { Children, cloneElement } from "react"
import { Form } from "react-bootstrap"
import * as Yup from "yup"
import { Formik, FormikProps } from "formik"
import { WizardStageProps } from "../WizardStage/WizardStage"
import { ProjectWizardFormValues, WizardData } from "../../models/projectWizard"

export interface WizardFormProps {
    children: React.ReactElement<WizardStageProps>[] | React.ReactElement<WizardStageProps>
    initialValues: ProjectWizardFormValues
    stage: number
    onSubmit: (values: ProjectWizardFormValues) => void
    onComplete: (values: ProjectWizardFormValues) => void
    validationSchema?: any
}

const WizardForm: React.FC<WizardFormProps> = props => {
    const { stage, initialValues, children, onSubmit, onComplete } = props
    const getCompleteSubmitting = (formikProps: FormikProps<WizardData>) => async () => {
        if (!Object.keys(formikProps.errors).length) {
            formikProps.setFieldValue("completed", true)
        }
        await formikProps.submitForm()
    }
    const getStages = (formikProps: FormikProps<WizardData>) =>
        Children.map(children, (child, index) =>
            cloneElement<WizardStageProps>(child as React.ReactElement<WizardStageProps>, {
                isActive: index === stage,
                setComplete: getCompleteSubmitting(formikProps)
            })
        )
    const validationSchema: Array<any> = Children.map<
        any,
        React.ReactElement<WizardStageProps>[] | React.ReactElement<WizardStageProps>
    >(children, child =>
        "props" in child
            ? child.props.validationSchema
                ? child.props.validationSchema
                : getDefaultValidationSchema
            : getDefaultValidationSchema
    )
    return (
        <Formik
            validateOnChange={false}
            initialValues={{ ...initialValues, completed: false }}
            validationSchema={validationSchema[stage]}
            onSubmit={values => {
                const { completed, ...data } = values
                completed ? onComplete(data) : onSubmit(data)
            }}
        >
            {(props: FormikProps<WizardData>) => (
                <Form noValidate onSubmit={props.handleSubmit}>
                    {getStages(props)}
                </Form>
            )}
        </Formik>
    )
}

const getDefaultValidationSchema = Yup.object().shape({})

export default WizardForm
