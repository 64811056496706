import React from "react"
import styles from "./ArticleTypeForm.module.scss"
import { FormikProps } from "formik"
import { WithT } from "i18next"
import { Form } from "react-bootstrap"
import LoadingButton from "../../LoadingButton/LoadingButton"
import { formTranslation } from "../../../locales/form"
import { shallowEqual, useSelector } from "react-redux"
import { nameof } from "../../../utility/common/nameof"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import { preventSubmitOnEnter } from "../../../utility/common/preventSubmitOnEnter"
import { ArticleType } from "../../../models/articleType"
import { selectCreateArticleTypeState, selectUpdateArticleTypeState } from "../../../store/knowledgeBase/selectors"
import IdField from "../../IdField/IdField"

const tNamespace = "articleTypes:form."

interface Props {
    articleType?: ArticleType
}

const FormikArticleTypeForm: React.FC<Props & FormikProps<ArticleType> & WithT> = props => {
    const { articleType, handleSubmit, t, values } = props
    const asyncState = useSelector(
        articleType ? selectUpdateArticleTypeState : selectCreateArticleTypeState,
        shallowEqual
    )
    const idDisabled = !!articleType

    return (
        <Form className={styles.articleTypeForm} onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <div className={styles.articleTypeForm__content}>
                {idDisabled && <IdField id={values.Id} />}
                <div className={styles.articleTypeForm__section}>
                    {!idDisabled && (
                        <DebouncedValidatableInput
                            id="formId"
                            type="text"
                            name={nameof<ArticleType>("Id")}
                            label={t(`${tNamespace}id`)}
                        />
                    )}
                    <DebouncedValidatableInput
                        id="formName"
                        type="text"
                        name={nameof<ArticleType>("Title")}
                        label={t(`${tNamespace}title`)}
                    />
                </div>
            </div>
            <div className={styles.articleTypeForm__footer}>
                <LoadingButton type="submit" loading={asyncState.inProcess} variant="primary" block>
                    {t(formTranslation.save)}
                </LoadingButton>
            </div>
        </Form>
    )
}

export default FormikArticleTypeForm
