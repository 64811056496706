import React, { useContext, useMemo } from "react"
import FormAccordion from "../../FormAccordion/FormAccordion"
import { nameof2 } from "../../../utility/common/nameof"
import { AuthAdvancedValues, WebChatChannelValues } from "../../../models/webChatValues"
import { useTranslation } from "react-i18next"
import { useFormikContext } from "formik"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import DebouncedCheckBox from "../../CheckBoxValidatableInput/DebouncedCheckBox"
import ConfigContext from "../../ConfigContext/ConfigContext"
import { formTranslation } from "../../../locales/form"
import InfoIcon from "../../InfoIcon/InfoIcon"

const tNamespace = "channel:form."
const tTooltipNamespace = "channel:tooltip."

const WebChatAuthorizationSettings = () => {
    const { t } = useTranslation()
    const { values } = useFormikContext<WebChatChannelValues>()

    const { WebConfig } = useContext(ConfigContext)
    const advancedSettingsEnabled = useMemo<boolean>(() => WebConfig.webChat.advancedSettingsEnabled, [WebConfig])

    return (
        <FormAccordion title={t(`${tNamespace}authorization`)}>
            <DebouncedValidatableInput
                id="formPhoneNumberMask"
                type="text"
                name={nameof2<WebChatChannelValues, AuthAdvancedValues>("AuthAdvanced", "phoneNumberMask")}
                label={t(`${tNamespace}phone-number-mask`)}
                disabled={!!values.AuthAdvanced.userFormFields.length}
            />
            <DebouncedValidatableInput
                id="formAllowedSymbolsRegexp"
                type="text"
                name={nameof2<WebChatChannelValues, AuthAdvancedValues>("AuthAdvanced", "allowedSymbolsRegexp")}
                label={t(`${tNamespace}form-fields-allowed-symbols`)}
                placeholder={t(formTranslation.enterRegex)}
                disabled={!!values.AuthAdvanced.userFormFields.length}
            />
            <DebouncedCheckBox
                id="formClearNonValidUserData"
                name={nameof2<WebChatChannelValues, AuthAdvancedValues>("AuthAdvanced", "clearNonValidUserData")}
                label={t(`${tNamespace}not-remove-inappropriate-characters-from-form-fields`)}
            />
            {advancedSettingsEnabled && (
                <DebouncedValidatableInput
                    id="formRequireAuthData"
                    type="text"
                    name={nameof2<WebChatChannelValues, AuthAdvancedValues>("AuthAdvanced", "requireAuthData")}
                    label={t(`${tNamespace}required-fields-for-auth-via-javascript`)}
                    placeholder={t(`${tNamespace}enter-list`)}
                />
            )}
            <DebouncedCheckBox
                id="formUsePersonalDataAgreement"
                name={nameof2<WebChatChannelValues, AuthAdvancedValues>("AuthAdvanced", "usePersonalDataAgreement")}
                label={t(`${tNamespace}use-personal-data-agreement`)}
            />
            <div className="extra-settings">
                <DebouncedValidatableInput
                    id="formPersonalDataText"
                    type="string"
                    as="textarea"
                    name={nameof2<WebChatChannelValues, AuthAdvancedValues>("AuthAdvanced", "personalDataText")}
                    label={t(`${tNamespace}personal-data-text`)}
                    className="channel-form__message"
                    icon={<InfoIcon id="iconPersonalDataText" content={t(`${tTooltipNamespace}personal-data-text`)} />}
                    disabled={!values.AuthAdvanced.usePersonalDataAgreement}
                />
            </div>
            <DebouncedValidatableInput
                id="formAuthButtonText"
                type="string"
                name={nameof2<WebChatChannelValues, AuthAdvancedValues>("AuthAdvanced", "authButtonText")}
                label={t(`${tNamespace}auth-button-text`)}
            />
        </FormAccordion>
    )
}

export default WebChatAuthorizationSettings
