import React, { KeyboardEvent, useCallback, useMemo, useState } from "react"
import { nameof2 } from "../../../utility/common/nameof"
import { PromoValues, WebChatChannelValues } from "../../../models/webChatValues"
import { Col, Form, InputGroup } from "react-bootstrap"
import FormAccordion from "../../FormAccordion/FormAccordion"
import { useTranslation } from "react-i18next"
import { FieldArray, useField, useFormikContext } from "formik"
import Toggle from "../../Toggle/Toggle"
import TextItemSetting from "../../TextItemSetting/TextItemSetting"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import DebouncedCheckBox from "../../CheckBoxValidatableInput/DebouncedCheckBox"

const tNamespace = "channel:form."
const tTimeNamespace = "common:time."

const WebChatPromoSettings: React.FC = () => {
    const { t } = useTranslation()
    const { values, setFieldValue } = useFormikContext<WebChatChannelValues>()

    const promoDisabled = useMemo(() => !values.PromoSettings.showPromo, [values.PromoSettings.showPromo])

    const [promoTimeoutMinField, promoTimeoutMinMeta] = useField(
        nameof2<WebChatChannelValues, PromoValues>("PromoSettings", "promoTimeoutMin")
    )
    const [promoTimeoutSecField, promoTimeoutSecMeta] = useField(
        nameof2<WebChatChannelValues, PromoValues>("PromoSettings", "promoTimeoutSec")
    )

    const [promoWidth, promoWidthMeta] = useField(
        nameof2<WebChatChannelValues, PromoValues>("PromoSettings", "promoWidth")
    )
    const [promoHeight, promoHeightMeta] = useField(
        nameof2<WebChatChannelValues, PromoValues>("PromoSettings", "promoHeight")
    )

    const [promoTimeoutEnabled, setPromoTimeoutEnabled] = useState(
        !!values.PromoSettings.promoTimeoutSec || !!values.PromoSettings.promoTimeoutMin
    )
    const handlePromoTimeoutToggle = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const checked = e.target.checked
            setPromoTimeoutEnabled(checked)
            if (!checked) {
                setFieldValue(nameof2<WebChatChannelValues, PromoValues>("PromoSettings", "promoTimeoutMin"), 0, false)
                setFieldValue(nameof2<WebChatChannelValues, PromoValues>("PromoSettings", "promoTimeoutSec"), 0, false)
            }
        },
        [setPromoTimeoutEnabled, setFieldValue]
    )

    const [promoAllowedUrlsEnabled, setPromoAllowedUrlsEnabled] = useState(
        !!values.PromoSettings.promoAllowedUrls.length
    )
    const handlePromoAllowedUrlsToggle = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const checked = e.target.checked
            setPromoAllowedUrlsEnabled(checked)
            if (!checked) {
                setFieldValue(
                    nameof2<WebChatChannelValues, PromoValues>("PromoSettings", "promoAllowedUrls"),
                    [],
                    false
                )
            }
        },
        [setPromoAllowedUrlsEnabled, setFieldValue]
    )

    return (
        <FormAccordion title={t(`${tNamespace}promo`)}>
            <DebouncedCheckBox
                id="formShowPromo"
                name={nameof2<WebChatChannelValues, PromoValues>("PromoSettings", "showPromo")}
                label={t(`${tNamespace}enable-promo`)}
            />
            <DebouncedValidatableInput
                id="formPromoWelcomeMessage"
                as="textarea"
                name={nameof2<WebChatChannelValues, PromoValues>("PromoSettings", "promoWelcomeMessage")}
                label={t(`${tNamespace}promo-text`)}
                className="webchat-form__promo-text"
                disabled={promoDisabled}
            />
            <Form.Row>
                <Col md={5}>
                    <Form.Label>{t(`${tNamespace}promo-width`)}</Form.Label>
                    <Form.Row>
                        <Form.Group as={Col} sm={4} controlId="formPromoWidth">
                            <Form.Control
                                type="number"
                                min="0"
                                name={promoWidth.name}
                                onChange={promoWidth.onChange}
                                onBlur={promoWidth.onBlur}
                                value={promoWidth.value}
                                isInvalid={promoWidthMeta.touched && !!promoWidthMeta.error}
                                disabled={promoDisabled}
                                className="promo-number-control"
                            />
                            <Form.Control.Feedback type="invalid">{promoWidthMeta.error}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Label column sm={4}>
                            {t(`${tNamespace}pixels`)}
                        </Form.Label>
                    </Form.Row>
                </Col>
                <Col md={5}>
                    <Form.Label>{t(`${tNamespace}promo-height`)}</Form.Label>
                    <Form.Row>
                        <Form.Group as={Col} sm={4} controlId="formPromoHeight">
                            <Form.Control
                                type="number"
                                min="0"
                                name={promoHeight.name}
                                onChange={promoHeight.onChange}
                                onBlur={promoHeight.onBlur}
                                value={promoHeight.value}
                                isInvalid={promoHeightMeta.touched && !!promoHeightMeta.error}
                                disabled={promoDisabled}
                                className="promo-number-control"
                            />
                            <Form.Control.Feedback type="invalid">{promoHeightMeta.error}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Label column sm={5}>
                            {t(`${tNamespace}pixels`)}
                        </Form.Label>
                    </Form.Row>
                </Col>
            </Form.Row>
            <FieldArray
                name={nameof2<WebChatChannelValues, PromoValues>("PromoSettings", "promoAllowedUrls")}
                render={({ push, remove }) => (
                    <>
                        <Form.Group controlId="formPromoAllowedUrls">
                            <Form.Label>{t(`${tNamespace}show-promo-on-pages`)}</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        <Toggle
                                            id="isActive"
                                            icons={false}
                                            checked={promoAllowedUrlsEnabled}
                                            onChange={handlePromoAllowedUrlsToggle}
                                            disabled={promoDisabled}
                                            small
                                        />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    disabled={!promoAllowedUrlsEnabled || promoDisabled}
                                    type="text"
                                    placeholder={t(`${tNamespace}enter-address-or-regex`)}
                                    onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                                        if (e.key === "Enter" && e.currentTarget.value) {
                                            push(e.currentTarget.value)
                                            e.currentTarget.value = ""
                                        }
                                    }}
                                />
                            </InputGroup>
                        </Form.Group>
                        {!promoDisabled &&
                            values.PromoSettings.promoAllowedUrls.map((v, index) => (
                                <TextItemSetting key={index} title={v} onDelete={() => remove(index)} />
                            ))}
                    </>
                )}
            />
            <div className="channel-form__section webchat-form__toggle-field">
                <Toggle
                    id="promoTimeoutEnabled"
                    icons={false}
                    checked={promoTimeoutEnabled}
                    onChange={handlePromoTimeoutToggle}
                    disabled={promoDisabled}
                    small
                />
                <label htmlFor="promoTimeoutEnabled" className="toggle-field__label">
                    {t(`${tNamespace}delay-appearance-of-promo`)}
                </label>
            </div>
            <Form.Row>
                <Col md={4}>
                    <Form.Row>
                        <Form.Group as={Col} sm={5} controlId="formPromoTimeoutMin">
                            <Form.Control
                                type="number"
                                min="0"
                                name={promoTimeoutMinField.name}
                                onChange={promoTimeoutMinField.onChange}
                                onBlur={promoTimeoutMinField.onBlur}
                                value={promoTimeoutMinField.value}
                                isInvalid={promoTimeoutMinMeta.touched && !!promoTimeoutMinMeta.error}
                                disabled={!promoTimeoutEnabled || promoDisabled}
                                className="promo-number-control"
                            />
                            <Form.Control.Feedback type="invalid">{promoTimeoutMinMeta.error}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Label column sm={5}>
                            {t(`${tTimeNamespace}minutes`)}
                        </Form.Label>
                    </Form.Row>
                </Col>
                <Col md={4}>
                    <Form.Row>
                        <Form.Group as={Col} sm={5} controlId="formPromoTimeoutSec">
                            <Form.Control
                                type="number"
                                min="0"
                                name={promoTimeoutSecField.name}
                                onChange={promoTimeoutSecField.onChange}
                                onBlur={promoTimeoutSecField.onBlur}
                                value={promoTimeoutSecField.value}
                                isInvalid={promoTimeoutSecMeta.touched && !!promoTimeoutSecMeta.error}
                                disabled={!promoTimeoutEnabled || promoDisabled}
                                className="promo-number-control"
                            />
                            <Form.Control.Feedback type="invalid">{promoTimeoutSecMeta.error}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Label column sm={5}>
                            {t(`${tTimeNamespace}seconds`)}
                        </Form.Label>
                    </Form.Row>
                </Col>
            </Form.Row>
        </FormAccordion>
    )
}

export default WebChatPromoSettings
