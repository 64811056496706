import React from "react"
import styles from "./TrainingHistoryLoader.module.scss"
import Skeleton from "../../Skeleton/Skeleton"
import useDelay from "../../../utility/common/useDelay"

const TrainingHistoryLoader: React.FC = () => {
    const display = useDelay()

    if (!display) {
        return null
    }

    return (
        <div className={styles.trainingHistoryLoader}>
            {new Array<number>(3).fill(0).map((_, i) => (
                <div key={i} className={styles.trainingHistoryLoader__item}>
                    <Skeleton />
                </div>
            ))}
        </div>
    )
}

export default TrainingHistoryLoader
