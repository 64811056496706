import React from "react"
import { ChannelFormProps } from "../ChannelForm/ChannelForm"
import { FormikProps } from "formik"
import { WithT } from "i18next"
import { shallowEqual, useSelector } from "react-redux"
import { selectCreateChannelState, selectUpdateChannelState } from "../../store/channels/selectors"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import { nameof, nameof2 } from "../../utility/common/nameof"
import FormAccordion from "../FormAccordion/FormAccordion"
import InfoIcon from "../InfoIcon/InfoIcon"
import { ChannelValues } from "../../models/channelValues"
import IdField from "../IdField/IdField"
import { ParamValues } from "../../models/parameterDeclaration"
import DebouncedValidatableInput from "../ValidatableInput/DebouncedValidatableInput"
import ChannelFormWrapper from "../ChannelFormWrapper/ChannelFormWrapper"
import FileNameRegexControl from "../ChannelForm/controls/FileNameRegexControl"

const tNamespace = "channel:form."
const tTooltipNamespace = "channel:tooltip."

const FormikTelegramChannelFormAdvanced: React.FC<ChannelFormProps & FormikProps<ChannelValues> & WithT> = props => {
    const { channel, values, t, handleSubmit, disabled } = props

    const asyncState = useSelector(channel ? selectUpdateChannelState : selectCreateChannelState, shallowEqual)

    return (
        <ChannelFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={asyncState.inProcess}>
            <IdField id={values.BotSettings.Id} className="channel-form__section" />
            <FormAccordion title={t(`${tNamespace}files`)}>
                <FileNameRegexControl filenameRegex={values.Params.filename_regex} t={t} />
                <DebouncedValidatableInput
                    id="formFileRegexErrorMessage"
                    type="text"
                    name={nameof2<ChannelValues, ParamValues>("Params", "invalid_file_name_error_message")}
                    label={t(`${tNamespace}error-text-invalid-file`)}
                />
            </FormAccordion>
            <FormAccordion title={t(`${tNamespace}webhook-preprocessing`)}>
                <ValidatableInput
                    id="formPreprocessorWebHook"
                    type="text"
                    name={nameof<ChannelValues>("PreprocessorWebHook")}
                    label={t(`${tNamespace}webhook`)}
                    icon={
                        <InfoIcon
                            id="popoverPreprocessorWebHook"
                            title={t(`${tNamespace}webhook-preprocessing`)}
                            content={t(`${tTooltipNamespace}webhook-preprocessing`)}
                        />
                    }
                />
            </FormAccordion>
        </ChannelFormWrapper>
    )
}

export default FormikTelegramChannelFormAdvanced
