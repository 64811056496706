import React from "react"
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"
import styles from "./Tag.module.scss"

export interface TagProps {
    className?: string
    title: string
    index: number
    onDelete?: (index: number) => void
    isInvalid?: boolean
}

const Tag: React.FC<TagProps> = props => {
    const { className, title, index, onDelete, isInvalid } = props

    return (
        <div className={cn(styles.tag, isInvalid && styles["tag_is-invalid"], className)}>
            <div className={styles.tag__title}>{title}</div>
            {onDelete && (
                <div className={styles.tag__close} onClick={() => onDelete(index)}>
                    <FontAwesomeIcon size="sm" icon={faTimes} className={styles.tag__icon} />
                </div>
            )}
        </div>
    )
}

export default Tag
