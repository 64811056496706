import { ActionResult, Dispatch } from "../../utility/common/storeHelper"
import * as constants from "./constants"
import { actions } from "./slice"
import { handleHttpException } from "../handleHttpException"
import { ArticleSubscriptionFormValues } from "../../models/knowledgeBaseSubscription"
import knowledgeBaseSubscriptionController from "../../api/controllers/knowledgeBaseSubscriptions"
import { formatSubscriptionDiff } from "../../utility/knowledgeBase/knowledgeBaseSubscriptions"

export const getCurrentSubscriptions =
    (projectId: string, articleCode: string): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.getCurrentSubscriptionsProcess())
        try {
            const articleSubscriptions = await knowledgeBaseSubscriptionController.get(projectId, articleCode)
            dispatch(actions.getCurrentSubscriptionsSuccess(articleSubscriptions))
        } catch (e) {
            handleHttpException(e, constants.GET_CURRENT_SUBSCRIPTIONS, actions.getCurrentSubscriptionsError, dispatch)
        }
    }

export const updateSubscriptions =
    (
        projectId: string,
        articleCode: string,
        subscriptions: ArticleSubscriptionFormValues,
        hidePopover: Function
    ): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        dispatch(actions.updateSubscriptionsProcess())
        try {
            const request = formatSubscriptionDiff(subscriptions)
            const articleSubscriptions = await knowledgeBaseSubscriptionController.update(
                projectId,
                articleCode,
                request
            )
            dispatch(actions.updateSubscriptionsSuccess(articleSubscriptions))
            hidePopover()
        } catch (e) {
            handleHttpException(e, constants.UPDATE_SUBSCRIPTIONS, actions.updateSubscriptionsFailed, dispatch)
        }
    }
