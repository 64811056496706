import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { selectCurrentUser } from "../../store/users/selectors"
import UserProfileForm from "../../components/UserProfileForm/UserProfileForm"
import "./User.scss"
import PageLayout from "../../components/PageLayout/PageLayout"
import { definedMenuItems } from "../../utility/menu/definedMenuItems"

const tProjectNamespace = "project:"

const UserPage: React.FC = () => {
    const user = useSelector(selectCurrentUser)
    const { t } = useTranslation()

    return (
        <PageLayout isCollapsed>
            <PageLayout.Content className="user-page">
                <div className="user-page__header">
                    <span className="user-page__title">
                        {t(`${tProjectNamespace}${definedMenuItems.Profile.title}`)}
                    </span>
                </div>
                {user && <UserProfileForm user={user} />}
            </PageLayout.Content>
        </PageLayout>
    )
}

export default UserPage
