import React, { Fragment } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import styles from "./TooltipTrigger.module.scss"
import { CustomOverlayTriggerProps } from "../../utility/common/customOverlayTypes"
import { OverlayInjectedProps } from "react-bootstrap/esm/Overlay"
import cn from "classnames"

export interface TooltipTriggerProps extends CustomOverlayTriggerProps {
    id: string
    condition?: boolean
    content?: string
}

const TooltipTrigger: React.FC<TooltipTriggerProps> = props => {
    return (
        <Fragment>
            {typeof props.condition === "undefined" || props.condition ? (
                <OverlayTrigger
                    {...props}
                    overlay={(overlayProps: OverlayInjectedProps) => (
                        <Tooltip id={props.id} {...overlayProps} className={cn(props.className, styles.tooltipTrigger)}>
                            {props.content}
                        </Tooltip>
                    )}
                >
                    {props.children}
                </OverlayTrigger>
            ) : (
                props.children
            )}
        </Fragment>
    )
}

export default TooltipTrigger
