import React from "react"
import styles from "./ColorCircleInput.module.scss"
import { useField } from "formik"
import cn from "classnames"
import { ClassProps } from "../../utility/common/props"

export interface ColorCircleInputProps extends ClassProps {
    name: string
    id: string
    colors: string[]
}

const ColorCircleInput: React.FC<ColorCircleInputProps> = props => {
    const [field, , helpers] = useField<any>(props)
    const { className, colors } = props
    const { setValue } = helpers
    const uppercaseColors = colors.map(element => element.toUpperCase())
    const isSelected = (color: string) => field.value === color && styles.colorCircleInput__circle_selected
    const setSelected = (color: string) => (isSelected(color) ? `0 0 0 2px ${color}` : "none")
    return (
        <div className={cn(styles.colorCircleInput, className)}>
            {uppercaseColors.map(element => (
                <div
                    key={element}
                    className={cn(styles.colorCircleInput__circle, isSelected(element))}
                    onClick={() => setValue(element)}
                    style={{
                        backgroundColor: element,
                        boxShadow: setSelected(element)
                    }}
                />
            ))}
        </div>
    )
}

export default ColorCircleInput
