import React, { useCallback, useContext, useEffect, useState } from "react"
import { ModalContext } from "../../components/ModalContext/ModalContext"

export const useModal = (component: React.FC) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [wasClosed, setWasClosed] = useState(false)
    const context = useContext(ModalContext)

    const openModal = useCallback(() => {
        setModalOpen(true)
    }, [])

    const closeModal = useCallback(() => {
        setWasClosed(true)
        setModalOpen(false)
    }, [])

    const onExited = useCallback(() => {
        context.hideModal()
    }, [context])

    useEffect(() => {
        if (!modalOpen) {
            if (!wasClosed) return
            setWasClosed(false)
        }
        context.showModal(component)
    }, [modalOpen, component, context, wasClosed])

    return { modalOpen, openModal, closeModal, onExited }
}
