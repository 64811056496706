import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Formik } from "formik"
import * as Yup from "yup"
import {
    ChannelStickersType,
    ChannelStickerValuesType,
    Sticker,
    StickerChannel,
    StickerValues
} from "../../../models/sticker"
import { createSticker, updateSticker } from "../../../store/stickers/thunks"
import FormikStickerForm from "./FormikStickerForm"
import { projectIdRegex } from "../../../utility/common/projectIdRegex"

const tNamespace = "stickers:form.error."

export const allStickersChannels = [
    StickerChannel.Viber,
    StickerChannel.Telegram,
    StickerChannel.Odnoklassniki,
    StickerChannel.Vkontakte,
    StickerChannel.WebChat
]

const getValuesFromSticker = (sticker: Sticker): StickerValues => ({
    Id: sticker.Id,
    Name: sticker.Name,
    StickerUrl: sticker.StickerUrl,
    ChannelStickers: allStickersChannels.reduce<ChannelStickerValuesType>((obj, stickerChannel) => {
        obj[stickerChannel] = sticker.ChannelStickers[stickerChannel]?.StickerId ?? ""
        return obj
    }, {})
})

const getDefaultValues = (): StickerValues => ({
    Id: "",
    Name: "",
    StickerUrl: "",
    ChannelStickers: {
        [StickerChannel.Odnoklassniki]: "",
        [StickerChannel.Telegram]: "",
        [StickerChannel.Viber]: "",
        [StickerChannel.Vkontakte]: "",
        [StickerChannel.WebChat]: ""
    }
})

const buildRequest = (projectId: string, values: StickerValues, sticker?: Sticker): Sticker => ({
    Id: values.Id,
    Name: values.Name,
    CustomerId: projectId,
    ChannelStickers: allStickersChannels.reduce<ChannelStickersType>((obj, stickerChannel) => {
        const stickerId = values.ChannelStickers[stickerChannel]
        if (stickerId) {
            obj[stickerChannel] = { StickerId: stickerId }
        }
        return obj
    }, {}),
    StickerUrl: values.StickerUrl,
    IsAnimated: sticker?.IsAnimated ?? false
})

interface Props {
    projectId: string
    sticker?: Sticker
    close: () => void
}

const StickerForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { projectId, sticker, close } = props

    return (
        <Formik
            enableReinitialize={true}
            initialValues={sticker ? getValuesFromSticker(sticker) : getDefaultValues()}
            validationSchema={() =>
                Yup.object().shape({
                    Id: Yup.string()
                        .requiredExcludeEmpty(`${tNamespace}id-required`)
                        .matches(projectIdRegex, {
                            message: `${tNamespace}invalid-id`,
                            excludeEmptyString: true
                        }),
                    Name: Yup.string().requiredExcludeEmpty(`${tNamespace}title-required`),
                    StickerUrl: Yup.string().requiredExcludeEmpty(`${tNamespace}url-required`)
                })
            }
            onSubmit={(values: StickerValues) => {
                if (sticker) {
                    dispatch(updateSticker(buildRequest(projectId, values, sticker), close))
                } else {
                    dispatch(createSticker(buildRequest(projectId, values), close))
                }
            }}
        >
            {formikProps => <FormikStickerForm sticker={sticker} {...formikProps} t={t} />}
        </Formik>
    )
}

export default StickerForm
