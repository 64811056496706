import React from "react"
import CatalogForm, { CatalogFormProps } from "../CatalogForm/CatalogForm"
import AddButton from "../AddButton/AddButton"
import PopoverTrigger from "../PopoverTrigger/PopoverTrigger"
import { CatalogFormValues } from "../../models/catalog"
import "./CatalogPopover.scss"
import { faPen, faPlus } from "@fortawesome/pro-light-svg-icons"
import CategoryAction from "../CategoryAction/CategoryAction"
import SettingsContextMenu from "../SettingsContextMenu/SettingsContextMenu"
import SettingsContextMenuItem from "../SettingsContextMenuItem/SettingsContextMenuItem"
import { useTranslation } from "react-i18next"
import { formTranslation } from "../../locales/form"
import { useModal } from "../../utility/common/useModal"
import AlertDialog from "../AlertDialog/AlertDialog"
import { usePopoverShown } from "../../utility/knowledgeBase/usePopoverShown"
import SettingsButton from "../SettingsButton/SettingsButton"
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash"
import { faArrowToBottom } from "@fortawesome/pro-light-svg-icons/faArrowToBottom"

const tNamespace = "knowledgeBase:"

export interface CatalogPopoverProps extends Omit<CatalogFormProps, "onCancel" | "onCreateCatalog" | "onEditCatalog"> {
    onCreateCatalog: (values: CatalogFormValues, callbackFn: () => void) => void
    onEditCatalog: (values: CatalogFormValues, callbackFn: () => void) => void
    onDeleteCatalog: (callbackFn: () => void) => void
    onEditClick?: () => void
    onDownloadClick?: () => void
    onCancelEditClick?: () => void
    isWideAddButton?: boolean
    disableRemoving?: boolean
    disableProjectsSelector?: boolean
    unavailableRemovingMessage?: string
}

const CatalogPopover: React.FC<CatalogPopoverProps> = props => {
    const {
        onCreateCatalog,
        onEditCatalog,
        onDeleteCatalog,
        onEditClick,
        onDownloadClick,
        catalogTitle,
        isEdit,
        permittedActionOptions,
        loading,
        permissions,
        isWideAddButton = false,
        disableRemoving = false,
        disableProjectsSelector,
        unavailableRemovingMessage
    } = props

    const { t } = useTranslation()
    const { shown, showPopover, hidePopover } = usePopoverShown()

    const { modalOpen, openModal, closeModal, onExited } = useModal(() => (
        <AlertDialog
            show={modalOpen}
            title={t(`${tNamespace}delete-catalog-alert.title`)}
            message={t(`${tNamespace}delete-catalog-alert.message`)}
            submitBtnText={t(formTranslation.delete)}
            onClose={closeModal}
            onSubmit={handleDeleteCatalog}
            variant="danger"
            onExited={onExited}
        />
    ))

    const handleCreateCatalog = (values: CatalogFormValues) => onCreateCatalog(values, handleHideEditPopover)
    const handleEditCatalog = (values: CatalogFormValues) => onEditCatalog(values, handleHideEditPopover)
    const handleDeleteCatalog = () => onDeleteCatalog(closeModal)

    const getSettings = () => {
        const settings = [
            <SettingsContextMenuItem
                key="edit-catalog"
                id="edit-catalog"
                text={t(formTranslation.edit)}
                onClick={handleShowEditPopover}
                icon={faPen}
            />
        ]

        if (onDownloadClick) {
            settings.push(
                <SettingsContextMenuItem
                    key="download-catalog"
                    id="download-catalog"
                    text={t(`${tNamespace}article-actions.download-catalog`)}
                    onClick={handleDownload}
                    icon={faArrowToBottom}
                />
            )
        }

        if (isEdit && !disableRemoving) {
            settings.push(
                <SettingsContextMenuItem
                    key="delete-catalog"
                    id="delete-catalog"
                    text={t(formTranslation.delete)}
                    danger
                    onClick={handleShowDeleteModal}
                    unavailableMessage={unavailableRemovingMessage}
                    icon={faTrash}
                />
            )
        }

        return settings
    }

    const handleShowEditPopover = () => {
        showPopover()
    }

    const handleHideEditPopover = () => {
        hidePopover()
        onEditClick && onEditClick()
    }

    const handleDownload = () => {
        hidePopover()
        onDownloadClick && onDownloadClick()
        onEditClick && onEditClick()
    }

    const handleShowDeleteModal = () => {
        hidePopover()
        openModal()
        onEditClick && onEditClick()
    }

    const getPopoverChildren = () => {
        if (isEdit) {
            return (
                <CategoryAction id="edit-catalog" key="edit-catalog">
                    <SettingsContextMenu
                        className="catalog-popover__settings-button"
                        items={getSettings()}
                        onHide={onEditClick}
                    >
                        <SettingsButton onClick={onEditClick} />
                    </SettingsContextMenu>
                </CategoryAction>
            )
        } else {
            if (isWideAddButton) {
                return (
                    <AddButton
                        key="addCatalog"
                        className="catalog-popover__add-catalog"
                        iconClassName="catalog-popover__add-catalog-icon"
                        variant="outline-light"
                        onClick={handleShowEditPopover}
                    />
                )
            } else {
                return (
                    <CategoryAction
                        id="add-catalog"
                        key="add-catalog"
                        tooltipText={t(formTranslation.add)}
                        icon={faPlus}
                        onClick={handleShowEditPopover}
                    />
                )
            }
        }
    }

    return (
        <PopoverTrigger
            className="catalog-popover"
            id="add-catalog"
            content={
                <CatalogForm
                    catalogTitle={catalogTitle}
                    isEdit={isEdit}
                    onCreateCatalog={handleCreateCatalog}
                    onEditCatalog={handleEditCatalog}
                    onCancel={handleHideEditPopover}
                    permissions={permissions}
                    permittedActionOptions={permittedActionOptions}
                    loading={loading}
                    disableProjectsSelector={disableProjectsSelector}
                />
            }
            trigger="click"
            placement="auto"
            show={shown}
        >
            {getPopoverChildren()}
        </PopoverTrigger>
    )
}

export default CatalogPopover
