import React, { useContext } from "react"
import cn from "classnames"

import "./PageLayout.scss"
import SidebarRight from "../SidebarRight/SidebarRight"

export const CloseSidebarContext = React.createContext(true)
export const PageLayoutContentId = "page-layout-content"
const LEFT_SIDEBAR_DEFAULT_WIDTH = 380
export const EXTENDED_SIDEBAR_WIDTH = 670

interface SidebarProps {
    className?: string
    title: string
    onClose: () => void
    onCloseClick?: () => void
    settings?: JSX.Element[]
    stepBack?: boolean
    onBack?: () => void
    extended?: boolean
}

const Sidebar: React.FC<SidebarProps> = props => {
    const { className, title, onClose, onCloseClick, children, settings, onBack, extended = false } = props
    const isCollapsed = useContext(CloseSidebarContext)

    return (
        <SidebarRight
            title={title}
            className={cn("page-layout-sidebar", className)}
            open={!isCollapsed}
            onClose={onClose}
            onCloseClick={onCloseClick}
            settings={settings}
            onBack={onBack}
            width={extended ? EXTENDED_SIDEBAR_WIDTH : undefined}
        >
            {!isCollapsed && children}
        </SidebarRight>
    )
}

interface LeftSidebarProps {
    className?: string
    headerClassName?: string
    title: string
    actions?: JSX.Element[]
    intermediate?: JSX.Element
    width?: number
    isScrollable?: boolean
}

const LeftSidebar: React.FC<LeftSidebarProps> = props => {
    const {
        className,
        headerClassName,
        title,
        actions,
        intermediate,
        width = LEFT_SIDEBAR_DEFAULT_WIDTH,
        isScrollable,
        children
    } = props

    return (
        <div className={cn("page-layout-left-sidebar", className)} style={{ flexBasis: width }}>
            <div className={cn("page-layout-left-sidebar__header", headerClassName)}>
                <div className="page-layout-left-sidebar__title">{title}</div>
                {actions && <div className="page-layout-left-sidebar__actions">{actions}</div>}
            </div>
            {intermediate && <div className="page-layout-left-sidebar__intermediate">{intermediate}</div>}
            <div
                className={cn(
                    "page-layout-left-sidebar__body",
                    !isScrollable ? "page-layout-left-sidebar__body_not-scrollable" : undefined
                )}
            >
                {children}
            </div>
        </div>
    )
}

interface ContentProps {
    className?: string
    overlappingSidebar?: boolean
}

const Content: React.FC<ContentProps> = props => {
    const { children, className, overlappingSidebar } = props
    const isCollapsed = useContext(CloseSidebarContext)

    return (
        <div
            id={PageLayoutContentId}
            className={cn(
                "page-layout-content",
                className,
                !isCollapsed && !overlappingSidebar ? "page-layout-content_full" : undefined
            )}
        >
            {children}
        </div>
    )
}

type IPageLayout<P> = React.FC<P> & {
    Content: typeof Content
    Sidebar: typeof Sidebar
    LeftSidebar: typeof LeftSidebar
}

interface Props {
    className?: string
    isCollapsed: boolean
}

const PageLayout: IPageLayout<Props> = props => {
    const { className, isCollapsed, children } = props
    return (
        <CloseSidebarContext.Provider value={isCollapsed}>
            <div className={cn("page-layout", className)}>{children}</div>
        </CloseSidebarContext.Provider>
    )
}

PageLayout.Content = Content
PageLayout.Sidebar = Sidebar
PageLayout.LeftSidebar = LeftSidebar

export default PageLayout
