import React, { useEffect } from "react"
import "./ArticleCommentThreadView.scss"
import { useDispatch, useSelector } from "react-redux"
import { selectCurrentProjectId } from "../../store/projects/selectors"
import Async from "../Async/Async"
import {
    selectArticleCommentInputStates,
    selectArticleCommentThreadState,
    selectArticleReplySendState
} from "../../store/knowledgeBaseComments/selectors"
import {
    cancelArticleCommentInput,
    enableArticleCommentInputMode,
    getArticleCommentThread
} from "../../store/knowledgeBaseComments/thunks"
import ArticleCommentThreadViewLoader from "./ArticleCommentThreadViewLoader"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import { ArticleComment, ArticleCommentInput, ArticleCommentThread } from "../../models/articleComment"
import ArticleCommentComponent from "../ArticleComment/ArticleCommentComponent"
import { selectCurrentUser } from "../../store/users/selectors"
import MessageInput from "../MessageInput/MessageInput"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { getThreadInputCode } from "../../utility/knowledgeBase/articleComment"

const tNamespace = "knowledgeBase:comment."

interface Props {
    rootComment: ArticleComment
    articleTitle: string
    onReplySend: (articleTitle: string, rootArticleComment: ArticleComment, value: ArticleCommentInput) => void
}

const ArticleCommentThreadView: React.FC<Props> = props => {
    const { rootComment, onReplySend, articleTitle } = props

    const { t } = useTranslation()
    const threadInputCode = getThreadInputCode(rootComment.ArticleCode, rootComment.Id)
    const dispatch = useDispatch()
    const projectId = useSelector(selectCurrentProjectId)
    const user = useSelector(selectCurrentUser)
    const articleCommentThreadState = useSelector(selectArticleCommentThreadState)
    const articleReplySendState = useSelector(selectArticleReplySendState)
    const articleCommentInputStates = useSelector(selectArticleCommentInputStates)

    useEffect(() => {
        if (projectId) {
            dispatch(getArticleCommentThread(projectId, rootComment))
        }
    }, [dispatch, projectId, rootComment])

    const inputState = articleCommentInputStates[threadInputCode]
    const handleSend = (message: string) => onReplySend(articleTitle, rootComment, { MessageText: message })
    const handleCancel = () => cancelArticleCommentInput(dispatch, threadInputCode)
    const handleEnableInput = () => enableArticleCommentInputMode(dispatch, threadInputCode)

    return (
        <Async<ArticleCommentThread>
            dataState={articleCommentThreadState}
            processView={<ArticleCommentThreadViewLoader />}
            errorView={({ message }) => <ErrorMessage text={message} />}
        >
            {({ data: replies }) => (
                <div className="article-comment-thread-view">
                    <div className="article-comment-thread-view__replies">
                        {[rootComment, ...replies.Items].map(c => (
                            <ArticleCommentComponent key={c.Id} data={c} />
                        ))}
                    </div>
                    <div className="article-comment-thread-view__input-container">
                        {inputState ? (
                            <MessageInput
                                onSend={handleSend}
                                onCancel={handleCancel}
                                user={user}
                                loading={articleReplySendState.inProcess}
                            />
                        ) : (
                            <Button variant="link" onClick={handleEnableInput}>
                                {t(`${tNamespace}to-reply`)}
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </Async>
    )
}

export default ArticleCommentThreadView
