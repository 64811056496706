import React from "react"
import { Col, Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useField } from "formik"
import InfoIcon from "../InfoIcon/InfoIcon"

const tNamespace = "common:time."

interface Props {
    id: string
    label: string
    name: string
    tooltip?: string
    daysHidden?: boolean
}

const TimeoutControl: React.FC<Props> = props => {
    const { t } = useTranslation()
    const { id, label, name, tooltip, daysHidden } = props

    const [daysField, daysFieldMeta] = useField(`${name}.Days`)
    const [hoursField, hoursFieldMeta] = useField(`${name}.Hours`)
    const [minutesField, minutesFieldMeta] = useField(`${name}.Minutes`)
    const [secondsField, secondsFieldMeta] = useField(`${name}.Seconds`)

    return (
        <Form.Group>
            <Form.Label className="pb-1">
                {label}
                {tooltip && <InfoIcon id={`tooltip-${id}`} content={tooltip} />}
            </Form.Label>
            <Form.Row>
                {!daysHidden && (
                    <Col md={3}>
                        <Form.Row>
                            <Form.Group as={Col} controlId={`${id}Days`}>
                                <Form.Control
                                    type="number"
                                    min="0"
                                    max="31"
                                    name={`${name}.Days`}
                                    onChange={daysField.onChange}
                                    onBlur={daysField.onBlur}
                                    value={daysField.value}
                                    isInvalid={daysFieldMeta.touched && !!daysFieldMeta.error}
                                />
                            </Form.Group>
                            <Form.Label column>{t(`${tNamespace}d`)}</Form.Label>
                        </Form.Row>
                    </Col>
                )}
                <Col md={3}>
                    <Form.Row>
                        <Form.Group as={Col} controlId={`${id}Hours`}>
                            <Form.Control
                                type="number"
                                min="00"
                                max="23"
                                name={`${name}.Hours`}
                                onChange={hoursField.onChange}
                                onBlur={hoursField.onBlur}
                                value={hoursField.value}
                                isInvalid={hoursFieldMeta.touched && !!hoursFieldMeta.error}
                            />
                        </Form.Group>
                        <Form.Label column>{t(`${tNamespace}hr`)}</Form.Label>
                    </Form.Row>
                </Col>
                <Col md={3}>
                    <Form.Row>
                        <Form.Group as={Col} controlId={`${id}Minutes`}>
                            <Form.Control
                                type="number"
                                min="00"
                                max="59"
                                name={`${name}.Minutes`}
                                onChange={minutesField.onChange}
                                onBlur={minutesField.onBlur}
                                value={minutesField.value}
                                isInvalid={minutesFieldMeta.touched && !!minutesFieldMeta.error}
                            />
                        </Form.Group>
                        <Form.Label column>{t(`${tNamespace}min`)}</Form.Label>
                    </Form.Row>
                </Col>
                <Col md={3}>
                    <Form.Row>
                        <Form.Group as={Col} controlId={`${id}Seconds`}>
                            <Form.Control
                                type="number"
                                min="00"
                                max="59"
                                name={`${name}.Seconds`}
                                onChange={secondsField.onChange}
                                onBlur={secondsField.onBlur}
                                value={secondsField.value}
                                isInvalid={secondsFieldMeta.touched && !!secondsFieldMeta.error}
                            />
                        </Form.Group>
                        <Form.Label column>{t(`${tNamespace}sec`)}</Form.Label>
                    </Form.Row>
                </Col>
            </Form.Row>
        </Form.Group>
    )
}

export default TimeoutControl
