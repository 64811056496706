export const changeFileUrlToProxy = (fileUrl: string) => {
    if (fileUrl.startsWith("blob:")) {
        return fileUrl
    }
    const parsedUrl = new URL(fileUrl)
    if (origin.startsWith("http://localhost")) {
        return fileUrl
    }

    const result = document.location.origin + "/api/dialogs/get_file" + parsedUrl.pathname
    return result.replace("file//", "file/")
}
