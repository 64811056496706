import React from "react"
import ReactDOM from "react-dom"
import { Provider, ReactReduxContext } from "react-redux"
import ResizeObserver from "resize-observer-polyfill"
import NetworkService from "./api/networkService"
import "./utility/common/setupYupCustomValidations"

import "./index.scss"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import store, { history } from "./store/store"
import "./i18n"
import { isMobileOnly } from "react-device-detect"
import MobileLockScreen from "./components/MobileLockScreen/MobileLockScreen"

// Use ResizeObserver polyfill
if (!(window as any).ResizeObserver) {
    ;(window as any).ResizeObserver = ResizeObserver
}

// initialize axios interceptors for auth
NetworkService.setupInterceptors(store)

ReactDOM.render(
    isMobileOnly ? (
        <MobileLockScreen />
    ) : (
        <Provider store={store}>
            <App history={history} context={ReactReduxContext} />
        </Provider>
    ),
    document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
