import React from "react"
import styles from "./MarkdownWidgetForm.module.scss"
import { FormikProps } from "formik/dist/types"
import { WithT } from "i18next"
import { preventSubmitOnEnter } from "../../../../../utility/common/preventSubmitOnEnter"
import { Form } from "formik"
import { Button } from "react-bootstrap"
import { formTranslation } from "../../../../../locales/form"
import { nameof } from "../../../../../utility/common/nameof"
import ValidatableInput from "../../../../ValidatableInput/ValidatableInput"
import { Widget } from "./MarkdownWidgetForm"
import CodeBlockValidatableInput from "../../../../CodeBlockValidatableInput/CodeBlockValidatableInput"

const tNamespace = "knowledgeBase:widget."

interface Props extends FormikProps<Widget>, WithT {
    onCancel: () => void
}

const FormikMarkdownWidgetForm: React.FC<Props> = props => {
    const { t, handleSubmit, onCancel } = props

    return (
        <Form className={styles.markdownWidgetForm} onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <div className={styles.markdownWidgetForm__content}>
                <ValidatableInput
                    id="formId"
                    type="text"
                    name={nameof<Widget>("id")}
                    label={t(`${tNamespace}id`)}
                    placeholder={t(formTranslation.enterText)}
                />
                <ValidatableInput
                    id="formTitle"
                    type="text"
                    name={nameof<Widget>("title")}
                    label={t(formTranslation.title)}
                    placeholder={t(formTranslation.enterText)}
                />
                <ValidatableInput
                    id="formDescription"
                    type="text"
                    name={nameof<Widget>("description")}
                    label={t(`${tNamespace}description`)}
                    placeholder={t(formTranslation.enterText)}
                />
                <CodeBlockValidatableInput id="formJson" name={nameof<Widget>("json")} label={t(`${tNamespace}json`)} />
            </div>
            <div className={styles.markdownWidgetForm__footer}>
                <Button className={styles.markdownWidgetForm__cancel} variant="light" onClick={onCancel}>
                    {t(formTranslation.cancel)}
                </Button>
                <Button className={styles.markdownWidgetForm__submit} variant="primary" type="submit">
                    {t(formTranslation.save)}
                </Button>
            </div>
        </Form>
    )
}

export default FormikMarkdownWidgetForm
