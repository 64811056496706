import { Action } from "redux"
import { SystemError } from "../core/error"
import { apiCallExceptionToSystemError } from "../utility/common/apiCallExceptionToSystemError"
import { logError, logDebug } from "../utility/common/logError"
import { Dispatch } from "../utility/common/storeHelper"
import { saveNotificationError } from "./notifications/thunks"
import { redirectToRoot } from "../utility/auth/credentialHelper"

export const handleUnauthorized = () => {
    logDebug("Received 401 code, redirecting to login page...")
    redirectToRoot()
}

export const handleHttpException = (
    ex: any,
    errMsgKey: string,
    createAction: (err: SystemError) => Action,
    dispatch: Dispatch,
    sendNotification: boolean = true,
    redirect = true
) => {
    if (ex?.response?.status === 401 && redirect) {
        return handleUnauthorized()
    }

    const error = apiCallExceptionToSystemError(errMsgKey, ex)
    logError(error)
    dispatch(createAction(error))
    if (sendNotification) {
        saveNotificationError(dispatch, error)
    }
}

export const handleHttpExceptionWithoutAction = (
    ex: any,
    errMsgKey: string,
    dispatch: Dispatch,
    sendNotification: boolean = true,
    redirect = true
) => {
    if (ex?.response?.status === 401 && redirect) {
        return handleUnauthorized()
    }

    const error = apiCallExceptionToSystemError(errMsgKey, ex)
    logError(error)
    if (sendNotification) {
        saveNotificationError(dispatch, error)
    }
}

export const handleHttpExceptionWithCallback = (
    ex: any,
    errMsgKey: string,
    dispatch: Dispatch,
    callback: (ex: SystemError) => void,
    sendNotification: boolean = true
) => {
    const error = apiCallExceptionToSystemError(errMsgKey, ex)
    logError(error)
    callback(error)
    if (sendNotification) {
        saveNotificationError(dispatch, error)
    }
}
