import { createBrowserHistory } from "history"
import { routerMiddleware as createRouterMiddleware } from "connected-react-router"
import thunk from "redux-thunk"
import { configureStore } from "@reduxjs/toolkit"
import "../utility/common/setupYupCustomValidations"

import createRootReducer from "./rootReducer"

export const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL + "/"
})
const routerMiddleware = createRouterMiddleware(history)

const store = configureStore({
    reducer: createRootReducer(history),
    middleware: [routerMiddleware, thunk],
    devTools: process.env.NODE_ENV === "development"
})

export type Store = typeof store

export default store
