import { apiInstance } from "./instances"
import { redirectToRoot } from "../utility/auth/credentialHelper"
import { Store } from "../store/store"

const networkService = {
    setupInterceptors: (store: Store) => {
        apiInstance.interceptors.response.use(
            response => {
                return response
            },
            error => {
                return Promise.reject(error)
            }
        )
    }
}

export default networkService
