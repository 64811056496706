import { Dispatch, ActionResult } from "../../utility/common/storeHelper"
import configController from "../../api/controllers/config"
import { errorTranslation } from "../../locales/error"
import { actions } from "./slice"
import { handleHttpException } from "../handleHttpException"

export const getConfig = (): ActionResult<Promise<void>> => async (dispatch: Dispatch) => {
    try {
        dispatch(actions.getConfigProcess())
        const configItem = await configController.get()
        dispatch(actions.getConfigSuccess(configItem))
    } catch (e) {
        handleHttpException(e, errorTranslation.config, err => actions.getConfigFailed(err), dispatch)
    }
}
