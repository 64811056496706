import React, { useCallback, useEffect, useMemo } from "react"
import { ChannelFormProps } from "../ChannelForm/ChannelForm"
import { FormikProps } from "formik"
import { ParamValues } from "../../models/parameterDeclaration"
import { AuthSettingsValues, AuthType, SyncValues, WebChatChannelValues } from "../../models/webChatValues"
import { WithT } from "i18next"
import { shallowEqual, useSelector } from "react-redux"
import {
    selectChannelAdditionalData,
    selectChannels,
    selectChannelsState,
    selectCreateChannelState,
    selectUpdateChannelState
} from "../../store/channels/selectors"
import { selectAgents } from "../../store/agents/selectors"
import ChannelFormHeader from "../ChannelFormHeader/ChannelFormHeader"
import { nameof, nameof2 } from "../../utility/common/nameof"
import FormAccordion from "../FormAccordion/FormAccordion"
import RadioButtonValidatableInput from "../RadioButtonValidatableInput/RadioButtonValidatableInput"
import { formTranslation } from "../../locales/form"
import CopyInputAsync from "../CopyInputAsync/CopyInputAsync"
import LoadingButton from "../LoadingButton/LoadingButton"
import InfoIcon from "../InfoIcon/InfoIcon"
import DebouncedValidatableInput from "../ValidatableInput/DebouncedValidatableInput"
import DebouncedCheckBox from "../CheckBoxValidatableInput/DebouncedCheckBox"
import { isWebChat } from "../../utility/channels/channelTranslator"
import { ChannelTypeString, WebChatAdditionalData } from "../../models/channel"
import DocLink from "../DocLink/DocLink"
import { openExternalLink } from "../../utility/common/urlHelper"
import ChannelFormWrapper from "../ChannelFormWrapper/ChannelFormWrapper"
import { testId } from "../../utility/tests/testId"

const tNamespace = "channel:form."
const tTooltipNamespace = "channel:tooltip."
const tLinkNamespace = "channel:link."

type OptionalWebChatAdditionalData = Partial<WebChatAdditionalData>

const FormikWebChatChannelFormGeneral: React.FC<ChannelFormProps & FormikProps<WebChatChannelValues> & WithT> =
    props => {
        const { channel, channelType, t, handleSubmit, values, setFieldValue, disabled } = props

        const asyncState = useSelector(channel ? selectUpdateChannelState : selectCreateChannelState, shallowEqual)
        const agents = useSelector(selectAgents)
        const channelsState = useSelector(selectChannelsState)
        const channelsAdditionalData = useSelector(selectChannelAdditionalData)
        const allChannels = useSelector(selectChannels)

        const agentOptions = useMemo(
            () => (agents ? agents.map(agent => ({ name: agent.Id, value: agent.Id })) : []),
            [agents]
        )

        const chatOptions = useMemo(
            () =>
                allChannels
                    ? allChannels
                          .filter(c => c.Type === ChannelTypeString.WebChat && (!channel || channel.Id !== c.Id))
                          .map(channel => ({ name: channel.Title, value: channel.Id }))
                    : [],
            [allChannels, channel]
        )

        const channelState = useMemo(() => channel && channelsState[channel.Id], [channel, channelsState])
        const channelAdditionalData = useMemo<OptionalWebChatAdditionalData>(
            () => (channel ? channelsAdditionalData[channel.Id] || {} : {}),
            [channel, channelsAdditionalData]
        )

        const goToTestPage = useCallback((url?: string) => {
            return () => url && openExternalLink(url)
        }, [])

        const handleToggleBlock = useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue(nameof2<WebChatChannelValues, ParamValues>("Params", "block"), !e.target.checked, false)
            },
            [setFieldValue]
        )

        useEffect(() => {
            const mediatorId = values.SyncSettings.mediatorId
            if (
                mediatorId &&
                mediatorId !== values.BotSettings.Id &&
                !chatOptions.some(option => option.value === mediatorId)
            ) {
                setFieldValue(nameof2<WebChatChannelValues, SyncValues>("SyncSettings", "enabled"), false)
                setFieldValue(
                    nameof2<WebChatChannelValues, SyncValues>("SyncSettings", "mediatorId"),
                    values.BotSettings.Id
                )
            }
        }, [chatOptions, setFieldValue, values.SyncSettings, values.BotSettings.Id])

        return (
            <ChannelFormWrapper
                className="webchat-form"
                onSubmit={handleSubmit}
                disabled={disabled}
                loading={asyncState.inProcess}
                footer={
                    channel && isWebChat(channel) ? (
                        <>
                            <FormAccordion
                                title={t(`${tNamespace}embed-code`)}
                                className="channel-form__footer-accordion"
                                loading={channelState === "wait"}
                            >
                                <CopyInputAsync value={channelAdditionalData.insertCode || ""} />
                            </FormAccordion>
                            <LoadingButton
                                variant="light"
                                onClick={goToTestPage(channelAdditionalData.testPageUrl)}
                                disabled={!channelAdditionalData.testPageUrl}
                                loading={channelState === "wait"}
                                className="channel-form__test-chat-button"
                                data-test-id={testId.testChat}
                            >
                                {t(`${tNamespace}test-chat`)}
                            </LoadingButton>
                        </>
                    ) : undefined
                }
            >
                <ChannelFormHeader
                    t={t}
                    channelType={channelType}
                    isActive={!values.Params.block}
                    onToggle={handleToggleBlock}
                />
                <DocLink type={channelType}>{t(`${tLinkNamespace}creating-webchat-instructions`)}</DocLink>
                <div className="channel-form__section">
                    <DebouncedValidatableInput
                        id="formTitle"
                        type="text"
                        name={nameof<WebChatChannelValues>("Title")}
                        label={t(`${tNamespace}title`)}
                    />
                    <DebouncedValidatableInput
                        disabled={!(agentOptions && agentOptions.length)}
                        id="formAgent"
                        as="select"
                        name={nameof2<WebChatChannelValues, ParamValues>("Params", "default_agent")}
                        label={t(`${tNamespace}call-processing-agent`)}
                    >
                        <option value="" hidden />
                        {agentOptions.map(option => (
                            <option value={option.value} key={option.value}>
                                {option.name}
                            </option>
                        ))}
                    </DebouncedValidatableInput>
                </div>
                <div className="channel-form__section">
                    <div className="channel-form__text">{t(`${tNamespace}authorization-settings`)}</div>
                    <RadioButtonValidatableInput
                        value={AuthType.Anonymously}
                        id="formAuthorizationSettings1"
                        name={nameof2<WebChatChannelValues, AuthSettingsValues>("AuthSettings", "type")}
                        label={t(`${tNamespace}anonymously`)}
                    />
                    <RadioButtonValidatableInput
                        inline
                        value={AuthType.RequireAuthFields}
                        id="formAuthorizationSettings2"
                        name={nameof2<WebChatChannelValues, AuthSettingsValues>("AuthSettings", "type")}
                        label={t(`${tNamespace}request-questionnaire`)}
                        icon={
                            <InfoIcon
                                id="popoverAuthorizationSettings2"
                                title={t(`${tNamespace}request-questionnaire`)}
                                content={t(`${tTooltipNamespace}authorization-request-questionnaire-info`)}
                            />
                        }
                    />
                    <div className="extra-settings">
                        <span className="channel-form__text">{t(`${tNamespace}required-fields`)}</span>
                        <div className="channel-form__section">
                            <DebouncedCheckBox
                                id="formRequireFirstnameField"
                                name={nameof2<WebChatChannelValues, AuthSettingsValues>(
                                    "AuthSettings",
                                    "requireFirstnameField"
                                )}
                                label={t(formTranslation.firstName)}
                                disabled={values.AuthSettings.type !== AuthType.RequireAuthFields}
                            />
                            <DebouncedCheckBox
                                id="formRequireLastnameField"
                                name={nameof2<WebChatChannelValues, AuthSettingsValues>(
                                    "AuthSettings",
                                    "requireLastnameField"
                                )}
                                label={t(formTranslation.lastName)}
                                disabled={values.AuthSettings.type !== AuthType.RequireAuthFields}
                            />
                            <DebouncedCheckBox
                                id="formRequirePhoneField"
                                name={nameof2<WebChatChannelValues, AuthSettingsValues>(
                                    "AuthSettings",
                                    "requirePhoneField"
                                )}
                                label={t(formTranslation.phoneNumber)}
                                disabled={values.AuthSettings.type !== AuthType.RequireAuthFields}
                            />
                        </div>
                    </div>
                    <RadioButtonValidatableInput
                        inline
                        value={AuthType.JavaScriptAuthorization}
                        id="formAuthorizationSettings3"
                        name={nameof2<WebChatChannelValues, AuthSettingsValues>("AuthSettings", "type")}
                        label={t(`${tNamespace}javascript-authorization`)}
                        icon={
                            <InfoIcon
                                id="popoverAuthorizationSettings3"
                                title={t(`${tNamespace}javascript-authorization`)}
                                content={t(`${tTooltipNamespace}authorization-javascript-authorization-info`)}
                            />
                        }
                    />
                    <div className="extra-settings">
                        <DebouncedCheckBox
                            id="formUseHash"
                            name={nameof2<WebChatChannelValues, AuthSettingsValues>("AuthSettings", "useHash")}
                            label={t(`${tNamespace}cryptographic-signature-required`)}
                            disabled={values.AuthSettings.type !== AuthType.JavaScriptAuthorization}
                        />
                        <DebouncedValidatableInput
                            id="formSalt"
                            type="password"
                            name={nameof2<WebChatChannelValues, AuthSettingsValues>("AuthSettings", "salt")}
                            label={t(`${tNamespace}salt`)}
                            disabled={
                                !(
                                    values.AuthSettings &&
                                    values.AuthSettings.useHash &&
                                    values.AuthSettings.type === AuthType.JavaScriptAuthorization
                                )
                            }
                        />
                        <DebouncedCheckBox
                            id="formSynchronizeWithChat"
                            name={nameof2<WebChatChannelValues, SyncValues>("SyncSettings", "enabled")}
                            label={t(`${tNamespace}synchronize-with-chat`)}
                            disabled={
                                !chatOptions.length || values.AuthSettings.type !== AuthType.JavaScriptAuthorization
                            }
                        />
                        <DebouncedValidatableInput
                            id="formSynchronizeChat"
                            as="select"
                            name={nameof2<WebChatChannelValues, SyncValues>("SyncSettings", "mediatorId")}
                            disabled={
                                !(
                                    values.SyncSettings.enabled &&
                                    chatOptions.length &&
                                    values.AuthSettings.type === AuthType.JavaScriptAuthorization
                                )
                            }
                        >
                            <option value="" hidden>
                                {t(`${tNamespace}choose-chat`)}
                            </option>
                            {chatOptions.map(option => (
                                <option value={option.value} key={option.value}>
                                    {option.name}
                                </option>
                            ))}
                        </DebouncedValidatableInput>
                    </div>
                </div>
            </ChannelFormWrapper>
        )
    }

export default FormikWebChatChannelFormGeneral
