import { Task } from "./task"
import { SelectedOperatorStatus } from "./operatorStatus"
import { isSelectedOperatorStatusDto, SelectedOperatorStatusDto } from "./operatorStatusDto"
import { isOperatorDto, OperatorDto } from "./operatorDto"

export interface CreateOperatorRequest {
    Id?: string
    FirstName: string
    LastName: string
    MiddleName?: string
    MaxCapacity?: number
    AssignmentWebHook?: string
    RoutingWebHook?: string
}

export interface DeleteFromQueueRequest {
    TenantId: string
    QueueId: string
}

export interface UpdateOperatorQueuesRequest {
    TenantId: string
    Queues: string[]
}

export interface UpdateOperator {
    FirstName: string
    LastName: string
    MiddleName: string | null
    MaxCapacity: number
    AssignmentWebHook: string | null
    RoutingWebHook: string | null
}

export interface TenantQueues {
    TenantId: string
    Queues: string[]
}

export interface Operator {
    Id: string
    FirstName: string
    LastName: string
    MiddleName?: string
    Status: SelectedOperatorStatus
    MaxCapacity: number
    Capacity: number
    AssignmentWebHook?: string
    RoutingWebHook?: string
    TenantsQueues: TenantQueues[]
    Tasks: Task[]
}

export interface OperatorSummary {
    Id: string
    FirstName: string
    LastName: string
    TenantId: string
    Queues: string[]
}

export interface OperatorSearch {
    Id: string
    FirstName: string
    LastName: string
}

export interface OperatorStatusUpdatedMsgDto {
    OperatorId: string
    Status: SelectedOperatorStatusDto
}
export const isOperatorStatusUpdatedMsgDto = (value: any): value is OperatorStatusUpdatedMsgDto => {
    return !!(value && typeof value.OperatorId === "string" && isSelectedOperatorStatusDto(value.Status))
}

export interface OperatorStatusUpdatedMsg {
    OperatorId: string
    Status: SelectedOperatorStatus
}

export interface OperatorBecameActiveMsgDto {
    Operator: OperatorDto
}
export const isOperatorBecameActiveMsgDto = (value: any): value is OperatorBecameActiveMsgDto => {
    return !!(value && isOperatorDto(value.Operator))
}

export interface OperatorBecameActiveMsg {
    Operator: Operator
}

export interface OperatorBecameInactiveMsg {
    OperatorId: string
}

export interface OperatorQueuesWithCategoryUpdated {
    QueueId: string
    CategoryId: string
}

export interface OperatorQueuesUpdatedMsg {
    OperatorId: string
    AddedTo: OperatorQueuesWithCategoryUpdated[]
    RemovedFrom: OperatorQueuesWithCategoryUpdated[]
}

export type AcceptInviteOperatorFunction = (operatorId: string, dialogId: string, notificationId: string) => void
