import { Dispatch } from "../../utility/common/storeHelper"
import * as constants from "./constants"
import agentsController from "../../api/controllers/agents"
import { actions } from "./slice"
import { Agent } from "../../models/agent"
import { handleHttpException } from "../handleHttpException"

export function getAgents(projectId: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.getAgentsProcess())
            const agents = await agentsController.getAgents(projectId)
            dispatch(actions.getAgentsSuccess(agents))
        } catch (e) {
            handleHttpException(e, constants.GET_AGENTS_FAILED_MESSAGE, err => actions.getAgentsFailed(err), dispatch)
        }
    }
}

export function getAgentDeclarations(projectId: string, type: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.getAgentDeclarationsProcess())
            const declaration = await agentsController.getDeclarations(projectId, type)
            dispatch(actions.getAgentDeclarationsSuccess({ type, declaration }))
        } catch (e) {
            handleHttpException(
                e,
                constants.GET_AGENT_DECLARATIONS_FAILED_MESSAGE,
                err => actions.getAgentDeclarationsFailed(err),
                dispatch
            )
        }
    }
}

export function createAgent(createdAgent: Agent, callback: (agent: Agent) => void) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.createAgentProcess())
            const agent = await agentsController.create(createdAgent)
            dispatch(actions.createAgentSuccess(agent))
            callback(agent)
        } catch (e) {
            handleHttpException(
                e,
                constants.CREATE_AGENT_FAILED_MESSAGE,
                err => actions.createAgentFailed(err),
                dispatch
            )
        }
    }
}

export function updateAgent(updatedAgent: Agent, callback: (agent: Agent) => void) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.updateAgentProcess())
            const agent = await agentsController.update(updatedAgent)
            dispatch(actions.updateAgentSuccess(agent))
            callback(agent)
        } catch (e) {
            handleHttpException(
                e,
                constants.UPDATE_AGENT_FAILED_MESSAGE,
                err => actions.updateAgentFailed(err),
                dispatch
            )
        }
    }
}

export function deleteAgent(projectId: string, agentId: string, callback: Function) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(actions.deleteAgentProcess())
            await agentsController.delete(projectId, agentId)
            dispatch(actions.deleteAgentSuccess(agentId))
            callback()
        } catch (e) {
            handleHttpException(
                e,
                constants.DELETE_AGENT_FAILED_MESSAGE,
                err => actions.deleteAgentFailed(err),
                dispatch
            )
        }
    }
}
