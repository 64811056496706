import React, { useMemo } from "react"
import { ChannelFormProps } from "../ChannelForm/ChannelForm"
import { FormikProps } from "formik"
import { WithT } from "i18next"
import { shallowEqual, useSelector } from "react-redux"
import { selectCreateChannelState, selectUpdateChannelState } from "../../store/channels/selectors"
import { selectAgents } from "../../store/agents/selectors"
import ChannelFormHeader from "../ChannelFormHeader/ChannelFormHeader"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import { nameof, nameof2 } from "../../utility/common/nameof"
import { BotSettingsValues, ChannelValues } from "../../models/channelValues"
import { ParamValues } from "../../models/parameterDeclaration"
import DocLink from "../DocLink/DocLink"
import ChannelServerAddress from "../ChannelServerAddress/ChannelServerAddress"
import ChannelFormWrapper from "../ChannelFormWrapper/ChannelFormWrapper"
import InfoIcon from "../InfoIcon/InfoIcon"
import FormAccordion from "../FormAccordion/FormAccordion"
import IdField from "../IdField/IdField"

const tNamespace = "channel:form."
const tLinkNamespace = "channel:link."
const tTooltipNamespace = "channel:tooltip."

const FormikYandexAlisaChannelFormGeneral: React.FC<ChannelFormProps & FormikProps<ChannelValues> & WithT> = props => {
    const { channel, channelType, t, handleSubmit, disabled, values } = props

    const asyncState = useSelector(channel ? selectUpdateChannelState : selectCreateChannelState, shallowEqual)
    const agents = useSelector(selectAgents)

    const agentOptions = useMemo(
        () => (agents ? agents.map(agent => ({ name: agent.Id, value: agent.Id })) : []),
        [agents]
    )

    return (
        <ChannelFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={asyncState.inProcess}>
            <ChannelFormHeader channelType={channelType} t={t} isActive={false} disabled />
            <DocLink type={channelType}>{t(`${tLinkNamespace}creating-bot-instructions`)}</DocLink>
            <div className="channel-form__section">
                <ValidatableInput
                    id="formTitle"
                    type="text"
                    name={nameof<ChannelValues>("Title")}
                    label={t(`${tNamespace}title`)}
                />
            </div>
            <div className="channel-form__section">
                <div className="channel-form__section-subtitle">{t(`${tNamespace}call-routing`)}</div>
                <ValidatableInput
                    id="formAgent"
                    as="select"
                    name={nameof2<ChannelValues, ParamValues>("Params", "default_agent")}
                    label={t(`${tNamespace}call-processing-agent`)}
                >
                    <option value="" hidden />
                    {agentOptions.map(option => (
                        <option value={option.value} key={option.value}>
                            {option.name}
                        </option>
                    ))}
                </ValidatableInput>
                <ValidatableInput
                    id="formToken"
                    type="password"
                    name={nameof2<ChannelValues, BotSettingsValues>("BotSettings", "Token")}
                    label={t(`${tNamespace}token`)}
                />
                <ChannelServerAddress channelId={values.BotSettings.Id} serverAddress={values.ServerAddress} />
            </div>
        </ChannelFormWrapper>
    )
}

const FormikYandexAlisaChannelFormAdvanced: React.FC<ChannelFormProps & FormikProps<ChannelValues> & WithT> = props => {
    const { channel, values, t, handleSubmit, disabled } = props

    const asyncState = useSelector(channel ? selectUpdateChannelState : selectCreateChannelState, shallowEqual)

    return (
        <ChannelFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={asyncState.inProcess}>
            <IdField id={values.BotSettings.Id} className="channel-form__section" />
            <FormAccordion title={t(`${tNamespace}webhook-preprocessing`)}>
                <ValidatableInput
                    id="formPreprocessorWebHook"
                    type="text"
                    name={nameof<ChannelValues>("PreprocessorWebHook")}
                    label={t(`${tNamespace}webhook`)}
                    icon={
                        <InfoIcon
                            id="popoverPreprocessorWebHook"
                            title={t(`${tNamespace}webhook-preprocessing`)}
                            content={t(`${tTooltipNamespace}webhook-preprocessing`)}
                        />
                    }
                />
            </FormAccordion>
        </ChannelFormWrapper>
    )
}

export { FormikYandexAlisaChannelFormGeneral, FormikYandexAlisaChannelFormAdvanced }
