import React from "react"
import styles from "./NextAnswer.module.scss"
import cn from "classnames"
import { useTranslation } from "react-i18next"

export interface NextAnswerProps {
    isSelected: boolean
    onSelect: () => void
}

const NextAnswer: React.FC<NextAnswerProps> = props => {
    const { isSelected, onSelect } = props
    const { t } = useTranslation()

    return (
        <hr
            className={cn(styles.nextAnswer, isSelected ? "ProseMirror-selectednode" : undefined)}
            data-content={t("knowledgeBase:article-content-editor.next-answer")}
            onClick={onSelect}
        />
    )
}

export default NextAnswer
