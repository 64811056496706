import { SimpleUser } from "./user"
import { SimpleRole } from "./role"
import { UserAccessPrimitive } from "./knowledgeBaseUserAccess"

export enum KnowledgeBaseAccessType {
    Login = "Login",
    Role = "Role",
    All = "All"
}

export enum KnowledgeBasePermittedAction {
    View = "View",
    Edit = "Edit"
}

export enum KnowledgeBasePermissionWeight {
    Login = 3,
    Role = 2,
    All = 1
}

export interface KnowledgeBasePermission {
    Type: KnowledgeBaseAccessType
    Action: KnowledgeBasePermittedAction
    Value: string
    ProjectId: string
    SearchValue: string
    Weight: KnowledgeBasePermissionWeight
}

export interface SearchUsersAndRolesResponse {
    Users: SimpleUser[]
    Roles: SimpleRole[]
}

export interface GetUsersAndRolesByPermissionsRequest {
    AccessItems: UserAccessPrimitive[]
}

export interface GetUsersAndRolesByPermissionsResponse extends SearchUsersAndRolesResponse {}

export interface KnowledgeBasePermittedActionSelectItem {
    Action: KnowledgeBasePermittedAction
    Title: string
}
