import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import useDelay from "../../utility/common/useDelay"
import Async from "../Async/Async"
import SettingsTableLoader from "../SettingsTable/SettingsTableLoader"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import { getSurveys } from "../../store/surveys/thunks"
import { selectGetSurveysState } from "../../store/surveys/selectors"
import ProjectSurveys from "./ProjectSurveys"
import { SurveyDto } from "../../models/survey"
import { getSlots } from "../../store/slots/thunks"

interface Props {
    projectId: string
    title: string
    onAddClick: () => void
    onSettingsClick: (survey: SurveyDto) => void
    onDeleteClick: (projectId: string, survey: SurveyDto) => void
}

const ProjectSurveysContainer: React.FC<Props> = props => {
    const { projectId, title, onAddClick, onSettingsClick, onDeleteClick } = props

    const dispatch = useDispatch()
    const display = useDelay()

    const apiState = useSelector(selectGetSurveysState)

    useEffect(() => {
        dispatch(getSurveys(projectId))
        dispatch(getSlots(projectId))
    }, [dispatch, projectId])

    if (!display) {
        return null
    }

    return (
        <Async
            dataState={apiState}
            processView={<SettingsTableLoader columns={2} rows={2} rowHeight={56} />}
            errorView={({ message }) => <ErrorMessage text={message} />}
        >
            {({ data: surveys }) => (
                <ProjectSurveys
                    projectId={projectId}
                    title={title}
                    surveys={surveys}
                    onAddClick={onAddClick}
                    onSettingsClick={onSettingsClick}
                    onDeleteClick={onDeleteClick}
                />
            )}
        </Async>
    )
}

export default ProjectSurveysContainer
