import React, { useCallback, useEffect, useMemo, useState } from "react"
import { ChannelFormProps } from "../ChannelForm/ChannelForm"
import { FormikProps } from "formik"
import { ProviderSettingsValues, WhatsappAuthType, WhatsAppChannelValues } from "../../models/channelValues"
import { WithT } from "i18next"
import { shallowEqual, useSelector } from "react-redux"
import {
    selectCreateChannelState,
    selectUpdateChannelState,
    selectWebhooksPrefixes
} from "../../store/channels/selectors"
import { selectAgents } from "../../store/agents/selectors"
import ChannelFormHeader from "../ChannelFormHeader/ChannelFormHeader"
import { nameof, nameof2 } from "../../utility/common/nameof"
import { formTranslation } from "../../locales/form"
import RadioButtonValidatableInput from "../RadioButtonValidatableInput/RadioButtonValidatableInput"
import { ParamValues } from "../../models/parameterDeclaration"
import DocLink from "../DocLink/DocLink"
import { ChannelTypeString, WhatsappChannelType } from "../../models/channel"
import DebouncedValidatableInput from "../ValidatableInput/DebouncedValidatableInput"
import { getTestChannelLink } from "../../utility/channels/testChannelLink"
import ChannelServerAddress from "../ChannelServerAddress/ChannelServerAddress"
import { openExternalLink } from "../../utility/common/urlHelper"
import ChannelFormWrapper from "../ChannelFormWrapper/ChannelFormWrapper"

const tNamespace = "channel:form."
const tLinkNamespace = "channel:link."

const FormikWhatsAppChannelFormGeneral: React.FC<ChannelFormProps & FormikProps<WhatsAppChannelValues> & WithT> =
    props => {
        const { channel, channelType, values, t, handleSubmit, disabled } = props

        const asyncState = useSelector(channel ? selectUpdateChannelState : selectCreateChannelState, shallowEqual)
        const agents = useSelector(selectAgents)

        const agentOptions = useMemo(
            () => (agents ? agents.map(agent => ({ name: agent.Id, value: agent.Id })) : []),
            [agents]
        )

        const phoneNumber = useMemo(() => channel?.Params.phone_number, [channel])
        const goToTestPage = useCallback(() => {
            if (phoneNumber) {
                openExternalLink(getTestChannelLink(channelType.substring(0, channelType.indexOf("_")), phoneNumber))
            }
        }, [channelType, phoneNumber])

        const [serverAddress, setServerAddress] = useState<string | undefined>(channel?.ServerAddress)
        const webhookPrefixes = useSelector(selectWebhooksPrefixes)
        useEffect(() => {
            if (!webhookPrefixes) return

            const provider = values.ProviderSettings.provider
            const webhookPrefix = webhookPrefixes[provider]

            if (!webhookPrefix) return

            setServerAddress(webhookPrefix + values.BotSettings.Id)
        }, [channel, values.BotSettings.Id, values.ProviderSettings.provider, webhookPrefixes])

        return (
            <ChannelFormWrapper
                onSubmit={handleSubmit}
                onTest={goToTestPage}
                disabled={disabled}
                testDisabled={!phoneNumber}
                loading={asyncState.inProcess}
            >
                <ChannelFormHeader channelType={channelType} t={t} isActive={false} disabled />
                <DocLink type={ChannelTypeString.Whatsapp}>
                    {t(`${tLinkNamespace}creating-whatsapp-bot-instructions`)}
                </DocLink>
                <div className="channel-form__section">
                    <DebouncedValidatableInput
                        id="formTitle"
                        type="text"
                        name={nameof<WhatsAppChannelValues>("Title")}
                        label={t(`${tNamespace}title`)}
                    />
                </div>
                <div className="channel-form__section">
                    <div className="channel-form__section-subtitle">{t(`${tNamespace}call-routing`)}</div>
                    <DebouncedValidatableInput
                        id="formAgent"
                        as="select"
                        name={nameof2<WhatsAppChannelValues, ParamValues>("Params", "default_agent")}
                        label={t(`${tNamespace}call-processing-agent`)}
                    >
                        <option value="" hidden />
                        {agentOptions.map(option => (
                            <option value={option.value} key={option.value}>
                                {option.name}
                            </option>
                        ))}
                    </DebouncedValidatableInput>
                    <DebouncedValidatableInput
                        id="formPhoneNumber"
                        type="text"
                        name={nameof2<WhatsAppChannelValues, ParamValues>("Params", "phone_number")}
                        label={t(`${tNamespace}phone-number`)}
                    />
                </div>
                <div className="channel-form__section">
                    <div className="channel-form__section-subtitle">{t(`${tNamespace}provider`)}</div>
                    <RadioButtonValidatableInput
                        value={WhatsappChannelType.InfoBIP}
                        id="formProvider1"
                        name={nameof2<WhatsAppChannelValues, ProviderSettingsValues>("ProviderSettings", "provider")}
                        label="InfoBIP"
                    />
                    <div className="extra-settings">
                        <DebouncedValidatableInput
                            id="formScenarioKey"
                            type="text"
                            name={nameof2<WhatsAppChannelValues, ProviderSettingsValues>(
                                "ProviderSettings",
                                "scenario_key"
                            )}
                            label={t(`${tNamespace}scenario-key`)}
                            disabled={values.ProviderSettings.provider !== WhatsappChannelType.InfoBIP}
                        />
                        <DebouncedValidatableInput
                            id="formHostUrl"
                            type="text"
                            name={nameof2<WhatsAppChannelValues, ProviderSettingsValues>(
                                "ProviderSettings",
                                "host_url"
                            )}
                            label={t(`${tNamespace}server`)}
                            disabled={values.ProviderSettings.provider !== WhatsappChannelType.InfoBIP}
                        />
                        <div className="channel-form__section">
                            <div className="channel-form__text">{t(`${tNamespace}authorization`)}</div>
                            <RadioButtonValidatableInput
                                value={WhatsappAuthType.ApiKey}
                                id="formAuthorizationSettings1"
                                name={nameof2<WhatsAppChannelValues, ProviderSettingsValues>(
                                    "ProviderSettings",
                                    "authType"
                                )}
                                label={t(`${tNamespace}via-api-key`)}
                                disabled={values.ProviderSettings.provider !== WhatsappChannelType.InfoBIP}
                            />
                            <div className="extra-settings">
                                <DebouncedValidatableInput
                                    id="formApiKeyStreamTel"
                                    type="password"
                                    name={nameof2<WhatsAppChannelValues, ProviderSettingsValues>(
                                        "ProviderSettings",
                                        "api_key"
                                    )}
                                    label="API Key"
                                    disabled={
                                        values.ProviderSettings.authType !== WhatsappAuthType.ApiKey ||
                                        values.ProviderSettings.provider !== WhatsappChannelType.InfoBIP
                                    }
                                />
                            </div>
                            <RadioButtonValidatableInput
                                value={WhatsappAuthType.LoginPassword}
                                id="formAuthorizationSettings2"
                                name={nameof2<WhatsAppChannelValues, ProviderSettingsValues>(
                                    "ProviderSettings",
                                    "authType"
                                )}
                                label={t(`${tNamespace}login-password`)}
                                disabled={values.ProviderSettings.provider !== WhatsappChannelType.InfoBIP}
                            />
                            <div className="extra-settings">
                                <DebouncedValidatableInput
                                    id="formLogin"
                                    type="text"
                                    name={nameof2<WhatsAppChannelValues, ProviderSettingsValues>(
                                        "ProviderSettings",
                                        "username"
                                    )}
                                    label={t(formTranslation.login)}
                                    disabled={
                                        values.ProviderSettings.authType !== WhatsappAuthType.LoginPassword ||
                                        values.ProviderSettings.provider !== WhatsappChannelType.InfoBIP
                                    }
                                />
                                <DebouncedValidatableInput
                                    id="formApiPassword"
                                    type="password"
                                    name={nameof2<WhatsAppChannelValues, ProviderSettingsValues>(
                                        "ProviderSettings",
                                        "password"
                                    )}
                                    label={t(formTranslation.password)}
                                    disabled={
                                        values.ProviderSettings.authType !== WhatsappAuthType.LoginPassword ||
                                        values.ProviderSettings.provider !== WhatsappChannelType.InfoBIP
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <RadioButtonValidatableInput
                        value={WhatsappChannelType.StreamTel}
                        id="formProvider2"
                        name={nameof2<WhatsAppChannelValues, ProviderSettingsValues>("ProviderSettings", "provider")}
                        label="StreamTelecom"
                    />
                    <div className="extra-settings">
                        <DebouncedValidatableInput
                            id="formUsername"
                            type="text"
                            name={nameof2<WhatsAppChannelValues, ProviderSettingsValues>("ProviderSettings", "login")}
                            label={t(formTranslation.login)}
                            disabled={values.ProviderSettings.provider !== WhatsappChannelType.StreamTel}
                        />
                        <DebouncedValidatableInput
                            id="formPassword"
                            type="password"
                            name={nameof2<WhatsAppChannelValues, ProviderSettingsValues>(
                                "ProviderSettings",
                                "apipassword"
                            )}
                            label={t(formTranslation.password)}
                            disabled={values.ProviderSettings.provider !== WhatsappChannelType.StreamTel}
                        />
                        <DebouncedValidatableInput
                            id="formSourceAddress"
                            type="text"
                            name={nameof2<WhatsAppChannelValues, ProviderSettingsValues>(
                                "ProviderSettings",
                                "sourceAddress"
                            )}
                            label={t(`${tNamespace}sender-name`)}
                            disabled={values.ProviderSettings.provider !== WhatsappChannelType.StreamTel}
                        />
                    </div>

                    <RadioButtonValidatableInput
                        value={WhatsappChannelType.MFMS}
                        id="formProvider3"
                        name={nameof2<WhatsAppChannelValues, ProviderSettingsValues>("ProviderSettings", "provider")}
                        label="MFMS"
                    />
                    <div className="extra-settings">
                        <DebouncedValidatableInput
                            id="formSubject"
                            type="text"
                            name={nameof2<WhatsAppChannelValues, ProviderSettingsValues>("ProviderSettings", "subject")}
                            label={t(`${tNamespace}sender-name`)}
                            disabled={values.ProviderSettings.provider !== WhatsappChannelType.MFMS}
                        />
                        <DebouncedValidatableInput
                            id="formApiKeyMFMS"
                            type="password"
                            name={nameof2<WhatsAppChannelValues, ProviderSettingsValues>("ProviderSettings", "apikey")}
                            label={t(`${tNamespace}api-password`)}
                            disabled={values.ProviderSettings.provider !== WhatsappChannelType.MFMS}
                        />
                    </div>
                    <ChannelServerAddress channelId={values.BotSettings.Id} serverAddress={serverAddress} />
                </div>
            </ChannelFormWrapper>
        )
    }

export default FormikWhatsAppChannelFormGeneral
