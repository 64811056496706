import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useModal } from "../../utility/common/useModal"
import AlertDialog from "../AlertDialog/AlertDialog"
import { formTranslation } from "../../locales/form"
import SettingsContextMenuItem from "../SettingsContextMenuItem/SettingsContextMenuItem"
import { faPen, faTrash } from "@fortawesome/pro-light-svg-icons"
import { CellProps, Column } from "react-table"
import Can from "../Can/Can"
import { ModifyKnowledgeBase } from "../../permissions"
import SettingsContextMenu from "../SettingsContextMenu/SettingsContextMenu"
import SettingsButton from "../SettingsButton/SettingsButton"
import { ArticleType } from "../../models/articleType"
import ProjectSettingsLayout from "../ProjectSettingsLayout/ProjectSettingsLayout"
import SettingsTableWithoutPagination from "../SettingsTable/SettingsTableWithoutPagination/SettingsTableWithoutPagination"

const tNamespace = "articleTypes:"

interface Props {
    projectId: string
    title: string
    articleTypes: ArticleType[]
    onAddClick: () => void
    onSettingsClick: (type: ArticleType) => void
    onDeleteClick: (type: ArticleType) => void
}

const ProjectArticleTypes: React.FC<Props> = props => {
    const { title, articleTypes, onAddClick, onSettingsClick, onDeleteClick } = props
    const { t } = useTranslation()

    const [typeToDelete, setTypeToDelete] = useState<ArticleType | null>(null)
    const { modalOpen, openModal, closeModal, onExited } = useModal(() => (
        <AlertDialog
            show={modalOpen}
            title={t(`${tNamespace}delete-confirmation.title`)}
            message={t(`${tNamespace}delete-confirmation.message`)}
            submitBtnText={t(formTranslation.delete)}
            onClose={closeModal}
            onSubmit={() => {
                typeToDelete && onDeleteClick(typeToDelete)
            }}
            variant="danger"
            onExited={onExited}
        />
    ))

    const getItems = (articleType: ArticleType) => {
        return (
            <>
                <SettingsContextMenuItem
                    id="project-article-type-edit"
                    icon={faPen}
                    text={t(formTranslation.edit)}
                    onClick={() => onSettingsClick(articleType)}
                />
                <SettingsContextMenuItem
                    id="project-article-type-delete"
                    icon={faTrash}
                    text={t(formTranslation.delete)}
                    useSeparator={true}
                    danger={true}
                    onClick={() => {
                        setTypeToDelete(articleType)
                        openModal()
                    }}
                />
            </>
        )
    }

    const columns: Column<ArticleType>[] = [
        {
            Header: "ID",
            accessor: "Id"
        },
        {
            Header: t(`${tNamespace}title`).toString(),
            accessor: "Title"
        },
        {
            id: "Settings",
            disableSortBy: true,
            Cell: (props: CellProps<ArticleType>) => (
                <Can permission={ModifyKnowledgeBase}>
                    <SettingsContextMenu items={getItems(props.row.original)}>
                        <SettingsButton />
                    </SettingsContextMenu>
                </Can>
            )
        }
    ]

    return (
        <ProjectSettingsLayout.Container
            onButtonClick={onAddClick}
            title={title}
            buttonText={t(`${tNamespace}add-type`)}
            permission={ModifyKnowledgeBase}
            addButton
        >
            <SettingsTableWithoutPagination columns={columns} data={articleTypes} onRowClick={onSettingsClick} />
        </ProjectSettingsLayout.Container>
    )
}

export default ProjectArticleTypes
