import React, { memo } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store/rootReducer"
import { selectCatalog, selectCurrentBranch } from "../../../store/knowledgeBase/selectors"
import type { ItemId } from "@atlaskit/tree/types"
import { mutateCurrentBranch, toggleCategoryExpanded } from "../../../store/knowledgeBase/slice"
import { push } from "connected-react-router"
import { buildSharedArticleActionUrl } from "../../../utility/knowledgeBase/articleUrlHelper"
import { getSharedCategories } from "../../../store/knowledgeBase/sharedThunks"
import type { TreeItem } from "@atlaskit/tree"
import LazyCatalog from "../LazyCatalog"

interface SharedCatalogProps {
    id: string
    url: string
    shareId: string
}

const SharedCatalog: React.FC<SharedCatalogProps> = props => {
    const { url, id, shareId } = props
    const dispatch = useDispatch()
    const tree = useSelector((state: RootState) => selectCatalog(state, id), shallowEqual)
    const { CurrentCategoryId } = useSelector(selectCurrentBranch, shallowEqual)

    const handleCategoryTitleClick = (categoryItem: TreeItem, catalogCode?: string, categoryCode?: string) => {
        if (categoryCode && categoryItem.id !== CurrentCategoryId) {
            dispatch(
                mutateCurrentBranch({ CurrentCategoryId: categoryItem.id as string, CurrentCatalogCode: catalogCode })
            )
            dispatch(push(buildSharedArticleActionUrl(url, categoryCode)))
        }
    }

    const handleToggle = (itemId: ItemId) => {
        dispatch(
            toggleCategoryExpanded({
                CatalogId: tree.rootId,
                CategoryId: itemId,
                IsExpanded: true
            })
        )
    }

    const handleExpandCategory = (itemId: ItemId, onExpand: (itemId: ItemId) => void) => {
        const { children, isChildrenLoading } = tree.items[itemId]

        onExpand(itemId)

        if (!isChildrenLoading && children.length === 0) {
            dispatch(getSharedCategories(shareId, itemId, tree.rootId))
        }
    }

    const handleCanEdit = () => false

    return (
        <LazyCatalog
            tree={tree}
            onExpandCategory={handleExpandCategory}
            onTitleClick={handleCategoryTitleClick}
            onToggle={handleToggle}
            onCanEdit={handleCanEdit}
            isDragEnabled={false}
        />
    )
}

export default memo(SharedCatalog)
