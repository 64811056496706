import React from "react"
import { ChannelFormProps } from "../ChannelForm/ChannelForm"
import { FormikProps } from "formik"
import { WithT } from "i18next"
import { shallowEqual, useSelector } from "react-redux"
import { selectCreateChannelState, selectUpdateChannelState } from "../../store/channels/selectors"
import { Form } from "react-bootstrap"
import { nameof2 } from "../../utility/common/nameof"
import RadioButtonValidatableInput from "../RadioButtonValidatableInput/RadioButtonValidatableInput"
import ColorInput from "../ColorInput/ColorInput"
import { ChannelValues } from "../../models/channelValues"
import { ParamValues } from "../../models/parameterDeclaration"
import DebouncedValidatableInput from "../ValidatableInput/DebouncedValidatableInput"
import ChannelFormWrapper from "../ChannelFormWrapper/ChannelFormWrapper"

const tNamespace = "channel:form."

const FormikViberChannelFormAppearance: React.FC<ChannelFormProps & FormikProps<ChannelValues> & WithT> = props => {
    const { channel, t, handleSubmit, disabled } = props

    const asyncState = useSelector(channel ? selectUpdateChannelState : selectCreateChannelState, shallowEqual)

    return (
        <ChannelFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={asyncState.inProcess}>
            <div className="channel-form__section">
                <div className="channel-form__text">{t(`${tNamespace}button-type`)}</div>
                <RadioButtonValidatableInput
                    value="inline"
                    id="formDefaultButtonsPlacement1"
                    name={nameof2<ChannelValues, ParamValues>("Params", "default_buttons_placement")}
                    label={t(`${tNamespace}in-text`)}
                />
                <RadioButtonValidatableInput
                    value="keyboard"
                    id="formDefaultButtonsPlacement2"
                    name={nameof2<ChannelValues, ParamValues>("Params", "default_buttons_placement")}
                    label={t(`${tNamespace}keyboard`)}
                />
            </div>
            <div className="channel-form__section">
                <Form.Group controlId="formButtonsColorPrimary">
                    <Form.Label>{t(`${tNamespace}button-color`)}</Form.Label>
                    <ColorInput
                        id="setButtonsColorPrimary"
                        name={nameof2<ChannelValues, ParamValues>("Params", "buttons_color_primary")}
                        className="channel-form__color-input_border"
                        simplified={true}
                        type="circles"
                    />
                </Form.Group>
                <Form.Group controlId="fromButtonsTextColorPrimary">
                    <Form.Label>{t(`${tNamespace}button-text-color`)}</Form.Label>
                    <ColorInput
                        id="setButtonsTextColorPrimary"
                        name={nameof2<ChannelValues, ParamValues>("Params", "buttons_text_color_primary")}
                        className="channel-form__color-input_border"
                        simplified={true}
                        type="circles"
                    />
                </Form.Group>
                <Form.Group controlId="fromKeyboardBgColor">
                    <Form.Label>{t(`${tNamespace}keyboard-bg-color`)}</Form.Label>
                    <ColorInput
                        id="setKeyboardBgColor"
                        name={nameof2<ChannelValues, ParamValues>("Params", "keyboard_bg_color")}
                        className="channel-form__color-input_border"
                        simplified={true}
                        type="circles"
                    />
                </Form.Group>
                <div className="channel-form__section">
                    <div className="channel-form__section-subtitle">{t(`${tNamespace}rating-dialog`)}</div>
                    <DebouncedValidatableInput
                        id="formDialogScoreText"
                        type="text"
                        name={nameof2<ChannelValues, ParamValues>("Params", "dialog_score_text")}
                        label={t(`${tNamespace}dialog-rating-request-text`)}
                    />
                    <DebouncedValidatableInput
                        id="formDialogScoreThankYou"
                        type="text"
                        name={nameof2<ChannelValues, ParamValues>("Params", "dialog_score_thank_you")}
                        label={t(`${tNamespace}text-after-dialog-rating`)}
                    />
                </div>
            </div>
        </ChannelFormWrapper>
    )
}

export default FormikViberChannelFormAppearance
