export interface Error {
    message: string
    key: string
}

export interface ErrorResponse {
    error: Error
}

export const isErrorReponse = (obj: any): obj is ErrorResponse => obj.error?.message && obj.error?.key
