import React from "react"
import "./ChannelFormHeader.scss"
import { WithT } from "i18next"
import Toggle from "../Toggle/Toggle"
import ChannelIconLarge from "../ChannelIconLarge/ChannelIconLarge"
import { testId } from "../../utility/tests/testId"

const tNamespace = "channel:form."

export interface ChannelFormHeaderProps extends WithT {
    channelType: string
    isActive: boolean
    disabled?: boolean
    onToggle?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const ChannelFormHeader: React.FC<ChannelFormHeaderProps> = props => {
    const { channelType, t, isActive, disabled, onToggle } = props

    return (
        <div className="channel-form-header">
            <div className="channel-form-header__content">
                <div className="channel-form-header__logo">
                    <ChannelIconLarge type={channelType} />
                </div>
                {t(`channel:channel-type.${channelType}`)}
                <div className="channel-form-header__toggle">
                    <label htmlFor="isActive" className="toggle__label">
                        {isActive ? t(`${tNamespace}active-status`) : t(`${tNamespace}inactive-status`)}
                    </label>
                    <Toggle
                        checked={isActive}
                        id="isActive"
                        icons={false}
                        className="toggle"
                        disabled={disabled}
                        onChange={onToggle}
                        data-test-id={testId.toggleChannelActivity}
                    />
                </div>
            </div>
        </div>
    )
}

export default ChannelFormHeader
