import React from "react"
import useDelay from "../../utility/common/useDelay"
import Skeleton from "../Skeleton/Skeleton"
import styles from "./SearchQueryArticlesLoader.module.scss"
import stylesSearchQueryArticles from "../SearchQueryArticles/SearchQueryArticles.module.scss"
import stylesSearchQueryArticle from "../SearchQueryArticle/SearchQueryArticle.module.scss"
import cn from "classnames"

interface Props {}

const SearchQueryArticlesLoader: React.FC<Props> = () => {
    const display = useDelay()
    if (!display) return null

    return (
        <div className={cn(stylesSearchQueryArticles.searchQueryArticles, styles.searchQueryArticlesLoader)}>
            <div className={stylesSearchQueryArticles.searchQueryArticles__body}>
                {new Array<number>(3).fill(0).map((_, i) => (
                    <div
                        key={i}
                        className={cn(
                            stylesSearchQueryArticle.searchQueryArticle,
                            styles.searchQueryArticlesLoader__item
                        )}
                    >
                        <Skeleton />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SearchQueryArticlesLoader
