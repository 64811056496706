import React, { useEffect, useRef } from "react"
import styles from "./MessageInput.module.scss"
import { User } from "../../models/user"
import { Formik } from "formik"
import { Button, Form } from "react-bootstrap"
import { ClassProps } from "../../utility/common/props"
import cn from "classnames"
import UserCard from "../UserCard/UserCard"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import { useTranslation } from "react-i18next"
import { formTranslation } from "../../locales/form"
import LoadingButton from "../LoadingButton/LoadingButton"

interface MessageInputInitialValue {
    text: string
}

export interface MessageInputProps extends ClassProps {
    loading?: boolean
    user?: User
    onCancel?: () => void
    onSend: (message: string) => void
    initialValue?: string
    placeholder?: string
    inputClassName?: string
}

const MessageInput: React.FC<MessageInputProps> = props => {
    const { className, inputClassName, placeholder, user, onSend, onCancel, loading, initialValue = "" } = props
    const { t } = useTranslation()
    const inputRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    return (
        <Formik<MessageInputInitialValue>
            initialValues={{ text: initialValue }}
            onSubmit={values => onSend(values.text)}
        >
            {formikProps => (
                <Form
                    className={cn(styles.messageInput, className)}
                    onSubmit={formikProps.handleSubmit}
                    onKeyPress={preventSubmitOnEnter}
                >
                    {user && (
                        <div className={styles.messageInput__user}>
                            <UserCard lastName={user.LastName} firstName={user.FirstName} picture={user.Picture} />
                        </div>
                    )}
                    <Form.Control
                        as="textarea"
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        name="text"
                        value={formikProps.values.text}
                        className={cn(styles.messageInput__control, inputClassName)}
                        placeholder={placeholder}
                        ref={inputRef}
                    />
                    <div className={styles.messageInput__buttonGroup}>
                        {onCancel && (
                            <Button variant="light" onClick={onCancel}>
                                {t(formTranslation.cancel)}
                            </Button>
                        )}
                        <LoadingButton
                            variant="primary"
                            type="submit"
                            className={styles.messageInput__submit}
                            loading={loading}
                        >
                            {t(formTranslation.send)}
                        </LoadingButton>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default MessageInput
