import React from "react"
import styles from "./CatalogForm.module.scss"
import { FormikProps } from "formik/dist/types"
import { CatalogFormValues } from "../../models/catalog"
import { WithT } from "i18next"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import { nameof } from "../../utility/common/nameof"
import { formTranslation } from "../../locales/form"
import { Button, Form } from "react-bootstrap"
import UserAccessForm from "../UserAccessForm/UserAccessForm"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import LoadingButton from "../LoadingButton/LoadingButton"
import { UserAccessCommonProps } from "../UserAccess/UserAccess"

const tNamespace = "knowledgeBase:"

interface Props extends UserAccessCommonProps {
    loading: boolean
    onCancel: () => void
}

const FormikCatalogForm: React.FC<Props & FormikProps<CatalogFormValues> & WithT> = props => {
    const { t, handleSubmit, onCancel, loading } = props

    return (
        <Form className={styles.catalogForm} onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <ValidatableInput
                id="catalogFormTitle"
                type="text"
                className="catalog-form__title"
                name={nameof<CatalogFormValues>("Title")}
                label={t(formTranslation.title)}
                placeholder={t(formTranslation.enterText)}
            />
            <UserAccessForm
                type="permission"
                title={t(`${tNamespace}permissions.title`)}
                name={nameof<CatalogFormValues>("Permissions")}
                {...props}
            />
            <div className={styles.catalogForm__footer}>
                <Button className={styles.catalogForm__cancel} variant="light" onClick={onCancel}>
                    {t(formTranslation.cancel)}
                </Button>
                <LoadingButton className={styles.catalogForm__submit} variant="primary" loading={loading} type="submit">
                    {t(formTranslation.save)}
                </LoadingButton>
            </div>
        </Form>
    )
}

export default FormikCatalogForm
