import { ChannelType, ChannelTypeString, isWhatsappChannelType } from "../../models/channel"

export const channelTypeStringConverter = {
    toChannelType: (channelValue: string): ChannelType => {
        const channelItem = Object.entries(ChannelTypeString).find(([_, value]) => value === channelValue)

        if (channelItem) {
            return isWhatsappChannelType(channelItem[1])
                ? ChannelType.Whatsapp
                : ChannelType[channelItem[0] as keyof typeof ChannelType]
        }

        return ChannelType.Unknown
    }
}
