import React from "react"
import styles from "./QuickActions.module.scss"
import QuickActionItem from "../QuickActionItem/QuickActionItem"
import { Row } from "react-bootstrap"
import { QuickAction } from "../../../../../utility/articleContentEditor/quickActionParser/quickActionAttribute"

interface Props {
    onClick: () => void
    actions: QuickAction[][]
    isEditable?: boolean
    onLocationChange: (path: string) => void
}

const QuickActions: React.FC<Props> = props => {
    const { onClick, actions, isEditable, onLocationChange } = props

    return (
        <>
            {actions.map(row => (
                <Row className={styles.quickActions} onClick={onClick}>
                    {row.map((action, index) => (
                        <QuickActionItem
                            action={action}
                            className={styles.quickActions__item}
                            isEdit={isEditable}
                            onLocationChange={onLocationChange}
                            key={index}
                        />
                    ))}
                </Row>
            ))}
        </>
    )
}

export default QuickActions
