import React from "react"
import { ChannelFormProps } from "../ChannelForm/ChannelForm"
import { FormikProps } from "formik"
import { ChannelValues, WhatsAppChannelValues } from "../../models/channelValues"
import { WithT } from "i18next"
import { shallowEqual, useSelector } from "react-redux"
import { selectCreateChannelState, selectUpdateChannelState } from "../../store/channels/selectors"
import { nameof2 } from "../../utility/common/nameof"
import CheckBoxValidatableInput from "../CheckBoxValidatableInput/CheckBoxValidatableInput"
import { ParamValues } from "../../models/parameterDeclaration"
import DebouncedValidatableInput from "../ValidatableInput/DebouncedValidatableInput"
import ChannelFormWrapper from "../ChannelFormWrapper/ChannelFormWrapper"

const tNamespace = "channel:form."

const FormikWhatsAppChannelFormAppearance: React.FC<ChannelFormProps & FormikProps<WhatsAppChannelValues> & WithT> =
    props => {
        const { channel, values, t, handleSubmit, disabled } = props

        const asyncState = useSelector(channel ? selectUpdateChannelState : selectCreateChannelState, shallowEqual)

        return (
            <ChannelFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={asyncState.inProcess}>
                <div className="channel-form__section">
                    <CheckBoxValidatableInput
                        id="formEnableTextActions"
                        name={nameof2<ChannelValues, ParamValues>("Params", "enable_text_actions")}
                        label={t(`${tNamespace}buttons-in-test-mode`)}
                    />
                    <CheckBoxValidatableInput
                        id="formAskCSI"
                        name={nameof2<ChannelValues, ParamValues>("Params", "ask_csi")}
                        label={t(`${tNamespace}request-rating-after-dialog`)}
                    />
                    <div className="extra-settings">
                        <DebouncedValidatableInput
                            id="formCSIButtonTemplateName"
                            type="text"
                            name={nameof2<ChannelValues, ParamValues>("Params", "csi_button_template_name")}
                            label={t(`${tNamespace}template-name`)}
                            disabled={!values.Params.ask_csi}
                        />
                        <DebouncedValidatableInput
                            id="formCSIButtonTemplateBody"
                            type="text"
                            name={nameof2<ChannelValues, ParamValues>("Params", "csi_button_template_body")}
                            label={t(`${tNamespace}template`)}
                            disabled={!values.Params.ask_csi}
                        />
                    </div>
                </div>
                <div className="channel-form__section">
                    <CheckBoxValidatableInput
                        id="formAskFCR"
                        name={nameof2<ChannelValues, ParamValues>("Params", "ask_fcr")}
                        label={t(`${tNamespace}request-fcr-after-dialog`)}
                    />
                    <div className="extra-settings">
                        <DebouncedValidatableInput
                            id="formButtonTemplateName"
                            type="text"
                            name={nameof2<ChannelValues, ParamValues>("Params", "fcr_button_template_name")}
                            label={t(`${tNamespace}template-name`)}
                            disabled={!values.Params.ask_fcr}
                        />
                        <DebouncedValidatableInput
                            id="formButtonTemplateBody"
                            type="text"
                            name={nameof2<ChannelValues, ParamValues>("Params", "fcr_button_template_body")}
                            label={t(`${tNamespace}template`)}
                            disabled={!values.Params.ask_fcr}
                        />
                    </div>
                </div>
                <div className="channel-form__section">
                    <CheckBoxValidatableInput
                        id="formPayloadInsteadOfText"
                        name={nameof2<ChannelValues, ParamValues>("Params", "payload_instead_of_text")}
                        label={t(`${tNamespace}replace-button-text-with-payload`)}
                    />
                </div>
            </ChannelFormWrapper>
        )
    }

export default FormikWhatsAppChannelFormAppearance
