import React, { RefObject, useEffect, useRef, useState } from "react"
import "./Frame.scss"
import Skeleton from "../Skeleton/Skeleton"

const FRAME_READY_MESSAGE = `crafttalk-workplace-connect:loaded`
type QueuedMessage = object | string

export interface FrameProps {
    name: string
    src?: string
    srcDoc?: string
    width?: string
    height?: string
    frameClassName?: string
    sandboxParams?: string
    allowFullScreen?: boolean
    postMessageData?: QueuedMessage
    targetOrigin?: string
    onReceiveMessage?: (message: string) => void
    onReady?: () => void
    onLoad?: (event: React.SyntheticEvent<HTMLIFrameElement, Event>) => void
    isMounted?: boolean
}

const getStringValue = (value?: string) => (value ? value : "")

const Frame: React.FC<FrameProps> = props => {
    const frameRef = useRef<HTMLIFrameElement>(null)
    const [ready, setReady] = useState<boolean>(false)
    const [queuedMessages, setQueuedMessages] = useState<QueuedMessage[]>([])

    const { onReceiveMessage, onReady, postMessageData, targetOrigin, isMounted = true } = props

    useEffect(() => {
        const listener = (e: MessageEvent) => {
            e.data === FRAME_READY_MESSAGE ? selfHandleReady() : onReceiveMessage && onReceiveMessage(e.data)
        }
        const selfHandleReady = () => {
            setReady(true)
            // Отправка отложенного сообщения при загрузке фрейма
            if (queuedMessages.length) {
                queuedMessages.forEach(queuedMessage => sendMessage(queuedMessage, frameRef, targetOrigin))
                setQueuedMessages([])
            }
            onReady && onReady()
        }
        window.addEventListener("message", listener)
        return () => {
            window.removeEventListener("message", listener, false)
        }
    }, [onReceiveMessage, onReady, frameRef, targetOrigin, queuedMessages])

    // Отправка сообщения
    useEffect(() => {
        if (postMessageData) {
            ready
                ? sendMessage(postMessageData, frameRef, targetOrigin)
                : setQueuedMessages(oldQueuedMessages => [...oldQueuedMessages, postMessageData])
        }
    }, [frameRef, ready, postMessageData, targetOrigin])

    return (
        <iframe
            style={{ display: isMounted ? undefined : "none" }}
            src={props.src}
            srcDoc={props.srcDoc}
            name={props.name}
            title={props.name}
            width={getStringValue(props.width)}
            height={getStringValue(props.height)}
            className={`frame ${getStringValue(props.frameClassName)}`}
            sandbox={props.sandboxParams}
            allowFullScreen={props.allowFullScreen}
            onLoad={props.onLoad}
            ref={frameRef}
        />
    )
}

const sendMessage = (postMessageData: object | string, frame?: RefObject<HTMLIFrameElement>, targetOrigin?: string) => {
    const serializedData = serializePostMessageData(postMessageData)
    serializedData &&
        frame &&
        frame.current &&
        frame.current.contentWindow &&
        frame.current.contentWindow.postMessage(serializedData, targetOrigin ? targetOrigin : "*")
}

const serializePostMessageData = (data: object | string): string =>
    typeof data === "object" ? JSON.stringify(data) : data

export const FrameLoading: React.FC = () => {
    return (
        <div className="frame__loading frame-loader">
            <div className="frame-loader__aside">
                <div className="frame-loader__aside-header">
                    <Skeleton />
                </div>
                <div className="frame-loader__aside-content">
                    <Skeleton />
                </div>
            </div>
            <div className="frame-loader__main">
                <div className="frame-loader__main-header">
                    <Skeleton />
                </div>
                <div className="frame-loader__main-content">
                    <Skeleton />
                </div>
            </div>
            <div className="frame-loader__aside">
                <div className="frame-loader__aside-header">
                    <Skeleton />
                </div>
                <div className="frame-loader__aside-content">
                    <Skeleton />
                </div>
            </div>
        </div>
    )
}

export default Frame
