import React from "react"
import { Form } from "react-bootstrap"
import { Formik, FormikProps } from "formik"
import ValidatableInput from "../ValidatableInput/ValidatableInput"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"
import "./RoleForm.scss"
import { AllRoleSettings, CreateRoleRequest, LogAction, Role, UpdateRoleRequest } from "../../models/role"
import { WithT } from "i18next"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import { nameof } from "../../utility/common/nameof"
import SettingsGroup, { getCheckedValues, initSettings, Settings } from "../SettingsGroup/SettingsGroup"
import { formTranslation } from "../../locales/form"
import { QueueName } from "../../models/queue"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { createRole, updateRole } from "../../store/roles/thunks"
import { selectCreateRoleState, selectUpdateRoleState } from "../../store/roles/selectors"
import { ModifyPositionsActions, ModifyUsersRouting } from "../../permissions"
import Can from "../Can/Can"
import LoadingButton from "../LoadingButton/LoadingButton"
import { testId } from "../../utility/tests/testId"
import IdField from "../IdField/IdField"
import { Select } from "../Select/Select"
import { ValueType } from "react-select"
import { OptionType } from "../AsyncSearchableInput/AsyncSearchableInput"
import { handleSettingsLabelTranslation, SettingsType } from "../../utility/common/handleSettingsLabelTranslation"

const tNamespace = "roles:"

interface RoleValues {
    title: string
    extId?: string
    generalActions: Settings<string>
    logActions: Settings<LogAction>
    queues: Settings<QueueName>
    defaultArticle: string
    maxDialogs: number
}

interface Props {
    projectId: string
    role?: Role
    allRoleSettings: AllRoleSettings
    close: Function
}

const FormikRoleForm: React.FC<Props & FormikProps<RoleValues> & WithT> = props => {
    const { role, t, values, handleSubmit, setFieldValue, allRoleSettings } = props

    const asyncState = useSelector(role ? selectUpdateRoleState : selectCreateRoleState, shallowEqual)

    const defaultArticlesInitialValue = values.defaultArticle
        ? {
              value: values.defaultArticle,
              label:
                  allRoleSettings.DefaultArticles?.find(a => a.SymbolCode === values.defaultArticle)?.Title ??
                  values.defaultArticle
          }
        : undefined

    const handleDefaultArticleChange = (option: ValueType<OptionType, false>) => {
        setFieldValue(nameof<RoleValues>("defaultArticle"), option ? option.value : "", false)
    }

    return (
        <Form className="role-form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
            <div className="role-form__content">
                <IdField id={role?.Id} className="role-form__section" />
                <ValidatableInput
                    id="formRoleName"
                    type="text"
                    name={nameof<RoleValues>("title")}
                    label={t(`${tNamespace}role`)}
                />
                <ValidatableInput
                    id="formRoleExtId"
                    type="text"
                    name={nameof<RoleValues>("extId")}
                    label={t(`${tNamespace}ext-id`)}
                />
                <SettingsGroup<string>
                    name={nameof<RoleValues>("generalActions")}
                    title={t(`${tNamespace}permissions`)}
                    values={values.generalActions}
                    getLabel={v => handleSettingsLabelTranslation(tNamespace, v, SettingsType.Roles, t)}
                    dataTestId={testId.permissions}
                />
                <Can permission={ModifyPositionsActions}>
                    {can => (
                        <SettingsGroup<LogAction>
                            name={nameof<RoleValues>("logActions")}
                            disabled={!can}
                            title={t(`${tNamespace}logging`)}
                            values={values.logActions}
                            getLabel={v => v.Description}
                            dataTestId={testId.logging}
                        />
                    )}
                </Can>
                <Can permission={ModifyUsersRouting}>
                    {can => (
                        <SettingsGroup<QueueName>
                            name={nameof<RoleValues>("queues")}
                            disabled={!can}
                            title={t(`${tNamespace}queues`)}
                            values={values.queues}
                            getLabel={v => v.Name}
                            dataTestId={testId.queues}
                        >
                            <ValidatableInput
                                id="formMaxDialogs"
                                type="number"
                                name={nameof<RoleValues>("maxDialogs")}
                                label={t(formTranslation.maxConcurrentTasks)}
                            />
                        </SettingsGroup>
                    )}
                </Can>
                <div className="role-form__section">
                    <Form.Group controlId="formDefaultArticle">
                        <Form.Label>{t(`${tNamespace}default-article`)}</Form.Label>
                        <Select
                            isClearable
                            placeholder={t(formTranslation.nothingSelected)}
                            defaultValue={defaultArticlesInitialValue}
                            isDisabled={!allRoleSettings.DefaultArticles?.length}
                            options={allRoleSettings.DefaultArticles?.map(option => ({
                                value: option.SymbolCode,
                                label: option.Title
                            }))}
                            noOptionsMessage={() => t(formTranslation.noResultsFound)}
                            onChange={handleDefaultArticleChange}
                        />
                    </Form.Group>
                </div>
            </div>
            <div className="role-form__footer">
                <LoadingButton type="submit" loading={asyncState.inProcess} variant="primary" block>
                    {t(formTranslation.save)}
                </LoadingButton>
            </div>
        </Form>
    )
}

const getDefaultValues = (allRoleSettings: AllRoleSettings): RoleValues => {
    const generalActionsSettings: Settings<string> = {}
    initSettings(
        generalActionsSettings,
        allRoleSettings.Permissions,
        [],
        v => v,
        v => v
    )
    const logActionsSettings: Settings<LogAction> = {}
    initSettings<LogAction, LogAction>(
        logActionsSettings,
        allRoleSettings.LogActions,
        [],
        v => v.Id,
        v => v.Id
    )
    const queuesSettings: Settings<QueueName> = {}
    initSettings<QueueName, string>(
        queuesSettings,
        allRoleSettings.Queues,
        [],
        v => v.Id,
        v => v
    )

    return {
        title: "",
        generalActions: generalActionsSettings,
        logActions: logActionsSettings,
        queues: queuesSettings,
        defaultArticle: "",
        maxDialogs: 3
    }
}

const getValuesFromRole = (role: Role, allRoleSettings: AllRoleSettings): RoleValues => {
    const generalActionsSettings: Settings<string> = {}
    initSettings(
        generalActionsSettings,
        allRoleSettings.Permissions,
        role.Permissions,
        v => v,
        v => v
    )
    const logActionsSettings: Settings<LogAction> = {}
    initSettings(
        logActionsSettings,
        allRoleSettings.LogActions,
        role.LogActions,
        v => v.Id,
        v => v.Id
    )
    const queuesSettings: Settings<QueueName> = {}
    initSettings(
        queuesSettings,
        allRoleSettings.Queues,
        role.Queues,
        v => v.Id,
        v => v
    )

    return {
        title: role.Title,
        extId: role.ExtId,
        generalActions: generalActionsSettings,
        logActions: logActionsSettings,
        queues: queuesSettings,
        defaultArticle: role.DefaultArticle ?? "",
        maxDialogs: role.MaxDialogs
    }
}

const buildCreateRoleRequest = (projectId: string, values: RoleValues): CreateRoleRequest => {
    return {
        ProjectId: projectId,
        ExtId: values.extId,
        Title: values.title,
        Permissions: getCheckedValues(values.generalActions),
        LogActions: getCheckedValues(values.logActions),
        Queues: getCheckedValues(values.queues).map(v => v.Id),
        ...(values.defaultArticle && { DefaultArticle: values.defaultArticle }),
        MaxDialogs: values.maxDialogs
    }
}

const buildUpdateRoleRequest = (values: RoleValues): UpdateRoleRequest => {
    return {
        Title: values.title,
        ExtId: values.extId,
        Permissions: getCheckedValues(values.generalActions),
        LogActions: getCheckedValues(values.logActions),
        Queues: getCheckedValues(values.queues).map(v => v.Id),
        ...(values.defaultArticle && { DefaultArticle: values.defaultArticle }),
        MaxDialogs: values.maxDialogs
    }
}

const RoleForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { projectId, role, allRoleSettings, close } = props

    return (
        <Formik
            enableReinitialize={true}
            initialValues={role ? getValuesFromRole(role, allRoleSettings) : getDefaultValues(allRoleSettings)}
            validationSchema={() => {
                return Yup.object().shape({
                    title: Yup.string().requiredExcludeEmpty(`${tNamespace}title-required`),
                    maxDialogs: Yup.number().required(formTranslation.enterNumber)
                })
            }}
            onSubmit={(values: RoleValues) => {
                if (role) {
                    dispatch(updateRole(role.Id, buildUpdateRoleRequest(values), close))
                } else {
                    dispatch(createRole(buildCreateRoleRequest(projectId, values), close))
                }
            }}
        >
            {formikProps => <FormikRoleForm {...props} {...formikProps} t={t} />}
        </Formik>
    )
}

export default RoleForm
