import React from "react"
import { ClassProps } from "../../utility/common/props"
import styles from "./MessageItem.module.scss"
import cn from "classnames"
import { Message, MessageAttachment, MessageDirection } from "../../models/message"
import ImageAttachment from "../ImageAttachment/ImageAttachment"
import FileAttachment from "../FileAttachment/FileAttachment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser, faUserCircle } from "@fortawesome/pro-light-svg-icons"
import { useTranslation } from "react-i18next"
import { getDateLocale } from "../../locales"
import { format, parseISO } from "date-fns"
import Button from "react-bootstrap/Button"
import { getLanguage } from "../../utility/common/language"
import { isImage } from "../../utility/message/IsImage"

export interface MessageItemProps extends ClassProps {
    message: Message
    reverseInOut?: boolean
}

const MessageItem: React.FC<MessageItemProps> = props => {
    const { className, message, reverseInOut } = props
    const { i18n } = useTranslation()
    const locale = { locale: getDateLocale(getLanguage(i18n)) }

    let isOutgoing = message.Direction === MessageDirection.Outgoing
    isOutgoing = reverseInOut ? !isOutgoing : isOutgoing

    const tryGetAttachments = (message: Message) => {
        if (!message.Attachment?.Url && !message.Attachments?.length) return null

        const attachments: MessageAttachment[] = []
        if (message.Attachment) attachments.push(message.Attachment)

        if (message.Attachments) attachments.push(...message.Attachments)

        return attachments
    }

    const attachments = tryGetAttachments(message)

    const renderAttachment = (attachment: MessageAttachment) => {
        if (isImage(attachment)) return <ImageAttachment attachment={attachment} />

        return <FileAttachment attachment={attachment} />
    }

    return (
        <div className={cn(styles.messageItem, isOutgoing ? styles.messageItem_out : undefined, className)}>
            <div className={styles.messageItem__body}>
                {message.Text && <div className={styles.messageItem__text}>{message.Text}</div>}
                {message.Actions.length > 0 && (
                    <div className={cn(styles.messageItem__actions, styles.messageItem__section)}>
                        {message.Actions.map(v => (
                            <Button as="div" key={v.action_id} className={styles.messageItem__action} variant="light">
                                {v.action_text}
                            </Button>
                        ))}
                    </div>
                )}
                {attachments && (
                    <div className={cn(styles.messageItem__attachments, styles.messageItem__section)}>
                        {attachments.map((v, i) => (
                            <div className={styles.messageItem__attachment} key={i}>
                                {renderAttachment(v)}
                            </div>
                        ))}
                    </div>
                )}
                <div className={styles.messageItem__footer}>
                    <div className={styles.messageItem__info}>
                        <FontAwesomeIcon
                            icon={isOutgoing ? faUserCircle : faUser}
                            className={cn(styles.messageItem__icon, isOutgoing && styles.messageItem__icon_operator)}
                        />
                    </div>
                    <div className={styles.messageItem__info}>
                        {isOutgoing ? message.OperatorName : message.UserName}
                    </div>
                    <div className={styles.messageItem__info}>
                        {format(parseISO(message.Added), "dd-MM-yyyy HH:mm:ss", locale)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MessageItem
