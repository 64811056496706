import React, { useCallback, useEffect, useState } from "react"
import "./ValidatableInput.scss"
import { useField } from "formik"
import { Col, ColProps, Form, FormControl, Row } from "react-bootstrap"
import cn from "classnames"
import { ValidatableInputProps } from "./ValidatableInput"
import debounce from "lodash/debounce"
import ConditionalWrapper from "../ConditionalWrapper/ConditionalWrapper"
import { useTranslation } from "react-i18next"
import AlertIcon from "../AlertIcon/AlertIcon"

interface FormControlProps extends React.ComponentPropsWithRef<typeof FormControl> {}

export const DebouncedFormControl: React.FC<FormControlProps> = props => {
    const { as, children, onChange, value } = props
    const [innerValue, setInnerValue] = useState(value)

    useEffect(() => {
        value ? setInnerValue(value) : setInnerValue("")
    }, [value])

    const debounced = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(event)
    }, 200)

    const handleOnChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            event.persist()
            setInnerValue(event.currentTarget.value)
            debounced(event)
        },
        [debounced]
    )

    return (
        <>
            <Form.Control {...props} as={as as any} onChange={handleOnChange} value={innerValue ?? ""}>
                {children}
            </Form.Control>
        </>
    )
}

const DebouncedValidatableInput: React.FC<DebouncedValidatableInputProps> = props => {
    const {
        id,
        label,
        className,
        icon,
        inputCol,
        labelCol,
        as,
        readonly,
        plaintext,
        disabled,
        type,
        rows,
        cols,
        placeholder,
        alert,
        children
    } = props
    const { t } = useTranslation()
    const [field, meta] = useField<any>(props)

    const formLabel = (label || icon) && (
        <>
            {label}
            {icon}
        </>
    )

    const error = meta.touched && meta.error ? t(meta.error) : undefined

    return (
        <Form.Group
            className={cn(
                "validatable-input",
                error && "validatable-input_invalid",
                alert && "validatable-input_with-alert",
                className
            )}
            as={labelCol && Row}
            controlId={id}
        >
            {formLabel && (
                <Form.Label column={!!labelCol} {...labelCol}>
                    {formLabel}
                </Form.Label>
            )}
            <ConditionalWrapper
                conditionValue={inputCol}
                wrapper={(children, inputCol) => <Col {...inputCol}>{children}</Col>}
            >
                <>
                    <DebouncedFormControl
                        className={as === "select" ? "select-input" : undefined}
                        disabled={disabled}
                        plaintext={readonly || plaintext}
                        type={type}
                        as={as as any}
                        name={field.name}
                        placeholder={placeholder}
                        value={field.value}
                        onChange={field.onChange}
                        rows={rows}
                        cols={cols}
                        isInvalid={!!error}
                    >
                        {children}
                    </DebouncedFormControl>
                    <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
                </>
            </ConditionalWrapper>
            {alert && <AlertIcon id={`${id}-alert`} content={error} className="validatable-input__alert" />}
        </Form.Group>
    )
}

export interface DebouncedValidatableInputProps extends ValidatableInputProps {
    inputCol?: ColProps
    labelCol?: ColProps
    alert?: boolean
}

export default DebouncedValidatableInput
