import React from "react"
import { FormikProps } from "formik"
import { WithT } from "i18next"
import { Form } from "react-bootstrap"
import { preventSubmitOnEnter } from "../../../utility/common/preventSubmitOnEnter"
import { formTranslation } from "../../../locales/form"
import LoadingButton from "../../LoadingButton/LoadingButton"
import { Props as ClassifierFormProps } from "./FormikClassifierForm"
import { ClassifierFormValues, ClassifierStatus } from "../../../models/classifier"
import ClassifierTrainingHistory from "../ClassifierTrainingHistory/ClassifierTrainingHistory"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import {
    selectCreateClassifierState,
    selectCurrentClassifier,
    selectCurrentTrainingClassifier,
    selectGetClassifierModelsState,
    selectUpdateClassifierState
} from "../../../store/classifiers/selectors"
import { rollbackClassifier } from "../../../store/classifiers/thunks"
import ErrorMessage from "../../ErrorMessage/ErrorMessage"
import TrainingHistoryLoader from "../TrainingHistoryLoader/TrainingHistoryLoader"
import { isDateTimeEmpty } from "../../../utility/classifier/classifierEvent"
import AsyncWithoutData from "../../Async/AsyncWithoutData"

const tNamespace = "classifiers:form."

const FormikClassifierFormTrainingHistory: React.FC<ClassifierFormProps & FormikProps<ClassifierFormValues> & WithT> =
    props => {
        const dispatch = useDispatch()
        const { t, handleSubmit, values, disabled, project, classifier, classifierModels } = props
        const asyncState = useSelector(
            classifier ? selectUpdateClassifierState : selectCreateClassifierState,
            shallowEqual
        )

        const apiState = useSelector(selectGetClassifierModelsState)
        const currentClassifier = useSelector(selectCurrentClassifier)
        const currentTrainingClassifier = useSelector(selectCurrentTrainingClassifier)

        const handleRollbackClassifier = (classifierId: string) => {
            dispatch(rollbackClassifier(project.id, values.ClassifierConfigId, classifierId))
        }

        return (
            <Form className="classifier-form" onSubmit={handleSubmit} onKeyPress={preventSubmitOnEnter}>
                <div className="classifier-form__content">
                    <AsyncWithoutData
                        state={apiState}
                        processView={<TrainingHistoryLoader />}
                        errorView={({ message }) => <ErrorMessage text={message} />}
                    >
                        <div className="classifier-form__section">
                            {!classifierModels.length && (
                                <div className="classifier-form__message classifier-form__section">
                                    {t(`${tNamespace}learning-history-is-empty`)}
                                </div>
                            )}
                            {classifierModels.map((model, index) => {
                                const isTrained = !isDateTimeEmpty(model.LearnedAt)
                                return (
                                    <ClassifierTrainingHistory
                                        key={model.ClassifierId}
                                        metrics={model.Metrics}
                                        learningStartedAt={model.LearningStartedAt}
                                        learnedAt={model.LearnedAt}
                                        previousMetrics={classifierModels[index + 1]?.Metrics}
                                        current={model.ClassifierId === currentClassifier.ClassifierId && isTrained}
                                        eventType={
                                            model.ClassifierId === currentTrainingClassifier?.ClassifierId
                                                ? currentTrainingClassifier.EventType
                                                : undefined
                                        }
                                        onRollback={() => handleRollbackClassifier(model.ClassifierId)}
                                        trained={isTrained}
                                        user={model.LearningStartedByUser}
                                        rollbackDisabled={currentClassifier?.Status === ClassifierStatus.Training}
                                    />
                                )
                            })}
                        </div>
                    </AsyncWithoutData>
                </div>
                <div className="classifier-form__footer">
                    <LoadingButton
                        type="submit"
                        loading={asyncState.inProcess}
                        variant="primary"
                        disabled={disabled}
                        block
                    >
                        {t(formTranslation.save)}
                    </LoadingButton>
                </div>
            </Form>
        )
    }

export default FormikClassifierFormTrainingHistory
