import React, { useEffect, useRef } from "react"
import styles from "./ProjectSettingsContainer.module.scss"
import AddButton from "../AddButton/AddButton"
import Can from "../Can/Can"
import ConditionalWrapper from "../ConditionalWrapper/ConditionalWrapper"
import cn from "classnames"
import LoadingButton from "../LoadingButton/LoadingButton"

interface Props {
    title: string
    permission?: string
    buttonText?: string
    onButtonClick?: () => void
    children: React.ReactNode
    notifications?: number
    addButton?: boolean
    hideButton?: boolean
    loading?: boolean
    testId?: string
}

const ProjectSettingsLayout: React.FC<Props> = props => {
    const {
        title,
        permission,
        buttonText,
        onButtonClick,
        children,
        notifications,
        addButton,
        hideButton,
        loading,
        testId
    } = props

    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.parentElement?.scrollIntoView()
        }
    }, [])

    return (
        <div className={styles.projectSettingsContainer} ref={containerRef}>
            <div className={styles.projectSettingsContainer__header}>
                <ProjectSettingsTitle>{title}</ProjectSettingsTitle>
                {onButtonClick && (
                    <ConditionalWrapper
                        conditionValue={permission}
                        wrapper={(children, permission) => <Can permission={permission}>{children}</Can>}
                    >
                        {addButton ? (
                            <AddButton
                                variant="outline-primary"
                                className={cn(
                                    styles["projectSettingsContainer__btn-add"],
                                    hideButton && styles["projectSettingsContainer__btn-add_hidden"]
                                )}
                                text={buttonText}
                                onClick={onButtonClick}
                                testId={testId}
                            />
                        ) : (
                            <LoadingButton
                                onClick={onButtonClick}
                                className={cn(
                                    styles["projectSettingsContainer__btn-add"],
                                    hideButton && styles["projectSettingsContainer__btn-add_hidden"]
                                )}
                                loading={loading}
                                testId={testId}
                            >
                                {buttonText}
                                {!!notifications && (
                                    <span className={styles.projectSettingsContainer__notifications}>
                                        {notifications}
                                    </span>
                                )}
                            </LoadingButton>
                        )}
                    </ConditionalWrapper>
                )}
            </div>
            {children}
        </div>
    )
}

const ProjectSettingsTitle: React.FC = ({ children }) => (
    <span className={styles.projectSettingsContainer__title}>{children}</span>
)

const ProjectSettingsSection: React.FC<{ border?: boolean }> = ({ border, children }) => (
    <div
        className={cn(
            styles.projectSettingsContainer__section,
            border && styles.projectSettingsContainer__section_border
        )}
    >
        {children}
    </div>
)

export default {
    Container: ProjectSettingsLayout,
    Title: ProjectSettingsTitle,
    Section: ProjectSettingsSection
}
