import React, { useState } from "react"
import CodeBlockInput, { CodeBlockInputProps } from "../CodeBlockInput/CodeBlockInput"

export interface CodeBlockEditorProps extends Omit<CodeBlockInputProps, "value"> {
    initialContent: string
    onSelect: () => void
    onRemove: () => void
}

const CodeBlockEditor: React.FC<CodeBlockEditorProps> = props => {
    const { initialContent, onSelect, onRemove, onChange, ...inputProps } = props
    const [content, setContent] = useState(initialContent)
    const [contentChanged, setContentChanged] = useState(false)

    const handleCodeChanged = (code: string) => {
        setContent(prevCode => {
            setContentChanged(prevCode !== code)
            return code
        })
    }

    const handleBlur = () => {
        if (contentChanged) {
            onSelect && onSelect()
            onChange(content || " ")
            setContentChanged(false)
        }
    }

    const handleRemove = () => {
        onSelect()
        onRemove()
    }

    return (
        <CodeBlockInput
            {...inputProps}
            value={content}
            onChange={handleCodeChanged}
            onBlur={handleBlur}
            onRemove={handleRemove}
        />
    )
}

export default CodeBlockEditor
