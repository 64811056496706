import React, { useState } from "react"
import "./ZoomImagePan.scss"
import { faSearchMinus, faSearchPlus } from "@fortawesome/pro-light-svg-icons"
import IconButton from "../IconButton/IconButton"
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch"
import { useTranslation } from "react-i18next"

export interface ZoomImagePanProps {
    src: string
}

const ZoomImageErrorMessage: React.FC = () => {
    const { t } = useTranslation()
    return t("common:image.not-available")
}

const ZoomImagePan: React.FC<ZoomImagePanProps> = props => {
    const { src } = props
    const [error, setError] = useState(false)

    return (
        <TransformWrapper centerOnInit limitToBounds={false}>
            {({ zoomIn, zoomOut }) => (
                <div className="zoom-image-pan">
                    <div className="zoom-image-pan__controls">
                        <IconButton
                            variant="outline-light"
                            className="zoom-image-pan__control"
                            icon={faSearchMinus}
                            onClick={() => zoomOut()}
                        />
                        <IconButton
                            variant="outline-light"
                            className="zoom-image-pan__control"
                            icon={faSearchPlus}
                            onClick={() => zoomIn()}
                        />
                    </div>
                    <TransformComponent wrapperClass="zoom-image-pan__wrapper">
                        {error ? <ZoomImageErrorMessage /> : <img src={src} alt="" onError={() => setError(true)} />}
                    </TransformComponent>
                </div>
            )}
        </TransformWrapper>
    )
}

export default ZoomImagePan
