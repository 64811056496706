export enum ButtonVisible {
    Visible = "true",
    NotVisible = "false"
}

const buttonVisibleValues = Object.values(ButtonVisible)

export const validateButtonVisible = (visibleAttrValue: string) => {
    const visible = visibleAttrValue as ButtonVisible
    return buttonVisibleValues.includes(visible) ? visible : ButtonVisible.Visible
}

export const isButtonVisible = (visibleAttrValue: string) => {
    return visibleAttrValue !== ButtonVisible.NotVisible
}
