import { tl } from "../common/language"

const tNamespace = "channel:score-message."

export const getScoreMessage = (
    language: string
) => `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html data-editor-version="2" class="sg-campaigns" xmlns="http://www.w3.org/1999/xhtml">
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1">
    <!--[if !mso]><!-->
    <meta http-equiv="X-UA-Compatible" content="IE=Edge">
    <!--<![endif]-->
    <!--[if (gte mso 9)|(IE)]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
    <!--[if (gte mso 9)|(IE)]>
    <style type="text/css">
        body {width: 600px;margin: 0 auto;}
        table {border-collapse: collapse;}
        table, td {mso-table-lspace: 0pt;mso-table-rspace: 0pt;}
        img {-ms-interpolation-mode: bicubic;}
    </style>
    <![endif]-->
    <style type="text/css">
        body, p, div {
            font-family: arial, helvetica, sans-serif;
            font-size: 14px;
        }
        body {
            color: #4f4f4f;
        }
        body a {
            color: #1066a8;
            text-decoration: none;
        }
        p { margin: 0; padding: 0; }
        table.wrapper {
            width:100% !important;
            table-layout: fixed;
            -webkit-font-smoothing: antialiased;
            -webkit-text-size-adjust: 100%;
            -moz-text-size-adjust: 100%;
            -ms-text-size-adjust: 100%;
        }
        img.max-width {
            max-width: 100% !important;
        }
        .star {
            width: 50px;
            height: 50px;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url("${window.location.origin}/${process.env.PUBLIC_URL}/star.png");
        }
        @media screen and (max-width:480px) {
            img.max-width {
                height: auto !important;
                max-width: 100% !important;
            }
        }
    </style>
    <!--user entered Head Start--><!--End Head user entered-->
</head>
<body>
<center class="wrapper" data-link-color="#1066a8" data-body-style="font-size:14px; font-family:arial,helvetica,sans-serif; color:#4f4f4f; background-color:#FFFFFF;">
    <div class="webkit">
        <table cellpadding="0" cellspacing="0" border="0" width="100%" class="wrapper" bgcolor="#FFFFFF">
            <tr>
                <td valign="top" bgcolor="#FFFFFF" width="100%">
                    <table width="100%" role="content-container" class="outer" align="center" cellpadding="0" cellspacing="0" border="0">
                        <tr>
                            <td width="100%">
                                <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                    <tr>
                                        <td>
                                            <!--[if mso]>
                                            <center>
                                                <table><tr><td width="600">
                                            <![endif]-->
                                            <table width="100%" cellpadding="0" cellspacing="0" border="0" style="width:100%; max-width:500px;" align="center">
                                                <tr>
                                                    <td role="modules-container" style="padding:0px 0px 0px 0px; color:#4f4f4f; text-align:left;" bgcolor="#FFFFFF" width="100%" align="left"><table class="module preheader preheader-hide" role="module" data-type="preheader" border="0" cellpadding="0" cellspacing="0" width="100%" style="display: none !important; mso-hide: all; visibility: hidden; opacity: 0; color: transparent; height: 0; width: 0;">
                                                        <tr>
                                                            <td role="module-content">
                                                                <p>${tl(`${tNamespace}title`, language)}</p>
                                                            </td>
                                                        </tr>
                                                    </table><table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
                                                        <tbody>
                                                        <tr>
                                                            <td style="font-size:6px; line-height:10px; padding:10px 0px 10px 10px;" valign="top" align="left">
                                                                <img class="max-width" border="0" style="display:block; color:#000000; text-decoration:none; font-family:Helvetica, arial, sans-serif; font-size:16px; max-width:8% !important; width:8%; height:auto !important;" width="48" alt="" data-proportionally-constrained="true" data-responsive="true" src="http://cdn.mcauto-images-production.sendgrid.net/021a26e08987261a/a22a1bbb-f618-4c16-9377-d3657f3f5ea7/164x159.png">
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table><table class="module" role="module" data-type="spacer" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
                                                        <tbody>
                                                        <tr>
                                                            <td style="padding:0px 0px 1px 0px;" role="module-content" bgcolor="#e0e0e0">
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
                                                        <tbody>
                                                        <tr>
                                                            <td style="padding:40px 0px 30px 0px; line-height:22px; text-align:inherit;" height="100%" valign="top" bgcolor="" role="module-content"><div><div style="font-family: inherit; text-align: center"><span style="color: #4f4f4f; font-size: 16px; font-family: helvetica, sans-serif">${tl(
                                                                `${tNamespace}hello`,
                                                                language
                                                            )}!</span></div><div></div></div></td>
                                                        </tr>
                                                        </tbody>
                                                    </table><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
                                                        <tbody>
                                                        <tr>
                                                            <td style="padding:18px 0px 5px 0px; line-height:30px; text-align:inherit;" height="100%" valign="top" bgcolor="" role="module-content"><div><h2 style="text-align: center"><span style="font-family: helvetica, sans-serif; color: #4f4f4f;">${tl(
                                                                `${tNamespace}title`,
                                                                language
                                                            )}</span></h2><div></div></div></td>
                                                        </tr>
                                                        </tbody>
                                                    </table><table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                        <tr>
                                                            <td align="center">
                                                                <table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" style="table-layout: fixed; max-width: 300px;" width='100%'>
                                                                    <tr>
                                                                        <td style='text-align: center;'>
                                                                            <a href="__CRAFTTALK_SCORE_LINK__1" target="_blank">
                                                                                <div class="star"></div>
                                                                            </a>
                                                                        </td>
                                                                        <td style='text-align: center;'>
                                                                            <a href="__CRAFTTALK_SCORE_LINK__2" target="_blank">
                                                                                <div class="star"></div>
                                                                            </a>
                                                                        </td>
                                                                        <td style='text-align: center;'>
                                                                            <a href="__CRAFTTALK_SCORE_LINK__3" target="_blank">
                                                                                <div class="star"></div>
                                                                            </a>
                                                                        </td>
                                                                        <td style='text-align: center;'>
                                                                            <a href="__CRAFTTALK_SCORE_LINK__4" target="_blank">
                                                                                <div class="star"></div>
                                                                            </a>
                                                                        </td>
                                                                        <td style='text-align: center;'>
                                                                            <a href="__CRAFTTALK_SCORE_LINK__5" target="_blank">
                                                                                <div class="star"></div>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
                                                        <tbody>
                                                        <tr>
                                                            <td style="padding:50px 0px 16px 0px; line-height:22px; text-align:inherit;" height="100%" valign="top" bgcolor="" role="module-content"><div><div style="font-family: inherit; text-align: center"><span style="color: #4f4f4f; font-size: 14px; font-family: helvetica, sans-serif">${tl(
                                                                `${tNamespace}thank-you`,
                                                                language
                                                            )}, <br>${tl(
    `${tNamespace}customer-service`,
    language
)}</span></div><div></div></div></td>
                                                        </tr>
                                                        </tbody>
                                                    </table></td>
                                                </tr>
                                            </table>
                                            <!--[if mso]>
                                            </td>
                                            </tr>
                                            </table>
                                            </center>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </div>
</center>
</body>
</html>`
