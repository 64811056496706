import { RefObject, useEffect } from "react"

export interface RefSupport {
    contains: Function
}

export function useOutsideClick<T extends RefSupport>(action: Function, ref: RefObject<T> | null) {
    const handleClickOutside = (e: MouseEvent) => {
        if (!ref?.current?.contains(e.target)) {
            action()
        }
    }
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)
        return () => document.removeEventListener("mousedown", handleClickOutside)
    })
}
