import React from "react"
import styles from "./InfoField.module.scss"
import cn from "classnames"
import { ClassProps } from "../../utility/common/props"

export interface InfoFieldProps extends ClassProps {
    name?: string
}

const InfoField: React.FC<InfoFieldProps> = props => {
    const { name, className, children } = props
    return (
        <div className={cn(styles.infoField, className)}>
            {name && <div className={styles.infoField__name}>{name}</div>}
            <div className={styles.infoField__value}>{children}</div>
        </div>
    )
}

export default InfoField
