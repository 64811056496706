import React from "react"
import { ClassProps } from "../../utility/common/props"
import styles from "./ImageAttachment.module.scss"
import cn from "classnames"
import { Image } from "react-bootstrap"
import { MessageAttachment } from "../../models/message"

export interface ImageAttachmentProps extends ClassProps {
    attachment: MessageAttachment
}

const ImageAttachment: React.FC<ImageAttachmentProps> = props => {
    const { className, attachment } = props

    return (
        <div className={cn(styles.imageAttachment, className)}>
            <Image className={styles.imageAttachment__content} src={attachment.Url} />
            <a
                href={attachment.Url}
                className={styles.imageAttachment__title}
                rel="noopener noreferrer"
                target="_blank"
            >
                {attachment.Name}
            </a>
        </div>
    )
}

export default ImageAttachment
