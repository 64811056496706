import React, { useCallback } from "react"
import "./MenuItemUser.scss"
import { ClassProps } from "../../utility/common/props"
import { User } from "../../models/user"
import { useDispatch, useSelector } from "react-redux"
import cn from "classnames"

import UserCard from "../UserCard/UserCard"
import { useTranslation } from "react-i18next"
import MenuItemRow from "../MenuItemRow/MenuItemRow"
import MenuIcon from "../MenuIcon/MenuIcon"
import SidebarContextMenu from "../SIdebarContextMenu/SidebarContextMenu"
import { actions } from "../../store/notifications/slice"
import translation from "../../locales/notification"
import { selectNewsStackNotifications } from "../../store/notifications/selectors"
import { unsubscribeFromUsersData } from "../../store/userOperator/thunks"
import { redirectToLogout } from "../../utility/auth/credentialHelper"
import { definedMenuItems } from "../../utility/menu/definedMenuItems"

const tNamespace = "project:"
interface Props extends ClassProps {
    user: User
    selectMenu?: (menuItemId: string) => void
    getSelectedMenu: (menuId: string) => boolean
}

const MenuItemUser: React.FC<Props> = props => {
    const { user, selectMenu, getSelectedMenu, className } = props
    const dispatch = useDispatch()
    const newsStack = useSelector(selectNewsStackNotifications)
    const { t } = useTranslation()
    const isUserSelected = getSelectedMenu(definedMenuItems.Profile.id) || getSelectedMenu(definedMenuItems.LogOut.id)
    const memoToggleNotificationList = useCallback(() => dispatch(actions.toggleNotificationsList()), [dispatch])
    const handleLogout = () => {
        dispatch(unsubscribeFromUsersData())
        redirectToLogout()
    }
    return (
        <SidebarContextMenu
            placement="top-end"
            items={
                <>
                    <MenuItemRow
                        icon={<MenuIcon menuId={definedMenuItems.Profile.id} className="menu-item-user__menu-icon" />}
                        title={t(`${tNamespace}${definedMenuItems.Profile.title}`)}
                        onClick={() => selectMenu && selectMenu(definedMenuItems.Profile.id)}
                        selected={getSelectedMenu(definedMenuItems.Profile.id)}
                        testId={definedMenuItems.Profile.id}
                    />
                    <MenuItemRow
                        icon={
                            <MenuIcon
                                menuId={definedMenuItems.NotificationList.id}
                                className="menu-item-user__menu-icon"
                            />
                        }
                        title={t(translation.menuTitle)}
                        onClick={memoToggleNotificationList}
                        testId={definedMenuItems.NotificationList.id}
                    />
                    <MenuItemRow
                        icon={<MenuIcon menuId={definedMenuItems.LogOut.id} className="menu-item-user__menu-icon" />}
                        title={t(`${tNamespace}${definedMenuItems.LogOut.title}`)}
                        onClick={handleLogout}
                        selected={false}
                        testId={definedMenuItems.LogOut.id}
                    />
                </>
            }
            className="menu-item-user__context"
            containerClassName={cn("menu-item-user", className)}
        >
            <UserCard
                className={cn("menu-item-user__card", isUserSelected && "selected")}
                firstName={user.FirstName}
                lastName={user.LastName}
                picture={user.Picture}
                indicator={
                    newsStack.length ? (
                        <div className="menu-item-user__notification-indicator">{newsStack.length}</div>
                    ) : undefined
                }
                isShort
            />
        </SidebarContextMenu>
    )
}

export default MenuItemUser
